import { memo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

const EmailModal = ({
  open,
  item,
  onClose,
}) => (
  <Modal
    title="Email"
    open={open}
    onClose={onClose}
  >
    <div className="email-deliverability-test-modal-wrapper">
      <div className="header">
        <div className="header-item">
          <p>SUBJECT</p>
          <p>{item.subject}</p>
        </div>

        <div className="header-item">
          <p>FROM </p>
          <p>{item.fromEmail}</p>
        </div>
      </div>

      <div className="email-container">
        <div className="email-body-iframe-wrapper">
          <iframe
            title="email-body"
            className="email-body-iframe"
            sandbox="allow-popups allow-popups-to-escape-sandbox"
            srcDoc={`${item.body
              .replaceAll('&lt;', '<')
              .replaceAll('&gt;', '>')
            }
          <style type="text/css">
            .mxr-highlight {
              background-color: rgba(23, 237, 195, 0.5);
            }
          </style>`}
            style={{ height: 400 }}
          />
        </div>
      </div>

      <div className="confirm-buttons">
        <Button color="secondaryButton" onClick={onClose}>Close</Button>
      </div>
    </div>
  </Modal>
);

EmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export default memo(EmailModal);
