import { useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import AuthorizedLayout from 'layouts/authorized';

import { routes } from 'routes';

import { ArrowRightIcon, PlusIcon, IPAddressIcon, ErrorWebIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import Button from 'components/Button';
import Pagination from 'components/Pagination';

import IPMonitorMenu from 'pages/IPMonitor/components/IPMonitorMenu';

import useWindowDimensions from 'hooks/useWindowDimensions';

import IPItem from './components/IPItem';
import AddIPModal from './components/AddIPModal';

import './styles.scss';

const RealTimeMonitor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  const perPage = 100;
  const mobileWidth = width <= 1400;

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);
  const [isAddIPsModalVisible, setIsAddIPsModalVisible] = useState(false);
  const [monitorChecksIntervalId, setMonitorChecksIntervalId] = useState(false);

  const { data: monitor, isLoading: isMonitorLoading, error } = monitorApi.useMonitor(+params.monitorId);
  const {
    data,
    isLoading: isMonitorCheckListLoading,
    isFetching: isMonitorCheckListFetching,
    refetch: refetchMonitorCheckList,
  } = monitorApi.useMonitorCheckList({ id: +params.monitorId, page, perPage });
  const [fetchedChecks, totalItems] = data ? [data.items, data.totalItems] : [null, null];
  const isLoading = isMonitorLoading || isMonitorCheckListLoading;
  const isNotFound = error?.status === 404;

  const isDisabled = monitor?.status === 'disabled';

  const isAnyItemInProcess = items.some((item) => !item.lastCheckFlag);

  useEffect(() => {
    if (fetchedChecks) {
      setItems(fetchedChecks);
    }
  }, [fetchedChecks]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  useEffect(() => {
    if (isAnyItemInProcess && !monitorChecksIntervalId
      && !(isMonitorCheckListFetching || isMonitorCheckListLoading)) {
      const intervalId = setInterval(() => {
        refetchMonitorCheckList();
      }, 5000);
      setMonitorChecksIntervalId(intervalId);
    }

    if (!isAnyItemInProcess && monitorChecksIntervalId) {
      clearInterval(monitorChecksIntervalId);
      setMonitorChecksIntervalId(null);
    }
  }, [isAnyItemInProcess, monitorChecksIntervalId, isMonitorCheckListFetching,
    isMonitorCheckListLoading, refetchMonitorCheckList]);

  const onEditIPName = useCallback(({ id, name }) => {
    setItems(items.map((item) => {
      if (item.id !== id) {
        return item;
      }

      item.name = name;

      return item;
    }));
  }, [items]);

  const navigateToIpMonitorPage = useCallback(() => {
    navigate(routes.ipMonitor.path);
  }, [navigate]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const ipItems = items.map((item) => (
    <IPItem
      key={`${item.id}-${item.name}-${item.lastCheckAt}-${item.lastCheckFlag}`}
      item={item}
      onEditIPName={onEditIPName}
      disabled={isDisabled}
    />
  ));

  return (
    <AuthorizedLayout>
      <AddIPModal
        open={isAddIPsModalVisible}
        onClose={() => setIsAddIPsModalVisible(false)}
        monitorId={+params.monitorId}
      />

      <div className="real-time-monitor-wrapper">
        <div>
          <div className="title">
            <div className="name-wrapper">
              <p>{isNotFound ? 'Monitor Not Found' : monitor?.title}</p>

              {isDisabled && (
              <Chip
                label="Disabled"
                variant="outlined"
                color="secondary"
                style={{ height: 24 }}
              />
              )}
            </div>

            {monitor && (
            <div className="title-actions">
              <Tooltip title={isDisabled ? 'You can’t add IPs to a disabled monitor' : ''} placement="top">
                <div>
                  <Button
                    className="add-ip-button"
                    startIcon={<PlusIcon />}
                    iconClassName="button-icon"
                    onClick={() => setIsAddIPsModalVisible(true)}
                    disabled={isDisabled}
                  >
                    {width >= 500 ? 'Add IP' : ''}
                  </Button>
                </div>
              </Tooltip>

              {items && (
              <IPMonitorMenu
                id={monitor.id}
                name={monitor.title}
                enabled={monitor.status === 'active'}
                ipList={items.map((checkItem) => ({
                  id: checkItem.id,
                  ipAddress: checkItem.item,
                  lastCheckFlag: checkItem.lastCheckFlag,
                  success: checkItem.success,
                }))}
                scheduleSeconds={monitor.scheduleSeconds}
                notificationId={+monitor.notification.replace('/v2/monitor-notifications/', '')}
                onDeleted={navigateToIpMonitorPage}
              />
              )}
            </div>
            )}
          </div>

          {width >= 500 && (
          <div className="breadcrumbs">
            <p onClick={navigateToIpMonitorPage} style={{ cursor: 'pointer' }} aria-hidden>
              IP Monitor
            </p>
            <ArrowRightIcon />
            <p>Real-time monitor</p>
          </div>
          )}
        </div>

        {!isLoading && !!items?.length && (
          <>
            {!mobileWidth && (
            <div className="ip-list">
              {isMonitorCheckListFetching && <CircularProgress className="table-loader" />}

              <div className="table-head">
                <div>
                  <p className="ip-address-head">IP Address</p>
                  <p className="name-head">Name</p>
                </div>
                <p className="country-head">Country</p>
                <p className="isp-head">ISP</p>
                <p className="last-scan-head">Last Scan</p>
                <p className="status-head">Status</p>
              </div>

              <div className="table-head-line" />

              {ipItems}

              {totalItems > items?.length && (
              <Pagination
                className="table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
              )}
            </div>
            )}

            {mobileWidth && (
            <div className="mobile-ip-list">
              {ipItems}

              {totalItems > items?.length && (
              <Pagination
                className="mobile-table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
              )}
            </div>
            )}
          </>
        )}

        {!isLoading && (!fetchedChecks?.length && page === 1) && (
          <div className="empty-state">
            {error ? <ErrorWebIcon /> : <IPAddressIcon /> }

            <p className="title">{error ? 'Monitor Not Found' : 'There are no IPs in this Monitor'}</p>

            {!error && (
            <Button
              className="add-ip-to-monitor-button"
              startIcon={<PlusIcon />}
              iconClassName="button-icon"
              onClick={() => setIsAddIPsModalVisible(true)}
            >
              Add IP to Monitor
            </Button>
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default RealTimeMonitor;
