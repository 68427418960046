import Pusher from 'pusher-js';

import queryClient from 'query-client';

import uiNotificationService from 'services/uiNotificatuion.service';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

const connect = ({ userId }) => {
  if (!['initialized', 'connecting', 'connected'].includes(pusher.connection.state)) {
    pusher.connect();
  }

  // Subscrube to channels
  const channel = pusher.subscribe(`user-${userId}`);

  channel.bind('infrastructure-monitor-processed', async (data) => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: ['monitor.list', { type: 'infrastructure' }],
      }),
      queryClient.invalidateQueries({
        queryKey: ['connectivity.list'],
      }),
    ]);

    uiNotificationService.showSuccess(data.message);
  });

  channel.bind('mx-risk-email-processed', (data) => {
    queryClient.invalidateQueries({
      queryKey: ['mxrisk.emailGroups'],
    });

    uiNotificationService.showSuccess(data.message);
  });

  channel.bind('ip-monitor-processed', async (data) => {
    const monitorId = +data.updateUrl.replace('/v2/monitors/', '');

    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: ['monitor', monitorId],
      }),
      queryClient.invalidateQueries({
        queryKey: ['monitor.checkList', monitorId],
      }),
    ]);

    uiNotificationService.showSuccess(data.message);
  });

  [
    'check-in-email-pending',
    'check-in-email-paused',
    'check-in-email-failed',
    'check-in-email-processed',
  ].forEach((eventName) => {
    channel.bind(eventName, (data) => {
      queryClient.invalidateQueries({
        queryKey: ['connectivity.list'],
      });

      if (eventName === 'check-in-email-failed') {
        uiNotificationService.showError(data.message);
      } else {
        uiNotificationService.showSuccess(data.message);
      }
    });
  });

  channel.bind('mx-risk-template-processed', (data) => {
    const groupId = +data.updateUrl.replace('/v2/mx-risk/template-groups/', '');

    queryClient.invalidateQueries({
      queryKey: ['mxrisk.emailGroupItems', groupId],
    });

    uiNotificationService.showSuccess(data.message);
  });

  [
    'placement-email-matched',
    'placement-test-finished',
  ].forEach((eventName) => {
    channel.bind(eventName, (data) => {
      queryClient.invalidateQueries({
        queryKey: ['placement.list'],
      });

      if (eventName !== 'placement-email-matched') {
        uiNotificationService.showSuccess(data.message);
      }
    });
  });
};

const disconnect = () => {
  pusher.disconnect();
};

export {
  connect,
  disconnect,
};
