import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faClone, faComma, faFileArrowDown, faListUl, faSemicolon } from '@fortawesome/pro-regular-svg-icons';

import { Menu } from '@mui/material';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const SendYourEmailModal = ({
  open,
  onClose,
  emails,
}) => {
  const [copyToClipboardMenu, setCopyToClipboardMenu] = useState(null);

  const onDownloadCSV = useCallback(() => {
    const csvContent = `data:text/csv;charset=utf-8,Emails\n${emails.join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'seed-list.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  }, [emails]);

  const onCopyAsComaSeparated = useCallback(() => {
    navigator.clipboard.writeText(emails.join(', '));

    uiNotificationService.showSuccess('Copied to clipboard');

    setCopyToClipboardMenu(null);
  }, [emails]);

  const onCopyAsSemicolonSeparated = useCallback(() => {
    navigator.clipboard.writeText(emails.join('; '));

    uiNotificationService.showSuccess('Copied to clipboard');

    setCopyToClipboardMenu(null);
  }, [emails]);

  const onCopyAsOnePerLine = useCallback(() => {
    navigator.clipboard.writeText(emails.join('\n'));

    uiNotificationService.showSuccess('Copied to clipboard');

    setCopyToClipboardMenu(null);
  }, [emails]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Send Your Email"
    >
      <div className="send-your-email-modal-wrapper">
        <div className="email-list-input-wrapper">
          <p>Send your email to the following list of addresses (seed list)</p>
          <Input
            value={emails.join(', ')}
            multiline
            rows={7}
            readOnly
            className="email-list-input"
          />
        </div>

        <div className="list-controls">
          <Button
            className="copy-csv-button"
            startIcon={<FontAwesomeIcon icon={faFileArrowDown} width={20} height={20} />}
            iconClassName="copy-icon"
            variant="outlined"
            onClick={onDownloadCSV}
          >
            Download as CSV
          </Button>

          <Button
            onClick={(e) => setCopyToClipboardMenu(e.currentTarget)}
            variant="outlined"
            className="copy-to-clipboard-button"
            iconClassName="copy-icon"
          >
            <div className="copy-as-select-wrapper">
              <FontAwesomeIcon icon={faClone} width={20} height={20} />
              <p>
                Copy to Clipboard as
              </p>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </Button>
          <Menu
            open={!!copyToClipboardMenu}
            onClose={() => setCopyToClipboardMenu(null)}
            anchorEl={copyToClipboardMenu}
          >
            <div className="copy-to-clipboard-menu-wrapper">
              <div onClick={onCopyAsComaSeparated} aria-hidden>
                <FontAwesomeIcon icon={faComma} />
                <p>Comma Separated</p>
              </div>

              <div onClick={onCopyAsSemicolonSeparated} aria-hidden>
                <FontAwesomeIcon icon={faSemicolon} />
                <p>Semicolon Separated</p>
              </div>

              <div onClick={onCopyAsOnePerLine} aria-hidden>
                <FontAwesomeIcon icon={faListUl} />
                <p>One-per-Line</p>
              </div>
            </div>
          </Menu>
        </div>

        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

SendYourEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(SendYourEmailModal);
