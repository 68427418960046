import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FormControlLabel, FormHelperText, Switch, Tooltip, Link, Menu } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';

import { HtmlEmbedIcon } from 'assets/icons';

import { connectivityApi } from 'resources/connectivity';
import { mxriskApi } from 'resources/mxrisk';

import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import Editor from 'components/Editor';
import InsertHtmlModal from 'pages/TemplateMXRisk/components/InsertHtmlModal';

import { routes } from 'routes';

import useElementExists from 'hooks/useElementExists';

import './styles.scss';

const selectItems = [
  {
    label: '7 days',
    value: 604800,
  },
];

const ConnectedInboxForm = ({ onChangeData, testData, errors }) => {
  const isEditorSourceMode = useElementExists('.ck-source-editing-area');

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isHtmlEmbedModalVisible, setIsHtmlEmbedModalVisible] = useState(false);
  const [importFromTemplatesMenu, setImportFromTemplatesMenu] = useState(null);

  const { data: emailsData, isLoading: isEmailsLoading } = connectivityApi.useConnectivityEmailAccounts({
    page: 1,
    perPage: 100,
    pagination: false,
    accountStatus: 'active',
  });
  const {
    data: mxriskValidationItems,
    isLoading: isMXRiskValidationItemsLoading,
  } = mxriskApi.useMXRiskValidationItems();

  const emails = useMemo(() => emailsData?.items || [], [emailsData]);

  const emailItems = useMemo(() => emails.map((email) => ({
    value: `/v2/connectivity/email-accounts/${email.id}`,
    label: email.fromEmail,
  })), [emails]);

  useEffect(() => {
    if (isEditorReady) {
      const target = document.querySelector('.ck-toolbar__items');

      if (target) {
        const el = document.createElement('div');
        ReactDOM.render(
          <div
            className={cn('html-embed-button-wrapper', isEditorSourceMode && 'html-embed-button-wrapper-disabled')}
            onClick={() => {
              if (!isEditorSourceMode) {
                setIsHtmlEmbedModalVisible(true);
              }
            }}
            aria-hidden
          >
            <HtmlEmbedIcon width={18} />
          </div>,
          el,
        );
        target.appendChild(el);

        // Optional: Clean up on component unmount
        return () => {
          ReactDOM.unmountComponentAtNode(el);
          el.remove();
        };
      }
    }
  }, [isEditorReady, isEditorSourceMode]);

  const onChangeEditor = useCallback((event, editor) => {
    const data = editor.getData();

    onChangeData(data, 'body');
  }, [onChangeData]);

  const onChangeSwitch = useCallback((e) => {
    onChangeData(e.target.checked, 'recurring');
  }, [onChangeData]);

  const onImportFromTemaplte = useCallback((item) => {
    onChangeData(item.subjectTemplate || '', 'subject');
    onChangeData(item.template || '', 'body');

    setImportFromTemplatesMenu(null);
  }, [onChangeData]);

  const isNoActiveEmailAccounts = !emailItems.length && !isEmailsLoading;

  return (
    <>
      {isHtmlEmbedModalVisible && (
      <InsertHtmlModal
        open={isHtmlEmbedModalVisible}
        onClose={() => setIsHtmlEmbedModalVisible(false)}
        onConfirm={(html) => {
          onChangeData(html, 'body');
          setIsHtmlEmbedModalVisible(false);
        }}
      />
      )}

      <div className="deliverability-test-form-email-select-wrapper">
        <Tooltip
          title={isNoActiveEmailAccounts ? 'You need to have an active email account to send a test email' : ''}
          placement="top"
        >
          <Select
            value={testData.emailAccount}
            name="emailAccount"
            placeholder="Select Email Account"
            onChange={onChangeData}
            error={!!errors.emailAccount}
            helperText={errors.emailAccount}
            items={emailItems}
            type="filled"
            disabled={isNoActiveEmailAccounts}
            renderValue={(renderValue) => (
              <p>
                {emailItems.find((selectItem) => selectItem.value === renderValue).label}
              </p>
            )}
          />
        </Tooltip>

        {isNoActiveEmailAccounts && (
        <p className="no-active-email-accounts-text">
          To send a test email, you need to have an active email account.
          You can create one on the
          {' '}
          <Link
            href={routes.emailAccounts.path}
            underline="hover"
            color="#17EDC3"
          >
            Email Accounts
          </Link>
          {' '}
          page.
        </p>
        )}
      </div>

      <div className="deliverability-test-form-subject-input-wrapper">
        <Input
          label="Subject"
          name="subject"
          error={!!errors.subject}
          helperText={errors.subject}
          onChange={onChangeData}
          value={testData.subject}
        />

        <div>
          <Button
            onClick={(e) => setImportFromTemplatesMenu(e.currentTarget)}
            className="import-template-button"
            iconClassName="import-icon"
            variant="subtle"
            startIcon={(
              <FontAwesomeIcon
                icon={faFileImport}
                width={20}
                {...isMXRiskValidationItemsLoading && { color: 'rgba(255, 255, 255, 0.3)' }}
              />
            )}
            disabled={isMXRiskValidationItemsLoading}
          >
            Import Template
          </Button>
          <Menu
            open={!!importFromTemplatesMenu}
            onClose={() => setImportFromTemplatesMenu(null)}
            anchorEl={importFromTemplatesMenu}
            PaperProps={{
              style: {
                maxHeight: '400px',
              },
            }}
          >
            <div className="import-template-menu-wrapper">
              {(mxriskValidationItems || [])
                .filter((item) => item.status === 'ready')
                .map((item) => (
                  <div key={item.id} onClick={() => onImportFromTemaplte(item)} aria-hidden>
                    <p>{item.name || 'Unknown'}</p>
                  </div>
                ))}
            </div>
          </Menu>
        </div>
      </div>

      <div
        style={{ marginBottom: errors.body ? 0 : '24px' }}
        className={cn('ck-editor-wrapper', !!errors.body && 'ck-editor-error')}
      >
        <Editor
          data={testData.body}
          onChange={onChangeEditor}
          onReady={() => setIsEditorReady(true)}
        />
      </div>
      {!!errors.body && <FormHelperText className="input-error-text">{errors.body}</FormHelperText>}

      <FormControlLabel
        className="deliverability-test-form-toggle"
        control={(
          <Switch
            checked={testData.recurring}
            onChange={onChangeSwitch}
          />
        )}
        label="Is Recurring"
      />

      <div className="deliverability-test-form-frequency-select-wrapper">
        <Select
          value={testData.scheduleSeconds || ''}
          name="scheduleSeconds"
          placeholder="Testing Frequency"
          error={!!errors.scheduleSeconds}
          helperText={errors.scheduleSeconds}
          onChange={onChangeData}
          disabled={!testData.recurring}
          items={selectItems}
          type="filled"
          renderValue={(renderValue) => (
            <p>
              {selectItems
                .find((selectItem) => selectItem.value === renderValue).label}
            </p>
          )}
        />
      </div>
    </>
  );
};

ConnectedInboxForm.propTypes = {
  testData: PropTypes.shape({
    emailAccount: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    recurring: PropTypes.bool.isRequired,
    scheduleSeconds: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  }).isRequired,
  onChangeData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    emailAccount: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    scheduleSeconds: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(ConnectedInboxForm);
