import { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { userApi } from 'resources/user';

import UnauthorizedLayout from 'layouts/unauthorized';

import PartyingFace from 'assets/illustrations/partying-face.png';

import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Button from 'components/Button';

import './styles.scss';

const Terms = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [checked, setChecked] = useState(false);

  const {
    mutate: confirmEmail,
    isLoading: isEmailConfirmationLoading,
  } = userApi.useConfirmRegistration();
  const { mutate: getUser, isLoading: isUserLoading } = userApi.useUser();
  const isLoading = isEmailConfirmationLoading || isUserLoading;

  const onCheckboxHandle = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const onCompleteSignUp = useCallback(() => {
    confirmEmail({
      agreeToTerms: checked,
      ...(location.state?.confirmationToken && { token: location.state.confirmationToken }),
    }, {
      onSuccess: () => {
        const userId = localStorage.getItem('userId');

        getUser(userId);
      },
    });
  }, [checked, location.state?.confirmationToken, confirmEmail, getUser]);

  const onLogout = useCallback(() => {
    localStorage.clear();

    navigate(routes.signIn.path);
  }, [navigate]);

  return (
    <UnauthorizedLayout>
      <div className="terms-wrapper">
        <img src={PartyingFace} alt="congrats" />

        <p>Thanks for joining MxRight</p>

        <p className="text">
          Start gathering insights and contact info for any website!
        </p>

        <Box className="checkbox-container" onClick={onCheckboxHandle}>
          <Checkbox checked={checked} />

          <p>
            I read and agree to the
            {' '}
            <Link
              href="https://mxright.com/terms/"
              underline="none"
              sx={{ color: '#17EDC3' }}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Terms of Service
            </Link>
            {' '}
            and
            {' '}
            <Link
              href="https://mxright.com/privacy-policy/"
              underline="none"
              sx={{ color: '#17EDC3' }}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Privacy Policy
            </Link>
            {' '}
            of this service.
          </p>
        </Box>

        <Button
          onClick={onCompleteSignUp}
          disabled={!checked}
          loading={isLoading}
        >
          Complete Sign Up
        </Button>

        <Button
          className="logout"
          onClick={onLogout}
          variant="text"
          color="secondary"
          disabled={isLoading}
        >
          Logout
        </Button>
      </div>
    </UnauthorizedLayout>
  );
};

export default Terms;
