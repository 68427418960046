import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { CircleAvatarIcon } from 'assets/icons';

import Button from 'components/Button';

import './styles.scss';

const CreateAccountHeader = ({
  title,
}) => {
  const navigate = useNavigate();

  const onNavigateToSignUp = useCallback(() => {
    navigate(routes.signUp.path);
  }, [navigate]);

  const onNavigateToLogin = useCallback(() => {
    navigate(routes.signIn.path);
  }, [navigate]);

  return (
    <div className="create-account-wrapper">
      <div>
        <CircleAvatarIcon />
        <p>{title}</p>
      </div>

      <div>
        <Button onClick={onNavigateToSignUp}>Create a Free Account</Button>
        <Button onClick={onNavigateToLogin} variant="outlined">Login</Button>
      </div>
    </div>
  );
};

CreateAccountHeader.propTypes = {
  title: PropTypes.string,
};

CreateAccountHeader.defaultProps = {
  title: '',
};

export default React.memo(CreateAccountHeader);
