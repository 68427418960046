import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

const DeleteModal = ({
  title,
  open,
  onClose,
  onDelete,
  className,
  isDeleteLoading,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    title={(
      <div className="delete-modal-title-wrapper">
        <div className="title-icon-wrapper">
          <FontAwesomeIcon icon={faTrashCan} color="#E6738E" size="lg" />
        </div>

        <p>{title}</p>
      </div>
    )}
  >
    <div className={cn('delete-modal-wrapper', className)}>

      <div className="confirm-buttons">
        <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
        <Button color="error" onClick={onDelete} loading={isDeleteLoading}>Delete</Button>
      </div>
    </div>
  </Modal>
);

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDeleteLoading: PropTypes.bool,
};

DeleteModal.defaultProps = {
  className: undefined,
  isDeleteLoading: false,
};

export default React.memo(DeleteModal);
