import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { SmallOpenedEyeIcon, TrashIcon, UnevenListIcon, DotsIcon } from 'assets/icons';

import { routes } from 'routes';

import { mxriskApi } from 'resources/mxrisk';

import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import DeleteModal from 'components/DeleteModal';
import MXRiskSpeedometer from 'components/MXRiskSpeedometer';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { getSpeedometerLabelByScore, getRuleDataBySentiment } from 'resources/mxrisk/mxrisk.helpers';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const HistoryItem = ({ item }) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [anchorEl, setAnchorEl] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const readabilityScore = item.mxrReadabilityRules.find((rule) => rule.name === 'Readability Score');

  const isError = item.status === 'error';

  const mobileWidth = width <= 1200;

  const speedometerLabel = isError
    ? {
      label: 'Error',
      color: '#F02B4F',
    }
    : getSpeedometerLabelByScore(item.score);

  const {
    mutate: deleteEmailGroupItem,
    isLoading: isDeleteEmailGroupItemLoading,
  } = mxriskApi.useDeleteEmailGroupItem();

  const onDeleteClick = useCallback((e) => {
    e.stopPropagation();

    setIsDeleteModalVisible(true);
  }, []);

  const onDelete = useCallback(() => {
    deleteEmailGroupItem(item.id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('History item has been deleted!');

        setIsDeleteModalVisible(false);
      },
    });
  }, [deleteEmailGroupItem, item.id]);

  const onOpenClick = useCallback((e) => {
    e.stopPropagation();

    navigate(routes.emailMXRiskResult.path.replace(':id', item.id));
  }, [navigate, item.id]);

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className="email-item-row-container" onClick={onOpenClick} aria-hidden>
      <DeleteModal
        title={`Are you sure you want to delete MX Risk scan from ${moment(item.createdAt).format(('MMM DD, YYYY h:mmA'))}?`}
        open={isDeleteModalVisible}
        onClose={(e) => { e.stopPropagation(); setIsDeleteModalVisible(false); }}
        onDelete={(e) => { e.stopPropagation(); onDelete(); }}
        isDeleteLoading={isDeleteEmailGroupItemLoading}
      />

      {!mobileWidth && (
      <div className="email-item-row">
        <div className="subject">
          <div className="list-icon-wrapper">
            <UnevenListIcon />
          </div>
          <p>{item.subject}</p>
        </div>

        <div className="mx-risk">
          <div className="speedometer-container">
            <MXRiskSpeedometer size="tiny" score={item.score} reversed loading={isError} />
          </div>
          <p>{speedometerLabel.label}</p>
        </div>

        <div className="readability-container">
          <p
            style={{ color: readabilityScore ? getRuleDataBySentiment(readabilityScore).color : null }}
          >
            {readabilityScore?.value || '-'}
          </p>
        </div>

        <div className="sentiment-container">
          {readabilityScore ? getRuleDataBySentiment(readabilityScore)?.icon : '-'}
        </div>

        <div className="check-date-container">
          <p>{moment(item.createdAt).format(('MMM DD, YYYY h:mmA'))}</p>
        </div>

        <div className="item-actions">
          <Tooltip onClick={onOpenClick} title="Open" placement="top">
            <div className="icon-wrapper">
              <SmallOpenedEyeIcon />
            </div>
          </Tooltip>
          <Tooltip onClick={onDeleteClick} title="Delete" placement="top" disabled={isDeleteEmailGroupItemLoading}>
            <div className="icon-wrapper">
              <TrashIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      )}

      {mobileWidth && (
      <div className="mobile-row">
        <div className="speedometer-and-info">
          <div className="speedometer-container">
            <MXRiskSpeedometer size="tiny" score={item.score} reversed loading={isError} />
          </div>

          <div className="mobile-item-info">
            <p>{item.subject}</p>
            <p>{moment(item.createdAt).format(('MMM DD, YYYY h:mmA'))}</p>
            <p><span>MX Risk:</span> {speedometerLabel.label}</p>

            <div>
              <p
                style={{ color: readabilityScore ? getRuleDataBySentiment(readabilityScore).color : null }}
              >
                <span>Readability:</span> {readabilityScore?.value || '-'}
              </p>
              <p
                style={{ display: 'flex', alignItems: 'center', gap: 4 }}
              >
                <span>SpamAssasin:</span> {readabilityScore ? getRuleDataBySentiment(readabilityScore)?.icon : '-'}
              </p>
            </div>
          </div>
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className={cn('menu-wrapper', anchorEl && 'menu-wrapper-active')}
          aria-hidden
        >
          <IconButton onClick={openMenu}>
            <DotsIcon />
          </IconButton>

          <Menu
            open={!!anchorEl}
            onClose={closeMenu}
            anchorEl={anchorEl}
          >
            <MenuItem
              onClick={onOpenClick}
              className="menu-item"
            >
              <SmallOpenedEyeIcon />
              <p>View</p>
            </MenuItem>
            <MenuItem
              onClick={onDeleteClick}
              className="menu-item"
              disabled={isDeleteEmailGroupItemLoading}
            >
              <TrashIcon />
              <p>Delete</p>
            </MenuItem>
          </Menu>
        </div>
      </div>
      )}

      {!mobileWidth && <div className="line" />}
    </div>
  );
};

HistoryItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default HistoryItem;
