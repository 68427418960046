import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import Button from 'components/Button';
import Input from 'components/Input';

import './styles.scss';

const InsertHtmlModal = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [text, setText] = useState('');

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Insert HTML"
    >
      <div className="insert-html-modal-wrapper">
        <Input
          label="Insert HTML"
          value={text}
          onChange={setText}
          multiline
          rows={7}
        />

        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
          <Button onClick={() => onConfirm(text)} disabled={!text}>Insert</Button>
        </div>
      </div>
    </Modal>
  );
};

InsertHtmlModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default React.memo(InsertHtmlModal);
