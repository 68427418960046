import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import cn from 'classnames';
import SvgGauge from 'svg-gauge';

import { createStyles, keyframes } from '@mantine/styles';

import {
  SpeedometerHandIcon, BigSpeedometerHandIcon,
  BigSpeedometerHandScaleIcon, MediumSpeedometerHandIcon,
  TinySpeedometerHandIcon,
} from 'assets/icons';

import { addStyle, removeStyle } from 'helpers/addStyles';

import './styles.scss';

const getRotateAnimation = (degValue) => keyframes({
  '100%': { transform: `rotateZ(${degValue}deg)` },
});

const useStyles = createStyles((_, { degValue }) => ({
  scoreClassName: {
    animation: `${getRotateAnimation(degValue)} 1s 1 ease-out`,
    '-webkit-animation-fill-mode': 'forwards', /* Chrome 16+, Safari 4+ */
    '-moz-animation-fill-mode': 'forwards', /* FF 5+ */
    '-o-animation-fill-mode': 'forwards', /* Not implemented yet */
    '-ms-animation-fill-mode': 'forwards', /* IE 10+ */
    'animation-fill-mode': 'forwards',
  },
}));

const getGradientsByScore = ({ score, reversed, loading }) => {
  const goodGradients = ['#17EDC3', '#86F0DB'];
  const badGradients = ['#F02B4F', '#F06780'];
  const mediumGradients = ['#FFD555', '#FFE494'];
  const neutralGradients = ['#444861', '#444861'];

  if (typeof score !== 'number' || loading) {
    return neutralGradients;
  }

  if (score >= 60) {
    return reversed ? badGradients : goodGradients;
  }

  if (score >= 24) {
    return mediumGradients;
  }

  return reversed ? goodGradients : badGradients;
};

const getDataByScore = ({ score, loading }) => {
  if (typeof score !== 'number' || loading) {
    return {
      value: 70,
    };
  }

  if (score >= 100) {
    return {
      value: 100,
      degValue: 70,
    };
  }

  if (score >= 90) {
    return {
      value: 90,
      degValue: 60,
    };
  }

  if (score >= 80) {
    return {
      value: 80,
      degValue: 44,
    };
  }

  if (score >= 70) {
    return {
      value: 70,
      degValue: 28,
    };
  }

  if (score >= 60) {
    return {
      value: 60,
      degValue: 12,
    };
  }

  if (score >= 50) {
    return {
      value: 50,
      degValue: -2,
    };
  }

  if (score >= 40) {
    return {
      value: 40,
      degValue: -20,
    };
  }

  if (score >= 30) {
    return {
      value: 30,
      degValue: -35,
    };
  }

  if (score >= 20) {
    return {
      value: 20,
      degValue: -50,
    };
  }

  if (score >= 10) {
    return {
      value: 10,
      degValue: -66,
    };
  }

  if (score >= 0) {
    return {
      value: 0,
      degValue: -75,
    };
  }
};

const getSpeedometerHandIconBySize = (size) => {
  if (size === 'giant') {
    return BigSpeedometerHandIcon;
  }

  if (size === 'large') {
    return BigSpeedometerHandIcon;
  }

  if (size === 'medium') {
    return MediumSpeedometerHandIcon;
  }

  if (size === 'small') {
    return SpeedometerHandIcon;
  }

  if (size === 'tiny') {
    return TinySpeedometerHandIcon;
  }

  return null;
};

// eslint-disable-next-line react/prop-types
const Gradient = ({ gradients, id }) => {
  useEffect(() => {
    const styleId = addStyle(`
      .gauge-container-${id} .value {
        stroke: url(#${id});
      }
    `);

    return () => {
      removeStyle(styleId);
    };
  }, [id]);

  return (
    <svg className="IDSAIOFASOIDFHAOSDF">
      <defs key={id} className="IDSAIOFASOIDFHAOSDF">
        <linearGradient
          id={id}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          className="IDSAIOFASOIDFHAOSDF"
        >
          <stop offset="0%" stopColor={gradients[0]} />
          <stop offset="100%" stopColor={gradients[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

const MXRiskSpeedometer = ({ size, score, reversed, loading, isOnlyArc, providedGradients, isRounded }) => {
  const id = nextId();

  const gaugeEl = useRef(null);
  const gaugeRef = useRef(null);

  const { value, degValue } = getDataByScore({ score, loading });
  const gradients = providedGradients || getGradientsByScore({ score, reversed, loading });

  const isLarge = size === 'large';

  useEffect(() => {
    if (!gaugeRef.current) {
      gaugeRef.current = SvgGauge(gaugeEl.current, {
        min: 0,
        max: 100,
        dialStartAngle: isRounded ? 180 : 200,
        dialEndAngle: isRounded ? 0 : -20,
        value: 0,
        showValue: false,
      });

      gaugeRef.current.setValue(0);
    }

    gaugeRef.current.setValueAnimated(value, 1);
  }, [value, isRounded]);

  const { classes } = useStyles({ degValue });

  const speedometerHandIconStyles = cn(
    'speedometer-hand',
    `speedometer-hand-${size}`,
    classes.scoreClassName,
  );

  const SpeedometerHand = getSpeedometerHandIconBySize(size);

  return (
    <div className="mxrisk-speedometer-wrapper">
      <div style={{ visibility: 'hidden', height: 0, width: 0 }}>
        <Gradient gradients={gradients} id={id} />
      </div>

      <div
        ref={gaugeEl}
        className={cn(
          'gauge-container',
          `gauge-container-${id}`,
          `gauge-container-${size}`,
        )}
      />

      {!isOnlyArc && (
        <> {isLarge
          ? (
            <div>
              <BigSpeedometerHandScaleIcon style={{ position: 'absolute', top: -17, left: -14 }} />
              <SpeedometerHand className={speedometerHandIconStyles} />
            </div>
          )
          : <SpeedometerHand className={speedometerHandIconStyles} />}
        </>
      )}
    </div>
  );
};

MXRiskSpeedometer.propTypes = {
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'giant']),
  score: PropTypes.number, // From 0 to 10
  reversed: PropTypes.bool,
  loading: PropTypes.bool,
  isOnlyArc: PropTypes.bool,
  isRounded: PropTypes.bool,
  providedGradients: PropTypes.arrayOf(PropTypes.string.isRequired),
};

MXRiskSpeedometer.defaultProps = {
  size: 'small',
  score: null,
  reversed: false,
  loading: false,
  isOnlyArc: false,
  isRounded: false,
  providedGradients: null,
};

export default React.memo(MXRiskSpeedometer);
