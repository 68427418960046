import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';

import formatNumber from 'helpers/formatNumber';

const MXRiskTemplateChecks = ({
  data,
  loading,
  onRefetch,
  isError,
}) => {
  const countDiff = data ? (data.current - data.previous) : 0;
  const isSmaller = countDiff < 0;

  return (
    <div className={cn('item', 'count-item')}>
      {loading && !isError && <CircularProgress className="loader" />}

      {isError && (
      <div className="item-error">
        <p>Something went wrong</p>

        <Button onClick={onRefetch}>Refresh</Button>
      </div>
      )}

      {!loading && !isError && (
      <>
        <div className="icon-wrapper">
          <FontAwesomeIcon
            icon={faEnvelopeOpenText}
            color="#17EDC3"
            size="lg"
          />
        </div>

        <p>Template Checks</p>

        <div className="count">
          <p>{formatNumber(data.current)}</p>

          <Chip
            className={cn('count-chip', isSmaller && 'count-chip-error')}
            label={formatNumber(Math.abs(countDiff))}
            icon={(
              <FontAwesomeIcon
                icon={!isSmaller ? faArrowUp : faArrowDown}
                color={!isSmaller ? '#17EDC3' : '#F02B4F'}
                size="sm"
              />
            )}
            style={{ border: 'unset' }}
          />
        </div>

        <div className="items-count">
          <p>{data.templatesCount}</p>
          <p>template{data.templatesCount === 1 ? '' : 's'}</p>
        </div>
      </>
      )}
    </div>
  );
};

MXRiskTemplateChecks.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  onRefetch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

MXRiskTemplateChecks.defaultProps = {
  data: null,
};

export default React.memo(MXRiskTemplateChecks);
