import React from 'react';
import ReactCoockieConsent from 'react-cookie-consent';

import Button from 'components/Button';

import './styles.scss';

const CookieConsent = () => (
  <ReactCoockieConsent
    buttonText="Got it!"
    ButtonComponent={Button}
    contentClasses="cookie-consent-wrapper"
    style={{
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #0288D1',
      padding: '10px 24px',
    }}
    buttonClasses="cookie-button"
  >
    <p>This website uses cookies to ensure you get the best experience on our website.</p>
    <a href="https://www.cookiesandyou.com" target="_blank" rel="noopener noreferrer"><p>Learn more</p></a>
  </ReactCoockieConsent>
);

export default React.memo(CookieConsent);
