import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';

import { userApi } from 'resources/user';

import { ErrorIcon } from 'assets/icons';

import CircularProgress from '@mui/material/CircularProgress';

import './styles.scss';

const NotFound = () => {
  const navigate = useNavigate();

  const {
    isFetching,
  } = userApi.useCurrentUser();

  if (isFetching) {
    return <div className="not-found-wrapper"><CircularProgress className="loader" /></div>;
  }

  return (
    <div className="not-found-wrapper">
      <div className="error-placeholder">
        <ErrorIcon />

        <p>Something went wrong</p>

        <p>
          The page you are looking doesn’t exist or an other error occurred.
          <span onClick={() => navigate(routes.dashboard.path)} aria-hidden> Go to home page</span>
        </p>

      </div>
    </div>
  );
};

export default NotFound;
