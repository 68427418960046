import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  EmailMXRiskIcon, CupOfTeaIcon, TurnOffIcon, TelegramIcon,
} from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import { routes } from 'routes';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import MXRiskSpeedometer from 'components/MXRiskSpeedometer';
import Button from 'components/Button';

import { getSpeedometerLabelByScore, getRuleDataBySentiment, getImageBySender } from 'resources/mxrisk/mxrisk.helpers';

import useWindowDimensions from 'hooks/useWindowDimensions';

import uiNotificationService from 'services/uiNotificatuion.service';

import GroupItemMenu from './components/GroupItemMenu';

import './styles.scss';

const GroupItem = ({ item, onDeleted }) => {
  const navigate = useNavigate();

  const { mutate: updateEmailGroup, isLoading: isUpdateEmailGroupLoading } = mxriskApi.useUpdateEmailGroup();
  const { mutate: updateValidationItem, isLoading: isUpdateValidationItemLoading } = mxriskApi.useUpdateValidationItem();

  const speedometerLabel = getSpeedometerLabelByScore(item.lastItem?.score);
  const image = getImageBySender(item.lastItem?.sender);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 900;

  const itemId = item['@id'].replace('/v2/mx-risk/email-groups/', '');

  const isWaiting = !item.lastItem;
  const isDisabled = !item.active;
  const isNew = item.lastItem?.new;

  const overallReadabilityScore = item.lastItem?.mxrReadabilityRules
    .find((rule) => rule.name === 'Readability Score');
  const readingEase = item.lastItem?.mxrReadabilityRules.find((rule) => rule.name === 'Reading Ease');
  const readingGradeLevel = item.lastItem?.mxrReadabilityRules.find((rule) => rule.name === 'Reading Level');
  const wordCount = item.lastItem?.mxrReadabilityRules.find((rule) => rule.name === 'Word Count');
  let readingTime = item.lastItem?.mxrReadabilityRules.find((rule) => rule.name === 'Reading Time');

  if (readingTime) {
    readingTime = {
      ...readingTime,
      value: Math.ceil(readingTime.value),
    };
  }

  const onCopyGeneratedEmail = useCallback(() => {
    navigator.clipboard.writeText(item.emailTo);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [item.emailTo]);

  const onActivateGroupClick = useCallback(() => {
    if (isUpdateEmailGroupLoading) {
      return;
    }

    updateEmailGroup({
      id: itemId,
      active: !item.active,
    });
  }, [isUpdateEmailGroupLoading, item.active, updateEmailGroup, itemId]);

  const onViewReport = useCallback(() => {
    updateValidationItem({
      id: item.lastItem.id,
      new: false,
    });

    navigate(routes.emailMXRiskResult.path.replace(':id', item.lastItem.id));
  }, [navigate, item.lastItem?.id, updateValidationItem]);

  return (
    <div className="group-item">
      <div className="group-title">
        <div>
          <EmailMXRiskIcon width={20} height={20} />

          <Tooltip title={item.name} placement="bottom">
            <p>{item.name}</p>
          </Tooltip>
        </div>

        <GroupItemMenu item={item} onDeleted={onDeleted} />
      </div>

      {!isWaiting && !isDisabled && (
      <div className="group-body">
        <div>
          <div className="speedometer-wrapper">
            <MXRiskSpeedometer size="medium" score={item.lastItem.score} reversed />

            <p
              className="speedometer-label"
              style={{ color: speedometerLabel.color, textTransform: 'uppercase', marginTop: -40 }}
            >
              {item.lastItem.scoreLabel}
            </p>
            <p className="speedometer-sublabel">MX RISK</p>
          </div>

          {!mobileWidth && (
          <Button
            className="view-report-button"
            {...!isNew && { color: 'secondaryButton' }}
            onClick={onViewReport}
            loading={isUpdateValidationItemLoading}
          >
            View Report
          </Button>
          )}
        </div>

        <div className="vertical-line" />

        <div className="info-wrapper">
          <div className="info-header">
            <div>
              <p className="subject">{item.lastItem.subject}</p>
              <div className="date-wrapper">
                <p>{moment(item.lastItem.createdAt).format(('DD MMM YYYY, h:mma'))}</p>

                {isNew && (
                <Chip
                  label="New"
                  color="primary"
                  style={{ width: 48, height: 20, fontSize: 12, fontWeight: 500 }}
                />
                )}
              </div>
            </div>

            {image ? <img className="email-provider-image" src={image} alt="email provider" /> : <TelegramIcon />}
          </div>

          <div className="line" />

          <div className="info-body">
            <div className="info-item">
              <p className="info-item-title">{overallReadabilityScore.name}</p>
              <div className="info-item-value">
                <p style={{ color: getRuleDataBySentiment(overallReadabilityScore).color }}>
                  {overallReadabilityScore?.value}
                </p>
              </div>
            </div>

            <div className="info-item" style={{ marginRight: -10 }}>
              <p className="info-item-title">{readingEase.name}</p>
              <div className="info-item-value">
                {getRuleDataBySentiment(readingEase).icon}
                <p>{readingEase?.value}</p>
              </div>
            </div>

            <div className="info-item">
              <p className="info-item-title">{readingGradeLevel.name}</p>
              <div className="info-item-value">
                {getRuleDataBySentiment(readingGradeLevel).icon}

                <p>{readingGradeLevel?.value}</p>
              </div>
            </div>

            <div className="info-item">
              <p className="info-item-title">{wordCount.name}</p>
              <div className="info-item-value">
                {getRuleDataBySentiment(wordCount).icon}
                <p>{wordCount?.score}</p>
              </div>
            </div>

            <div className="info-item">
              <p className="info-item-title">{readingTime.name}</p>
              <div className="info-item-value">
                {getRuleDataBySentiment(readingTime).icon}
                <p>{moment.duration(readingTime.value, 'seconds').asMinutes() >= 1
                  ? moment.utc(moment.duration(readingTime.value, 'seconds').asMilliseconds()).format('m[m] s[s]')
                  : `${moment.duration(readingTime.value, 'seconds').seconds()}s`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

      {isWaiting && !isDisabled && (
      <div className="waiting-wrapper">
        <div>
          <CupOfTeaIcon />
        </div>

        <div className="vertical-line" />

        <div className="waiting-info">
          <p>Waiting for your email</p>
          <p>Send an email to initiate MX Risk scan</p>
          <Tooltip title="Copy" onClick={onCopyGeneratedEmail}>
            <p>{item.emailTo}</p>
          </Tooltip>
        </div>
      </div>
      )}

      {isDisabled && (
      <div className="disabled-wrapper">
        <div
          className="turn-off-icon-wrapper"
          onClick={onActivateGroupClick}
          aria-hidden
        >
          <Tooltip title="Activate MX Risk Group" placement="top">
            <TurnOffIcon />
          </Tooltip>

        </div>

        <div className="vertical-line" />
        <p className="disabled-text">Email MX Risk is Disabled</p>
      </div>
      )}
    </div>
  );
};

GroupItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default React.memo(GroupItem);
