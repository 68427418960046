import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/sharp-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons';

import { monitorApi } from 'resources/monitor';
import { accountApi } from 'resources/account';

import useWindowDimensions from 'hooks/useWindowDimensions';

import uiNotificationService from 'services/uiNotificatuion.service';

import {
  PencilIcon, BellIcon, EmailLetterIcon,
  SMSIcon, SlackIcon, PagerDutyIcon,
  TrashIcon, DotsIcon,
} from 'assets/icons';

import DeleteModal from 'components/DeleteModal';
import MonitorListModal from './components/MonitorListModal';
import UsedMonitorsModal from './components/UsedMonitorsModal';
import AddOrEditNotificationPresetModal from './components/AddOrEditNotificationPresetModal';

import './styles.scss';

const getEmailColumnString = (emails) => {
  if (!emails?.length) {
    return null;
  }

  return emails.join(', ');
};

const NotificationPresetItem = ({ item: initialItem, index, itemsLength }) => {
  const [item, setItem] = useState(initialItem);
  const [isMonitorListModalVisible, setIsMonitorListModalVisible] = useState(false);
  const [isUsedMonitorsModalVisible, setIsUsedMonitorsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddOrEditModalVisible, setIsAddOrEditModalVisible] = useState(false);

  const [menuElement, setMenuElement] = useState(null);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 1000;

  const { data: account } = accountApi.useAccount();
  const { mutate: updateAccount } = accountApi.useUpdateAccount();
  const {
    mutate: deleteMonitorNotification,
    isLoading: isDeleteMonitorNotification,
  } = monitorApi.useDeleteMonitorNotification(item.id);

  const emailCoulumnString = getEmailColumnString(item.target.emails);
  const isDefault = account?.defaultNotificationPreset === item['@id'];

  const onChangeDefaultNotificationPreset = () => {
    if (isDefault) {
      return;
    }

    updateAccount({
      defaultNotificationPreset: item['@id'],
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Default Notification Presets has been updated!');
      },
    });
  };

  const onEditClick = useCallback(() => {
    setIsAddOrEditModalVisible(true);
  }, []);

  const onDeleteClick = useCallback(() => {
    if (item.monitors.length) {
      setIsUsedMonitorsModalVisible(true);

      return;
    }

    setIsDeleteModalVisible(true);
  }, [item.monitors.length]);

  const onDeleteNotification = useCallback(() => {
    deleteMonitorNotification(item.id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Notification preset has been deleted!');

        setIsDeleteModalVisible(false);
      },
    });
  }, [deleteMonitorNotification, item.id]);

  const openMenu = useCallback((e) => {
    setMenuElement(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuElement(null);
  }, []);

  return (
    <>
      {isMonitorListModalVisible && (
      <MonitorListModal
        open={isMonitorListModalVisible}
        onClose={() => setIsMonitorListModalVisible(false)}
        notification={item}
      />
      )}
      <DeleteModal
        title="Are you sure you want to delete this Notification Preset?"
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={onDeleteNotification}
      />
      {isUsedMonitorsModalVisible && (
      <UsedMonitorsModal
        open={isUsedMonitorsModalVisible}
        onClose={() => setIsUsedMonitorsModalVisible(false)}
        notification={item}
      />
      )}
      {isAddOrEditModalVisible && (
      <AddOrEditNotificationPresetModal
        open={isAddOrEditModalVisible}
        onClose={() => setIsAddOrEditModalVisible(false)}
        initialData={item}
        onUpdated={(data) => setItem(data)}
      />
      )}

      {mobileWidth
        ? (
          <div className="mobile-notification-item-container">
            <div className="mobile-notification-item-content">
              <div className="notification-preset-bell-icon-container">
                <BellIcon />
              </div>

              <div className="mobile-notification-item-info">
                <p>{item.presetName}</p>

                <p>{emailCoulumnString || '—'}</p>

                <p>Monitors: {item.monitors.length}</p>

                <div className="mobile-notification-types">
                  <Tooltip title="Email" placement="top">
                    <EmailLetterIcon className={!item.target.emails?.length ? 'notification-preset-disabled-icon' : null} />
                  </Tooltip>
                  <Tooltip title="Phone" placement="top">
                    <SMSIcon className={!item.target.sms ? 'notification-preset-disabled-icon' : null} />
                  </Tooltip>
                  <Tooltip title="Slack" placement="top">
                    <SlackIcon className={!item.target.slack ? 'notification-preset-disabled-icon' : null} />
                  </Tooltip>
                  <Tooltip title="PagerDuty" placement="top">
                    <PagerDutyIcon className={!item.target.pagerDuty ? 'notification-preset-disabled-icon' : null} />
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className={cn('menu-wrapper', menuElement && 'menu-wrapper-active')}>
              <IconButton onClick={openMenu}>
                <DotsIcon />
              </IconButton>

              <Menu
                open={!!menuElement}
                onClose={closeMenu}
                anchorEl={menuElement}
              >
                <MenuItem onClick={() => { onEditClick(); closeMenu(); }} className="menu-item">
                  <PencilIcon />
                  <p>Edit</p>
                </MenuItem>
                <MenuItem onClick={() => { onDeleteClick(); closeMenu(); }} className="menu-item">
                  <TrashIcon />
                  <p>Delete</p>
                </MenuItem>
              </Menu>
            </div>
          </div>
        )
        : (
          <div className="notification-item-container">
            <div className="notificaiton-row">
              <div className="notificaiton-row-name">
                <div className="notification-preset-bell-icon-container">
                  <BellIcon />
                </div>

                <p>{item.presetName}</p>

                <Tooltip title={isDefault ? null : 'Set a Default'} placement="top">
                  <FontAwesomeIcon
                    icon={isDefault ? faStarSolid : faStarRegular}
                    fontSize={12}
                    className={cn({ 'start-icon': !isDefault })}
                    cursor="pointer"
                    color={isDefault ? '#FFA726' : '#FFFFFF80'}
                    onClick={onChangeDefaultNotificationPreset}
                  />
                </Tooltip>
              </div>

              <div className="notificaiton-row-email">
                <p {...!emailCoulumnString && { className: 'dash' }}>{emailCoulumnString || '—'}</p>
              </div>

              <div className="notificaiton-row-notification">
                <Tooltip title="Email" placement="top">
                  <EmailLetterIcon className={!item.target.emails?.length ? 'notification-preset-disabled-icon' : null} />
                </Tooltip>
                <Tooltip title="Phone" placement="top">
                  <SMSIcon className={!item.target.sms ? 'notification-preset-disabled-icon' : null} />
                </Tooltip>
                <Tooltip title="Slack" placement="top">
                  <SlackIcon className={!item.target.slack ? 'notification-preset-disabled-icon' : null} />
                </Tooltip>
                <Tooltip title="PagerDuty" placement="top">
                  <PagerDutyIcon className={!item.target.pagerDuty ? 'notification-preset-disabled-icon' : null} />
                </Tooltip>
              </div>

              <div className="notificaiton-row-monitors">
                <Tooltip title="View monitors" placement="top">
                  <div
                    className="monitors-count-wrapper"
                    onClick={() => item.monitors.length && setIsMonitorListModalVisible(true)}
                    aria-hidden
                  >
                    <p>{item.monitors.length}</p>
                  </div>
                </Tooltip>
              </div>

              <div className="item-actions">
                <IconButton onClick={onEditClick}><PencilIcon /></IconButton>
                <IconButton
                  onClick={onDeleteClick}
                  disabled={isDeleteMonitorNotification}
                >
                  <TrashIcon />
                </IconButton>
              </div>
            </div>

            {index !== itemsLength - 1 ? <div className="line" /> : <div className="pb16" />}
          </div>
        )}
    </>
  );
};

NotificationPresetItem.propTypes = {
  item: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  itemsLength: PropTypes.number.isRequired,
};

export default NotificationPresetItem;
