import { colors } from './colors';

const theme = {
  fontFamily: 'Roboto, sans-serif',
  fontFamilyMonospace: 'monospace',
  headings: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
  },
  colorScheme: 'dark',
  colors,
  primaryShade: {
    light: 5,
    dark: 0,
  },
  radius: {
    xs: 4,
    sm: 6,
    md: 8,
    lg: 16,
    xl: 32,
  },
  fontSizes: {
    h2: 28,
    h3: 24,
    h4: 20,
    regular: 16,
    small: 14,
    caption: 12,
  },
  other: {
    transition: {
      speed: {
        fast: '200ms',
        smooth: '300ms',
        slow: '400ms',
        slowest: '1000ms',
      },
      easing: {
        linear: 'linear',
        ease: 'ease',
        easeIn: 'ease-in',
        easeOut: 'ease-out',
        easeInOut: 'ease-in-out',
        easeInBack: 'cubic-bezier(0.82,-0.2, 0.32, 1.84)',
        easeOutBack: 'cubic-bezier(0.5,-1.18, 0.51, 1.11)',
        easeInOutBack: 'cubic-bezier(.64,-0.56,.34,1.55)',
      },
    },
    weight: {
      regular: '400',
      medium: '500',
      semiBold: '600',
      bold: '700',
      extraBold: '800',
    },
    fontFamilyBodyText: 'Roboto, sans-serif',
  },
};

export default theme;
