import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import cn from 'classnames';

import { routes } from 'routes';

import { SmallClockIcon } from 'assets/icons';

import { emailApi } from 'resources/email';
import { mxrightApi } from 'resources/mxright';

import MenuItem from '@mui/material/MenuItem';

import Select from 'components/Select';
import ScoreCircle from 'components/ScoreCircle';
import ReportHistoryModal from 'components/ReportHistoryModal';
import DeleteModal from 'components/DeleteModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const PER_PAGE = 10;

const ReportHistorySelect = ({
  className,
  initialCommonReportData,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const [value, setValue] = useState(params.email);
  const [isReportHistoryModalVisible, setIsReportHistoryModalVisible] = useState(false);

  const [emailCheckToDelete, setEmailCheckToDelete] = useState(null);
  const [isDeleteEmailCheckModalVisible, setIsDeleteEmailCheckModalVisible] = useState(false);

  const [page, setPage] = useState(1);

  const { data: fetchedCommonReportData } = mxrightApi.useCommonData(!initialCommonReportData && params.email);
  const commonReportData = fetchedCommonReportData || initialCommonReportData;

  const { data: emailList } = emailApi.useEmailList({
    fromEmail: commonReportData?.fromEmail,
    page,
    perPage: PER_PAGE,
  });
  const { mutate: deleteEmailCheck, isLoading: isDeleteEmailCheckLoading } = emailApi.useDeleteEmailCheck();

  const onOpenReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(true);
  }, []);

  const onCloseReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(false);
  }, []);

  const selectItems = useMemo(() => (emailList ? emailList.items.map((item) => ({
    value: item.toEmail,
    label: moment(item.insertTimestamp).format('MMM DD, YYYY [at] h:mmA'),
    ...item,
  })) : []), [emailList]);

  const onReportHistoryItemClick = useCallback((data) => {
    if (!data) {
      return;
    }

    setValue(data);
    navigate(routes.result.path.replace(':email', data));
  }, [navigate]);

  const onHistoryItemDeleteClick = useCallback((item) => {
    setIsDeleteEmailCheckModalVisible(true);
    setEmailCheckToDelete(item);
  }, []);

  const onHistoryItemDelete = useCallback(() => {
    const emailCheckId = +emailCheckToDelete['@id'].replace('/v2/emails/', '');

    deleteEmailCheck(emailCheckId, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email check has been deleted');

        if (emailCheckToDelete.toEmail === params.email) {
          const readySelectItems = selectItems.filter((selectItem) => selectItem.status === 'ready');

          if (readySelectItems.length <= 1) {
            navigate(routes.emailInfrastructure.path);
          } else {
            const anotherReport = readySelectItems.find((selectItem) => selectItem.toEmail !== params.email);

            navigate(routes.result.path.replace(':email', anotherReport.toEmail));
          }
        }

        setEmailCheckToDelete(null);
        setIsDeleteEmailCheckModalVisible(false);
      },
    });
  }, [emailCheckToDelete, deleteEmailCheck, navigate, selectItems, params.email]);

  return (
    <>
      {!!selectItems.length && isReportHistoryModalVisible && (
      <ReportHistoryModal
        open={isReportHistoryModalVisible}
        onClose={onCloseReportHistoryModal}
        forName={commonReportData.fromEmail}
        items={selectItems}
        page={page}
        perPage={PER_PAGE}
        totalItems={emailList?.totalItems}
        onPageChanged={(newPage) => setPage(newPage)}
        onItemClick={(item) => onReportHistoryItemClick(item.value)}
        onDelete={onHistoryItemDeleteClick}
      />
      )}
      {isDeleteEmailCheckModalVisible && emailCheckToDelete && (
      <DeleteModal
        title={`Are you sure you want to delete email check from ${emailCheckToDelete.label}?`}
        open={isDeleteEmailCheckModalVisible}
        onClose={() => setIsDeleteEmailCheckModalVisible(false)}
        onDelete={onHistoryItemDelete}
        isDeleteLoading={isDeleteEmailCheckLoading}
      />
      )}

      {commonReportData && (
      <Select
        value={value}
        className={cn('report-history-select', className)}
        onChange={onReportHistoryItemClick}
        items={selectItems}
        renderValue={() => {
          const date = new Date(commonReportData?.resultAt);

          return (
            <div className="report-history-select-value">
              <SmallClockIcon />
              <p>{date?.toLocaleString(navigator.language)}
              </p>
            </div>
          );
        }}
      >
        {selectItems.slice(0, 10).map((item) => (
          <MenuItem key={item['@id']} value={item.value} className="report-history-select-menu-item">
            <ScoreCircle
              mxrScoreTotal={10}
              mxrScoreTotalPass={item.mxrScore}
              ratingColor={item.ratingColor}
              ratingLabel={item.ratingLabel || 'U'}
              onlyCircle
              size="tiny"
            />

            <p className="label">{item.label}</p>
          </MenuItem>
        ))}
        {emailList?.totalItems > 1 && (
        <div>
          <div className="report-history-select-line" />
          <p className="report-history-select-see-more" onClick={onOpenReportHistoryModal} aria-hidden>
            See more
          </p>
        </div>
        )}
      </Select>
      )}
    </>
  );
};

ReportHistorySelect.propTypes = {
  className: PropTypes.string,
  initialCommonReportData: PropTypes.shape(),
};

ReportHistorySelect.defaultProps = {
  className: null,
  initialCommonReportData: null,
};

export default React.memo(ReportHistorySelect);
