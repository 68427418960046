import { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faClone, faComma, faFileArrowDown, faListUl, faSemicolon } from '@fortawesome/pro-regular-svg-icons';

import { Menu, LinearProgress } from '@mui/material';

import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const SeedListInProgress = ({ item }) => {
  const [copyToClipboardMenu, setCopyToClipboardMenu] = useState(null);

  const percent = Math.floor((item.lastRun.receivedCount / item.lastRun.total) * 100);

  const onDownloadCSV = useCallback(() => {
    const csvContent = `data:text/csv;charset=utf-8,Emails\n${item.inboxes.join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'seed-list.csv');
    document.body.appendChild(link); // Required for Firefox

    link.click();
  }, [item.inboxes]);

  const onCopyAsComaSeparated = useCallback(() => {
    navigator.clipboard.writeText(item.inboxes.join(', '));

    uiNotificationService.showSuccess('Copied to clipboard');

    setCopyToClipboardMenu(null);
  }, [item.inboxes]);

  const onCopyAsSemicolonSeparated = useCallback(() => {
    navigator.clipboard.writeText(item.inboxes.join('; '));

    uiNotificationService.showSuccess('Copied to clipboard');

    setCopyToClipboardMenu(null);
  }, [item.inboxes]);

  const onCopyAsOnePerLine = useCallback(() => {
    navigator.clipboard.writeText(item.inboxes.join('\n'));

    uiNotificationService.showSuccess('Copied to clipboard');

    setCopyToClipboardMenu(null);
  }, [item.inboxes]);

  return (
    <div className="connected-email">
      <div className="connected-email-content">
        <p>Waiting for your email...</p>
        <p>{percent}%</p>
      </div>

      <LinearProgress
        variant="determinate"
        value={percent}
        className="progress-bar"
      />

      <p className="connected-email-description">
        Send an email to the deliverability seed list. Copy or download the list below:
      </p>

      <div className="connected-email-controls">
        <Button
          onClick={(e) => setCopyToClipboardMenu(e.currentTarget)}
          variant="subtle"
          className="copy-to-clipboard-button"
          iconClassName="copy-icon"
        >
          <div className="copy-as-select-wrapper">
            <FontAwesomeIcon icon={faClone} width={20} height={20} />
            <p>
              Copy to Clipboard as
            </p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </Button>
        <Menu
          open={!!copyToClipboardMenu}
          onClose={() => setCopyToClipboardMenu(null)}
          anchorEl={copyToClipboardMenu}
        >
          <div className="copy-to-clipboard-menu-wrapper">
            <div onClick={onCopyAsComaSeparated} aria-hidden>
              <FontAwesomeIcon icon={faComma} />
              <p>Comma Separated</p>
            </div>

            <div onClick={onCopyAsSemicolonSeparated} aria-hidden>
              <FontAwesomeIcon icon={faSemicolon} />
              <p>Semicolon Separated</p>
            </div>

            <div onClick={onCopyAsOnePerLine} aria-hidden>
              <FontAwesomeIcon icon={faListUl} />
              <p>One-per-Line</p>
            </div>
          </div>
        </Menu>

        <Button
          onClick={onDownloadCSV}
          className="copy-csv-button"
          iconClassName="copy-icon"
          variant="subtle"
          startIcon={<FontAwesomeIcon className="copy-icon" icon={faFileArrowDown} width={20} height={20} />}
        >
          Download as CSV
        </Button>
      </div>
    </div>
  );
};

SeedListInProgress.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default memo(SeedListInProgress);
