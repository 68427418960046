import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { accountApi } from 'resources/account';
import { userApi } from 'resources/user';

import { routes } from 'routes';

import CircularProgress from '@mui/material/CircularProgress';

import './styles.scss';
import uiNotificationService from 'services/uiNotificatuion.service';

const Callback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: getAuthToken } = accountApi.useGetAuthToken();
  const { mutate: getUser } = userApi.useUser();

  const { href } = window.location;

  useEffect(() => {
    const errorDescription = searchParams.get('error_description');

    if (errorDescription) {
      uiNotificationService.showError(errorDescription);
    }

    let type = searchParams.get('type');

    if (searchParams.get('code')) {
      type = 'authorithation';
    }

    switch (type) {
      case 'confirmation':
        const confirmationToken = searchParams.get('token');

        if (confirmationToken) {
          navigate(routes.terms.path, { state: { confirmationToken } });
        }
        break;
      case 'reset-password':
        const resetPasswordToken = searchParams.get('token');

        if (resetPasswordToken) {
          navigate(routes.setNewPassword.path, { state: { resetPasswordToken } });
        }
        break;

      case 'authorithation':
        if (!href.includes('code=')) {
          return;
        }

        getAuthToken(href.substring(href.indexOf('?') + 1), {
          onSuccess: () => {
            const userId = localStorage.getItem('userId');

            getUser(userId, {
              onSuccess: () => {
                navigate(routes.dashboard.path);
                navigate(0);
              },
            });
          },
        });
        break;

      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, getAuthToken, getUser]);

  return (
    <div className="callback-wrapper">
      <CircularProgress className="loader" />
    </div>
  );
};

export default Callback;
