import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import queryClient from 'query-client';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';
import UnauthorizedLayout from 'layouts/unauthorized';

import NotFound from 'pages/NotFound';

import { ArrowRightIcon, CrossIcon, BigShareIcon, DotsIcon, ShareIcon } from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';
import { emailApi } from 'resources/email';
import { userApi } from 'resources/user';

import Button from 'components/Button';
import ShareReportModal from 'components/ShareReportModal';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import useWindowDimensions from 'hooks/useWindowDimensions';

import uiNotificationService from 'services/uiNotificatuion.service';

import EmailTemplate from 'pages/TemplateMXRisk/components/EmailTemplate';
import EmailResult from 'pages/TemplateMXRisk/components/EmailResult';

import './styles.scss';

// eslint-disable-next-line react/prop-types
const Layout = ({ children, currentUser }) => (currentUser
  ? <AuthorizedLayout>{children}</AuthorizedLayout>
  : <UnauthorizedLayout>{children}</UnauthorizedLayout>);

const EmailMXRiskResult = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 700;

  const [menuEl, setMenuEl] = useState(null);

  const [isShareReportModalVisible, setIsShareReportModalVisible] = useState(false);

  const { data: currentUser } = userApi.useCurrentUser();
  const { data: analyzeResult, refetch, error } = mxriskApi.useAnalyzeResult(+params.id);
  const { mutate: updateValidationItem, isLoading: isUpdateValidationItemLoading } = mxriskApi.useUpdateValidationItem();
  const { mutate: sendSharedReport, isLoading: isSendSharedReportLoading } = emailApi.useSendSharedReport();

  const isLoading = !analyzeResult || analyzeResult.status === 'in_process';

  useEffect(() => {
    if (analyzeResult && analyzeResult.status === 'in_process') {
      const intervalId = setInterval(() => {
        const freshAnalyzeResult = queryClient.getQueryData(['mxrisk.analyzeResult', +params.id]);

        if (freshAnalyzeResult.status === 'in_process') {
          refetch();

          return;
        }

        clearInterval(intervalId);
      }, 2000);
    }
  }, [analyzeResult, params.id, refetch]);

  useEffect(() => {
    if (analyzeResult && analyzeResult.status === 'error') {
      uiNotificationService.showError('Server error. Please try again');

      navigate(routes.templateMXRisk.path);
    }
  }, [navigate, analyzeResult]);

  const navigateToEmailMXRiskPage = useCallback(() => {
    navigate(routes.emailMXRisk.path);
  }, [navigate]);

  const onShareSwitchChange = useCallback((isShare) => {
    updateValidationItem({
      id: analyzeResult.id,
      shared: isShare,
    });
  }, [updateValidationItem, analyzeResult?.id]);

  const onShareClick = useCallback(() => {
    setIsShareReportModalVisible(true);
  }, []);

  const openMenu = useCallback((e) => {
    setMenuEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuEl(null);
  }, []);

  const onSendReport = useCallback(({ email, message }) => {
    sendSharedReport({
      recipient: email,
      url: window.location.href,
      subject: 'Shared Email MX Risk report',
      message,
      resource: analyzeResult['@id'],
      linkText: 'Email MX Risk report',
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('The email was successfully sent');

        setIsShareReportModalVisible(false);
        closeMenu();
      },
    });
  }, [sendSharedReport, closeMenu, analyzeResult]);

  if (error?.status === 404) {
    return <NotFound />;
  }

  return (
    <Layout currentUser={currentUser}>
      {isShareReportModalVisible && (
      <ShareReportModal
        open={isShareReportModalVisible}
        onClose={() => { setIsShareReportModalVisible(false); closeMenu(); }}
        linkToShare={window.location.href}
        isShareEnabled={analyzeResult.shared}
        onSwitchChange={onShareSwitchChange}
        isSwitchDisabled={isUpdateValidationItemLoading}
        onSend={onSendReport}
        isSendLoading={isSendSharedReportLoading}
        isOwner={analyzeResult.ownedByMe}
      />
      )}

      <div className="email-mxrisk-result-wrapper">
        <div>
          <div className="title-wrapper">

            <div className="title">
              <p>{analyzeResult?.group?.name || 'Group name'}</p>
            </div>

            {!isLoading && !mobileWidth && (analyzeResult?.ownedByMe || analyzeResult?.shared) && (
            <IconButton onClick={onShareClick}>
              <BigShareIcon />
            </IconButton>
            )}

            {!isLoading && mobileWidth && (analyzeResult?.ownedByMe || analyzeResult?.shared) && (
            <div className={cn('menu-wrapper', menuEl && 'menu-wrapper-active')}>
              <IconButton onClick={openMenu}>
                <DotsIcon />
              </IconButton>

              <Menu
                open={!!menuEl}
                onClose={closeMenu}
                anchorEl={menuEl}
              >
                <MenuItem
                  onClick={onShareClick}
                  className="menu-item"
                >
                  <ShareIcon />
                  <p>Share</p>
                </MenuItem>
              </Menu>
            </div>
            )}
          </div>

          <div className="breadcrumbs">
            <p
              onClick={currentUser ? navigateToEmailMXRiskPage : undefined}
              style={currentUser ? { cursor: 'pointer' } : undefined}
              aria-hidden
            >
              Email MX Risk
            </p>
            <ArrowRightIcon />
            <p>{analyzeResult?.group?.name || 'Group name'}</p>
          </div>
        </div>

        <div className="result-wrapper">
          <EmailTemplate isLoading={isLoading} analyzeResult={analyzeResult} isEmail />
          <EmailResult isLoading={isLoading} analyzeResult={analyzeResult} />
        </div>

        {currentUser && (
        <Button
          className="close-button"
          color="secondaryButton"
          onClick={() => navigate(-1)}
          startIcon={<CrossIcon />}
          iconClassName="button-icon"
        >
          Close
        </Button>
        )}
      </div>
    </Layout>
  );
};

export default EmailMXRiskResult;
