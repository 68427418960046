import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { routes } from 'routes';

import {
  AuthLogoIcon, MenuIcon, CrossIcon,
} from 'assets/icons';

import IconButton from '@mui/material/IconButton';

import AuthMenu from 'components/AuthMenu';

import { userApi } from 'resources/user';

import useWindowDimensions from 'hooks/useWindowDimensions';

import Sidebar from '../Sidebar';

import './styles.scss';

const Header = ({ mobile, hideMenu }) => {
  const [isMobileSidebarOpened, setIsMobileSidebarOpened] = useState(false);

  const { data: accountsData } = userApi.useConnectedAccounts();

  const activeAccountId = +sessionStorage.getItem('activeAccountId');
  const activeAccountData = accountsData?.find((accountData) => accountData.account.id === activeAccountId);

  const { width } = useWindowDimensions();
  const mobileWidth = mobile || width <= 700;

  const onMobileMenuToggle = useCallback(() => {
    setIsMobileSidebarOpened(!isMobileSidebarOpened);
  }, [isMobileSidebarOpened]);

  return (
    <div className={cn('header-wrapper', mobileWidth && isMobileSidebarOpened && 'mobile-sidebar-opened')}>
      <header className={cn(mobileWidth && {
        'mobile-header': true,
        'mobile-header-closed': !isMobileSidebarOpened,
        'mobile-header-opened': isMobileSidebarOpened,
      })}
      >
        {mobileWidth && (
        <div className="mobile-logo-wrapper">
          {!isMobileSidebarOpened && !hideMenu && (
          <IconButton onClick={onMobileMenuToggle}>
            <MenuIcon />
          </IconButton>
          )}

          <Link to={routes.dashboard.path}>
            <AuthLogoIcon height={30} width={105} />
          </Link>
        </div>
        )}

        {isMobileSidebarOpened && !hideMenu
          ? (
            <IconButton onClick={onMobileMenuToggle}>
              <CrossIcon style={{ transform: 'scale(1.2)' }} />
            </IconButton>
          )
          : (
            <div className="auth-menu">
              {activeAccountData && <p>{activeAccountData.account.companyName}</p>}
              <AuthMenu />
            </div>
          )}
      </header>

      {isMobileSidebarOpened && !hideMenu && (
      <aside className="mobile-sidebar">
        <div className="line" />

        <Sidebar />
      </aside>
      )}
    </div>
  );
};

Header.propTypes = {
  mobile: PropTypes.bool,
  hideMenu: PropTypes.bool,
};

Header.defaultProps = {
  mobile: false,
  hideMenu: false,
};

export default React.memo(Header);
