import PropTypes from 'prop-types';

import { MailboxIcon } from 'assets/icons';

import Button from 'components/Button';

import './styles.scss';

const EmptyState = ({ onClick }) => (
  <div className="empty-state">
    <MailboxIcon />

    <p className="empty-state-title">Create your first deliverability test</p>

    <Button onClick={onClick}>
      Start a Test
    </Button>
  </div>
);

EmptyState.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EmptyState;
