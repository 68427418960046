import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';

import { routes } from 'routes';

import { CardioLineBigIcon, CupOfTeaIcon } from 'assets/icons';

import Tooltip from '@mui/material/Tooltip';

import ScoreCircle from 'components/ScoreCircle';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

import EmailInfrastructureMenu from '../EmailInfrastructureMenu';

const getColorByQuality = (quality) => {
  if (quality >= 0.75) {
    return 'success';
  }

  if (quality > 0.5) {
    return 'warning';
  }

  return 'error';
};

const EmailInfrastructureItem = ({
  id,
  title,
  mxrScore,
  ratingColor,
  ratingLabel,
  lastRun,
  lastRunAt,
  enabled,
  runDetails,
  scheduleSeconds,
  notificationId,
  emailAccountId,
  generatedEmail,
  onDeleted,
}) => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    id,
    name: title,
    enabled,
    scheduleSeconds,
    notificationId,
  });

  const isResultExists = !!(runDetails?.length && lastRunAt);

  const onUpdated = useCallback((updatedData) => {
    setData((old) => ({
      ...old,
      ...updatedData,
    }));
  }, []);

  const onNavigateToMonitorHistory = useCallback(() => {
    if (isResultExists) {
      navigate(routes.monitorResult.path
        .replace(':monitorId', id)
        .replace(':email', lastRun.toEmail));
    }
  }, [navigate, isResultExists, id, lastRun]);

  const onCopyGeneratedEmail = useCallback(() => {
    navigator.clipboard.writeText(generatedEmail);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [generatedEmail]);

  return (
    <div
      className={cn('email-item', !isResultExists && 'email-item-default-cursor')}
      onClick={onNavigateToMonitorHistory}
      aria-hidden
    >
      <div>
        <div
          className={cn('email-title-container', !isResultExists && 'default-cursor')}
        >
          <ScoreCircle
            {...(mxrScore && {
              mxrScoreTotal: 100,
              mxrScoreTotalPass: mxrScore * 10,
            })}
            onlyCircle
            size="medium"
            ratingColor={ratingColor}
            ratingLabel={ratingLabel}
            waiting={!mxrScore}
            disabled={!data.enabled}
          />

          <div className="email-monitor-title">
            {isResultExists ? (
              <Tooltip title={data.name} placement="bottom">
                <p className="monitor-name">{data.name}</p>
              </Tooltip>
            ) : <p className="monitor-name">{data.name}</p>}

            <div>
              {lastRunAt ? (
                <div>
                  <p className="monitor-status">Last Scan: {moment(lastRunAt).format('MMM DD, YYYY')}</p>
                </div>
              )
                : <p className="waiting-text">Waiting for initial scan</p>}
            </div>
          </div>
        </div>

        <div onClick={(e) => { e.stopPropagation(); }} aria-hidden>
          <EmailInfrastructureMenu
            id={data.id}
            name={data.name}
            enabled={data.enabled}
            scheduleSeconds={data.scheduleSeconds}
            notificationId={data.notificationId}
            emailAccountId={emailAccountId}
            generatedEmail={generatedEmail}
            lastRun={lastRun}
            lastRunAt={lastRunAt}
            onDeleted={onDeleted}
            onUpdated={onUpdated}
          />
        </div>
      </div>

      <div className="service-groups-container">
        {data.enabled && lastRunAt && !!runDetails.length && runDetails.map((checkItem) => {
          const color = getColorByQuality(checkItem.passCount / checkItem.totalCount);

          return (
            <div className="check-item" key={`${checkItem.id}-${checkItem.title}`}>
              <div className="email-title">
                <div className={cn('circle', `circle-${color}`)} />

                <p>{checkItem.title}</p>
              </div>

              <p className={cn('tests-pass-text', `tests-pass-text-${color}`)}>
                {checkItem.passCount}/{checkItem.totalCount}
              </p>
            </div>
          );
        })}

        {data.enabled && !lastRunAt && (
        <div className="service-groups-waiting-state">
          <CupOfTeaIcon />
          <p>Waiting for your email</p>

          <div className="generated-email-container">
            <p>Send an email to initiate the scan to</p>
            <Tooltip title="Copy" onClick={onCopyGeneratedEmail}>
              <p>{generatedEmail}</p>
            </Tooltip>
          </div>
        </div>
        )}

        {!data.enabled && (
        <div className="service-groups-disabled-state">
          <CardioLineBigIcon />
          <p>Monitoring is Disabled</p>
        </div>
        )}
      </div>
    </div>
  );
};

EmailInfrastructureItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  mxrScore: PropTypes.number,
  ratingColor: PropTypes.string,
  ratingLabel: PropTypes.string,
  lastRun: PropTypes.shape({
    id: PropTypes.number,
    score: PropTypes.number,
    toEmail: PropTypes.string,
  }),
  lastRunAt: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  runDetails: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    passCount: PropTypes.number,
    totalCount: PropTypes.number,
  })).isRequired,
  scheduleSeconds: PropTypes.number.isRequired,
  notificationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emailAccountId: PropTypes.number.isRequired,
  onDeleted: PropTypes.func.isRequired,
  generatedEmail: PropTypes.string.isRequired,
};

EmailInfrastructureItem.defaultProps = {
  mxrScore: null,
  lastRun: null,
  lastRunAt: null,
  ratingColor: null,
  ratingLabel: null,
  notificationId: null,
};

export default React.memo(EmailInfrastructureItem);
