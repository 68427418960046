import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/pro-regular-svg-icons';

import { GoogleLogo40x40Icon, Microsoft365Icon } from 'assets/icons';

import { connectivityApi } from 'resources/connectivity';

import Modal from 'components/Modal';

import AddIMAPModal from './components/AddIMAPModal';

import './styles.scss';

const AddEmailAccountModal = ({
  open,
  onClose,
}) => {
  const [isIMAPModalVisible, setIsIMAPModalVisible] = useState(false);

  const {
    mutate: getGoogleAuthUrl,
  } = connectivityApi.useGetConnectivityGoogleAuthUrl();
  const {
    mutate: getMSAuthUrl,
  } = connectivityApi.useGetConnectivityMSAuthUrl();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add Email Account"
    >
      {isIMAPModalVisible && (
      <AddIMAPModal
        open={isIMAPModalVisible}
        onClose={() => setIsIMAPModalVisible(false)}
        onAdded={() => {
          setIsIMAPModalVisible(false);
          onClose();
        }}
      />
      )}
      <div className="add-email-account-modal-wrapper">
        <div className="email-items-list">
          {[
            {
              label: 'Sign in with Google',
              onClick: () => {
                getGoogleAuthUrl({}, {
                  onSuccess: (data) => {
                    window.open(data.authUrl, '_self');
                    onClose();
                  },
                });
              },
              icon: <GoogleLogo40x40Icon />,
            },
            {
              label: 'Microsoft 365',
              onClick: () => {
                getMSAuthUrl({}, {
                  onSuccess: (data) => {
                    window.open(data.authUrl, '_self');
                    onClose();
                  },
                });
              },
              icon: <Microsoft365Icon />,
            },
            { label: 'Other (IMAP / SMTP)',
              onClick: () => setIsIMAPModalVisible(true),
              icon: (
                <div
                  style={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: 4,
                  }}
                >
                  <FontAwesomeIcon icon={faAt} color="rgba(255, 255, 255, 0.70)" />
                </div>
              ),
            },
          ].map((item) => (
            <div
              className="add-email-item"
              key={item.label}
              onClick={item.onClick}
              aria-hidden
            >
              {item.icon}

              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

AddEmailAccountModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(AddEmailAccountModal);
