import { Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { GmailIcon, OutlookIcon, WorkmailIcon, YahooIcon } from 'assets/icons';
import PropTypes from 'prop-types';

import { PLACEMENT_TEST_STATISTIC } from 'pages/DeliverabilityTest/components/TestCard';

import ReportRow from './components/ReportRow';

import './styles.scss';

const ICON_BY_PROVIDER_TYPE = {
  google: <GmailIcon />,
  o365: <OutlookIcon />,
  other: <WorkmailIcon />, // TODO: replace with correct icon
};

const EmailProviderReport = ({ placementTestRun }) => {
  let isShowOther = false;

  const stats = (placementTestRun?.emailProviders || []).map((item) => ({
    name: item.providerLabel,
    icon: ICON_BY_PROVIDER_TYPE[item.providerType],
    stats: PLACEMENT_TEST_STATISTIC.map((stat) => {
      if (stat.id === 'other' && item[stat.id] > 0) {
        isShowOther = true;
      }

      return {
        ...stat,
        percent: item
          ? ((item[stat.id] / item.totalCount) * 100).toFixed(2)
          : 0,
      };
    }),
  }));

  if (!isShowOther) {
    stats.forEach((stat) => {
      stat.stats = stat.stats.filter((item) => item.id !== 'other');
    });
  }

  return (
    <>
      <p className="email-provider-report-title">Email Provider Delivery Report</p>
      <TableContainer sx={{ background: 'unset', marginBottom: '90px', boxShadow: 'unset' }} component={Paper}>
        <Table sx={{ tableLayout: 'fixed' }} aria-label="table">
          <TableBody>
            {stats.map((stat) => (
              <>
                <ReportRow key={stat.id} data={stat} />
                <TableRow sx={{ height: '8px' }} />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

EmailProviderReport.propTypes = {
  placementTestRun: PropTypes.shape().isRequired,
};

export default EmailProviderReport;
