import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { FilesIcon, SendEmailIcon, PressIcon } from 'assets/icons';

import { emailApi } from 'resources/email';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import CircularProgress from '@mui/material/CircularProgress';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const AddHocScanModal = ({
  open,
  onClose,
  type,
  onActionButtonClick,
  generatedEmail: defaultGeneratedEmail,
}) => {
  const navigate = useNavigate();

  const { data: generatedEmail, isLoading } = defaultGeneratedEmail
    ? { data: defaultGeneratedEmail }
    : emailApi.useGenerateEmail();

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(generatedEmail);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [generatedEmail]);

  const onShowReport = useCallback(() => {
    navigate(routes.result.path.replace(':email', generatedEmail));
  }, [navigate, generatedEmail]);

  const getDataByType = (t) => {
    const data = {
      hocScan: {
        title: 'Ad-Hoc Scan',
        actionButton: {
          title: 'Show my Report',
          onClick: onShowReport,
        },
      },
      emailMonitor: {
        title: 'Initialize the first monitor scan',
      },
      copyEmail: {
        title: 'Monitor Address',
      },
    };

    return data[t];
  };
  const dataByType = getDataByType(type);

  const onButtonClick = useCallback(() => {
    if (dataByType.actionButton?.onClick) {
      dataByType.actionButton.onClick();
    }

    if (onActionButtonClick) {
      onActionButtonClick(generatedEmail);
    }
  }, [dataByType.actionButton, onActionButtonClick, generatedEmail]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={dataByType.title}
    >
      <div className="hoc-scan-modal-wrapper">
        {isLoading ? <CircularProgress className="loader" /> : (
          <>
            <div className="copy-wrapper">
              <SendEmailIcon style={{ minWidth: 96 }} />

              <div className="email-wrapper">
                <p className="title">Send an email to this email address</p>

                <div className="copy-email-wrapper">
                  <Input className="link-input" value={generatedEmail} readOnly />

                  <Button
                    className="copy-button"
                    startIcon={<FilesIcon />}
                    iconClassName="copy-icon"
                    onClick={onCopyToClipboard}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            </div>

            {dataByType.actionButton && (
            <>
              <div className="line" />

              <div className="show-report-wrapper">
                <PressIcon />

                <div>
                  <p className="title">After email is sent, press the button below</p>
                  <Button onClick={onButtonClick} loading={isLoading}>{dataByType.actionButton.title}</Button>
                </div>
              </div>
            </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

AddHocScanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['hocScan', 'emailMonitor', 'copyEmail']),
  onActionButtonClick: PropTypes.func,
  generatedEmail: PropTypes.string,
};

AddHocScanModal.defaultProps = {
  type: 'hocScan',
  onActionButtonClick: null,
  generatedEmail: null,
};

export default React.memo(AddHocScanModal);
