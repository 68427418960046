import { useQuery, useMutation } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useTemplateEngines() {
  const itemsPerPage = 50;

  const getTemplateEngines = () => api.get('/v2/mx-risk/template-engines', {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get('/v2/mx-risk/template-engines', {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxrisk.templateEngines'], getTemplateEngines);
}

export function useAnalyzeTemplate() {
  const analyzeTemplate = ({
    openTag,
    closeTag,
    from,
    subjectTemplate,
    template,
    templateEngine,
    name,
    save,
    itemId,
    folderId,
  }) => api.post('/v2/mx-risk/analyze-template', {
    openTag,
    closeTag,
    from,
    subjectTemplate,
    template,
    ...(templateEngine && { templateEngine }),
    ...(name && { name }),
    ...(save && { save }),
    ...(itemId && { itemId }),
    ...(folderId && { folder: `/v2/mx-risk/template-groups/${folderId}` }),
  });

  return useMutation(analyzeTemplate, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['mxrisk.analyzeResult', data.id]);

      const templateGroupId = +data.group['@id'].replace('/v2/mx-risk/template-groups/', '');
      queryClient.invalidateQueries(['mxrisk.emailGroupItems', templateGroupId]);
    },
  });
}

export function useEmailGroups({ page, perPage }) {
  const getEmailGroups = () => api.get('/v2/mx-risk/email-groups', { page, itemsPerPage: perPage })
    .then((data) => ({
      totalItems: data['hydra:totalItems'],
      items: data['hydra:member'],
    }));

  return useQuery(['mxrisk.emailGroups', page, perPage], getEmailGroups);
}

export function useEmailGroup(id) {
  const getEmailGroup = () => api.get(`/v2/mx-risk/email-groups/${id}`);

  return useQuery(['mxrisk.emailGroup', id], getEmailGroup);
}

export function useEmailGroupItems({ id, page, perPage }) {
  const getEmailGroupItems = () => api.get(`/v2/mx-risk/email-groups/${id}/items`, { page, itemsPerPage: perPage })
    .then((data) => ({
      totalItems: data['hydra:totalItems'],
      items: data['hydra:member'],
    }));

  return useQuery(['mxrisk.emailGroupItems', id, page, perPage], getEmailGroupItems);
}

export function useEmailForGroup() {
  const itemsPerPage = 50;

  const getEmailForGroup = () => api.get('/v2/email-fors', {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get('/v2/email-fors', {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxrisk.emailForGroup'], getEmailForGroup);
}

export function useCreateEmailGroup() {
  const createEmailGroup = ({
    emailFor,
    name,
    emailTo,
  }) => api.post('/v2/mx-risk/email-groups', {
    emailFor,
    name,
    active: true,
    ...(emailTo && { emailTo }),
  });

  return useMutation(createEmailGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('mxrisk.emailGroups');
    },
  });
}

export function useUpdateEmailGroup() {
  const updateEmailGroup = ({
    id,
    active,
    name,
    emailFor,
  }) => api.patch(`/v2/mx-risk/email-groups/${id}`, {
    ...(active !== undefined && { active }),
    ...(name !== undefined && { name }),
    ...(emailFor !== undefined && { emailFor }),
  });

  return useMutation(updateEmailGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('mxrisk.emailGroups');
    },
  });
}

export function useAnalyzeResult(id) {
  const getAnalyzeResult = () => (id ? api.get(`/v2/mx-risk/validation-items/${id}`) : null);

  return useQuery(['mxrisk.analyzeResult', id], getAnalyzeResult, {
    keepPreviousData: false,
  });
}

export function useDeleteEmailGroup() {
  const deleteEmailGroup = (id) => api.delete(`/v2/mx-risk/email-groups/${id}`);

  return useMutation(deleteEmailGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('mxrisk.emailGroups');
    },
  });
}

export function useUpdateValidationItem() {
  const updateValidationItem = ({
    id,
    new: isNew,
    name,
    groupId,
    shared,
  }) => api.patch(`/v2/mx-risk/validation-items/${id}`, {
    ...(isNew !== undefined && { new: isNew }),
    ...(name && { name }),
    ...(groupId && { groupId }),
    ...(shared !== undefined && { shared }),
  });

  return useMutation(updateValidationItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('mxrisk.emailGroups');
      queryClient.invalidateQueries(['mxrisk.analyzeResult', data.id]);
    },
  });
}

export function useDeleteEmailGroupItem() {
  const deleteEmailGroupItem = (id) => api.delete(`/v2/mx-risk/validation-items/${id}`);

  return useMutation(deleteEmailGroupItem, {
    onSuccess: () => {
      queryClient.invalidateQueries('mxrisk.emailGroupItems');
    },
  });
}

export function useTemplateGroups({ page, perPage, pagination = true }) {
  const getTemplateGroups = () => api.get('/v2/mx-risk/template-groups', { page, itemsPerPage: perPage, pagination })
    .then((data) => ({
      totalItems: data['hydra:totalItems'],
      items: data['hydra:member'],
    }));

  return useQuery(['mxrisk.templateGroups', page, perPage, pagination], getTemplateGroups);
}

export function useCreateTemplateGroup() {
  const createTemplateGroup = ({ name }) => api.post('/v2/mx-risk/template-groups', {
    name,
    active: true,
  });

  return useMutation(createTemplateGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('mxrisk.templateGroups');
    },
  });
}

export function useUpdateTemplateGroup() {
  const updateTemplateGroup = ({
    id,
    active,
    name,
  }) => api.patch(`/v2/mx-risk/template-groups/${id}`, {
    ...(active && { active }),
    ...(name && { name }),
  });

  return useMutation(updateTemplateGroup, {
    onSuccess: (templateGroup) => {
      queryClient.invalidateQueries('mxrisk.templateGroups');
      queryClient.invalidateQueries(['mxrisk.templateGroup', { id: templateGroup.id }]);
    },
  });
}

export function useDeleteTemplateGroup() {
  const deleteTemplateGroup = (id) => api.delete(`/v2/mx-risk/template-groups/${id}`);

  return useMutation(deleteTemplateGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('mxrisk.templateGroups');
    },
  });
}

export function useTemplateGroup(id) {
  const getTemplateGroup = () => (id ? api.get(`/v2/mx-risk/template-groups/${id}`) : null);

  return useQuery(['mxrisk.templateGroup', { id }], getTemplateGroup);
}

export function useMXRiskValidationItems() {
  const itemsPerPage = 50;

  const getMXRiskValidationItems = () => api.get('/v2/mx-risk/validation-items', {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get('/v2/mx-risk/validation-items', {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxrisk.validationItemsList'], getMXRiskValidationItems);
}
