import queryClient from 'query-client';
import { useMutation, useQuery } from 'react-query';

import api from 'services/api.service';

export function useList() {
  const getList = () => api.get('/v2/placement-tests').then((res) => res['hydra:member']);

  return useQuery(['placement.list'], getList);
}

export function useCreate() {
  const create = ({
    title, subject, body, placementType,
    emailAccount, recurring, scheduleSeconds, fromEmail,
  }) => api.post('/v2/placement-tests', {
    title,
    subject,
    body,
    placementType,
    emailAccount,
    recurring,
    scheduleSeconds,
    fromEmail,
  });

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries('placement.list');
    },
  });
}

export function useUpdate() {
  const update = ({ id, title, shared }) => api.patch(`/v2/placement-tests/${id}`, {
    title,
    shared,
  });

  return useMutation(update, {
    onSuccess: (placementTest) => {
      queryClient.invalidateQueries('placement.list');
      queryClient.invalidateQueries(['placement', { id: placementTest.id }]);
    },
  });
}

export function useDelete() {
  const deletePlacementTest = (id) => api.delete(`/v2/placement-tests/${id}`);

  return useMutation(deletePlacementTest, {
    onSuccess: () => {
      queryClient.invalidateQueries('placement.list');
    },
  });
}

export function useGet(id) {
  const getPlacementTest = () => api.get(`/v2/placement-tests/${id}`);

  return useQuery(['placement', { id }], getPlacementTest);
}

export function useGetRun({ id, runId }) {
  const getPlacementTestRun = () => api.get(`/v2/placement-tests/${id}/placement-test-runs/${runId}`);

  return useQuery(['placement.run', { id, runId }], getPlacementTestRun);
}

export function useGetRunList({
  id,
  page,
  perPage,
}) {
  const getPlacementTestRunList = () => api.get(`/v2/placement-tests/${id}/placement-test-runs`, {
    page,
    itemsPerPage: perPage,

  })
    .then((data) => ({
      items: data['hydra:member'],
      totalItems: data['hydra:totalItems'],
    }));

  return useQuery(['placement.runList', { id }], getPlacementTestRunList);
}
