/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';

import MXRiskSpeedometer from 'components/MXRiskSpeedometer';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { getSpeedometerLabelByScore, getRuleDataBySentiment } from 'resources/mxrisk/mxrisk.helpers';

import './styles.scss';

const EmailResult = ({ isLoading, analyzeResult }) => {
  const { width } = useWindowDimensions();
  const mobileWidth = width <= 1300;

  const scoreData = analyzeResult?.score !== undefined ? getSpeedometerLabelByScore(analyzeResult.score) : null;

  const overallReadabilityScore = analyzeResult?.mxrReadabilityRules
    .find((rule) => rule.name === 'Readability Score');
  const readingEase = analyzeResult?.mxrReadabilityRules.find((rule) => rule.name === 'Reading Ease');
  const readingGradeLevel = analyzeResult?.mxrReadabilityRules.find((rule) => rule.name === 'Reading Level');
  const wordCount = analyzeResult?.mxrReadabilityRules.find((rule) => rule.name === 'Word Count');
  let readingTime = analyzeResult?.mxrReadabilityRules.find((rule) => rule.name === 'Reading Time');

  if (readingTime) {
    readingTime = {
      ...readingTime,
      value: Math.ceil(readingTime.value),
    };
  }

  return (
    <div className="email-result-wrapper">
      <div className="email-result-description">
        <div className="email-result-speedometer-container">
          <div className="email-result-speedometer">
            <MXRiskSpeedometer
              key={Boolean(analyzeResult?.score && !isLoading)}
              size="large"
              score={typeof analyzeResult?.score === 'number' && !isLoading
                ? analyzeResult.score
                : null}
              reversed
              loading={isLoading}
            />
          </div>

          <div className="speedometer-title-wrapper">
            {isLoading ? (
              <>
                <div className="loading-item" style={{ width: 103 }} />
                <div className="loading-item" style={{ width: 40 }} />
              </>
            )
              : (
                <>
                  <p style={{ color: scoreData?.color, textTransform: 'uppercase' }}>{analyzeResult?.scoreLabel}</p>
                  <p>MX RISK</p>
                </>
              )}
          </div>
        </div>

        <div className="description-wrapper">
          {isLoading
            ? (
              <div className="description-placeholder-list">
                {(width <= 1000 ? [
                  [41, [96]],
                  [75, [107, 78]],
                  [155, [110, 100]],
                  [96, [80, 78]],
                  [114, [107, 100]],
                ] : [
                  [41, [96]],
                  [75, [277, 88]],
                  [155, [324, 139]],
                  [96, [245, 88]],
                  [114, [277, 160]],
                ]).map((item, index) => (
                  <div style={{ display: 'flex', gap: 30 }} key={index}>
                    <div style={{ display: 'flex', gap: 10, width: 160 }}>
                      <div className="loading-item" style={{ width: 33 }} />
                      <div className="loading-item" style={{ width: item[0] }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                      {/* // eslint-disable-next-line no-undef */}
                      {item[1].map((i, subIndex) => <div className="loading-item" style={{ width: i }} key={subIndex} />)}
                    </div>
                  </div>
                ))}
              </div>
            )
            : (
              <div className="description-content">
                {mobileWidth
                  ? (
                    <div className="mobile-result-items">
                      {analyzeResult?.rules.map((rule) => {
                        const ruleData = getRuleDataBySentiment(rule);

                        return (
                          <div className="mobile-result-item" key={rule.id}>
                            <div className="mobile-result-title">
                              {ruleData.icon}

                              <p>{rule.name}</p>
                            </div>
                            <p className="mobile-result-description">{rule.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  )
                  : (
                    <div>
                      <div className="table-head">
                        <p className="karma">karma</p>
                        <p className="rule">rule</p>
                        <p className="description">description</p>
                      </div>

                      <div className="table-body">
                        {analyzeResult?.rules.map((rule) => {
                          const ruleData = getRuleDataBySentiment(rule);

                          return (
                            <div className="table-body-item" key={rule.id}>
                              <div className="karma">{ruleData.icon}</div>
                              <p className="rule">{rule.name}</p>
                              <p className="description">{rule.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>

      <div className="email-result-footer">
        <div className={cn('footer-items', 'footer-items-loading')}>
          {isLoading
            ? Array(6).fill().map((_, index) => (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }} key={index}>
                <div className="loading-item" style={{ width: 103 }} />
                <div className="loading-item" style={{ width: 40 }} />
              </div>
            ))
            : (
              <>
                {overallReadabilityScore && (
                <div className="footer-item">
                  <p className="footer-item-title">{overallReadabilityScore.name}</p>
                  <p className="footer-item-text" style={{ color: getRuleDataBySentiment(overallReadabilityScore).color }}>
                    {overallReadabilityScore.value}
                  </p>
                </div>
                )}
                {readingEase && (
                <div className="footer-item">
                  <p className="footer-item-title">{readingEase.name}</p>

                  <div className="footer-item-content">
                    {getRuleDataBySentiment(readingEase).icon}

                    <p className="footer-item-text">
                      {readingEase.value}
                    </p>
                  </div>
                </div>
                )}
                {readingGradeLevel && (
                <div className="footer-item">
                  <p className="footer-item-title">{readingGradeLevel.name}</p>

                  <div className="footer-item-content">
                    {getRuleDataBySentiment(readingGradeLevel).icon}

                    <p className="footer-item-text">
                      {readingGradeLevel.value}
                    </p>
                  </div>
                </div>
                )}
                {wordCount && (
                <div className="footer-item">
                  <p className="footer-item-title">{wordCount.name}</p>

                  <div className="footer-item-content">
                    {getRuleDataBySentiment(wordCount).icon}

                    <p className="footer-item-text">
                      {wordCount.score}
                    </p>
                  </div>
                </div>
                )}
                {readingTime && (
                <div className="footer-item">
                  <p className="footer-item-title">{readingTime.name}</p>

                  <div className="footer-item-content">
                    {getRuleDataBySentiment(readingTime).icon}

                    <p className="footer-item-text">
                      {moment.duration(readingTime.value, 'seconds').asMinutes() >= 1
                        ? moment.utc(moment.duration(readingTime.value, 'seconds').asMilliseconds()).format('m[m] s[s]')
                        : `${moment.duration(readingTime.value, 'seconds').seconds()}s`}
                    </p>
                  </div>
                </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

EmailResult.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  analyzeResult: PropTypes.shape(),
};

EmailResult.defaultProps = {
  analyzeResult: null,
};

export default React.memo(EmailResult);
