/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { faSquareInfo } from '@fortawesome/pro-regular-svg-icons';

import { Center, Group, Stack, Text, Popover, Box } from '@mantine/core';

const BlacklistMatrixItem = ({
  item,
  backgroundColor,
  failed,
  firstTierPerRow,
  tierIndex,
  tierRowsIndex,
  tiersLength,
  tierRowsLength,
  scrollToServiceItem,
  disablePopover,
}) => {
  const isDomainDetail = item['@type'] === 'DomainDetail';
  const records = isDomainDetail ? item.domainRecords : item.ipRecords;

  return (
    <Popover
      disabled={!records?.length || disablePopover}
      width={240}
      position="top"
      radius={0}
      offset={2}
      withArrow
      arrowSize={16}
      styles={{
        dropdown: {
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23 !important',
          boxShadow: '0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
          borderColor: 'transparent',
        },
        arrow: {
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #050A23 !important',
          borderColor: 'transparent',
        },
      }}
    >
      <Popover.Target>
        <Center
          style={{
            padding: '12px 8px',
            backgroundColor: failed
              ? 'rgba(224, 27, 73, 0.50)'
              : backgroundColor,
            height: item.tier === 1 ? '100px' : '40px',
            ...(tierIndex === 0 && tierRowsIndex === firstTierPerRow - 1 && {
              borderTopRightRadius: 10,
            }),
            ...(tierIndex === tiersLength - 1 && tierRowsIndex === tierRowsLength - 1 && {
              borderBottomRightRadius: 10,
            }),
            ...(records?.length && !disablePopover && {
              cursor: 'pointer',
            }),
          }}
        >
          <Tooltip title={item.title} arrow placement="top">
            <Text
              className={cn('tier-item-title', { failed })}
              truncate
            >
              {item.title}
            </Text>
          </Tooltip>
        </Center>
      </Popover.Target>

      <Popover.Dropdown className="blacklist-matrix-item-popover">
        <Text className="blacklist-matrix-item-popover-title">Listed IP Addresses</Text>

        {!!records?.length && (
          <Stack spacing={0} className="blacklist-matrix-item-popover-list scroll">
            {records
              .sort((a, b) => {
                if (a.state?.value === 'Fail' && b.state?.value !== 'Fail') {
                  return -1;
                }

                if (a.state?.value !== 'Fail' && b.state?.value === 'Fail') {
                  return 1;
                }

                return 0;
              })
              .map((record) => {
                const isFailed = record.state?.value === 'Fail';

                return (
                  <Group
                    key={record['@id']}
                    className="popover-ip-record"
                    position="apart"
                    align="start"
                    noWrap
                  >
                    <Group spacing={8} align="start" noWrap>
                      <FontAwesomeIcon
                        icon={isFailed ? faBan : faCircleCheck}
                        color={isFailed ? '#F02B4F' : '#17EDC3'}
                        fontSize={16}
                        width={24}
                        height={24}
                        style={{ marginTop: '3px' }}
                      />

                      <Stack spacing={0}>
                        <Text className="popover-ip-record-title">
                          {isDomainDetail ? record.title : record.ipInfo?.ipAddress}
                        </Text>
                        <Text className="popover-ip-record-description">
                          {isDomainDetail ? record.description : record.ipInfo?.typeInfo}
                        </Text>
                      </Stack>
                    </Group>

                    <Group spacing={8} className="popover-ip-record-actions">
                      {!!scrollToServiceItem && (
                      <Tooltip title="View all blacklists" placement="top">
                        <Box sx={{ cursor: 'pointer' }} onClick={() => scrollToServiceItem(record)}>
                          <FontAwesomeIcon icon={faSquareInfo} color="#17EDC3" />
                        </Box>
                      </Tooltip>
                      )}
                    </Group>
                  </Group>
                );
              })}
          </Stack>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

BlacklistMatrixItem.propTypes = {
  item: PropTypes.shape().isRequired,
  backgroundColor: PropTypes.string.isRequired,
  failed: PropTypes.bool,
  firstTierPerRow: PropTypes.number.isRequired,
  tierIndex: PropTypes.number.isRequired,
  tierRowsIndex: PropTypes.number.isRequired,
  tiersLength: PropTypes.number.isRequired,
  tierRowsLength: PropTypes.number.isRequired,
  scrollToServiceItem: PropTypes.func,
  disablePopover: PropTypes.bool,
};

BlacklistMatrixItem.defaultProps = {
  failed: false,
  scrollToServiceItem: null,
  disablePopover: false,
};

export default React.memo(BlacklistMatrixItem);
