import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';

import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';

const IPScansOverview = ({
  items,
  loading,
  onRefetch,
  isError,
}) => {
  const totalGreen = items.length ? items.reduce((acc, item) => acc + item.green, 0) : 0;
  const totalRed = items.length ? items.reduce((acc, item) => acc + item.red, 0) : 0;
  const isEmpty = (totalGreen + totalRed) === 0;

  const biggestCount = items.reduce((acc, item) => {
    const checksCount = item.green > item.red ? item.green : item.red;

    return acc > checksCount ? acc : checksCount;
  }, 0);
  const biggestCountLength = biggestCount.toString().length;

  const healtyItems = items.length === 1
    ? [
      {
        y: items[0].green,
        x: moment(items[0].dateFrom).toDate(),
      },
      {
        y: items[0].green,
        x: moment(items[0].dateTo).toDate(),
      },
    ]
    : items.map((dataItem) => ({
      y: dataItem.green,
      x: moment(dataItem.dateFrom).toDate(),
    }));
  const blacklistedItems = items.length === 1
    ? [
      {
        y: items[0].red,
        x: moment(items[0].dateFrom).toDate(),
      },
      {
        y: items[0].red,
        x: moment(items[0].dateTo).toDate(),
      },
    ]
    : items.map((dataItem) => ({
      y: dataItem.red,
      x: moment(dataItem.dateFrom).toDate(),
    }));

  return (
    <div className={cn('item', 'graph-item')}>
      {loading && !isError && <CircularProgress className="loader" />}

      {isError && (
      <div className="item-error">
        <p>Something went wrong</p>

        <Button onClick={onRefetch}>Refresh</Button>
      </div>
      )}

      {!loading && !isError && (
      <div className="graph-content">
        <div>
          <p>IP Scans Overview</p>

          <div className="indicators">
            <div>
              <div className="indicator" />
              <p>Healthy</p>
              <p>{!isEmpty ? `${Math.round((totalGreen / (totalGreen + totalRed)) * 100)}%` : '-'}</p>
            </div>

            <div>
              <div className={cn('indicator', 'indicator-error')} />
              <p>Blacklisted</p>
              <p>{!isEmpty ? `${Math.round((totalRed / (totalGreen + totalRed)) * 100)}%` : '-'}</p>
            </div>
          </div>
        </div>

        <div style={{ height: 200, width: '100%' }}>
          <ResponsiveLine
            data={[{
              id: 'Healthy',
              color: '#17EDC3',
              data: items.length
                ? healtyItems
                : [{ y: 0, x: moment().toDate() }],
            }, {
              id: 'Blacklisted',
              color: '#F02B4F',
              data: items.length
                ? blacklistedItems
                : [{ y: 0, x: moment().toDate() }],
            }]}
            height={200}
            colors={{ scheme: 'dark2' }}
            theme={{
              grid: { line: {
                stroke: 'rgba(255, 255, 255, 0.08)',
                strokeWidth: 1,
              } },
              axis: { ticks: { text: {
                fontSize: 10,
                fill: 'rgba(255, 255, 255, 0.3)',
              } } },
              crosshair: { line: {
                stroke: 'rgba(255, 255, 255, 0.3)',
              } },
            }}
            defs={[
              linearGradientDef('gradient', [
                { offset: 0, color: 'inherit' },
                { offset: 80, color: 'inherit', opacity: 0.5 },
                { offset: 100, color: 'inherit', opacity: 0 },
              ]),
            ]}
            fill={[
              { match: '*', id: 'gradient' },
            ]}
            enableArea
            enablePoints={false}
            enableGridX={false}
            useMesh
            tooltip={({ point }) => (
              <div className="graph-tooltip">
                <p>{moment(point.data.xFormatted).format('MMMM DD, YYYY')}</p>
                <p>Total {point.serieId}: {point.data.y}</p>
              </div>
            )}
            margin={{
              bottom: 30,
              left: 28 + (biggestCountLength > 3 ? biggestCountLength * 6 : 0),
              right: 15,
              top: 10 }}
            axisLeft={{ tickSize: 0, tickPadding: 12 }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 12,
              tickValues: items.length < 7 ? items.length : 7,
              format: '%b %d',
            }}
            xScale={{ type: 'time' }}
          />
        </div>
      </div>
      )}
    </div>
  );
};

IPScansOverview.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired,
  onRefetch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

IPScansOverview.defaultProps = {
  items: [],
};

export default React.memo(IPScansOverview);
