import { useRouteError } from 'react-router-dom';

import { ErrorIcon } from 'assets/icons';

import Maintenance from 'pages/Maintenance';

import './styles.scss';

const Error = () => {
  const error = useRouteError();

  const isMaintenance = error?.status?.toString()[0] === '5';

  if (isMaintenance) {
    return <Maintenance />;
  }

  return (
    <div className="error-wrapper">
      <div className="error-placeholder">
        <ErrorIcon />

        <p>Something went wrong</p>

        <p>
          We apologize for the inconvenience.
          We are aware of the system error and our team is working hard to resolve the issue as soon as possible.
          Thank you for your patience.
        </p>

      </div>
    </div>
  );
};

export default Error;
