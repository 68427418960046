import React, { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { LicenseInfo } from '@mui/x-license-pro';

import queryClient from 'query-client';

import theme from 'theme';
import mantineTheme from 'theme/mantine-theme';

import { routes, scopes } from 'routes';

import { userApi } from 'resources/user';

import Error from 'pages/Error';
import Maintenance from 'pages/Maintenance';

import RedirectFromRoot from 'components/RedirectFromRoot';

import * as pusherService from 'services/pusher.service';

LicenseInfo
  .setLicenseKey('fff94d17e665944b31a733acd4bc2effTz03MTU4NCxFPTE3MjIwODc2MjcwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

const RouterContainer = () => {
  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    isError,
    error,
  } = userApi.useCurrentUser();
  const {
    data: accountsData,
  } = userApi.useConnectedAccounts();

  useEffect(() => {
    if (currentUser) {
      const userId = localStorage.getItem('userId');

      pusherService.connect({ userId });

      return;
    }

    pusherService.disconnect();
  }, [currentUser]);

  useEffect(() => {
    const lastAccountId = +localStorage.getItem('lastAccountId');

    if (accountsData && lastAccountId) {
      const isAccountExists = accountsData.some((accountData) => accountData.account.id === lastAccountId);

      if (isAccountExists) {
        sessionStorage.setItem('activeAccountId', lastAccountId);
      }
    }
  }, [accountsData]);

  const authenticatedPages = [];
  const notAuthenticatedPages = [];
  const generalPages = [];

  Object.values(routes).forEach((route) => {
    const pages = Array.isArray(route.path)
      ? route.path.map((path) => ({
        path,
        element: route.component,
        errorElement: <Error />,
      }))
      : [{
        path: route.path,
        element: route.component,
        errorElement: <Error />,
      }];

    pages.forEach((page) => {
      if (route.scope === scopes.none) {
        generalPages.push(page);

        return;
      }

      if (route.scope === scopes.authenticated) {
        authenticatedPages.push(page);

        return;
      }

      notAuthenticatedPages.push(page);
    });
  });

  const isMaintenance = error?.status?.toString()[0] === '5' || ['ERR_NETWORK', 'ECONNABORTED'].includes(error?.status);
  const isTermsNotAccepted = queryClient.getQueryData(['isTermsNotAccepted']);

  const pages = isMaintenance
    ? []
    : [...generalPages, ...(currentUser ? authenticatedPages : notAuthenticatedPages)];
  const router = createBrowserRouter([
    ...pages,
    {
      path: '*',
      element: isMaintenance ? <Maintenance /> : <RedirectFromRoot />,
      errorElement: <Error />,
    },
  ]);

  return (isCurrentUserLoading || (isError && !isMaintenance && !isTermsNotAccepted))
    ? <div />
    : (
      <RouterProvider router={router} key={!!currentUser} />
    );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <MantineProvider theme={mantineTheme}>
      <ThemeProvider theme={theme}>
        <NotificationsProvider
          autoClose={3000}
          position="top-right"
          limit={3}
          style={{
            zIndex: 1301,
          }}
        >
          <RouterContainer />
        </NotificationsProvider>
      </ThemeProvider>
    </MantineProvider>
  </QueryClientProvider>
);

export default App;
