import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { mxriskApi } from 'resources/mxrisk';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const AddFolderModal = ({
  open,
  onClose,
  onAdded,
}) => {
  const [name, setName] = useState('');

  const {
    mutate: createTemplateGroup,
    isLoading: isCreateTemplateGroupLoading,
  } = mxriskApi.useCreateTemplateGroup();

  const [errors, setErrors] = useState({
    name: null,
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [name]);

  const onSaveClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    createTemplateGroup({
      name,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Folder has been created');

        setName('');

        if (onAdded) {
          onAdded();
        }

        onClose();
      },
    });
  }, [validateForm, name, onClose, createTemplateGroup, onAdded]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add Folder"
    >
      <div className="create-template-group-modal-wrapper">
        <Input
          label="Folder Name"
          value={name}
          onChange={(value) => onChangeInput({ value, setter: setName, errorField: 'name' })}
          error={!!errors.name}
          helperText={errors.name}
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              onSaveClick();
            }
          }}
        />
        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
          <Button onClick={onSaveClick} loading={isCreateTemplateGroupLoading}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

AddFolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func,
};

AddFolderModal.defaultProps = {
  onAdded: null,
};

export default React.memo(AddFolderModal);
