import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Group, Box, Collapse, UnstyledButton, Text, Stack, Center } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';

import AddFolderModal from 'pages/TemplateMXRisk/components/AddFolderModal';

import { TEMPLATE_MX_RISK_LABEL } from 'layouts/authorized/components/Sidebar';

import TemplateMXRiskFolderMenu from './components/TemplateMXRiskFolderMenu';

import { useStyles } from './styles';

export const LinksGroup = ({
  icon: Icon, label, links, link, subLinks, opened, onClick, openedSubLink, setOpenedSubLink,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, theme } = useStyles();

  const hasLinks = Array.isArray(links);

  const [isAddEmailMXRiskGroupVisible, setIsAddEmailMXRiskGroupVisible] = useState(false);

  const onSectionClick = useCallback(() => {
    if (hasLinks) {
      onClick(label);
    }

    if (link) {
      navigate(link);
    }
  }, [onClick, label, link, navigate, hasLinks]);

  const ChevronIcon = useMemo(() => (theme.dir === 'ltr'
    ? IconChevronRight : IconChevronLeft), [theme.dir]);

  const locationPathName = useMemo(() => `${location.pathname}${location.hash || ''}`, [location.pathname, location.hash]);

  const isSublinkActive = useMemo(() => links?.some((item) => item.link === locationPathName
    || item.subLinks?.some((subLink) => subLink === locationPathName)
    || item.links?.some((subItem) => subItem.link === locationPathName
    || subItem.subLinks?.some((subLink) => subLink === locationPathName))), [links, locationPathName]);

  const isActive = useMemo(
    () => link === locationPathName || subLinks
      ?.some((subLink) => locationPathName.includes(subLink)) || isSublinkActive,
    [link, locationPathName, subLinks, isSublinkActive],
  );

  const renderLinks = useCallback(({ items, paddingLeft }) => items
    ?.map(({ icon: SubIcon, links: subItems, ...subLink }) => {
      const isActiveLink = subLink.link === locationPathName
    || subLink.subLinks?.some((item) => locationPathName === item
    || subItems?.some((i) => i.link === locationPathName || i.subLinks?.some((j) => locationPathName === j)));

      return (
        <Stack key={subLink.label} spacing={0}>
          <Group
            position="apart"
            className={cn(
              classes.link,
              (isActiveLink && !subItems) && classes.activeLink,
            )}
            onClick={() => {
              navigate(subLink.link);

              if (subItems) {
                setOpenedSubLink((prev) => (prev === subLink.label ? null : subLink.label));
              }
            }}
            sx={{ paddingLeft }}
            spacing={0}
            noWrap
          >
            <Group ml={36} w="100%">
              {SubIcon && <SubIcon />}

              <Group
                spacing={6}
                {...(subLink.templateMXRiskFolder && {
                  position: 'apart',
                  sx: { flex: 1 },
                })}
              >
                <Text className={classes.label}>{subLink.label}</Text>

                {subLink.templateMXRiskFolder && subLink.label !== 'Default' && (
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <TemplateMXRiskFolderMenu
                      className={classes.menu}
                      item={subLink.templateMXRiskFolder.item}
                    />
                  </Box>
                )}
              </Group>
            </Group>

            <Group noWrap>
              {subLink.label === TEMPLATE_MX_RISK_LABEL && (
              <Center
                className={classes.addFolder}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsAddEmailMXRiskGroupVisible(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Center>
              )}

              {subItems && !subLink.hideCollapseIcon && (
              <ChevronIcon
                className={classes.chevron}
                size="1rem"
                stroke={1.5}
                style={{
                  transform: openedSubLink === subLink.label ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
                }}
              />
              )}
            </Group>
          </Group>

          {subItems && (
          <Collapse in={openedSubLink === subLink.label || subLink.hideCollapseIcon}>
            {renderLinks({ items: subItems, paddingLeft: 28 })}
          </Collapse>
          )}
        </Stack>
      );
    }), [classes, locationPathName, navigate, theme.dir, openedSubLink, setOpenedSubLink]);

  const items = useMemo(() => renderLinks({ items: links, paddingLeft: 16 }), [links, renderLinks]);

  return (
    <>
      <AddFolderModal
        open={isAddEmailMXRiskGroupVisible}
        onClose={() => setIsAddEmailMXRiskGroupVisible(false)}
      />

      <UnstyledButton
        onClick={onSectionClick}
        className={cn(classes.control, isActive && classes.activeControl)}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon />
            <Text>{label}</Text>
          </Box>

          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>

      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
};

LinksGroup.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    subLinks: PropTypes.arrayOf(PropTypes.string),
  })),
  link: PropTypes.string,
  subLinks: PropTypes.arrayOf(PropTypes.string),
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  openedSubLink: PropTypes.string,
  setOpenedSubLink: PropTypes.func.isRequired,
};

LinksGroup.defaultProps = {
  links: null,
  link: null,
  subLinks: null,
  openedSubLink: null,
};

export default React.memo(LinksGroup);
