import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CrossIcon } from 'assets/icons';

import MUIModal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import './styles.scss';

const Modal = ({
  children,
  open,
  onClose,
  title,
  disableEscapeKeyDown,
  disableBackdropClick,
  style,
}) => {
  const onCloseClick = useCallback((_, reason) => {
    if (disableBackdropClick && reason && reason === 'backdropClick') {
      return;
    }

    onClose();
  }, [onClose, disableBackdropClick]);

  return (
    <MUIModal
      open={open}
      onClose={onCloseClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableBackdropClick={disableBackdropClick}
    >
      <div className="modal-container scroll" style={style}>
        <div className="title-wrapper">
          <p style={{ zIndex: 1 }}>{title}</p>

          <IconButton onClick={onClose}>
            <CrossIcon className="cross-icon" style={{ zIndex: 1 }} />
          </IconButton>
        </div>

        {children}
      </div>
    </MUIModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  style: PropTypes.shape(),
};

Modal.defaultProps = {
  disableEscapeKeyDown: false,
  disableBackdropClick: false,
  style: null,
};

export default React.memo(Modal);
