import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Stack, CircularProgress } from '@mui/material';

import Button from 'components/Button';
import MXRiskSpeedometer from 'components/MXRiskSpeedometer';

const EmailDeliverability = ({
  data,
  loading,
  onRefetch,
  isError,
}) => {
  const handleDeliveryScore = () => {
    if (data.totalCount === 0) {
      return 0;
    }

    return Math.floor((data.inboxCount / data.totalCount) * 100);
  };

  return (
    <div className={cn('item', 'email-deliverability-item')}>
      {loading && !isError && <CircularProgress className="loader" />}

      {isError && (
      <div className="item-error">
        <p>Something went wrong</p>

        <Button onClick={onRefetch}>Refresh</Button>
      </div>
      )}

      {!loading && !isError && (
      <div className="email-deliverability-block-content">
        <div>
          <p>Email Deliverability</p>
        </div>

        <Stack className="inbox-report-statistic" direction="row">
          <div>
            <div className="inbox-report-statistic-score">
              <MXRiskSpeedometer
                isRounded
                isOnlyArc
                providedGradients={['#17EDC3', '#0091FF']}
                size="giant"
                score={handleDeliveryScore()}
              />

              <div className="inbox-report-statistic-score-info">
                <p className="inbox-report-statistic-score-info-percents">{`${handleDeliveryScore()}%`}</p>
                <p className="inbox-report-statistic-score-info-text">MX Delivery Score</p>
              </div>
            </div>
          </div>

          <Stack spacing="18px">
            {[
              { label: 'Tests', value: data.testsCount },
              { label: 'Active', value: data.activeTestsCount },
              { label: 'Messages', value: data.totalCount },
            ].map((item) => (
              <Stack key={item.label} direction="row" className="inbox-report-statistic-item">
                <p className="inbox-report-statistic-item-text">{item.label}</p>
                <p className="inbox-report-statistic-item-value">{item.value}</p>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </div>
      )}
    </div>
  );
};

EmailDeliverability.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  onRefetch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

EmailDeliverability.defaultProps = {
  data: null,
};

export default React.memo(EmailDeliverability);
