import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import cn from 'classnames';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { ChartUpIcon, WaitingClockIcon } from 'assets/icons';

import { getDoughnutData, getDoughnutBackgroundColor } from './helpers/getCircleOptions';

import './styles.scss';

const STYLES_BY_SIZE = {
  medium: {
    circleMarkClassname: 'circle-mark_medium',
    size: 48,
  },
  small: {
    circleMarkClassname: 'circle-mark_small',
    size: 40,
  },
  tiny: {
    circleMarkClassname: 'circle-mark_tiny',
    size: 24,
  },
};

const ScoreCircle = ({
  mxrScoreTotal,
  mxrScoreTotalPass,
  ratingColor,
  ratingLabel,
  onlyCircle,
  size,
  blured,
  waiting,
  disabled,
}) => {
  const isLoading = !mxrScoreTotal && !mxrScoreTotalPass;
  const backgroundColor = getDoughnutBackgroundColor({
    color: ratingColor,
    size,
    disabled,
  });

  const doughnutData = !isLoading && getDoughnutData({
    mxrScoreTotal,
    mxrScoreTotalPass,
    ...(backgroundColor && { backgroundColor }),
  });

  const stylesBySize = STYLES_BY_SIZE[size];

  if (isLoading) {
    const circleSize = stylesBySize?.size || 160;

    return (
      <div className="circle-loader" style={{ width: circleSize, height: circleSize }}>
        <CircularProgress
          {...((disabled || blured) && { value: 75, variant: 'determinate' })}
          size={circleSize}
          color="neutral"
        />

        {blured && !waiting && <div className="blured-wrapper"><div /> <div /></div>}
        {!blured && !waiting && <p>Waiting</p>}
        {!blured && waiting && <WaitingClockIcon className="waiting-icon" />}
      </div>
    );
  }

  const CircleMark = () => (
    <div className={cn('circle-mark', stylesBySize?.circleMarkClassname)}>
      <Doughnut
        data={doughnutData.data}
        options={doughnutData.options}
        height={stylesBySize?.size}
      />

      <Typography
        className="mxr-mark"
        style={{ color: disabled ? 'rgba(255, 255, 255, 0.23)' : ratingColor }}
      >
        {ratingLabel}
      </Typography>
    </div>
  );

  if (onlyCircle) {
    return (
      <CircleMark />
    );
  }

  return (
    <div className="score-circle">
      <p>Score</p>

      <CircleMark />

      <div className="mxr-pass-percentage">
        <ChartUpIcon />
        <p>
          {doughnutData.passPerc}
          %
        </p>
      </div>
    </div>
  );
};

ScoreCircle.propTypes = {
  mxrScoreTotal: PropTypes.number,
  mxrScoreTotalPass: PropTypes.number,
  ratingColor: PropTypes.string,
  ratingLabel: PropTypes.string,
  onlyCircle: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'tiny']),
  blured: PropTypes.bool,
  waiting: PropTypes.bool,
  disabled: PropTypes.bool,
};

ScoreCircle.defaultProps = {
  mxrScoreTotal: null,
  mxrScoreTotalPass: null,
  ratingColor: '#FFFFFF',
  ratingLabel: 'U',
  onlyCircle: false,
  size: null,
  blured: false,
  waiting: false,
  disabled: false,
};

export default React.memo(ScoreCircle);
