import { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PLACEMENT_TEST_STATISTIC } from 'pages/DeliverabilityTest/components/TestCard';

import './styles.scss';

const TestEnded = ({ item }) => {
  const { lastRun } = item;

  const runStats = PLACEMENT_TEST_STATISTIC.map((stat) => ({
    ...stat,
    percent: item.lastRun
      ? ((item.lastRun[stat.id] / item.lastRun.total) * 100).toFixed(2)
      : 0,
  }))
  // remove 'Other' if it's 0
    .filter((stat) => stat.id !== 'other' || stat.percent > 0);

  return (
    <div className="test-ended-stats">
      <p className="test-ended-stats-text">Email Placement</p>
      <ul className="test-ended-stats-list">
        {runStats.map((stat) => (
          <li className="test-ended-stats-list-item" key={stat.text}>
            <div className="test-ended-stats-list-item-name">
              <div style={{ backgroundColor: stat.color }} />
              <p>{stat.text}</p>
            </div>
            <p className="test-ended-stats-list-item-percent">{stat.percent}%</p>
          </li>
        ))}
      </ul>
      <p className="test-ended-stats-last-scan">Last Scan: {moment(lastRun.createdAt).format('MMM DD, YYYY')}</p>
    </div>
  );
};

TestEnded.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default memo(TestEnded);
