import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import queryClient from 'query-client';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';
import UnauthorizedLayout from 'layouts/unauthorized';

import NotFound from 'pages/NotFound';

import {
  ArrowRightIcon, OutlinedPencilIcon, CheckIcon,
  CrossIcon, PencilIcon, BigShareIcon, DotsIcon, ShareIcon,
} from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';
import { emailApi } from 'resources/email';
import { userApi } from 'resources/user';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Input from 'components/Input';
import Button from 'components/Button';
import ShareReportModal from 'components/ShareReportModal';

import useOutsideClick from 'hooks/useOutsideClick';
import useWindowDimensions from 'hooks/useWindowDimensions';

import uiNotificationService from 'services/uiNotificatuion.service';

import SaveTemplateModal from './components/SaveTemplateModal';
import MoveToFolder from './components/MoveToFolder';

import EmailTemplate from '../components/EmailTemplate';
import EmailResult from '../components/EmailResult';

import './styles.scss';

// eslint-disable-next-line react/prop-types
const Layout = ({ children, currentUser }) => (currentUser
  ? <AuthorizedLayout>{children}</AuthorizedLayout>
  : <UnauthorizedLayout>{children}</UnauthorizedLayout>);

const TemplateMXRiskResult = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 700;

  const editNameRef = useRef(null);

  const [name, setName] = useState(null || '');
  const [isEditName, setIsEditName] = useState(false);

  const [menuEl, setMenuEl] = useState(null);

  const [isSaveTemplateModalVisible, setIsSaveTemplateModalVisible] = useState(false);
  const [isShareReportModalVisible, setIsShareReportModalVisible] = useState(false);

  const { data: currentUser } = userApi.useCurrentUser();
  const { data: analyzeResult, isFetching, refetch, error } = mxriskApi.useAnalyzeResult(+params.id);
  const { mutate: updateValidationItem, isLoading: isUpdateValidationItemLoading } = mxriskApi.useUpdateValidationItem();
  const { mutate: sendSharedReport, isLoading: isSendSharedReportLoading } = emailApi.useSendSharedReport();

  const groupId = analyzeResult?.group['@id'].replace('/v2/mx-risk/template-groups/', '');

  const isLoading = isFetching || analyzeResult?.status === 'in_process';

  useOutsideClick(editNameRef, () => setIsEditName(false));

  // in progress
  useEffect(() => {
    if (analyzeResult && analyzeResult.status === 'in_process') {
      const intervalId = setInterval(() => {
        const freshAnalyzeResult = queryClient.getQueryData(['mxrisk.analyzeResult', +params.id]);

        if (freshAnalyzeResult?.status === 'in_process') {
          refetch();

          return;
        }

        clearInterval(intervalId);
      }, 2000);
    }
  }, [analyzeResult, params.id, refetch]);

  // success
  useEffect(() => {
    if (analyzeResult && !['in_process', 'error'].includes(analyzeResult.status)) {
      setName(analyzeResult.name);
    }
  }, [analyzeResult]);

  // error
  useEffect(() => {
    if (analyzeResult && analyzeResult.status === 'error') {
      uiNotificationService.showError('Server error. Please try again');

      navigate(routes.templateMXRiskEdit.path.replace(':id', params.id));
    }
  }, [navigate, analyzeResult, params.id]);

  const onEditName = useCallback(() => {
    if (!name) {
      return;
    }

    updateValidationItem({
      id: analyzeResult.id,
      name,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Name has been updated');

        setIsEditName(false);
      },
    });
  }, [updateValidationItem, name, analyzeResult?.id]);

  const navigateToTemplateGroupPage = useCallback(() => {
    if (groupId) {
      navigate(routes.templateMXRiskFolder.path.replace(':templateGroupId', groupId));
    }
  }, [navigate, groupId]);

  const onShareSwitchChange = useCallback((isShare) => {
    updateValidationItem({
      id: analyzeResult.id,
      shared: isShare,
    });
  }, [updateValidationItem, analyzeResult?.id]);

  const onShareClick = useCallback(() => {
    setIsShareReportModalVisible(true);
  }, []);

  const openMenu = useCallback((e) => {
    setMenuEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuEl(null);
  }, []);

  const onSendReport = useCallback(({ email, message }) => {
    sendSharedReport({
      recipient: email,
      url: window.location.href,
      subject: 'Shared Template MX Risk report',
      message,
      resource: analyzeResult['@id'],
      linkText: 'Template MX Risk report',
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('The email was successfully sent');

        setIsShareReportModalVisible(false);
        closeMenu();
      },
    });
  }, [sendSharedReport, closeMenu, analyzeResult]);

  if (error?.status === 404) {
    return <NotFound />;
  }

  return (
    <Layout currentUser={currentUser}>
      {isSaveTemplateModalVisible && (
      <SaveTemplateModal
        open={isSaveTemplateModalVisible}
        onClose={() => setIsSaveTemplateModalVisible(false)}
        analyzeResult={analyzeResult}
        defaultName={name}
      />
      )}
      {isShareReportModalVisible && (
      <ShareReportModal
        open={isShareReportModalVisible}
        onClose={() => { setIsShareReportModalVisible(false); closeMenu(); }}
        linkToShare={window.location.href}
        isShareEnabled={analyzeResult.shared}
        onSwitchChange={onShareSwitchChange}
        isSwitchDisabled={isUpdateValidationItemLoading}
        onSend={onSendReport}
        isSendLoading={isSendSharedReportLoading}
        isOwner={analyzeResult.ownedByMe}
      />
      )}

      <div className="email-template-mxrisk-result-wrapper">
        <div>
          <div className="title-wrapper">
            <div className="title">
              {isEditName
                ? (
                  <div className="title-edit-mode" ref={editNameRef}>
                    <Input
                      className="name-input"
                      placeholder="Name"
                      value={name}
                      onChange={setName}
                      onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                          onEditName();
                        }
                      }}
                    />

                    <IconButton
                      onClick={onEditName}
                      disabled={!name}
                      className={cn('check-icon-button', !name && 'check-icon-button-disabled')}
                    >
                      <CheckIcon />
                    </IconButton>
                  </div>
                )
                : (
                  <div className="title-show-mode">
                    <p>{isLoading ? 'Loading...' : (name || 'Untitled template')}</p>

                    {!isLoading && analyzeResult?.ownedByMe && (
                    <Tooltip
                      onClick={() => setIsEditName(true)}
                      title="Edit"
                      placement="top"
                      className="edit-icon-container"
                    >
                      <OutlinedPencilIcon />
                    </Tooltip>
                    )}

                    {!isLoading && analyzeResult?.ownedByMe && (
                    <MoveToFolder
                      analyzeResult={analyzeResult}
                    />
                    )}
                  </div>
                )}
            </div>

            {!isLoading && !mobileWidth && (analyzeResult?.ownedByMe || analyzeResult?.shared) && (
            <IconButton onClick={onShareClick}>
              <BigShareIcon />
            </IconButton>
            )}

            {!isLoading && mobileWidth && (analyzeResult?.ownedByMe || analyzeResult?.shared) && (
            <div className={cn('menu-wrapper', menuEl && 'menu-wrapper-active')}>
              <IconButton onClick={openMenu}>
                <DotsIcon />
              </IconButton>

              <Menu
                open={!!menuEl}
                onClose={closeMenu}
                anchorEl={menuEl}
              >
                <MenuItem
                  onClick={onShareClick}
                  className="menu-item"
                >
                  <ShareIcon />
                  <p>Share</p>
                </MenuItem>
              </Menu>
            </div>
            )}
          </div>

          <div className="breadcrumbs">
            <p>Template MX Risk</p>
            <ArrowRightIcon />
            <p
              onClick={analyzeResult?.ownedByMe ? navigateToTemplateGroupPage : undefined}
              style={groupId && analyzeResult?.ownedByMe ? { cursor: 'pointer' } : undefined}
              aria-hidden
            >
              {analyzeResult?.group?.name || 'Result'}
            </p>
          </div>
        </div>

        <div className="result-wrapper">
          <EmailTemplate isLoading={isLoading} analyzeResult={analyzeResult} isHighlited={!isLoading} isEmail />
          <EmailResult isLoading={isLoading} analyzeResult={analyzeResult} />
        </div>

        {!isLoading && currentUser && (
        <div className="buttons">
          <Button
            className="close-button"
            color="secondaryButton"
            onClick={navigateToTemplateGroupPage}
            startIcon={<CrossIcon />}
            iconClassName="button-icon"
          >
            Close
          </Button>

          <Tooltip
            title={!analyzeResult?.ownedByMe ? 'Read-Only View: This template is owned by another team member.' : null}
            placement="top"
          >
            <div>
              <Button
                className={cn('edit-button', !analyzeResult?.ownedByMe && 'edit-button-disabled')}
                color="secondaryButton"
                onClick={() => navigate(routes.templateMXRiskEdit.path.replace(':id', params.id))}
                startIcon={<PencilIcon width={20} height={20} color="red" />}
                iconClassName="button-icon"
                disabled={!analyzeResult?.ownedByMe}
              >
                Edit
              </Button>
            </div>
          </Tooltip>
        </div>
        )}
      </div>
    </Layout>
  );
};

export default TemplateMXRiskResult;
