import { memo } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';

const containerMaxHeight = 140;

const BarItem = ({ item }) => {
  const { color, icon, percent, text } = item;

  const barHeight = Math.floor((percent / 100) * containerMaxHeight);

  return (
    <li className="deliverability-test-bar">
      <div className="bar-info">
        <FontAwesomeIcon fontSize={20} icon={icon} color={color} />
        <p style={{ color, marginLeft: '8px' }}>{text.toUpperCase()}</p>
      </div>
      <div className="bar-wrapper">
        <p>{percent}%</p>
        <div className="bar" style={{ height: barHeight, backgroundColor: color }} />
      </div>
    </li>
  );
};

BarItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default memo(BarItem);
