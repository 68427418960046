import { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { routes } from 'routes';

import { Card, CardHeader, IconButton, Chip, CardContent, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faRectangleAd, faHexagonExclamation, faCircleXmark,
  faEnvelopeCircleCheck, faCircleQuestion,
} from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

import { CupOfTeaIcon, PencilIcon, TrashIcon, SmallOpenedEyeIcon } from 'assets/icons';

import { placementApi } from 'resources/placement';

import { PLACEMENT_TEST_STATUSES } from 'resources/placement/placement.constants';

import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';

import { PLACEMENT_TYPE } from 'pages/DeliverabilityTest/helpers/tests';

import uiNotificationService from 'services/uiNotificatuion.service';

import SeedListInProgress from './components/SeedListInProgress';
import TestEnded from './components/TestEnded';
import ConnectedEmailInProgress from './components/ConnectedEmailInProgress';
import EditModal from './components/EditModal';
import EmailModal from './components/EmailModal';

import './styles.scss';

export const PLACEMENT_TEST_STATISTIC = [
  {
    id: 'inbox',
    text: 'Inbox',
    color: '#17EDC3',
    icon: faEnvelopeCircleCheck,
  },
  {
    id: 'promo',
    text: 'Promotions',
    color: '#FFD54F',
    icon: faRectangleAd,
  },
  {
    id: 'spam',
    text: 'Spam',
    color: '#FB3A61',
    icon: faHexagonExclamation,
  },
  {
    id: 'other',
    text: 'Other',
    color: '#E0E0E0',
    icon: faCircleQuestion,
  },
  {
    id: 'notDelivered',
    text: 'Not delivered',
    color: '#4E5576',
    icon: faCircleXmark,
  },
];

const TestCard = ({ item }) => {
  const navigate = useNavigate();

  const [menuEl, setMenuEl] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const {
    mutate: deletePlacementTest,
    isLoading: isDeletePlacementTestLoading,
  } = placementApi.useDelete();

  const itemId = item['@id'].replace('/v2/placement-tests/', '');

  const isSeedList = item.placementType === PLACEMENT_TYPE.SEED_LIST;
  const isInProgress = [PLACEMENT_TEST_STATUSES.ACTIVE, PLACEMENT_TEST_STATUSES.PENDING].includes(item.placementTestStatus);
  const chipLabel = isSeedList ? 'Seed List' : 'Connected Email';

  const onClick = useCallback(() => {
    const lastRunId = +item.lastRun['@id'].replace(`/v2/placement-tests/${itemId}/placement-test-runs/`, '');

    navigate(routes.deliverabilityTestDetails.path
      .replace(':id', itemId)
      .replace(':runId', lastRunId));
  }, [navigate, itemId, item.lastRun]);

  const onDelete = useCallback(() => {
    deletePlacementTest(itemId, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Test has been deleted');

        setIsDeleteModalVisible(false);
        setMenuEl(null);
      },
    });
  }, [deletePlacementTest, itemId]);

  const manageModalsVisibility = useCallback((e) => {
    const modalName = e.currentTarget.dataset.modal;

    switch (modalName) {
      case 'editModal':
        setIsEditModalVisible((prev) => !prev);

        break;
      case 'emailModal':
        setIsEmailModalVisible((prev) => !prev);

        break;
      case 'deleteModal':
        setIsDeleteModalVisible((prev) => !prev);

        break;
      default:
        break;
    }

    setMenuEl(null);
  }, []);

  const renderContent = () => {
    if (!isInProgress) {
      return <TestEnded item={item} />;
    }

    if (isSeedList && isInProgress) {
      return <SeedListInProgress item={item} />;
    }

    if (!isSeedList && isInProgress) {
      return <ConnectedEmailInProgress item={item} />;
    }

    return null;
  };

  const runStats = PLACEMENT_TEST_STATISTIC.map((stat) => ({
    ...stat,
    percent: item.lastRun
      ? Math.round((item.lastRun[stat.id] / item.lastRun.total) * 100)
      : 0,
  }))
  // remove 'Other' if it's 0
    .filter((stat) => stat.id !== 'other' || stat.percent > 0);

  // Check if all percent values are 0
  const allPercentsZero = runStats.every((stat) => stat.percent === 0);

  if (allPercentsZero) {
    runStats.push({
      id: 'noData',
      text: 'No data',
      color: 'rgba(255, 255, 255, 0.12)',
      percent: 100,
    });
  }

  return (
    <>
      {isEmailModalVisible && (
      <EmailModal
        item={item}
        open={isEmailModalVisible}
        onClose={() => setIsEmailModalVisible(false)}
      />
      )}

      {isEditModalVisible && (
      <EditModal
        open={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        item={item}
      />
      )}

      {isDeleteModalVisible && (
      <DeleteModal
        title="Are you sure you want to delete this test?"
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={onDelete}
        isDeleteLoading={isDeletePlacementTestLoading}
      />
      )}

      <Card
        sx={{
          borderRadius: '12px',
          backgroundColor: 'unset',
          backgroundImage: 'unset',
          boxShadow: 'unset',
        }}
        className="test-card"
      >
        <CardHeader
          className="test-card-header"
          sx={{
            padding: '24px',
          }}
          avatar={(
            <div className="test-card-header-icon-wrapper">
              <FontAwesomeIcon fontSize={24} icon={faEnvelope} color="#17EDC3" />
              {item.recurring && (
              <div className="test-card-header-refresh-icon">
                <FontAwesomeIcon fontSize={12} icon={faArrowsRotate} color="#000000DE" />
              </div>
              )}
            </div>
        )}
          action={(
            <div className={cn('menu-wrapper', menuEl && 'menu-wrapper-active')}>
              <IconButton onClick={(e) => setMenuEl(e.currentTarget)}>
                <MoreVert />
              </IconButton>

              <Menu
                open={!!menuEl}
                onClose={() => setMenuEl(null)}
                anchorEl={menuEl}
              >
                <MenuItem
                  className="menu-item"
                  data-modal="emailModal"
                  onClick={manageModalsVisibility}
                >
                  <SmallOpenedEyeIcon />
                  <p>View Email</p>
                </MenuItem>

                <MenuItem
                  className="menu-item"
                  data-modal="editModal"
                  onClick={manageModalsVisibility}

                >
                  <PencilIcon />
                  <p>Edit</p>
                </MenuItem>

                <MenuItem
                  className="menu-item"
                  disabled={false}
                  data-modal="deleteModal"
                  onClick={manageModalsVisibility}

                >
                  <TrashIcon />
                  <p>Delete</p>
                </MenuItem>
              </Menu>
            </div>
        )}
          title={<p className="test-card-header-title">{item.title}</p>}
          subheader={(
            <div className="test-card-header-subtitle">
              <p className="test-card-header-subtitle-text">{item.fromEmail}</p>
              <Chip className="test-card-header-subtitle-chip" label={chipLabel} />
            </div>
        )}
        />
        <CardContent
          sx={{ padding: 0 }}
          className="test-card-content"
        >
          {renderContent()}

          {isInProgress ? (
            <div className="test-card-content-cup-of-tea">
              <CupOfTeaIcon />
            </div>
          ) : (
            <div className="test-card-content-diagram">
              <div className="test-card-content-diagram-circle">
                <Doughnut
                  width={120}
                  data={{
                    datasets: [{
                      backgroundColor: runStats.map((stat) => stat.color),
                      pointRadius: 0,
                      pointHitRadius: 100,
                      borderWidth: 0,
                      data: runStats.map((stat) => stat.percent),
                    }],
                  }}
                  options={{
                    cutoutPercentage: 75,
                    spanGaps: false,
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    tooltips: { enabled: false },
                  }}
                />
              </div>

              <Button onClick={onClick} className="view-report-button" color="secondaryButton">
                View Report
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

TestCard.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default memo(TestCard);
