import { memo } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';

import './styles.scss';

const SeedListForm = ({ onChangeData, testData, errors }) => (
  <div className="deliverability-test-form-email-input-wrapper">
    <Input
      label="From Email"
      name="fromEmail"
      value={testData.fromEmail}
      onChange={onChangeData}
      error={!!errors.fromEmail}
      helperText={errors.fromEmail}
    />
  </div>
);

SeedListForm.propTypes = {
  testData: PropTypes.shape({
    body: PropTypes.string.isRequired,
    fromEmail: PropTypes.string.isRequired,
  }).isRequired,
  onChangeData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    fromEmail: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(SeedListForm);
