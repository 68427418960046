import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FilesIcon } from 'assets/icons';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const CopyEmailModal = ({
  open,
  onClose,
  email,
}) => {
  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(email);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [email]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Send an email to this email address"
    >
      <div className="copy-email-modal-wrapper">
        <p className="description">
          After you send the email, MX Right will scan it and generate the report in your MX Right admin panel.
          You can send unlimited emails.
        </p>

        <div className="copy-email-wrapper">
          <Input className="link-input" value={email} readOnly />

          <Button
            className="copy-button"
            startIcon={<FilesIcon />}
            iconClassName="copy-icon"
            onClick={onCopyToClipboard}
          >
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CopyEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default React.memo(CopyEmailModal);
