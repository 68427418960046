import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowBottomIcon, ArrowTopIcon } from 'assets/icons';

import { Divider, IconButton, Chip } from '@mui/material';

import { Grid, Text } from '@mantine/core';

import BlacklistMatrix from 'pages/Results/components/BlacklistMatrix';

import { getChipValue } from 'pages/MonitorResults';

import './styles.scss';

const BlacklistServiceItem = forwardRef(({ item, mobileWidth }, ref) => {
  const itemRef = useRef(null);

  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);

  const isHostDomainDetail = item['@type'] === 'HostDomainDetail';

  const onDescriptionOpenToggle = useCallback(() => {
    setIsDescriptionOpened(!isDescriptionOpened);
  }, [isDescriptionOpened]);

  useImperativeHandle(ref, () => ({
    openDescription: () => setIsDescriptionOpened(true),
    scrollToItem: () => {
      if (itemRef.current) {
        const elementPosition = itemRef.current.getBoundingClientRect().top + window.scrollY;
        const offset = 100; // Adjust this value based on your header height

        // Scroll to the element's position minus the offset
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    },
  }));

  return (
    <div ref={itemRef} className="honeypot-results-item blacklist-services-item">
      <Grid justify="space-between" columns={9}>
        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text
            className="blacklist-services-item-text"
            truncate
          >
            {isHostDomainDetail ? (item.title || '-') : (item.ipInfo?.ipAddress || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text className="honeypot-results-item-text" truncate>
            {isHostDomainDetail ? item.description : (item.ipInfo?.typeInfo || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text className="honeypot-results-item-text" truncate>
            {isHostDomainDetail ? '-' : (item.ipInfo?.domain || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text className="honeypot-results-item-text" truncate>
            {isHostDomainDetail ? '-' : (item.ipInfo?.ipDetail?.country || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={1} className="honeypot-results-item-column honeypot-results-item-chip">
          <div
            className="chip-and-arrow-wrapper"
            {...!mobileWidth && { style: { marginRight: '-35px' } }}
          >
            {item.state && (
            <div className="chip-wrapper">
              <Chip
                className="chip"
                label={getChipValue({ state: item.state, mobileWidth })}
                variant={item.state.value === 'Fail' ? 'filled' : 'outlined'}
                color={item.state.color}
              />
            </div>
            )}

            {!mobileWidth && (
            <IconButton onClick={onDescriptionOpenToggle}>
              {isDescriptionOpened
                ? <ArrowTopIcon className="arrow" />
                : <ArrowBottomIcon className="arrow" />}
            </IconButton>
            )}
          </div>
        </Grid.Col>
      </Grid>

      {mobileWidth && (
      <div className="more-info">
        <p>More info</p>

        <IconButton onClick={onDescriptionOpenToggle}>
          {isDescriptionOpened
            ? <ArrowTopIcon className="arrow" />
            : <ArrowBottomIcon className="arrow" />}
        </IconButton>
      </div>
      )}

      {isDescriptionOpened && (
      <BlacklistMatrix
        generalState={item.state}
        mobileWidth={mobileWidth}
        items={item.blocklistResultDetails}
        withoutTitle
        disablePopover
      />
      )}

      <Divider sx={{ my: '16px' }} />
    </div>
  );
});

BlacklistServiceItem.propTypes = {
  item: PropTypes.shape().isRequired,
  mobileWidth: PropTypes.bool.isRequired,
};

export default React.memo(BlacklistServiceItem);
