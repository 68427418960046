import { useQuery, useMutation } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useCreateIMAPAccount() {
  const createIMAPAccount = ({
    fromName,
    fromEmail,
    username,
    password,
    host,
    port,
    secureLevel,
    smtpUsername,
    smtpPassword,
    smtpHost,
    smtpPort,
    smtpSecureLevel,
  }) => api.post('/v2/connectivity/create-imap-account', {
    fromName,
    fromEmail,
    imap: {
      username,
      password,
      host,
      port: +port,
      secureLevel,
    },
    smtp: {
      smtpUsername,
      smtpPassword,
      smtpHost,
      smtpPort: +smtpPort,
      smtpSecureLevel,
    },
  });

  return useMutation(createIMAPAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}

export function useGetConnectivityGoogleAuthUrl() {
  const getConnectivityGoogleAuthUrl = () => api.get('/v2/connectivity/google-auth-url');

  return useMutation(getConnectivityGoogleAuthUrl);
}

export function useConnectivityEmailAccounts({
  page,
  perPage,
  pagination = true,
  accountStatus, // array or string
}) {
  const getConnectivityEmailAccounts = () => api.get('/v2/connectivity/email-accounts', {
    page,
    itemsPerPage: perPage,
    pagination,
    accountStatus,
  }).then((data) => ({
    totalItems: data['hydra:totalItems'],
    items: data['hydra:member'],
  }));

  return useQuery(['connectivity.list', { page, perPage, pagination, accountStatus }], getConnectivityEmailAccounts);
}

export function useConnectivityGoogleConnect() {
  const connectGoogleConnectivity = (code) => api.post('/v2/connectivity/google-connect', { code });

  return useMutation(connectGoogleConnectivity, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}

export function useUpdateConnectivityEmailAccountStatus() {
  const updateEmailConnectivityAccountStatus = ({
    id,
    status, // 'active' || 'archived'
  }) => api.patch(`/v2/connectivity/email-accounts/change-status/${id}`, { accountStatus: status });

  return useMutation(updateEmailConnectivityAccountStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}

export function useDeleteConnectivityEmailAccount() {
  const deleteConnectivityEmailAccount = (id) => api.delete(`/v2/connectivity/email-accounts/${id}`);

  return useMutation(deleteConnectivityEmailAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}

export function useCreateConnectivityMonitor() {
  const createConnectivityMonitor = ({
    emailAccountId,
    title,
    scheduleSeconds,
    notificationId,
    notificationEnabled,
  }) => api.post('/v2/connectivity/create-monitor', {
    emailAccount: `/v2/connectivity/email-accounts/${emailAccountId}`,
    title,
    scheduleSeconds,
    notificationId: `/v2/monitor-notifications/${notificationId}`,
    notificationEnabled,
  });

  return useMutation(createConnectivityMonitor, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
      queryClient.invalidateQueries('monitor.list');
    },
  });
}

export function useAutoDiscover() {
  const sendAutoDiscover = ({
    emailAddress,
  }) => api.post('/v2/connectivity/auto-discover', {
    emailAddress,
  });

  return useMutation(sendAutoDiscover);
}

export function useGetConnectivityMSAuthUrl() {
  const getConnectivityMSAuthUrl = () => api.get('/v2/connectivity/ms-auth-url');

  return useMutation(getConnectivityMSAuthUrl);
}

export function useConnectivityO365Connect() {
  const connectO365Connectivity = (code) => api.post('/v2/connectivity/o365-connect', { code });

  return useMutation(connectO365Connectivity, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}

export function useUpdateIMAPAccount() {
  const updateIMAPAccount = ({
    id,
    fromName,
    fromEmail,
    imap,
    smtp,
  }) => api.patch(`/v2/connectivity/email-accounts/${id}`, {
    ...(fromName && { fromName }),
    ...(fromEmail && { fromEmail }),
    ...(imap && { imap }),
    ...(smtp && { smtp }),
  });

  return useMutation(updateIMAPAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}

export function useReconnectConnectivityEmailAccount() {
  const reconnectConnectivityEmailAccount = (id) => api.post('/v2/connectivity/reactivate', {
    emailAccount: `/v2/connectivity/email-accounts/${id}`,
  });

  return useMutation(reconnectConnectivityEmailAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('connectivity.list');
    },
  });
}
