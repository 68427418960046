import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { Doughnut } from 'react-chartjs-2';

import { routes } from 'routes';

import { SmallClockIcon } from 'assets/icons';

import { placementApi } from 'resources/placement';

import { Box, MenuItem } from '@mui/material';

import Select from 'components/Select';
import ReportHistoryModal from 'components/ReportHistoryModal';

import { PLACEMENT_TEST_STATISTIC } from 'pages/DeliverabilityTest/components/TestCard';

import './styles.scss';

const PER_PAGE = 10;

const PlacementTestRunsHistorySelect = ({
  placementTestId,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const placementTestRunId = +params.runId;

  const [open, setOpen] = useState(false);
  const [isReportHistoryModalVisible, setIsReportHistoryModalVisible] = useState(false);

  const [page, setPage] = useState(1);

  const {
    data: placementTestRunsData,
    isLoading: isPlacementTestRunsLoading,
  } = placementApi.useGetRunList({
    id: placementTestId,
    page,
    perPage: PER_PAGE,
  });

  const onOpenReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(true);
    setOpen(false);
  }, []);

  const onCloseReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(false);
  }, []);

  const selectItems = placementTestRunsData
    ? placementTestRunsData.items.map((item) => ({
      value: item.id,
      label: moment(item.createdAt).format('MMM DD, YYYY [at] h:mmA'),
      ...item,
    }))
    : [];

  const onItemSelected = useCallback((id) => {
    navigate(routes.deliverabilityTestDetails.path
      .replace(':id', placementTestId)
      .replace(':runId', id));
  }, [navigate, placementTestId]);

  if (isPlacementTestRunsLoading) {
    return null;
  }

  return (
    <>
      {!!selectItems.length && isReportHistoryModalVisible && (
      <ReportHistoryModal
        open={isReportHistoryModalVisible}
        onClose={onCloseReportHistoryModal}
        forName="Deliverability Test"
        items={selectItems}
        page={page}
        perPage={PER_PAGE}
        totalItems={placementTestRunsData?.totalItems}
        onPageChanged={(newPage) => setPage(newPage)}
        onItemClick={(item) => onItemSelected(item.value)}
        isPlacementTest
      />
      )}

      {placementTestRunId && (
      <Select
        value={placementTestRunId}
        open={open}
        className="placement-test-runs-history-select"
        onChange={onItemSelected}
        onClick={() => setOpen(!open)}
        items={selectItems}
        renderValue={(selectedPlacementTestId) => {
          const placementTestRun = selectItems.find((item) => item.id === selectedPlacementTestId);

          return (
            <div className="placement-test-runs-history-select-value">
              <SmallClockIcon />
              <p>{moment(placementTestRun?.createdAt).format('M/DD/YYYY [at] h:mm:ss A')}
              </p>
            </div>
          );
        }}
      >
        {selectItems.slice(0, 5).map((item) => {
          const runStats = PLACEMENT_TEST_STATISTIC.map((stat) => ({
            ...stat,
            percent: Math.round((item[stat.id] / item.total) * 100),
          }))
          // remove 'Other' if it's 0
            .filter((stat) => stat.id !== 'other' || stat.percent > 0);

          return (
            <MenuItem
              key={item['@id']}
              value={item.value}
              className="placement-test-runs-history-select-menu-item"
            >
              <Box className="placement-test-runs-history-select-menu-item-score">
                <Doughnut
                  data={{
                    datasets: [{
                      backgroundColor: runStats.map((stat) => stat.color),
                      pointRadius: 0,
                      pointHitRadius: 100,
                      borderWidth: 0,
                      data: runStats.map((stat) => stat.percent),
                    }],
                  }}
                  options={{
                    cutoutPercentage: 75,
                    spanGaps: false,
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    tooltips: { enabled: false },
                  }}
                />
              </Box>

              <p className="label">{item.label}</p>
            </MenuItem>
          );
        })}

        {placementTestRunsData?.totalItems > 5 && (
        <div>
          <div className="placement-test-runs-history-select-line" />
          <p className="placement-test-runs-history-select-see-more" onClick={onOpenReportHistoryModal} aria-hidden>
            See more
          </p>
        </div>
        )}
      </Select>
      )}
    </>
  );
};

PlacementTestRunsHistorySelect.propTypes = {
  placementTestId: PropTypes.number.isRequired,
};

export default React.memo(PlacementTestRunsHistorySelect);
