import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ActionIcon } from '@mantine/core';

import { PencilIcon, TrashIcon } from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';

import { Box, Menu, MenuItem } from '@mui/material';

import DeleteModal from 'components/DeleteModal';
import RenameModal
  from 'pages/TemplateMXRisk/components/TemplateFolder/components/TemplateFolderMenu/components/RenameModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const TemplateMXRiskFolderMenu = ({
  item,
  className,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { mutate: deleteTemplateGroup, isLoading: isDeleteTemplateGroupLoading } = mxriskApi.useDeleteTemplateGroup();

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDelete = useCallback(() => {
    deleteTemplateGroup(item.id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Template Group has been deleted.');

        setIsDeleteModalVisible(false);
        closeMenu();
      },
    });
  }, [deleteTemplateGroup, closeMenu, item.id]);

  return (
    <Box>
      {isRenameModalVisible && (
      <RenameModal
        open={isRenameModalVisible}
        onClose={() => { setIsRenameModalVisible(false); closeMenu(); }}
        item={item}
      />
      )}
      {isDeleteModalVisible && (
      <DeleteModal
        title="Are you sure you want to delete this template group and all its templates?"
        open={isDeleteModalVisible}
        onClose={() => { setIsDeleteModalVisible(false); closeMenu(); }}
        onDelete={onDelete}
        isDeleteLoading={isDeleteTemplateGroupLoading}
      />
      )}

      <Box
        className={cn(className, {
          'template-mx-risk-folder-menu-active': anchorEl,
        })}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ActionIcon
          onClick={openMenu}
          {...anchorEl && ({ variant: 'light' })}
          disabled={isDeleteTemplateGroupLoading}
        >
          <FontAwesomeIcon icon={faEllipsis} fontSize={14} />
        </ActionIcon>

        <Menu
          open={!!anchorEl}
          onClose={closeMenu}
          anchorEl={anchorEl}
        >
          <MenuItem
            onClick={() => setIsRenameModalVisible(true)}
            className="menu-item"
          >
            <PencilIcon />
            <p>Edit</p>
          </MenuItem>
          <MenuItem
            onClick={() => setIsDeleteModalVisible(true)}
            className="menu-item"
          >
            <TrashIcon />
            <p>Delete</p>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

TemplateMXRiskFolderMenu.propTypes = {
  item: PropTypes.shape().isRequired,
  className: PropTypes.string,
};

TemplateMXRiskFolderMenu.defaultProps = {
  className: '',
};

export default React.memo(TemplateMXRiskFolderMenu);
