import { createTheme } from '@mui/material/styles';

const secondaryColor = 'rgba(255, 255, 255, 0.7)';

const theme = createTheme({
  palette: {
    primary: {
      main: '#17EDC3',
    },
    success: {
      main: '#17EDC3',
    },
    secondary: {
      main: secondaryColor,
    },
    secondaryButton: {
      main: 'rgba(255, 255, 255, 0.12)',
    },
    error: {
      main: '#F02B4F',
    },
    warning: {
      main: '#FFA726',
    },
    neutral: {
      main: 'rgba(255, 255, 255, 0.1)',
    },
    action: {
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabled: 'rgba(255, 255, 255, 0.3)',
    },

    mode: 'dark',
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: secondaryColor,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: secondaryColor,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-disabled': {
            color: '#757575',
            // eslint-disable-next-line max-len
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
          },
        },
        track: {
          backgroundColor: 'white',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#0091FF',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          lineHeight: '16px',
          ...(ownerState.color === 'default' && {
            color: 'white',
            border: '1.3px solid rgba(255, 255, 255, 0.26)',
          }),
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: '#9acfed',
          background: 'rgba(2, 136, 209, 0.12)',
        },
        standardError: {
          color: 'white',
          background: 'rgba(240, 43, 79, 0.30)',

          '& .MuiAlert-icon': {
            color: '#F02B4F',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: 'white',

          ...(ownerState.selected && {
            backgroundColor: 'rgba(255, 255, 255, 0.12) !important',
          }),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          color: 'rgba(255, 255, 255, 0.7)',
          textTransform: 'unset',
          padding: '9px 16px',
        }),
      },
    },
  },
});

export default theme;
