import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  DotsIcon, PencilIcon, TrashIcon,
} from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import DeleteModal from 'components/DeleteModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import RenameModal from './components/RenameModal';

const TemplateFolderMenu = ({ item, onDeleted }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { mutate: deleteTemplateGroup, isLoading: isDeleteTemplateGroupLoading } = mxriskApi.useDeleteTemplateGroup();

  const itemId = item['@id'].replace('/v2/mx-risk/template-groups/', '');

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDelete = useCallback(() => {
    deleteTemplateGroup(itemId, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Template Group has been deleted.');

        onDeleted();
        setIsDeleteModalVisible(false);
        closeMenu();
      },
    });
  }, [deleteTemplateGroup, itemId, closeMenu, onDeleted]);

  return (
    <>
      {isRenameModalVisible && (
      <RenameModal
        open={isRenameModalVisible}
        onClose={() => { setIsRenameModalVisible(false); closeMenu(); }}
        item={item}
      />
      )}
      <DeleteModal
        title="Are you sure you want to delete this template group and all its templates?"
        open={isDeleteModalVisible}
        onClose={() => { setIsDeleteModalVisible(false); closeMenu(); }}
        onDelete={onDelete}
        isDeleteLoading={isDeleteTemplateGroupLoading}
      />

      <div className={cn('menu-wrapper', anchorEl && 'menu-wrapper-active')}>
        <IconButton onClick={openMenu}>
          <DotsIcon />
        </IconButton>

        <Menu
          open={!!anchorEl}
          onClose={closeMenu}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={() => setIsRenameModalVisible(true)} className="menu-item">
            <PencilIcon />
            <p>Rename</p>
          </MenuItem>

          <MenuItem onClick={() => setIsDeleteModalVisible(true)} className="menu-item">
            <TrashIcon />
            <p>Delete</p>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

TemplateFolderMenu.propTypes = {
  item: PropTypes.shape().isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default React.memo(TemplateFolderMenu);
