import { showNotification } from '@mantine/notifications';

import { CheckCircleOutlinedIcon, ErrorOutlineIcon } from 'assets/icons';

const uiNotificationService = {
  showSuccess: (message) => {
    const textColor = 'rgba(0, 0, 0, 0.87)';

    showNotification({
      title: ' ',
      message,
      width: '100%',
      icon: <CheckCircleOutlinedIcon />,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.primary[4],
          borderColor: theme.colors.primary[4],
          minHeight: 56,
        },
        description: {
          color: textColor,
          fontWeight: 500,
          fontSize: 14,
        },
        closeButton: {
          width: 18,
          color: textColor,

          '&:hover': {
            background: theme.colors.primary[4],
            color: textColor,
          },
          '&:active': {
            background: theme.colors.primary[4],
            color: textColor,
          },
        },
        icon: {
          backgroundColor: 'transparent !important',
        },
      }),
    });
  },

  showError: (message) => {
    const textColor = 'white';

    showNotification({
      title: ' ',
      message,
      icon: <ErrorOutlineIcon />,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.red[4],
          borderColor: theme.colors.red[4],
          minHeight: 56,
        },
        description: {
          color: textColor,
          fontWeight: 500,
          fontSize: 14,
        },
        closeButton: {
          width: 18,
          color: textColor,

          '&:hover': {
            background: theme.colors.red[4],
            color: textColor,
          },
          '&:active': {
            background: theme.colors.red[4],
            color: textColor,
          },
        },
        icon: {
          backgroundColor: 'transparent !important',
        },
      }),
    });
  },
};

export default uiNotificationService;
