import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { routes } from 'routes';

import { accountApi } from 'resources/account';

import {
  TwitterIcon, LinkedinSmallIcon, YoutubeIcon,
  FacebookIcon, AuthLogoIcon, CodeMonitorIcon,
} from 'assets/icons';

import IconButton from '@mui/material/IconButton';

import './styles.scss';

const Maintenance = () => {
  const navigate = useNavigate();

  const { status, refetch } = accountApi.useHealthStatus();

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [refetch]);

  if (status === 'success') {
    navigate(-1);
  }

  return (
    <div className="maintenance-wrapper">
      <aside className="sidebar">
        <Link to={routes.dashboard.path}>
          <AuthLogoIcon />
        </Link>

        <div className="line" />

        <div className="menu">
          {[74, 103, 63, 96, 84].map((labelWidth) => (
            <div key={labelWidth}>
              <div className="circle" />

              <div className="label" style={{ width: labelWidth }} />
            </div>
          ))}
        </div>
      </aside>

      <div className="content">
        <div>
          <CodeMonitorIcon />
          <p className="title">Maintenance in Progress</p>
          <p className="text">
            We are currently performing system maintenance. During this time, you may experience some disruption in service.
            We apologize for any inconvenience and appreciate your patience.
          </p>
        </div>
      </div>

      <footer>
        <div>
          <p className="rights">© {moment().year()} MX Right. All rights reserved.</p>
          {/* <a href="https://help.mxright.com" target="_blank" rel="noopener noreferrer"><p>Knowledge Base</p></a> */}
          <a href="https://api.mxright.com/v2/docs?ui=re_doc" target="_blank" rel="noopener noreferrer">
            <p>MX Right API</p>
          </a>
          <a href="https://mxright.com/privacy-policy" target="_blank" rel="noopener noreferrer"><p>Privacy Policy</p></a>
          <a href="https://mxright.com/terms" target="_blank" rel="noopener noreferrer"><p>Terms</p></a>
        </div>

        <div className="social-container">
          {/* <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><TwitterIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><LinkedinSmallIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><YoutubeIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><FacebookIcon /></IconButton> */}
        </div>
      </footer>
    </div>
  );
};

export default Maintenance;
