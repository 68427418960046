import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';
import MUIRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import './styles.scss';

const RadioGroup = ({ value, onChange, items, isRow, name }) => (
  <MUIRadioGroup
    row={isRow}
    aria-labelledby="demo-controlled-radio-buttons-group"
    value={value}
    onChange={(e) => onChange(e.target.value, name)}
  >
    {items.map((item) => (
      <FormControlLabel
        key={item.value}
        value={item.value}
        control={<Radio />}
        label={item.label}
        disabled={item.disabled}
      />
    ))}
  </MUIRadioGroup>
);

RadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  isRow: PropTypes.bool,
  name: PropTypes.string,
};

RadioGroup.defaultProps = {
  isRow: false,
  name: null,
};

export default React.memo(RadioGroup);
