import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import Button from 'components/Button';

import { checkSelectItems } from 'pages/IPMonitor/components/AddOrEditIPMonitorModal';

import './styles.scss';

const PricingPlanErrorModal = ({
  open,
  onClose,
  violations,
}) => {
  if (!violations) {
    return null;
  }

  const messageCount = {};
  let mergedData = [];

  violations.forEach((item) => {
    const { message } = item;
    messageCount[message] = (messageCount[message] || 0) + 1;
  });

  violations.forEach((item) => {
    const { message } = item;

    if (messageCount[message] > 1) {
      mergedData.push(`You have ${messageCount[message]} "${message}" messages`);
    } else {
      mergedData.push(message);
    }
  });

  mergedData = mergedData.map((str) => {
    // Regular expression pattern to match the desired format
    const pattern = /You have (\d+) "Frequency (.+?) is not allowed" message(s?)/;

    // Check if the string matches the pattern
    if (pattern.test(str)) {
      // Replace the matched pattern with the desired format
      return str.replace(pattern, 'You have $1 monitors using a $2 check frequency');
    }

    // Return the string unchanged if it doesn't match the pattern
    return str;
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="You can't switch to this plan because:"
    >
      <div className="pricing-plan-error-modal-wrapper">

        <div className="list">
          {[...new Set(mergedData)].map((message) => {
            checkSelectItems.forEach((item) => {
              const regex = new RegExp(`${item.value}`, 'g');
              message = message.replace(regex, `${item.label}`);
            });

            return (
              <p key={message}>- {message}</p>
            );
          })}
        </div>

        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

PricingPlanErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  violations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default React.memo(PricingPlanErrorModal);
