import { useCallback, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from 'dayjs';

import AuthorizedLayout from 'layouts/authorized';

import { dashboardApi } from 'resources/dashboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';

import CookieConsent from 'components/CookieConsent';

import IconButton from '@mui/material/IconButton';

import useWindowDimensions from 'hooks/useWindowDimensions';

import MXRiskChecks from './components/MXRiskChecks';
import IPChecks from './components/IPChecks';
import MXRiskTemplateChecks from './components/MXRiskTemplateChecks';
import EmailMXRiskChecks from './components/EmailMXRiskChecks';
import IPScansOverview from './components/IPScansOverview';
import EmailInfrastructureDoughnut from './components/EmailInfrastructureDoughnut';
import IPScansList from './components/IPScansList';
import EmailScansList from './components/EmailScansList';
import EmailDeliverability from './components/EmailDeliverability';
import EmailAccounts from './components/EmailAccounts';

import './styles.scss';

const today = dayjs();

const shortcutsItems = [
  {
    label: 'Last 7 Days',
    getValue: () => [today.subtract(7, 'days'), today],
  },
  {
    label: 'Last 30 Days',
    getValue: () => [today.subtract(30, 'days'), today],
  },
];

const Dashboard = () => {
  const { width } = useWindowDimensions();
  const mobileWidth = width <= 800;

  const defaultDateRangeValue = [dayjs().subtract(7, 'day'), today];
  const [dateRangeValue, setDateRangeValue] = useState(defaultDateRangeValue);
  const [confirmedDateRangeValue, setConfirmedDateRangeValue] = useState(defaultDateRangeValue);

  const from = moment(dayjs(confirmedDateRangeValue[0]).toDate()).format();
  const to = moment(dayjs(confirmedDateRangeValue[1]).toDate()).format();

  const {
    data: emailChecks,
    isFetching: isEmailChecksFetching,
    refetch: refetchEmailChecks,
    dataUpdatedAt: emailChecksUpdatedAt,
    isError: isEmailChecksError,
  } = dashboardApi.useEmailChecksCount({
    from,
    to,
  });
  const {
    data: ipChecks,
    isFetching: isIPChecksFetching,
    refetch: refetchIPChecks,
    dataUpdatedAt: ipChecksUpdatedAt,
    isError: isIPChecksError,
  } = dashboardApi.useIPChecksCount({
    from,
    to,
  });
  const {
    data: mxriskTemplateChecks,
    isFetching: isMXRiskTemplateFetching,
    refetch: refetchMXRiskTemplate,
    dataUpdatedAt: mxriskTemplateUpdatedAt,
    isError: isMXRiskTemplateError,
  } = dashboardApi.useMXRiskTemplateChecksCount({
    from,
    to,
  });
  const {
    data: emailMXRiskChecks,
    isFetching: isEmailMXRiskChecksFetching,
    refetch: refetchEmailMXRiskChecks,
    dataUpdatedAt: emailMXRiskChecksUpdatedAt,
    isError: isEmailMXRiskChecksError,
  } = dashboardApi.useEmailMXRiskChecksCount({
    from,
    to,
  });
  const {
    data: ipScans,
    isFetching: isIpScansFetching,
    refetch: refetchIpScans,
    dataUpdatedAt: ipScansUpdatedAt,
    isError: isIpScansError,
  } = dashboardApi.useIPScansOverview({
    from,
    to,
  });
  const {
    data: emailScans,
    isFetching: isEmailScansFetching,
    refetch: refetchEmailScans,
    dataUpdatedAt: emailScansUpdatedAt,
    isError: isEmailScansError,
  } = dashboardApi.useEmailScansSummary({
    from,
    to,
  });
  const {
    data: ipMonitorChecks,
    isFetching: isIPMonitorChecksFetching,
    refetch: refetchIPMonitorChecks,
    dataUpdatedAt: ipMonitorChecksUpdatedAt,
    isError: isIPMonitorChecksError,
  } = dashboardApi.useIPScansList({
    from,
    to,
  });
  const {
    data: emailScansChecks,
    isFetching: isEmailScansChecksFetching,
    refetch: refetchEmailScansChecks,
    dataUpdatedAt: emailScansChecksUpdatedAt,
    isError: isEmailScansChecksError,
  } = dashboardApi.useInfrastructureScansList({
    from,
    to,
  });
  const {
    data: emailAccountsOveriverview,
    isFetching: isEmailAccountsOveriverviewFetching,
    refetch: refetchEmailAccountsOveriverview,
    dataUpdatedAt: emailAccountsOveriverviewUpdatedAt,
    isError: isEmailAccountsOveriverviewError,
  } = dashboardApi.useEmailAccountsOveriverview({
    from,
    to,
  });
  const {
    data: placementTestsOverview,
    isFetching: isPlacementTestsOverviewFetching,
    refetch: refetchPlacementTestsOverview,
    dataUpdatedAt: placementTestsOverviewUpdatedAt,
    isError: isPlacementTestsOverviewError,
  } = dashboardApi.usePlacementTestsOverview({
    from,
    to,
  });

  const isFetching = isEmailChecksFetching || isIPChecksFetching || isIpScansFetching
  || isMXRiskTemplateFetching || isEmailMXRiskChecksFetching || isEmailScansFetching
  || isIPMonitorChecksFetching || isEmailScansChecksFetching
  || isEmailAccountsOveriverviewFetching || isPlacementTestsOverviewFetching;

  const latesDataUpdatedDate = moment.max([
    emailChecksUpdatedAt, ipChecksUpdatedAt, mxriskTemplateUpdatedAt,
    emailMXRiskChecksUpdatedAt, ipScansUpdatedAt, emailScansUpdatedAt,
    ipMonitorChecksUpdatedAt, emailScansChecksUpdatedAt,
    emailAccountsOveriverviewUpdatedAt, placementTestsOverviewUpdatedAt]
    .map((date) => moment(date)));

  const onRefetchAll = useCallback(() => {
    refetchEmailChecks();
    refetchIPChecks();
    refetchMXRiskTemplate();
    refetchEmailMXRiskChecks();
    refetchIpScans();
    refetchEmailScans();
    refetchIPMonitorChecks();
    refetchEmailScansChecks();
    refetchEmailAccountsOveriverview();
    refetchPlacementTestsOverview();
  }, [refetchEmailChecks, refetchIPChecks, refetchMXRiskTemplate,
    refetchEmailMXRiskChecks, refetchIpScans, refetchEmailScans,
    refetchIPMonitorChecks, refetchEmailScansChecks,
    refetchEmailAccountsOveriverview, refetchPlacementTestsOverview]);

  const onDateRangeChange = useCallback((newDateRangeValue) => {
    setDateRangeValue(newDateRangeValue);
  }, []);

  const onAcceptDateRange = useCallback((newDateRangeValue) => {
    if (!newDateRangeValue[0] || !newDateRangeValue[1]
    || newDateRangeValue[0] > today || newDateRangeValue[1] > today
    || newDateRangeValue[0] > newDateRangeValue[1]
    || !newDateRangeValue[0].isValid() || !newDateRangeValue[1].isValid()) {
      return;
    }

    setConfirmedDateRangeValue(newDateRangeValue);
  }, []);

  return (
    <AuthorizedLayout>
      <div className="dashboard-wrapper">
        <div>
          <div className="title">
            <p>Dashboard</p>

            <div className="period-wrapper">
              <div>
                <IconButton
                  className={cn(isFetching && 'rotate-365')}
                  onClick={onRefetchAll}
                >
                  <FontAwesomeIcon icon={faArrowsRotate} width={14} color="rgba(255, 255, 255, 0.50)" />
                </IconButton>
                <p>
                  Last sync at {moment(latesDataUpdatedDate).format('MMMM D, YYYY hh:mmA')}
                </p>
              </div>

              <div className="date-range-wrapper">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    value={dateRangeValue}
                    onChange={onDateRangeChange}
                    slots={{
                      field: SingleInputDateRangeField,
                    }}
                    slotProps={{
                      field: { value: dateRangeValue },
                      ...(width > 500 && {
                        shortcuts: {
                          items: shortcutsItems,
                        },
                        actionBar: { actions: ['cancel', 'accept'] },
                      }),
                    }}
                    sx={{ width: 225 }}
                    calendars={mobileWidth ? 1 : 2}
                    maxDate={today}
                    closeOnSelect={false}
                    onAccept={onAcceptDateRange}
                    disableAutoMonthSwitching
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-content">
          <div className="count-group">
            <MXRiskChecks
              data={emailChecks}
              loading={isEmailChecksFetching}
              onRefetch={refetchEmailChecks}
              isError={isEmailChecksError}
            />
            <IPChecks
              data={ipChecks}
              loading={isIPChecksFetching}
              onRefetch={refetchIPChecks}
              isError={isIPChecksError}
            />
            <MXRiskTemplateChecks
              data={mxriskTemplateChecks}
              loading={isMXRiskTemplateFetching}
              onRefetch={refetchMXRiskTemplate}
              isError={isMXRiskTemplateError}
            />
            <EmailMXRiskChecks
              data={emailMXRiskChecks}
              loading={isEmailMXRiskChecksFetching}
              onRefetch={refetchEmailMXRiskChecks}
              isError={isEmailMXRiskChecksError}
            />
          </div>
          <div className="graph-group">
            <IPScansOverview
              key={confirmedDateRangeValue}
              items={ipScans}
              loading={isIpScansFetching}
              onRefetch={refetchIpScans}
              isError={isIpScansError}
            />
            <EmailInfrastructureDoughnut
              data={emailScans}
              loading={isEmailScansFetching}
              onRefetch={refetchEmailScans}
              isError={isEmailScansError}
            />
          </div>
          <div className="graph-group">
            <EmailDeliverability
              data={placementTestsOverview}
              loading={isPlacementTestsOverviewFetching}
              onRefetch={refetchPlacementTestsOverview}
              isError={isPlacementTestsOverviewError}
            />
            <EmailAccounts
              data={emailAccountsOveriverview}
              loading={isEmailAccountsOveriverviewFetching}
              onRefetch={refetchEmailAccountsOveriverview}
              isError={isEmailAccountsOveriverviewError}
            />
          </div>
          <div className="list-group">
            <IPScansList
              data={ipMonitorChecks}
              loading={isIPMonitorChecksFetching}
              onRefetch={refetchIPMonitorChecks}
              isError={isIPMonitorChecksError}
            />
            <EmailScansList
              data={emailScansChecks}
              loading={isEmailScansChecksFetching}
              onRefetch={refetchEmailScansChecks}
              isError={isEmailScansChecksError}
            />
          </div>
        </div>
      </div>

      <CookieConsent />
    </AuthorizedLayout>
  );
};

export default Dashboard;
