import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { connectivityApi } from 'resources/connectivity';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const EditEmailProviderModal = ({
  open,
  onClose,
  item,
}) => {
  const [fromName, setFromName] = useState(item.fromName);

  const { mutate: updatedIMAPAccount, isLoading: isUpdateIMAPAccountLoading } = connectivityApi.useUpdateIMAPAccount();

  const [errors, setErrors] = useState({
    fromName: null,
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!fromName) {
      newErrors.fromName = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [fromName]);

  const onSaveClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    updatedIMAPAccount({
      id: item.id,
      fromName,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email account has been updated');

        onClose();
      },
      onError: (error) => {
        if (error.data.violations?.length) {
          const filedsErrors = {};

          error.data.violations.forEach((violation) => {
            filedsErrors[violation.propertyPath] = violation.message;
          });

          setErrors((oldErrors) => ({
            ...oldErrors,
            ...filedsErrors,
          }));
        }
      },
    });
  }, [validateForm, updatedIMAPAccount, item.id, fromName, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit Email Account"
      className="edit-email-provider-modal"
    >
      <div className="edit-email-provider-modal-wrapper">

        <div>
          <Input
            label="From Name"
            value={fromName}
            onChange={(value) => onChangeInput({ value, setter: setFromName, errorField: 'fromName' })}
            error={!!errors.fromName}
            helperText={errors.fromName}
          />

          <div className="confirm-buttons">
            <Button
              className="cancel-button"
              color="secondaryButton"
              onClick={onClose}
              disabled={isUpdateIMAPAccountLoading}
            >
              Cancel
            </Button>
            <Button
              className="save-button"
              onClick={onSaveClick}
              loading={isUpdateIMAPAccountLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditEmailProviderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export default React.memo(EditEmailProviderModal);
