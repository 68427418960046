import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { WatchingEyeIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import CircularProgress from '@mui/material/CircularProgress';

import Modal from 'components/Modal';

import './styles.scss';

const MonitorListModal = ({ open, onClose, notification }) => {
  const { data: monitors, isLoading: isMonitorListLoading } = monitorApi.useMonitorListByNotification(notification.id);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Monitors for ${notification.presetName}`}
    >
      <div className="monitor-list-modal-wrapper">
        <div className="line" />

        {!isMonitorListLoading ? (
          <div className="monitor-table">
            <div className="monitor-table-head">
              <p className="name">Name</p>
              <p className="type">Type</p>
              <p className="status">Status</p>
            </div>

            <div className="monitor-table-body">
              {monitors.map((monitor) => (
                <div key={monitor.id}>
                  <div className="line" />

                  <div className={cn('monitor-table-row', monitor.status === 'disabled' && 'monitor-table-row-disabled')}>
                    <div className="name">
                      <div>
                        <WatchingEyeIcon />
                      </div>

                      <p>{monitor.title}</p>
                    </div>

                    <div className="type">
                      <p>{monitor.type} Monitor</p>
                    </div>

                    <div className="status">
                      <p>{monitor.status}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
          : <CircularProgress className="loader" />}
      </div>
    </Modal>
  );
};

MonitorListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.shape().isRequired,
};

export default React.memo(MonitorListModal);
