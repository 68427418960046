export const colors = {
  primary: [
    '#E7FDF9',
    '#BDFAEE',
    '#92F7E3',
    '#68F3D8',
    '#12EDC2', // main (index: 4)
    '#12EDB2',
    '#0FBD9B',
    '#0B8E74',
    '#075F4E',
    '#042F27',
  ],
  red: [
    '#FDEDED',
    '#F9CFCF',
    '#F6B1B1',
    '#F29393',
    '#EF7575',
    '#EB5757',
    '#D74949',
    '#C43B3B',
    '#B02C2C',
    '#9D1E1E',
  ],
};
