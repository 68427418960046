import React, { forwardRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor, AccessibilityHelp, Alignment, AutoLink, Autosave, BalloonToolbar,
  BlockQuote, Bold, Essentials, GeneralHtmlSupport, Heading, Italic, Link,
  List, MediaEmbed, Paragraph, RemoveFormat, SelectAll, SourceEditing, SpecialCharacters,
  Underline, Undo,
} from 'ckeditor5';

// eslint-disable-next-line import/no-unresolved
import 'ckeditor5/ckeditor5.css';

import './styles.scss';

const editorDefaultConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'sourceEditing',
      '|',
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'removeFormat',
      '|',
      // 'specialCharacters',
      'link',
      'mediaEmbed',
      'blockQuote',
      '|',
      'alignment',
      '|',
      'bulletedList',
      'numberedList',
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Essentials,
    // FullPage,
    GeneralHtmlSupport,
    Heading,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    RemoveFormat,
    SelectAll,
    SourceEditing,
    SpecialCharacters,
    Underline,
    Undo,
  ],
  // balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph',
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5',
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6',
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /^((?!(script|object|iframe|embed)).)*$/, // Disallow script/object/frame/embed tags
        styles: true,
        attributes: true,
        classes: true,
      },
    ],
  },
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file',
        },
      },
    },
  },
  placeholder: 'Type or paste your content here!',
};

const Editor = forwardRef((props, ref) => (
  <CKEditor
    editor={ClassicEditor}
    config={editorDefaultConfig}
    {...props}
    ref={ref}
  />
));

export default React.memo(Editor);
