import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { routes } from 'routes';

import {
  AuthLogoIcon, TwitterIcon, LinkedinSmallIcon,
  YoutubeIcon, FacebookIcon,
} from 'assets/icons';

import IconButton from '@mui/material/IconButton';

import useWindowDimensions from 'hooks/useWindowDimensions';

import AuthMenu from 'components/AuthMenu';
import Sidebar from './components/Sidebar';
import Header from './components/Header';

import './styles.scss';

const AuthorizedLayout = ({ children }) => {
  const { width } = useWindowDimensions();
  const mobileWidth = width <= 700;

  return (
    <>
      <Helmet>
        <meta name="robots" content="none" />
      </Helmet>

      <div className="authorized-wrapper">
        {!mobileWidth && (
        <aside className="sidebar scroll">
          <div>
            <Link to={routes.dashboard.path}>
              <AuthLogoIcon />
            </Link>

            <div className="line" style={{ margin: '32px 0' }} />

            <Sidebar />
          </div>

          <AuthMenu withName />
        </aside>
        )}

        <div className="footer-and-content-wrapper">
          <div className="content">
            {mobileWidth && <Header />}

            {children}
          </div>

          <footer>
            <div>
              <p className="rights">© {moment().year()} MX Right. All rights reserved.</p>
              {/* <a href="https://help.mxright.com" target="_blank" rel="noopener noreferrer"><p>Knowledge Base</p></a> */}
              <a href="https://api.mxright.com/v2/docs?ui=re_doc" target="_blank" rel="noopener noreferrer">
                <p>MX Right API</p>
              </a>
              <a
                href="https://mxright.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              ><p>Privacy Policy</p>
              </a>
              <a href="https://mxright.com/terms" target="_blank" rel="noopener noreferrer"><p>Terms</p></a>
            </div>

            <div className="social-container">
              {/* <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><TwitterIcon /></IconButton>
              <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}>
                <LinkedinSmallIcon />
              </IconButton>
              <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><YoutubeIcon /></IconButton>
              <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><FacebookIcon /></IconButton> */}
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

AuthorizedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthorizedLayout);
