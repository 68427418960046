import React, { useState } from 'react';

import AuthorizedLayout from 'layouts/authorized';

import { PlusIcon, WebNotificationIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import Button from 'components/Button';

import useWindowDimensions from 'hooks/useWindowDimensions';

import NotificationPresetItem from './components/NotificationPresetItem';
// eslint-disable-next-line max-len
import AddOrEditNotificationPresetModal from './components/NotificationPresetItem/components/AddOrEditNotificationPresetModal';

import './styles.scss';

const NotificationPresets = () => {
  const [isAddOrEditModalVisible, setIsAddOrEditModalVisible] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 1000;

  const {
    data: monitorNotificationList,
    isLoading,
  } = monitorApi.useMonitorNotificationList();

  const notificationPresetItems = monitorNotificationList?.map((monitorNotification, index) => (
    <NotificationPresetItem
      key={`${monitorNotification.id}-${monitorNotification.presetName}-${monitorNotification.monitors.length}`}
      item={monitorNotification}
      index={index}
      itemsLength={monitorNotificationList.length}
    />
  ));

  return (
    <AuthorizedLayout>
      {isAddOrEditModalVisible && (
      <AddOrEditNotificationPresetModal
        open={isAddOrEditModalVisible}
        onClose={() => setIsAddOrEditModalVisible(false)}
      />
      )}

      <div className="notification-presets-wrapper">
        <div className="title-wrapper">
          <p className="title">Notification Presets</p>

          <Button
            startIcon={<PlusIcon />}
            iconClassName="button-icon"
            onClick={() => setIsAddOrEditModalVisible(true)}
          >
            {width <= 600 ? '' : 'Add Preset'}
          </Button>
        </div>

        {!isLoading && !monitorNotificationList?.length && (
        <div className="empty-state">
          <WebNotificationIcon />

          <p className="title">You don&apos;t have any Notification Presets</p>

          <Button
            className="add-preset-button"
            startIcon={<PlusIcon />}
            iconClassName="button-icon"
            onClick={() => setIsAddOrEditModalVisible(true)}
          >
            Add Preset
          </Button>
        </div>
        )}

        {!isLoading && !!monitorNotificationList?.length && !mobileWidth && (
        <div className="notification-table">
          <div className="notification-table-head">
            <p className="notification-table-head-name">Name</p>
            <p className="notification-table-head-email">Email</p>
            <p className="notification-table-head-notification">Notification</p>
            <p className="notification-table-head-monitors">Monitors</p>
          </div>

          <div className="line" />

          {notificationPresetItems}
        </div>
        )}

        {mobileWidth && (
        <div className="mobile-notification-presets-wrapper">
          {notificationPresetItems}
        </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default NotificationPresets;
