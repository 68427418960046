import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactCropper from 'react-easy-crop';

import Modal from 'components/Modal';

import Button from 'components/Button';

import getCroppedImg from './cropImage';

import './styles.scss';

const Cropper = ({ aspect, image, cropShape, onCancel, onSubmit, isShown, cropperContainerStyles }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const croppedAreaPixels = useRef(null);

  const handleCropComplete = useCallback((croppedArea, finalCroppedAreaPixels) => {
    croppedAreaPixels.current = finalCroppedAreaPixels;
  }, []);

  const handleSubmit = useCallback(async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels.current);

    onSubmit(croppedImage);
  }, [croppedAreaPixels, onSubmit, image]);

  return (
    <Modal
      open={isShown}
      onClose={onCancel}
      title="Crop Image"
    >
      <div className="container">
        <div style={cropperContainerStyles}>
          <ReactCropper
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            aspect={aspect}
            image={image}
            cropShape={cropShape}
            onCropComplete={handleCropComplete}
          />
        </div>

        <Button onClick={handleSubmit} className="crop-button">Crop</Button>
      </div>
    </Modal>
  );
};

Cropper.propTypes = {
  aspect: PropTypes.number.isRequired,
  cropShape: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  image: PropTypes.string,
  isShown: PropTypes.bool,
  cropperContainerStyles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Cropper.defaultProps = {
  cropShape: 'rect',
  isShown: false,
  cropperContainerStyles: null,
  image: null,
};

export default React.memo(Cropper);
