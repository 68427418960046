import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { mxriskApi } from 'resources/mxrisk';

import { QuestionMarkIcon } from 'assets/icons';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import Popover from 'components/Popover';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const AddOrEditEmailMXRiskGroupModal = ({
  open,
  onClose,
  initialData,
  onGroupAdded,
}) => {
  const [name, setName] = useState(initialData ? initialData.name : '');
  const [testingForSelectValue, setTestingForSelectValue] = useState(null);

  const [questionIconRef, setQuestionIconRef] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { data: emailForGroup, isLoading: isEmailForGroupLoading } = mxriskApi.useEmailForGroup();
  const { mutate: createEmailGroup, isLoading: isCreateEmailGroupLoading } = mxriskApi.useCreateEmailGroup();
  const { mutate: updateEmailGroup, isLoading: isUpdateEmailGroupLoading } = mxriskApi.useUpdateEmailGroup();

  const testingForSelectItems = emailForGroup?.map((emailFor) => ({
    value: emailFor['@id'],
    label: emailFor.testingFor,
  }));

  const [errors, setErrors] = useState({
    name: null,
  });

  useEffect(() => {
    if (testingForSelectItems && !testingForSelectValue) {
      if (initialData) {
        const currentValue = testingForSelectItems
          .find((item) => item.value === initialData.emailFor)
          .value;

        setTestingForSelectValue(currentValue);

        return;
      }

      setTestingForSelectValue(testingForSelectItems[0].value);
    }
  }, [testingForSelectItems, testingForSelectValue, initialData]);

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [name]);

  const onNextClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    if (initialData) {
      const itemId = initialData['@id'].replace('/v2/mx-risk/email-groups/', '');

      updateEmailGroup({
        id: itemId,
        emailFor: testingForSelectValue,
        name,
      }, {
        onSuccess: () => {
          uiNotificationService.showSuccess('Email group has been updated');

          onClose();
        },
      });

      return;
    }

    createEmailGroup({
      emailFor: testingForSelectValue,
      name,
    }, {
      onSuccess: (data) => {
        uiNotificationService.showSuccess('Email group has been added');

        if (onGroupAdded) {
          onGroupAdded(data);
        }

        onClose();
      },
    });
  }, [validateForm, createEmailGroup, name, testingForSelectValue,
    onGroupAdded, onClose, initialData, updateEmailGroup]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ overflow: 'visible' }}
      title={`${initialData ? 'Edit' : 'Add'} Email MX Risk Group`}
      className="add-email-mx-risk-modal"
    >
      <div className="add-email-mx-risk-modal-wrapper">
        {isEmailForGroupLoading && <CircularProgress className="loader" />}

        {!isEmailForGroupLoading && (
        <div>
          <div className="inputs">
            <Input
              label="Group name"
              value={name}
              onChange={(value) => onChangeInput({ value, setter: setName, errorField: 'name' })}
              error={!!errors.name}
              helperText={errors.name}
              rightIcon={(
                <IconButton onClick={() => setIsPopoverVisible(!isPopoverVisible)}>
                  <QuestionMarkIcon
                    ref={setQuestionIconRef}
                  />
                </IconButton>
              )}
              onKeyDown={(event) => {
                if (initialData && event.code === 'Enter') {
                  onNextClick();
                }
              }}
            />
            {isPopoverVisible && (
              <Popover
                forRef={questionIconRef}
                title="Email MX Risk Group"
                description="Groups help you organize Email MX Risk tests.
                You may use these groups to categorize marketing emails based on
                specific campaign types or to separate clients
                if you operate an email marketing agency.
                This organization allows for easier analysis and management of various campaigns."
                onClose={() => setIsPopoverVisible(false)}
                placement="top-end"
              />
            )}

            {testingForSelectValue && (
              <Select
                wrapperClassName="select-wrapper"
                value={testingForSelectValue}
                onChange={setTestingForSelectValue}
                items={testingForSelectItems}
                type="filled"
                renderValue={(renderValue) => (
                  <div className="select-value">
                    <p>Testing for</p>
                    <p>
                      {testingForSelectItems.find((selectItem) => selectItem.value === renderValue).label}
                    </p>
                  </div>
                )}
              />
            )}
          </div>

          <div className="confirm-buttons">
            <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
            <Button onClick={onNextClick} loading={isUpdateEmailGroupLoading || isCreateEmailGroupLoading}>
              {initialData ? 'Save' : 'Next'}
            </Button>
          </div>
        </div>
        )}
      </div>
    </Modal>
  );
};

AddOrEditEmailMXRiskGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.shape(),
  onGroupAdded: PropTypes.func,
};

AddOrEditEmailMXRiskGroupModal.defaultProps = {
  initialData: null,
  onGroupAdded: null,
};

export default React.memo(AddOrEditEmailMXRiskGroupModal);
