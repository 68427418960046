import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faPen, faPlug } from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { userApi } from 'resources/user';

import Tooltip from '@mui/material/Tooltip';

import DeleteModal from 'components/DeleteModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import EditAPIKeyModal from './components/EditAPIKeyModal';

import './styles.scss';

const APIKeyItem = ({ item }) => {
  const [isEditAPIKeyModalVisible, setIsEditAPIKeyModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [key, setKey] = useState('');

  const { mutate: getAPIKey } = userApi.useGetAPIKey();
  const { mutate: deleteAPIKey, isLoading: isDeleteAPIKeyLoading } = userApi.useDeleteAPIKey();

  const onKeyCopy = useCallback(() => {
    if (key) {
      navigator.clipboard.writeText(key);
      uiNotificationService.showSuccess('Copied to Clipboard');

      return;
    }

    getAPIKey(item.identifier, {
      onSuccess: (data) => {
        setKey(data.key);
        navigator.clipboard.writeText(data.key);

        uiNotificationService.showSuccess('Copied to Clipboard');
      },
    });
  }, [getAPIKey, item.identifier, key]);

  const onDelete = useCallback(() => {
    deleteAPIKey(item.identifier, {
      onSuccess: () => {
        uiNotificationService.showSuccess('API key has been deleted');
      },
    });
  }, [deleteAPIKey, item.identifier]);

  return (
    <div className="api-key-wrapper">
      {isEditAPIKeyModalVisible && (
      <EditAPIKeyModal
        open={isEditAPIKeyModalVisible}
        onClose={() => setIsEditAPIKeyModalVisible(false)}
        item={item}
      />
      )}
      {isDeleteModalVisible && (
      <DeleteModal
        title={`Are you sure you want to delete "${item.name}" API key?`}
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={onDelete}
        isDeleteLoading={isDeleteAPIKeyLoading}
      />
      )}

      <div className="api-key-item">
        <div className="name-wrapper">
          <div className="name-icon-wrapper">
            <FontAwesomeIcon icon={faPlug} color="#17EDC3" />
          </div>

          <p>{item.name}</p>
        </div>

        <div className="key-wrapper">
          {key ? <p>{key}</p> : <p>********************</p>}
        </div>

        <div className="created-wrapper">
          <p>{moment(item.createdAt).format('MMM DD, YYYY - h:mmA')}</p>
        </div>

        <div className="item-actions">
          <Tooltip onClick={onKeyCopy} title="Copy" placement="top">
            <FontAwesomeIcon icon={faClone} color="rgba(255, 255, 255, 0.56)" />
          </Tooltip>

          <Tooltip onClick={() => setIsEditAPIKeyModalVisible(true)} title="Edit" placement="top">
            <FontAwesomeIcon icon={faPen} color="rgba(255, 255, 255, 0.56)" />
          </Tooltip>

          <Tooltip onClick={() => setIsDeleteModalVisible(true)} title="Delete" placement="top">
            <FontAwesomeIcon icon={faTrashCan} color="rgba(255, 255, 255, 0.56)" />
          </Tooltip>
        </div>

      </div>

      <div className="line" />
    </div>
  );
};

APIKeyItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default APIKeyItem;
