import { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import AuthorizedLayout from 'layouts/authorized';

import { PlusIcon, EmptyMonitorIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';
import Pagination from 'components/Pagination';

import useWindowDimensions from 'hooks/useWindowDimensions';

import IPMonitorItem from './components/IPMonitorItem';
import AddOrEditIPMonitorModal from './components/AddOrEditIPMonitorModal';

import './styles.scss';

const ITEM_WIDTH = 340;
const ITEM_HEIGHT = 295;

const IPMonitor = () => {
  const { width, height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);
  const [isAddIPMonitorVisible, setIsAddIPMonitorVisible] = useState(false);

  const rows = Math.floor((width - 352) / ITEM_WIDTH);
  const columns = Math.floor((height - 306) / ITEM_HEIGHT);
  const perPage = (rows > 0 ? rows : 1) * (columns > 0 ? columns : 1);

  const {
    data,
    isLoading,
    isFetching,
  } = monitorApi.useMonitorList({
    type: 'ip',
    page,
    perPage,
  });
  const [fetchedMonitors, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isEmptyState = !isFetching && !items.length;

  useEffect(() => {
    if (fetchedMonitors) {
      setItems(fetchedMonitors);
    }
  }, [fetchedMonitors]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const onOpenAddMonitorModal = useCallback(() => {
    setIsAddIPMonitorVisible(true);
  }, []);

  const onCloseAddMonitorModal = useCallback(() => {
    setIsAddIPMonitorVisible(false);
  }, []);

  const refreshItemList = useCallback(() => {
    searchParams.set('page', 1);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const EmptyState = () => (
    <div className="empty-state">
      <EmptyMonitorIcon />

      <p className="empty-state-title">Create your first monitor</p>

      <Button startIcon={<PlusIcon />} iconClassName="button-icon" onClick={onOpenAddMonitorModal}>Add Monitor</Button>
    </div>
  );

  return (
    <AuthorizedLayout>
      {isAddIPMonitorVisible && (
      <AddOrEditIPMonitorModal
        open={isAddIPMonitorVisible}
        onClose={onCloseAddMonitorModal}
        onMonitorAdded={refreshItemList}
      />
      )}

      <div className="ip-monitor-wrapper">
        <div>
          <div className="title">
            <p>IP Monitor</p>

            {!isEmptyState && (
            <Button startIcon={<PlusIcon />} iconClassName="button-icon" onClick={onOpenAddMonitorModal}>
              Add Monitor
            </Button>
            )}
          </div>
        </div>

        {isEmptyState && <EmptyState />}

        {!!items.length && (
          <div className="list-wrapper">
            <div className="monitor-list">
              {items.map((item) => (
                <IPMonitorItem
                  key={item.id}
                  id={item.id}
                  name={item.title}
                  enabled={item.status === 'active'}
                  ipList={item.checkItems.map((checkItem) => ({
                    id: checkItem.id,
                    ipAddress: checkItem.item,
                    lastCheckFlag: checkItem.lastCheckFlag,
                    success: checkItem.success,
                  }))}
                  scheduleSeconds={item.scheduleSeconds}
                  notificationId={+item.notification.replace('/v2/monitor-notifications/', '')}
                  onDeleted={refreshItemList}
                />
              ))}
            </div>

            {totalItems > items.length && (
            <Pagination
              className="table-pagination"
              activePage={page}
              perPage={perPage}
              totalItems={totalItems}
              onPageChanged={onPageChanged}
            />
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default IPMonitor;
