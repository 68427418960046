import { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { LinearProgress } from '@mui/material';

import { PLACEMENT_TEST_STATUSES } from 'resources/placement/placement.constants';

import './styles.scss';

const ConnectedEmailInProgress = ({ item }) => {
  const percent = Math.floor((item.lastRun.receivedCount / item.lastRun.total) * 100);

  const isPending = item.placementTestStatus === PLACEMENT_TEST_STATUSES.PENDING;
  const isActive = item.placementTestStatus === PLACEMENT_TEST_STATUSES.ACTIVE;

  return (
    <div className="seed-list-in-progress">
      {isPending && <div />}

      {isActive && (
      <div>
        <div className="seed-list-in-progress-content">
          <p>Deliverability testing in progress...</p>
          <p>{percent}%</p>
        </div>

        <LinearProgress
          variant="determinate"
          value={percent}
          className="progress-bar"
        />
      </div>
      )}

      <p className="seed-list-in-progress-time">
        {isPending ? 'Pending' : `Test started ${moment(item.lastRun.createdAt).fromNow()}`}
      </p>
    </div>
  );
};

ConnectedEmailInProgress.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default memo(ConnectedEmailInProgress);
