import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Doughnut } from 'react-chartjs-2';

import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';

import formatNumber from 'helpers/formatNumber';

const scoreToValue = (score) => {
  const scoreValues = {
    'A+': 12,
    A: 11,
    'A-': 10,
    'B+': 9,
    B: 8,
    'B-': 7,
    'C+': 6,
    C: 5,
    'C-': 4,
    'D+': 3,
    D: 2,
    F: 1,
  };

  return scoreValues[score] || 0;
};

const EmailInfrastructureDoughnut = ({
  data,
  loading,
  onRefetch,
  isError,
}) => {
  const sortedData = data
    ? { ...data, scores: data.scores.sort((a, b) => scoreToValue(b.score) - scoreToValue(a.score)) }
    : null;

  const totalCount = data ? data.total : 0;

  const scoresList = sortedData?.scores.length
    ? sortedData?.scores
    : [
      { '@id': '1', score: 'A', count: 0, color: '#17EDC3' },
      { '@id': '2', score: 'B', count: 0, color: '#FFA726' },
      { '@id': '3', score: 'F', count: 0, color: '#F02B4F' },
    ];

  return (
    <div className={cn('item', 'graph-item')}>
      {loading && !isError && <CircularProgress className="loader" />}

      {isError && (
        <div className="item-error">
          <p>Something went wrong</p>

          <Button onClick={onRefetch}>Refresh</Button>
        </div>
      )}

      {!loading && !isError && (
        <div className="graph-content">
          <div>
            <p>Email Infrastructure</p>
          </div>

          <div className="percent-content-wrapper">
            <div className="percent-content">
              {scoresList.map((item) => {
                let percent = totalCount >= 1 ? Math.round((item.count / totalCount) * 100) : 0;

                if (totalCount >= 1 && !percent) {
                  percent = '< 1';
                }

                return (
                  <div key={item['@id']}>
                    <div className="point" style={{ background: item.color }} />

                    <p>Score {item.score}</p>

                    <p>{percent ? `${percent}%` : '-'}</p>
                  </div>
                );
              })}
            </div>

            <div className="doughnut">
              <Doughnut
                width={160}
                data={{
                  datasets: [{
                    backgroundColor: sortedData?.scores.length
                      ? sortedData.scores.map((score) => score.color)
                      : ['rgba(255, 255, 255, 0.2)'],
                    pointRadius: 0,
                    pointHitRadius: 100,
                    borderWidth: 0,
                    data: sortedData?.scores.length
                      ? sortedData.scores.map((score) => (score.count / totalCount) * 100)
                      : [100],
                  }],
                }}
                options={{
                  cutoutPercentage: 75,
                  spanGaps: false,
                  legend: {
                    display: false,
                  },
                  maintainAspectRatio: false,
                  tooltips: { enabled: false },
                }}
              />

              <div className="doughnut-content">
                <p>{formatNumber(totalCount)}</p>

                <p>check{totalCount === 1 ? '' : 's'}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EmailInfrastructureDoughnut.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  onRefetch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

EmailInfrastructureDoughnut.defaultProps = {
  data: null,
};

export default React.memo(EmailInfrastructureDoughnut);
