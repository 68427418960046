import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';

import { PLACEMENT_TEST_STATISTIC } from 'pages/DeliverabilityTest/components/TestCard';

import BarItem from './components/BarItem';
import InboxReportHistoryModal from './components/InboxReportHistoryModal';

import './styles.scss';

const InboxReport = ({
  placementTestRun,
  showHistoricalData,
}) => {
  const [isInboxReportHistoryModalVisible, setIsInboxReportHistoryModalVisible] = useState(false);

  const onToggleHistoryClick = useCallback(() => {
    setIsInboxReportHistoryModalVisible((prevIsOpen) => !prevIsOpen);
  }, []);

  const runStats = PLACEMENT_TEST_STATISTIC.map((stat) => ({
    ...stat,
    percent: placementTestRun
      ? ((placementTestRun[stat.id] / placementTestRun.total) * 100).toFixed(2)
      : 0,
  }))
    // remove 'Other' if it's 0
    .filter((stat) => stat.id !== 'other' || stat.percent > 0);

  return (
    <>
      {isInboxReportHistoryModalVisible && (
        <InboxReportHistoryModal
          open={isInboxReportHistoryModalVisible}
          onClose={onToggleHistoryClick}
        />
      )}
      <div className="deliverability-test-details-inbox-report">
        <div className="deliverability-test-details-inbox-report-header">
          <p className="deliverability-test-details-inbox-report-header-title">Inbox Placement Report</p>

          {showHistoricalData && (
          <button
            type="button"
            onClick={setIsInboxReportHistoryModalVisible}
            className="deliverability-test-details-inbox-report-header-history"
          >
            <FontAwesomeIcon fontSize={16} icon={faClockRotateLeft} color="#FFFFFF8F" />
            <p className="deliverability-test-details-inbox-report-header-history-text">Historical Data</p>
          </button>
          )}
        </div>

        <div className="deliverability-test-details-inbox-report-content">
          <ul className="deliverability-test-details-inbox-report-content-bar-list">
            {runStats.map((stat) => <BarItem key={stat.text} item={stat} />)}
          </ul>
        </div>
      </div>
    </>
  );
};

InboxReport.propTypes = {
  placementTestRun: PropTypes.shape().isRequired,
  showHistoricalData: PropTypes.bool,
};

InboxReport.defaultProps = {
  showHistoricalData: false,
};

export default InboxReport;
