import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { LogoIcon } from 'assets/icons';

import './styles.scss';

const UnauthorizedLayout = ({ children }) => (
  <>
    <Helmet>
      <meta name="robots" content="none" />
    </Helmet>

    <div className="unauthorized-wrapper">
      <header>
        <LogoIcon />
      </header>
      <div className="content">
        {children}
      </div>
    </div>
  </>

);

UnauthorizedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(UnauthorizedLayout);
