import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';

import MUIPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import './styles.scss';

const Pagination = ({
  activePage,
  perPage,
  totalItems,
  onPageChanged,
  className,
}) => {
  const pageCount = Math.ceil(totalItems / perPage);

  const onPageChange = useCallback((event, value) => {
    onPageChanged(value);
  }, [onPageChanged]);

  return (
    <MUIPagination
      className={cn('pagination', className)}
      page={activePage}
      onChange={onPageChange}
      count={pageCount}
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: ChevronLeftIcon, next: ChevronRightIcon }}
          {...item}
        />
      )}
    />
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  className: null,
};

export default React.memo(Pagination);
