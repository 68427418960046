import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  navbar: {
    height: 'unset',
    backgroundColor: 'inherit',
    borderRight: 'unset',
    paddingBottom: 0,
    marginLeft: -24,
    width: 'calc(100% + 48px)',
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  backWrapper: {
    padding: '0 24px',
  },

  backContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& p': {
      marginLeft: 16,
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: 0.46,
      color: 'rgba(255, 255, 255, 0.56)',
    },
  },
}));
