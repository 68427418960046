import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { connectivityApi } from 'resources/connectivity';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';

import { Alert, AlertTitle } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

const PORT_TYPE_SELECT_ITEMS = [
  { label: 'SSL', value: 'ssl' },
  { label: 'TLS', value: 'tls' },
  { label: 'None', value: 'none' },
];

const EditIMAPModal = ({
  open,
  onClose,
  item,
}) => {
  const [fromName, setFromName] = useState(item.fromName);
  const [fromEmail, setFromEmail] = useState(item.fromEmail);

  const [serverData, setServerData] = useState({
    imap: {
      server: item.imap.host,
      port: item.imap.port, // numbers
      type: item.imap.secureLevel, // ssl | tls
      username: item.imap.username,
      password: '',
    },
    smtp: {
      server: item.smtp.smtpHost,
      port: item.smtp.smtpPort, // numbers
      type: item.smtp.smtpSecureLevel, // ssl | tls
      username: item.smtp.smtpUsername,
      password: '',
    },
  });

  const { mutate: updatedIMAPAccount, isLoading: isUpdateIMAPAccountLoading } = connectivityApi.useUpdateIMAPAccount();

  const [errors, setErrors] = useState({
    fromName: null,
    fromEmail: null,
    imap: {
      server: null,
      port: null,
      type: null,
      username: null,
    },
    smtp: {
      server: null,
      port: null,
      type: null,
      username: null,
    },
  });
  const [generalErrors, setGeneralErrors] = useState({
    imap: {
      title: 'IMAP Connection Failed',
      description: null,
      expanded: false,
    },
    smtp: {
      title: 'SMTP Connection Failed',
      description: null,
      expanded: false,
    },
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!fromName) {
      newErrors.fromName = 'This field is required';
    }

    if (!fromEmail) {
      newErrors.fromEmail = 'This field is required';
    }

    if (fromEmail && !validateEmail(fromEmail)) {
      newErrors.fromEmail = 'Incorrect email format';
    }

    if (!serverData.imap.server) {
      newErrors.imap = {
        ...newErrors.imap,
        server: 'This field is required',
      };
    }

    if (!serverData.imap.port) {
      newErrors.imap = {
        ...newErrors.imap,
        port: 'This field is required',
      };
    }

    if (serverData.imap.port && Number.isNaN(Number(serverData.imap.port))) {
      newErrors.imap = {
        ...newErrors.imap,
        port: 'This field should be a number',
      };
    }

    if (!serverData.imap.username) {
      newErrors.imap = {
        ...newErrors.imap,
        username: 'This field is required',
      };
    }

    if (!serverData.smtp.server) {
      newErrors.smtp = {
        ...newErrors.smtp,
        server: 'This field is required',
      };
    }

    if (!serverData.smtp.port) {
      newErrors.smtp = {
        ...newErrors.smtp,
        port: 'This field is required',
      };
    }

    if (serverData.smtp.port && Number.isNaN(Number(serverData.smtp.port))) {
      newErrors.smtp = {
        ...newErrors.smtp,
        port: 'This field should be a number',
      };
    }

    if (!serverData.smtp.username) {
      newErrors.smtp = {
        ...newErrors.smtp,
        username: 'This field is required',
      };
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [fromName, fromEmail, serverData]);

  const onSaveClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    setGeneralErrors((oldGeneralErrors) => ({
      ...oldGeneralErrors,
      imap: {
        ...oldGeneralErrors.imap,
        description: null,
      },
      smtp: {
        ...oldGeneralErrors.smtp,
        description: null,
      },
    }));

    updatedIMAPAccount({
      id: item.id,
      fromName,
      fromEmail,
      imap: {
        username: serverData.imap.username,
        ...(serverData.imap.password && { password: serverData.imap.password }),
        host: serverData.imap.server,
        port: +serverData.imap.port,
        secureLevel: serverData.imap.type,
      },
      smtp: {
        smtpUsername: serverData.smtp.username,
        ...(serverData.smtp.password && { smtpPassword: serverData.smtp.password }),
        smtpHost: serverData.smtp.server,
        smtpPort: +serverData.smtp.port,
        smtpSecureLevel: serverData.smtp.type,
      },
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email account has been updated');

        onClose();
      },
      onError: (error) => {
        if (error.data.violations?.length) {
          const filedsErrors = {};

          error.data.violations.forEach((violation) => {
            filedsErrors[violation.propertyPath] = violation.message;
          });

          setErrors((oldErrors) => ({
            ...oldErrors,
            ...filedsErrors,
          }));

          const imapConnectionError = error.data.violations.find((violation) => violation.propertyPath === 'imapConnection');
          const smtpConnectionError = error.data.violations.find((violation) => violation.propertyPath === 'smtpConnection');

          setGeneralErrors((oldGeneralErrors) => ({
            ...oldGeneralErrors,
            imap: {
              ...oldGeneralErrors.imap,
              description: imapConnectionError?.message || null,
            },
            smtp: {
              ...oldGeneralErrors.smtp,
              description: smtpConnectionError?.message || null,
            },
          }));
        }
      },
    });
  }, [validateForm, updatedIMAPAccount, fromName, onClose, fromEmail, serverData, item.id]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit Email Account - IMAP / SMTP"
      className="edit-imap-modal"
    >
      <div className="edit-imap-modal-wrapper">

        <div>
          <div className="inputs">
            <Input
              label="From Name"
              value={fromName}
              onChange={(value) => onChangeInput({ value, setter: setFromName, errorField: 'fromName' })}
              error={!!errors.fromName}
              helperText={errors.fromName}
            />

            <Input
              label="Email Address"
              value={fromEmail}
              onChange={(value) => onChangeInput({ value, setter: setFromEmail, errorField: 'fromEmail' })}
              error={!!errors.fromEmail}
              helperText={errors.fromEmail}
              readOnly
            />
          </div>

          <div className="line" />

          <div className="inputs">
            {generalErrors.imap.description && (
              <Alert
                severity="error"
                action={(
                  <div
                    className="more-error-info"
                    onClick={() => {
                      setGeneralErrors((oldGeneralErrors) => ({
                        ...oldGeneralErrors,
                        imap: {
                          ...oldGeneralErrors.imap,
                          expanded: !oldGeneralErrors.imap.expanded,
                        },
                      }));
                    }}
                    aria-hidden
                  >
                    <p>More Info</p>

                    {generalErrors.imap.expanded
                      ? <FontAwesomeIcon icon={faChevronUp} color="rgba(255, 255, 255, 0.70)" fontSize={13} />
                      : <FontAwesomeIcon icon={faChevronDown} color="rgba(255, 255, 255, 0.70)" fontSize={13} />}
                  </div>
              )}
              >
                <AlertTitle sx={{ marginBottom: generalErrors.imap.expanded ? '16px' : 0 }}>
                  {generalErrors.imap.title}
                </AlertTitle>

                {generalErrors.imap.expanded && <p style={{ maxWidth: 400 }}>{generalErrors.imap.description}</p>}
              </Alert>
            )}

            <Input
              label="IMAP Server"
              value={serverData.imap.server}
              onChange={(value) => {
                setServerData((oldServerData) => ({
                  ...oldServerData,
                  imap: {
                    ...oldServerData.imap,
                    server: value,
                  },
                }));

                if (errors.imap.server) {
                  setErrors((oldErrors) => ({
                    ...oldErrors,
                    imap: {
                      ...oldErrors.imap,
                      server: null,
                    },
                  }));
                }
              }}
              error={!!errors.imap.server}
              helperText={errors.imap.server}
            />

            <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
              <Input
                label="Port"
                value={serverData.imap.port}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    imap: {
                      ...oldServerData.imap,
                      port: value,
                    },
                  }));

                  if (errors.imap.port) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      imap: {
                        ...oldErrors.imap,
                        port: null,
                      },
                    }));
                  }
                }}
                error={!!errors.imap.port}
                helperText={errors.imap.port}
                style={{ width: 300 }}
              />

              {serverData.imap.type && (
                <Select
                  wrapperClassName="select-wrapper"
                  value={serverData.imap.type}
                  onChange={(value) => {
                    setServerData((oldServerData) => ({
                      ...oldServerData,
                      imap: {
                        ...oldServerData.imap,
                        type: value,
                      },
                    }));
                  }}
                  items={PORT_TYPE_SELECT_ITEMS}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>SSL/TLS</p>
                      <p>
                        {PORT_TYPE_SELECT_ITEMS.find((selectItem) => selectItem.value === renderValue).label}
                      </p>
                    </div>
                  )}
                />
              )}
            </div>

            <Input
              label="Username"
              value={serverData.imap.username}
              onChange={(value) => {
                setServerData((oldServerData) => ({
                  ...oldServerData,
                  imap: {
                    ...oldServerData.imap,
                    username: value,
                  },
                }));

                if (errors.imap.username) {
                  setErrors((oldErrors) => ({
                    ...oldErrors,
                    imap: {
                      ...oldErrors.imap,
                      username: null,
                    },
                  }));
                }
              }}
              error={!!errors.imap.username}
              helperText={errors.imap.username}
            />

            <Input
              label={`Password${!serverData.imap.password ? ' (unchanged)' : ''}`}
              value={serverData.imap.password}
              onChange={(value) => {
                setServerData((oldServerData) => ({
                  ...oldServerData,
                  imap: {
                    ...oldServerData.imap,
                    password: value,
                  },
                }));

                if (errors.imap.password) {
                  setErrors((oldErrors) => ({
                    ...oldErrors,
                    imap: {
                      ...oldErrors.imap,
                      password: null,
                    },
                  }));
                }
              }}
              error={!!errors.imap.password}
              helperText={errors.imap.password}
              type="password"
            />
          </div>

          <div className="line" />

          <div className="inputs">
            {generalErrors.smtp.description && (
              <Alert
                severity="error"
                action={(
                  <div
                    className="more-error-info"
                    onClick={() => {
                      setGeneralErrors((oldGeneralErrors) => ({
                        ...oldGeneralErrors,
                        smtp: {
                          ...oldGeneralErrors.smtp,
                          expanded: !oldGeneralErrors.smtp.expanded,
                        },
                      }));
                    }}
                    aria-hidden
                  >
                    <p>More Info</p>

                    {generalErrors.smtp.expanded
                      ? <FontAwesomeIcon icon={faChevronUp} color="rgba(255, 255, 255, 0.70)" fontSize={13} />
                      : <FontAwesomeIcon icon={faChevronDown} color="rgba(255, 255, 255, 0.70)" fontSize={13} />}
                  </div>
              )}
              >
                <AlertTitle sx={{ marginBottom: generalErrors.smtp.expanded ? '16px' : 0 }}>
                  {generalErrors.smtp.title}
                </AlertTitle>

                {generalErrors.smtp.expanded && <p style={{ maxWidth: 400 }}>{generalErrors.smtp.description}</p>}
              </Alert>
            )}

            <Input
              label="SMTP Server"
              value={serverData.smtp.server}
              onChange={(value) => {
                setServerData((oldServerData) => ({
                  ...oldServerData,
                  smtp: {
                    ...oldServerData.smtp,
                    server: value,
                  },
                }));

                if (errors.smtp.server) {
                  setErrors((oldErrors) => ({
                    ...oldErrors,
                    smtp: {
                      ...oldErrors.smtp,
                      server: null,
                    },
                  }));
                }
              }}
              error={!!errors.smtp.server}
              helperText={errors.smtp.server}
            />

            <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
              <Input
                label="Port"
                value={serverData.smtp.port}
                onChange={(value) => {
                  setServerData((oldServerData) => ({
                    ...oldServerData,
                    smtp: {
                      ...oldServerData.smtp,
                      port: value,
                    },
                  }));

                  if (errors.smtp.port) {
                    setErrors((oldErrors) => ({
                      ...oldErrors,
                      smtp: {
                        ...oldErrors.smtp,
                        port: null,
                      },
                    }));
                  }
                }}
                error={!!errors.smtp.port}
                helperText={errors.smtp.port}
                style={{ width: 300 }}
              />

              {serverData.smtp.type && (
                <Select
                  wrapperClassName="select-wrapper"
                  value={serverData.smtp.type}
                  onChange={(value) => {
                    setServerData((oldServerData) => ({
                      ...oldServerData,
                      smtp: {
                        ...oldServerData.smtp,
                        type: value,
                      },
                    }));
                  }}
                  items={PORT_TYPE_SELECT_ITEMS}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>SSL/TLS</p>
                      <p>
                        {PORT_TYPE_SELECT_ITEMS.find((selectItem) => selectItem.value === renderValue).label}
                      </p>
                    </div>
                  )}
                />
              )}
            </div>

            <Input
              label="Username"
              value={serverData.smtp.username}
              onChange={(value) => {
                setServerData((oldServerData) => ({
                  ...oldServerData,
                  smtp: {
                    ...oldServerData.smtp,
                    username: value,
                  },
                }));

                if (errors.smtp.username) {
                  setErrors((oldErrors) => ({
                    ...oldErrors,
                    smtp: {
                      ...oldErrors.smtp,
                      username: null,
                    },
                  }));
                }
              }}
              error={!!errors.smtp.username}
              helperText={errors.smtp.username}
            />

            <Input
              label={`Password${!serverData.smtp.password ? ' (unchanged)' : ''}`}
              value={serverData.smtp.password}
              onChange={(value) => {
                setServerData((oldServerData) => ({
                  ...oldServerData,
                  smtp: {
                    ...oldServerData.smtp,
                    password: value,
                  },
                }));

                if (errors.smtp.password) {
                  setErrors((oldErrors) => ({
                    ...oldErrors,
                    smtp: {
                      ...oldErrors.smtp,
                      password: null,
                    },
                  }));
                }
              }}
              error={!!errors.smtp.password}
              helperText={errors.smtp.password}
              type="password"
            />
          </div>

          <div className="confirm-buttons">
            <Button
              className="cancel-button"
              color="secondaryButton"
              onClick={onClose}
              disabled={isUpdateIMAPAccountLoading}
            >
              Cancel
            </Button>
            <Button
              className="save-button"
              onClick={onSaveClick}
              loading={isUpdateIMAPAccountLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditIMAPModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export default React.memo(EditIMAPModal);
