import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown, faUpFromBracket } from '@fortawesome/pro-regular-svg-icons';

import { Box, Chip, IconButton, Tooltip } from '@mui/material';

import { CheckIcon, OutlinedPencilIcon } from 'assets/icons';

import { placementApi } from 'resources/placement';

import Input from 'components/Input';

import useOutsideClick from 'hooks/useOutsideClick';

import { PLACEMENT_TYPE } from 'pages/DeliverabilityTest/helpers/tests';

import uiNotificationService from 'services/uiNotificatuion.service';

import SharePlacementTestModal from './components/SharePlacementTestModal';
import PlacementTestRunsHistorySelect from './components/PlacementTestRunsHistorySelect';

import './styles.scss';

const HeaderInfo = ({
  placementTest,
  isOwner,
}) => {
  const editNameRef = useRef(null);

  const [name, setName] = useState(placementTest.title);
  const [isEditName, setIsEditName] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const placementTestId = +placementTest['@id'].replace('/v2/placement-tests/', '');

  const {
    mutate: updatePlacementTest,
    isLoading: isUpdatePlacementTestLoading,
  } = placementApi.useUpdate();

  const isSeedList = placementTest.placementType === PLACEMENT_TYPE.SEED_LIST;
  const chipLabel = isSeedList ? 'Seed List' : 'Connected Email';

  useOutsideClick(editNameRef, () => setIsEditName(false));

  const onEditName = useCallback(() => {
    if (!name) {
      return;
    }

    updatePlacementTest({
      id: placementTestId,
      title: name,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Title has been updated');

        setIsEditName(false);
      },
    });
  }, [updatePlacementTest, name, placementTestId]);

  return (
    <div className="deliverability-test-details-header">
      {isOwner && isShareModalVisible && (
      <SharePlacementTestModal
        placementTest={placementTest}
        onClose={() => setIsShareModalVisible(false)}
      />
      )}

      <div>
        <div className="title">
          {isEditName
            ? (
              <div className="title-edit-mode" ref={editNameRef}>
                <Input
                  className="name-input"
                  placeholder="Title"
                  value={name}
                  onChange={setName}
                  onKeyDown={(event) => {
                    if (event.code === 'Enter') {
                      onEditName();
                    }
                  }}
                />

                <IconButton
                  onClick={onEditName}
                  disabled={!name || isUpdatePlacementTestLoading}
                  className={cn(
                    'check-icon-button',
                    (!name || isUpdatePlacementTestLoading) && 'check-icon-button-disabled',
                  )}
                >
                  <CheckIcon />
                </IconButton>
              </div>
            )
            : (
              <div className="deliverability-test-details-header-name-part">
                <p>{placementTest.title}</p>

                {isOwner && (
                <Tooltip
                  title="Change name"
                  placement="top"
                  className="edit-icon-container"
                  onClick={() => setIsEditName(true)}
                >
                  <OutlinedPencilIcon />
                </Tooltip>
                )}
              </div>
            )}
        </div>
        <div className="deliverability-test-details-header-email-part">
          {placementTest.recurring && (
          <div className="deliverability-test-details-header-email-part-reload">
            <FontAwesomeIcon fontSize={12} icon={faRotate} color="#000000DE" />
          </div>
          )}
          <p className="deliverability-test-details-header-email-part-text">{placementTest.fromEmail}</p>
          <Chip label={chipLabel} />
        </div>
      </div>
      <div className="deliverability-test-details-header-controls-part">
        {placementTest.recurring && (
        <Box sx={{ marginRight: '16px' }}>
          <PlacementTestRunsHistorySelect
            placementTestId={placementTestId}
            initialPlacement
          />
        </Box>
        )}

        {isOwner && (
        <IconButton className="action-icon" onClick={() => setIsShareModalVisible(true)}>
          <FontAwesomeIcon
            fontSize={24}
            icon={faUpFromBracket}
            color={placementTest.shared ? '#17EDC3' : '#FFFFFF8F'}
          />
        </IconButton>
        )}
        <IconButton className="action-icon">
          <FontAwesomeIcon fontSize={24} icon={faCloudArrowDown} color="#FFFFFF8F" />
        </IconButton>
      </div>
    </div>
  );
};

HeaderInfo.propTypes = {
  placementTest: PropTypes.shape().isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default HeaderInfo;
