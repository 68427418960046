import React from 'react';
import cn from 'classnames';
import moment from 'moment';

import { accountApi } from 'resources/account';
import { subscriptionApi } from 'resources/subscription';
import { userApi } from 'resources/user';

import AuthorizedLayout from 'layouts/authorized';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons';

import { PencilIcon, PaymentHistoryIcon, CreditCardIcon } from 'assets/icons';

import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { DataGrid } from '@mui/x-data-grid';

import './styles.scss';

const Billing = () => {
  const { data: currentUser } = userApi.useCurrentUser();
  const {
    data: account,
    isLoading: isAccountLoading,
  } = accountApi.useAccount();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
  } = subscriptionApi.useSubscription(account?.subscription
    ? +account.subscription.replace('/v2/subscriptions/', '')
    : null);
  const {
    data: payments,
    isLoading: isPaymentsLoading,
    isFetching: isPaymentsFetching,
  } = subscriptionApi.useSubscriptionPayments(subscription
    ? subscription.payments.map((paymentIri) => +paymentIri.replace('/v2/payments/', ''))
    : null);
  const {
    data: customerPortal,
    isLoading: isCustomerPortalLoading,
  } = subscriptionApi.useCustomerPortal(account?.stripeId);

  const isLoading = isAccountLoading || isSubscriptionLoading || isPaymentsLoading || isCustomerPortalLoading;

  const isMonthlySubscription = !!subscription?.monthlyPrice && !subscription?.annualPrice;
  const price = subscription?.monthlyPrice || subscription?.annualPrice;

  const rows = payments || [];
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      field: 'paymentDate',
      headerName: 'Date',
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => moment(value).format('ddd, MMM DD, YYYY'),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      valueFormatter: ({ value }) => currencyFormatter.format(value / 100),
      type: 'string',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <Typography color={params.value === 'succeeded' ? '#17EDC3' : '#F02B4F'}>
          {params.value === 'succeeded' ? 'Success' : 'Failed'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Receipt',
      width: 88,
      type: 'actions',
      getActions: (params) => [
        <IconButton size="small" onClick={() => window.open(params.row.receiptUrl, '_blank')}>
          <Tooltip title="Invoice" placement="top" arrow>
            <FontAwesomeIcon fontSize={20} icon={faFileInvoice} color="rgba(255, 255, 255, 0.56)" />
          </Tooltip>
        </IconButton>,
      ],
    },
  ];

  return (
    <AuthorizedLayout>
      <div className="billing-wrapper">
        <p className="title">Billing</p>

        {isLoading && <CircularProgress style={{ margin: 'auto' }} />}

        {!isLoading && (
          <div className="billing-content">
            <div className="billing-details-wrapper">
              <div className="billing-details-card">
                <div className="billing-details-header">
                  <p>Business Plan</p>

                  {!subscription?.free && (
                  <div className={cn('actions', 'show-on-hover')}>
                    <Tooltip title="Edit" placement="top" arrow>
                      <PencilIcon
                        className="item-button"
                        onClick={() => window.open(customerPortal.link)}
                      />
                    </Tooltip>
                  </div>
                  )}
                </div>

                <div className="billing-details-content">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faUser} color="#17EDC3" size="lg" />
                  </div>

                  <p>{subscription?.free
                    ? 'Free'
                    : `$${(price / 100).toFixed(2)}/${isMonthlySubscription ? 'month' : 'year'}`}
                  </p>
                </div>
              </div>

              <div className="billing-details-card">
                <div className="billing-details-header">
                  <p>Billing Details</p>

                  {!subscription?.free && (
                  <div className={cn('actions', 'show-on-hover')}>
                    <Tooltip title="Edit" placement="top" arrow>
                      <PencilIcon
                        className="item-button"
                        onClick={() => window.open(customerPortal.link)}
                      />
                    </Tooltip>
                  </div>
                  )}
                </div>

                <div className="billing-details-content">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faUser} color="#17EDC3" size="lg" />
                  </div>

                  <p>{subscription?.paymentMethod?.billingDetailEmail || currentUser.emailAddress}</p>
                </div>
              </div>

              <div className="billing-details-card">
                <div className="billing-details-header">
                  <p>Payment Method</p>

                  {!subscription?.free && (
                  <div className={cn('actions', 'show-on-hover')}>
                    <Tooltip title="Edit" placement="top" arrow>
                      <PencilIcon
                        className="item-button"
                        onClick={() => window.open(customerPortal.link)}
                      />
                    </Tooltip>
                  </div>
                  )}
                </div>

                <div className="billing-details-content">
                  <CreditCardIcon />

                  <div>
                    {subscription?.paymentMethod ? (
                      <>
                        <p>Ending in {subscription.paymentMethod?.last4}</p>
                        {subscription.paymentMethod?.cardExpirationDate
                      && <p className="card-expiration-date">Exp. {subscription.paymentMethod.cardExpirationDate}</p>}
                      </>
                    ) : <p style={{ color: 'rgba(255, 255, 255, 0.70)' }}>No Credit Card</p>}
                  </div>
                </div>
              </div>
            </div>

            <div className="table-wrapper">
              <div className="payment-hisotry-header">
                <p>Payment history</p>
              </div>
              <div style={{ height: 349 }}>
                {(!subscription || isPaymentsFetching || !!rows.length)
                && (
                <DataGrid
                  sx={{
                    '&': {
                      // eslint-disable-next-line max-len
                      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23',
                    },
                    '.MuiDataGrid-columnHeaders': {
                      minHeight: '36px !important',
                      maxHeight: '36px !important',
                      lineHeight: '36px !important',
                      borderColor: 'rgba(255,255,255, 0.04)',
                    },
                    '.MuiDataGrid-columnHeader': {
                      padding: '0 16px',
                    },
                    '.MuiDataGrid-cell': {
                      borderColor: 'rgba(255,255,255, 0.04)',
                      color: 'white',
                      fontSize: 14,
                      padding: '0 16px',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '24px',
                      letterSpacing: '0.17px',
                      color: 'rgba(255, 255, 255, 0.5)',
                    },
                    '& .MuiInputBase-root, & .MuiInputBase-root:hover, & .MuiInputBase-root:focus-within': {
                      // eslint-disable-next-line max-len
                      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), #090D23',
                    },
                    '.MuiDataGrid-footerContainer': {
                      borderColor: 'rgba(255,255,255, 0.04)',
                      button: {
                        color: 'white',
                      },
                      svg: {
                        color: 'white',
                      },
                    },
                    '.MuiDataGrid-sortIcon': {
                      color: 'white',
                    },
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '.MuiDataGrid-iconSeparator': {
                      display: 'none',
                    },
                    '.MuiToolbar-root': {
                      color: 'white',
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  hideFooter={rows.length <= 5}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15]}
                  loading={!subscription || isPaymentsFetching}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnMenu
                  disableDensitySelector
                  disableVirtualization
                />
                )}

                {subscription && !isPaymentsFetching && !rows.length
                && (
                <div className="empty-state">
                  <PaymentHistoryIcon />

                  <p>No payment history yet</p>
                </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default Billing;
