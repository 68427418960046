import { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import AuthorizedLayout from 'layouts/authorized';

import { PlusIcon, OpenedLetterWithSmileIcon } from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';
import Pagination from 'components/Pagination';

import useWindowDimensions from 'hooks/useWindowDimensions';

import AddOrEditEmailMXRiskGroupModal from './components/AddOrEditEmailMXRiskGroupModal';
import CopyEmailModal from './components/CopyEmailModal';
import GroupItem from './components/GroupItem';

import './styles.scss';

const ITEM_WIDTH = 532;
const ITEM_HEIGHT = 313;

const EmailMXRisk = () => {
  const { width, height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);

  const [isAddEmailMXRiskGroupVisible, setIsAddEmailMXRiskGroupVisible] = useState(false);
  const [isCopyEmailModalVisible, setIsCopyEmailModalVisible] = useState(false);
  const [emailToCopy, setEmailToCopy] = useState(null);

  const rows = Math.floor((width - 352) / ITEM_WIDTH);
  const columns = Math.floor((height - 306) / ITEM_HEIGHT);
  const perPage = (rows > 0 ? rows : 1) * (columns > 0 ? columns : 1);

  const {
    data,
    isLoading,
    isFetching,
  } = mxriskApi.useEmailGroups({
    page,
    perPage,
  });
  const [fetchedMonitors, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isEmptyState = !isFetching && !items.length;

  useEffect(() => {
    if (fetchedMonitors) {
      setItems(fetchedMonitors);
    }
  }, [fetchedMonitors]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const EmptyState = () => (
    <div className="empty-state">
      <OpenedLetterWithSmileIcon />

      <p className="empty-state-title">Create your first group</p>

      <Button
        startIcon={<PlusIcon />}
        iconClassName="button-icon"
        onClick={() => setIsAddEmailMXRiskGroupVisible(true)}
      >
        Add Email MX Risk Group
      </Button>
    </div>
  );

  const refreshItemList = useCallback(() => {
    searchParams.set('page', 1);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onGroupAdded = useCallback((emailGroup) => {
    refreshItemList();

    setIsCopyEmailModalVisible(true);
    setEmailToCopy(emailGroup.emailTo);
  }, [refreshItemList]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <AuthorizedLayout>
      {isAddEmailMXRiskGroupVisible && (
      <AddOrEditEmailMXRiskGroupModal
        open={isAddEmailMXRiskGroupVisible}
        onClose={() => setIsAddEmailMXRiskGroupVisible(false)}
        onGroupAdded={onGroupAdded}
      />
      )}
      {emailToCopy && (
      <CopyEmailModal
        open={isCopyEmailModalVisible}
        onClose={() => setIsCopyEmailModalVisible(false)}
        email={emailToCopy}
      />
      )}

      <div className="email-mx-risk-wrapper">
        <div>
          <div className="title">
            <p>Email MX Risk</p>

            {!isEmptyState && (
            <Button
              startIcon={<PlusIcon />}
              iconClassName="button-icon"
              onClick={() => setIsAddEmailMXRiskGroupVisible(true)}
            >
              Add Group
            </Button>
            )}
          </div>
        </div>

        {isEmptyState && <EmptyState />}

        {!!items.length && (
          <div className="list-wrapper">
            <div className="monitor-list">
              {items.map((item) => (
                <GroupItem key={item['@id']} item={item} onDeleted={refreshItemList} />
              ))}
            </div>

            {totalItems > items.length && (
            <Pagination
              className="table-pagination"
              activePage={page}
              perPage={perPage}
              totalItems={totalItems}
              onPageChanged={onPageChanged}
            />
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default EmailMXRisk;
