import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { userApi } from 'resources/user';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const EditAPIKeyModal = ({
  open,
  onClose,
  item,
}) => {
  const [name, setName] = useState(item.name);

  const { mutate: updateAPIKey, isLoading } = userApi.useUpdateAPIKey();

  const [errors, setErrors] = useState({
    name: null,
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [name]);

  const onSaveClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    updateAPIKey({
      identifier: item.identifier,
      name,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('API key has been updated');

        onClose();
      },
    });
  }, [validateForm, onClose, updateAPIKey, item.identifier, name]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit API Key"
    >
      <div className="edit-api-key-modal-wrapper">
        <Input
          label="API key name"
          value={name}
          onChange={(value) => onChangeInput({ value, setter: setName, errorField: 'name' })}
          error={!!errors.name}
          helperText={errors.name}
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              onSaveClick();
            }
          }}
        />
        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
          <Button onClick={onSaveClick} loading={isLoading}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

EditAPIKeyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export default React.memo(EditAPIKeyModal);
