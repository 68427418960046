import React, { useCallback, useState } from 'react';

import AuthorizedLayout from 'layouts/authorized';

import { APIIcon, PlusIcon } from 'assets/icons';

import Button from 'components/Button';

import CircularProgress from '@mui/material/CircularProgress';

import { userApi } from 'resources/user';

import APIKeyItem from './components/APIKeyItem';
import AddAPIKeyModal from './components/AddAPIKeyModal';

import './styles.scss';

const APIKeys = () => {
  const [isAddAPIKeyModalVisible, setIsAddAPIKeyModalVisible] = useState(false);

  const { data, isLoading } = userApi.useAPIKeysList();

  const onOpenAPIDocs = useCallback(() => {
    window.open(`${process.env.REACT_APP_API_URL}/v2/docs`, '_blank');
  }, []);

  const onAddAPIKeyClick = useCallback(() => {
    setIsAddAPIKeyModalVisible(true);
  }, []);

  const EmptyState = () => (
    <div className="empty-state">
      <APIIcon />

      <p className="empty-state-title">You don&apos;t have any API Keys</p>

      <p className="empty-state-description">
        Find the email address of any professional with the most advanced email-finding tool.
        Generate a key below and see our API docs to get started.
      </p>

      <div className="buttons">
        <Button
          className="add-api-key-button"
          startIcon={<PlusIcon />}
          iconClassName="button-icon"
          onClick={onAddAPIKeyClick}
        >
          API Key
        </Button>

        <Button
          className="api-docs-button"
          variant="outlined"
          onClick={onOpenAPIDocs}
        >
          API Docs
        </Button>
      </div>
    </div>
  );

  return (
    <AuthorizedLayout>
      {isAddAPIKeyModalVisible && (
      <AddAPIKeyModal
        open={isAddAPIKeyModalVisible}
        onClose={() => setIsAddAPIKeyModalVisible(false)}
      />
      )}

      <div className="api-keys-wrapper">
        <div className="title-wrapper">
          <p className="title">API Keys</p>

          <div className="buttons">
            <Button
              className="api-docs-button"
              variant="outlined"
              onClick={onOpenAPIDocs}
            >
              API Docs
            </Button>

            <Button
              className="add-api-key-button"
              startIcon={<PlusIcon />}
              iconClassName="button-icon"
              onClick={onAddAPIKeyClick}
            >
              Add API Key
            </Button>
          </div>
        </div>

        {isLoading && <CircularProgress style={{ margin: 'auto' }} />}

        {!isLoading && !data.length && <EmptyState />}

        {!isLoading && !!data.length && (
          <div className="api-keys-list">
            <div className="table-head">
              <p className="name-head">Name</p>
              <p className="key-head">Key</p>
              <p className="created-head">Created</p>
            </div>

            <div className="table-head-line" />

            {data.map((item) => <APIKeyItem key={item['@id']} item={item} />)}
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default APIKeys;
