import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { QuestionMarkIcon } from 'assets/icons';

import { userApi } from 'resources/user';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
import Popover from 'components/Popover';
import Select from 'components/Select';

import IconButton from '@mui/material/IconButton';

import { USER_ROLES } from 'resources/user/user.constants';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

const ROLE_SELECT_OPTIONS = Object.values(USER_ROLES).map((value) => {
  const upperCaseLabel = value.substring(value.lastIndexOf('_') + 1);

  return {
    label: upperCaseLabel[0] + upperCaseLabel.slice(1).toLowerCase(),
    value,
  };
});

const AddOrEditUserModal = ({
  open,
  onClose,
  user,
}) => {
  const [email, setEmail] = useState(user?.emailAddress || '');
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [role, setRole] = useState(user?.role || USER_ROLES.USER);

  const [errors, setErrors] = useState({
    email: null,
  });

  const [questionIconRef, setQuestionIconRef] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { mutate: addToAccount, isLoading: isAddToAccountLoading } = userApi.useAddToAccount();
  const { mutate: updateUser, isLoading: isUpdateUserLoading } = userApi.useUpdateConnectedUser();

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'This field is required';
    }

    if (email && !validateEmail(email)) {
      newErrors.email = 'Incorrect email format';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [email]);

  const onInvite = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    addToAccount({ email, firstName, lastName, role }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Invitation sent');

        onClose();
      },
    });
  }, [validateForm, addToAccount, email,
    firstName, lastName, role, onClose]);

  const onEdit = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    updateUser({ email, firstName, lastName, role }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('User has been updated');

        onClose();
      },
    });
  }, [validateForm, updateUser, email,
    firstName, lastName, role, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${user ? 'Edit' : 'Add'} User`}
      style={{ overflow: 'visible' }}
    >
      <div className="add-or-edit-user-modal-wrapper">
        <div className="form">
          <Input
            label="Email"
            value={email}
            onChange={(value) => onChangeInput({ value, setter: setEmail, errorField: 'email' })}
            error={!!errors.email}
            helperText={errors.email}
            readOnly={!!user}
          />

          <div className="name-inputs">
            <Input
              label="First name"
              value={firstName}
              onChange={setFirstName}
            />

            <Input
              label="Last name"
              value={lastName}
              onChange={setLastName}
            />
          </div>

          <div className="role-input-wrapper">
            <Select
              items={ROLE_SELECT_OPTIONS}
              value={role}
              onChange={setRole}
              className="role-select"
            />

            <IconButton className="role-question-button" onClick={() => setIsPopoverVisible(true)}>
              <QuestionMarkIcon
                ref={setQuestionIconRef}
              />
            </IconButton>

            {isPopoverVisible && (
              <Popover
                forRef={questionIconRef}
                title="User Roles"
                description={(
                  <div className="role-tooltip-description">
                    <p><span>Admin:</span> Full access to all features, plus user management.</p>
                    <p><span>User:</span> Access to all features. No user or billing management.</p>
                  </div>
                )}
                onClose={() => setIsPopoverVisible(false)}
                placement="top-end"
              />
            )}
          </div>
        </div>

        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
          <Button
            onClick={user ? onEdit : onInvite}
            loading={isAddToAccountLoading || isUpdateUserLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AddOrEditUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

AddOrEditUserModal.defaultProps = {
  user: null,
};

export default React.memo(AddOrEditUserModal);
