import { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { styled } from '@mui/material';

import {
  CheckIcon, CancelIcon, ResurrectionMarkIcon,
  PencilIcon, HistoryIcon, TrashIcon, PlayIcon,
  CheckOutlinedIcon, DotsIcon,
} from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons';

import Chip from '@mui/material/Chip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import Input from 'components/Input';
import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import useOutsideClick from 'hooks/useOutsideClick';
import useWindowDimensions from 'hooks/useWindowDimensions';

import HistoryModal from './components/HistoryModal';
import EditNameModal from './components/EditNameModal';

import './styles.scss';

export const getRawDataByStatus = (lastCheckFlag) => {
  switch (lastCheckFlag) {
    case 'green':
      return {
        icon: <CheckIcon />,
        color: '#17EDC3',
        backgroundColor: 'rgba(23, 237, 195, 0.16)',
        chip: {
          label: 'Healthy',
          color: 'primary',
        },
      };
    case 'yellow':
      return {
        icon: <ResurrectionMarkIcon />,
        color: '#FFA726',
        backgroundColor: 'rgba(255, 167, 38, 0.30)',
        chip: {
          label: 'Down',
          color: 'warning',
        },
      };
    case 'red':
      return {
        icon: <CancelIcon />,
        color: '#F02B4F',
        backgroundColor: 'rgba(240, 43, 79, 0.30)',
        chip: {
          label: 'Blacklisted',
          color: 'error',
        },
      };
    default:
      return {
        icon: <ResurrectionMarkIcon />,
        color: '#FFA726',
        backgroundColor: 'rgba(255, 167, 38, 0.30)',
        chip: {
          label: 'Processing',
          color: 'default',
        },
      };
  }
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#282d44',
    fontSize: theme.typography.pxToRem(12),
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: '#282d44',
  },
}));

const IPItem = ({ item: initialItem, onEditIPName, disabled }) => {
  const { width } = useWindowDimensions();

  const editNameInputWrapperRef = useRef(null);
  const editInputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [item, setItem] = useState(initialItem);
  const [ipName, setIpName] = useState(initialItem.name || '');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [isEditNameModalVisible, setIsEditNameModalVisible] = useState(false);
  const [scanningIntervalId, setScanningIntervalId] = useState(null);

  const mobileWidth = width <= 1400;

  const {
    mutate: updateMonitorCheck,
    isLoading: isUpdateMonitorCheckLoading,
  } = monitorApi.useUpdateMonitorCheck();
  const {
    mutate: deleteMonitorCheck,
    isLoading: isDeleteMonitorCheckLoading,
  } = monitorApi.useDeleteMonitorCheck();
  const {
    mutate: runScanNow,
    isLoading: isRunScanNowLoading,
  } = monitorApi.useMonitorCheckScanNow();
  const {
    data: monitorCheckList,
    refetch: refetchMonitorCheckList,
  } = monitorApi.useMonitorCheckListById(item.id);

  const rawData = getRawDataByStatus(item.lastCheckFlag);

  let scanTooltipTitle = 'Scan now';

  if (item.isScanning || isRunScanNowLoading) {
    scanTooltipTitle = 'Scanning';
  } else if (disabled) {
    scanTooltipTitle = 'You can’t scan IP while the monitor is disabled';
  }

  useEffect(() => {
    if (item.isEditMode) {
      editInputRef.current.focus();
    }
  }, [item.isEditMode]);

  const onEditClick = useCallback(() => {
    setItem({
      ...item,
      isEditMode: true,
    });
  }, [item]);

  useOutsideClick(editNameInputWrapperRef, () => setItem({
    ...item,
    isEditMode: false,
  }));

  const onEditIPNameSave = useCallback(() => {
    updateMonitorCheck({ id: item.id, name: ipName }, {
      onSuccess: (data) => {
        onEditIPName({ id: item.id, name: data.name });
        setItem({
          ...item,
          isEditMode: false,
        });
      },
    });
  }, [item, ipName, onEditIPName, updateMonitorCheck]);

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDeleteClick = useCallback(() => {
    setIsDeleteModalVisible(true);

    closeMenu();
  }, [closeMenu]);

  const onDelete = useCallback(() => {
    deleteMonitorCheck({
      id: item.id,
      monitorId: +item.monitor.replace('/v2/monitors/', ''),
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('IP has been deleted!');

        setIsDeleteModalVisible(false);
      },
    });
  }, [deleteMonitorCheck, item.id, item.monitor]);

  const onHistoryClick = useCallback(() => {
    setIsHistoryModalVisible(true);

    closeMenu();
  }, [closeMenu]);

  useEffect(() => {
    if (item.isScanning && item.lastCheckAt !== monitorCheckList?.lastCheckAt) {
      setItem({
        ...item,
        ...monitorCheckList,
        isScanning: false,
      });

      clearInterval(scanningIntervalId);
      setScanningIntervalId(null);
    }
  }, [item, monitorCheckList, scanningIntervalId]);

  const onScanNowClick = useCallback(() => {
    if (isRunScanNowLoading || item.isScanStarting || item.isScanning) {
      return;
    }

    setItem({
      ...item,
      isScanStarting: true,
    });

    runScanNow(item.id, {
      onSuccess: () => {
        setItem({
          ...item,
          isScanning: true,
        });

        const intervalId = setInterval(() => {
          refetchMonitorCheckList();
        }, 3000);
        setScanningIntervalId(intervalId);
      },
    });

    closeMenu();
  }, [item, runScanNow, isRunScanNowLoading, refetchMonitorCheckList, closeMenu]);

  return (
    <div className="ip-row-container">
      <DeleteModal
        title="Are you sure you want to delete this IP and all its history?"
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={onDelete}
        isDeleteLoading={isDeleteMonitorCheckLoading}
      />
      {isHistoryModalVisible && (
      <HistoryModal
        open={isHistoryModalVisible}
        onClose={() => setIsHistoryModalVisible(false)}
        monitorCheck={item}
      />
      )}
      <EditNameModal
        open={isEditNameModalVisible}
        onClose={() => setIsEditNameModalVisible(false)}
        item={item}
        onEditIPName={onEditIPName}
      />

      {!mobileWidth && (
      <div className={cn('ip-row', disabled && 'ip-row-disabled')}>
        <div>
          <div className="ip-address">
            <div className={cn('status-icon', {
              healthy: item.lastCheckFlag === 'green',
              down: item.lastCheckFlag === 'yellow',
              blacklisted: item.lastCheckFlag === 'red',
              default: item.lastCheckFlag === null,
              disabled,
            })}
            >
              {rawData.icon}
            </div>

            <HtmlTooltip
              title={(
                <div className="ip-address-tooltip-value">
                  <div>
                    <p>IP Address</p>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 6,
                      }}
                    >
                      <p style={{ maxWidth: 120 }}>{item.item}</p>

                      <div
                        className="status-icon"
                        style={{
                          backgroundColor: rawData.backgroundColor,
                        }}
                      >
                        {rawData.icon}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>ISP</p>
                    <p>{item.ipDetail?.isp || '-'}</p>
                  </div>
                  <div>
                    <p>Continent</p>
                    <p>{item.ipDetail?.continent || '-'}</p>
                  </div>
                  <div>
                    <p>Country</p>
                    <div style={{ display: 'flex', gap: 6 }}>
                      <p>{item.ipDetail?.country || '-'}</p>
                      {item.ipDetail?.countryFlagEmoji && <p style={{ fontSize: 24 }}>{item.ipDetail.countryFlagEmoji}</p>}
                    </div>
                  </div>
                  <div>
                    <p>State/Regions</p>
                    <p>{item.ipDetail?.state || '-'}</p>
                  </div>
                  <div>
                    <p>City</p>
                    <p>{item.ipDetail?.city || '-'}</p>
                  </div>
                  <div>
                    <p>Latitude</p>
                    <p>{item.ipDetail?.latitude || '-'}</p>
                  </div>
                  <div>
                    <p>Longitude</p>
                    <p>{item.ipDetail?.longitude || '-'}</p>
                  </div>
                </div>
              )}
              arrow
              placement="bottom-start"
            >
              <div className="ip-address-wrapper">
                <p
                  onClick={onHistoryClick}
                  aria-hidden
                  className="ip-address-value"
                >
                  {item.item}
                </p>

                <FontAwesomeIcon
                  icon={faMagnifyingGlassPlus}
                  className="magnifying-glass-icon"
                  color="#17EDC3"
                />
              </div>
            </HtmlTooltip>
          </div>

          {item.isEditMode
            ? (
              <div className="edit-wrapper" ref={editNameInputWrapperRef}>
                <Input
                  className="edit-input"
                  value={ipName}
                  onChange={setIpName}
                  ref={editInputRef}
                  onKeyDown={(event) => {
                    if (event.code === 'Enter') {
                      if (ipName === item.name || (ipName === '' && item.name === null)) {
                        return;
                      }

                      onEditIPNameSave();
                    }
                  }}
                />

                <Button
                  onClick={onEditIPNameSave}
                  disabled={ipName === item.name || (ipName === '' && item.name === null)}
                  loading={isUpdateMonitorCheckLoading}
                >
                  Save
                </Button>
              </div>
            )
            : (
              <div className="name-wrapper">
                <p className={cn(!item.name && 'disabled-text')}>{item.name || 'n/a'}</p>
                <Tooltip onClick={onEditClick} title="Edit IP Name" placement="top">
                  <div className={cn('icon-wrapper', 'icon-wrapper-pencil')}>
                    <PencilIcon />
                  </div>
                </Tooltip>
              </div>
            )}
        </div>

        <div className="country-container">
          <p>{item.ipDetail?.countryFlagEmoji || '-'}</p>
        </div>

        <div className="isp-container">
          <Tooltip title={item.ipDetail?.isp || '-'}>
            <p>{item.ipDetail?.isp || '-'}</p>
          </Tooltip>
        </div>

        <div className="last-scan-container">
          <p>{item.lastCheckAt && moment(item.lastCheckAt).format('MMM DD, YYYY h:mmA')}</p>
        </div>

        <div className="chip-container">
          {item.isScanning
            ? <CircularProgress size={20} />
            : (
              <Chip
                className={cn(disabled && 'disabled-chip')}
                label={rawData.chip.label}
                variant="outlined"
                color={rawData.chip.color}
                onClick={onHistoryClick}
                style={{ cursor: 'pointer' }}
              />
            )}
        </div>

        {!item.isEditMode && (
        <div className="item-actions">
          <Tooltip
            title={scanTooltipTitle}
            placement="top"
            onClick={!disabled ? onScanNowClick : null}
          >
            <div className={cn('icon-wrapper', (item.isScanning || isRunScanNowLoading) && 'icon-wrapper-disabled')}>
              <CheckOutlinedIcon {...disabled && { style: { opacity: 0.3 } }} />
            </div>
          </Tooltip>
          <Tooltip onClick={onHistoryClick} title="Scan History" placement="top">
            <div className="icon-wrapper">
              <HistoryIcon />
            </div>
          </Tooltip>
          <Tooltip onClick={onDeleteClick} title="Delete" placement="top" disabled={isDeleteMonitorCheckLoading}>
            <div className="icon-wrapper">
              <TrashIcon />
            </div>
          </Tooltip>
        </div>
        )}
      </div>
      )}

      {mobileWidth && (
      <div className="mobile-ip-row">
        <div className="mobile-icon-and-item">
          <div className={cn('status-icon', {
            healthy: item.lastCheckFlag === 'green',
            down: item.lastCheckFlag === 'yellow',
            blacklisted: item.lastCheckFlag === 'red',
            default: item.lastCheckFlag === null,
            disabled,
          })}
          >
            {rawData.icon}
          </div>

          <div className="mobile-item-info">
            <p>{item.item}</p>
            {ipName && <p>{ipName}</p>}
            {item.lastCheckAt && <p>Last Scan: {moment(item.lastCheckAt).format('MMM DD, YYYY h:mmA')}</p>}
            <div className="mobile-item-country">
              <p>Country:</p>
              <span>{item.ipDetail?.countryFlagEmoji || '-'}</span>
            </div>
            <p>ISP: {item.ipDetail?.isp || '-'}</p>

            <div className="mobile-chip-container">
              {item.isScanning
                ? <CircularProgress size={20} />
                : (
                  <Chip
                    className={cn(disabled && 'disabled-chip')}
                    label={rawData.chip.label}
                    variant="outlined"
                    color={rawData.chip.color}
                  />
                )}
            </div>
          </div>
        </div>

        <div className={cn('menu-wrapper', anchorEl && 'menu-wrapper-active')}>
          <IconButton onClick={openMenu}>
            <DotsIcon />
          </IconButton>

          <Menu
            open={!!anchorEl}
            onClose={closeMenu}
            anchorEl={anchorEl}
          >
            <MenuItem
              onClick={!disabled ? onScanNowClick : null}
              className="menu-item"
              disabled={item.isScanning || isRunScanNowLoading || disabled}
            >
              <PlayIcon />
              <p>Scan Now</p>
            </MenuItem>
            <MenuItem
              onClick={onHistoryClick}
              className="menu-item"
            >
              <HistoryIcon />
              <p>Scan History</p>
            </MenuItem>
            <MenuItem
              onClick={() => { setIsEditNameModalVisible(true); closeMenu(); }}
              className="menu-item"
            >
              <PencilIcon />
              <p>Rename</p>
            </MenuItem>
            <MenuItem
              onClick={onDeleteClick}
              className="menu-item"
              disabled={isDeleteMonitorCheckLoading}
            >
              <TrashIcon />
              <p>Delete</p>
            </MenuItem>
          </Menu>
        </div>
      </div>
      )}

      {!mobileWidth && <div className="line" />}
    </div>
  );
};

IPItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onEditIPName: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default IPItem;
