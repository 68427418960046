const greyGradient = 'linear-gradient(90deg, #464A5E 0%, #373D59 100%)';

const generateLinearGradient = (email) => {
  const numericValue = email
    .split('')
    .reduce((sum, char) => sum + char.charCodeAt(0), 0);

  // Generate gradient colors based on the numeric value
  const color1 = `rgb(${(numericValue % 128) + 128}, ${(numericValue % 64) + 128}, ${(numericValue % 32) + 128})`;
  const color2 = `rgb(${((numericValue + 128) % 128) + 128}, ${((numericValue + 64) % 64) + 128}, ${((numericValue + 32) % 32) + 128})`;

  // Create the linear gradient style
  const linearGradient = `linear-gradient(45deg, ${color1}, ${color2})`;

  return linearGradient;
};

export const getGreyGradient = () => greyGradient;

export default generateLinearGradient;
