const { useState, useEffect } = require('react');

export default function useElementExists(selector) {
  const [exists, setExists] = useState(!!document.querySelector(selector));

  useEffect(() => {
    const checkElementExists = () => {
      setExists(!!document.querySelector(selector));
    };

    // Set up mutation observer to watch for DOM changes
    const observer = new MutationObserver(checkElementExists);

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [selector]);

  return exists;
}
