import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { FilesIcon, LetterIcon } from 'assets/icons';

import { userApi } from 'resources/user';
import { emailApi } from 'resources/email';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

const ShareReportModal = ({
  open,
  onClose,
  linkToShare,
  toEmail,
  isShareEnabled,
  onSwitchChange,
  isSwitchDisabled,
  onSend,
  isSendLoading,
  isOwner,
}) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('Please review my email infrastructure report from MX Right.');

  const [errors, setErrors] = useState({
    email: null,
    message: null,
  });

  const { data: currentUser, isLoading: isCurrentUserLoading } = userApi.useCurrentUser();
  const { mutate: sendReport, isLoading: isSendReportLoading } = emailApi.useSendReport();

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(linkToShare);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [linkToShare]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'This field is required';
    }

    if (!message) {
      newErrors.message = 'This field is required';
    }

    if (email && !validateEmail(email)) {
      newErrors.email = 'Incorrect email format';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [email, message]);

  const onEmailSend = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    if (onSend) {
      onSend({ email, message });

      return;
    }

    if (currentUser) {
      sendReport({ toEmail, recipient: email, message }, {
        onSuccess: () => {
          uiNotificationService.showSuccess('The email was successfully sent');
          onClose();
        },
      });

      return;
    }

    window.location = `mailto:${email}?&body=${message}`;
  }, [validateForm, email, message, currentUser,
    sendReport, onClose, toEmail, onSend]);

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  if (isCurrentUserLoading) {
    return;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Share report"
    >
      <div className="share-report-modal-wrapper">
        {isOwner && (
        <FormControlLabel
          className="switch-container"
          control={(
            <Switch
              checked={isShareEnabled}
              onChange={(e) => onSwitchChange(e.target.checked)}
            />
          )}
          label="Shared"
          disabled={isSwitchDisabled}
        />
        )}

        <div className="link-to-share-wrapper">
          <p>Share Link</p>

          <div className="copy-button-wrapper">
            <Input className="link-input" value={linkToShare} />

            <Button
              className="copy-button"
              startIcon={<FilesIcon />}
              iconClassName="copy-icon"
              onClick={onCopyToClipboard}
              disabled={!isShareEnabled}
            >
              Copy Link
            </Button>
          </div>
        </div>

        {isOwner && (
        <>
          <div className="line" />

          <div className="email-wrapper">
            <p>Send via email</p>

            <div className="email-form">
              <Input
                placeholder="Email address"
                value={email}
                onChange={(value) => onChangeInput({ value, setter: setEmail, errorField: 'email' })}
                error={!!errors.email}
                helperText={errors.email}
              />

              <Input
                label="Message"
                value={message}
                onChange={(value) => onChangeInput({ value, setter: setMessage, errorField: 'message' })}
                error={!!errors.message}
                helperText={errors.message}
                multiline
              />

              <Button
                className="send-button"
                startIcon={<LetterIcon />}
                iconClassName="send-icon"
                onClick={onEmailSend}
                disabled={!isShareEnabled || isSendReportLoading || isSendLoading}
              >
                Send
              </Button>
            </div>
          </div>
        </>
        )}
      </div>
    </Modal>
  );
};

ShareReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkToShare: PropTypes.string.isRequired,
  toEmail: PropTypes.string,
  isShareEnabled: PropTypes.bool.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  isSwitchDisabled: PropTypes.bool.isRequired,
  onSend: PropTypes.func,
  isSendLoading: PropTypes.bool,
  isOwner: PropTypes.bool,
};

ShareReportModal.defaultProps = {
  onSend: null,
  toEmail: null,
  isSendLoading: false,
  isOwner: false,
};

export default React.memo(ShareReportModal);
