import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ErrorOutlineIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import queryClient from 'query-client';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

export const checkRadioItems = [
  {
    label: 'On Schedule',
    value: 'on_schedule',
  },
  {
    label: (
      <div className="pro-label">
        <p>Continuous</p>
        <div><p>PRO</p></div>
      </div>
    ),
    value: 'continuous',
    disabled: true,
  },
];

export const checkSelectItems = [
  {
    label: '1 hour',
    value: 3600,
  },
  {
    label: '3 hours',
    value: 10800,
  },
  {
    label: '6 hours',
    value: 21600,
  },
  {
    label: '12 hours',
    value: 43200,
  },
  {
    label: '1 day',
    value: 86400,
  },
];

export const notificationsRadioItems = [
  {
    label: 'Saved Presets',
    value: 'saved_presets',
  },
  {
    label: 'Custom Settings',
    value: 'custom_settings',
  },
];

export const notificationFrequencySelectItems = [
  {
    label: 'Every Check',
    value: 'everyCheck',
  },
  {
    label: 'On status change',
    value: 'onChange',
  },
];

const AddOrEditNotificationPresetModal = ({
  open,
  onClose,
  initialData,
  onAdded,
  onUpdated,
}) => {
  const [presetName, setPresetName] = useState(initialData?.presetName || '');
  const [notificationFrequencySelectValue, setNotificationFrequencySelectValue] = useState(
    initialData?.schedule || notificationFrequencySelectItems[0].value,
  );
  const [customNotificationSettigs, setCustomerNotificationSettings] = useState({
    email: {
      enabled: !!initialData?.target.emails?.length,
      value: (initialData?.target.emails && initialData?.target.emails.join(', ')) || '',
      error: null,
    },
    sms: {
      enabled: !!initialData?.target.sms,
      value: initialData?.target.sms || '',
      error: null,
    },
    slack: {
      enabled: !!initialData?.target.slack,
      value: initialData?.target.slack || '',
      error: null,
    },
    pagerDuty: {
      enabled: !!initialData?.target.pagerDuty,
      value: initialData?.target.pagerDuty || '',
      error: null,
    },
  });
  const [errors, setErrors] = useState({
    name: null,
    ipList: null,
    presetName: null,
  });
  const [generalError, setGeneralError] = useState(null);

  const {
    mutate: createMonitorNotification,
    isLoading: isCreateMonitorNotificaitonLoading,
  } = monitorApi.useCreateMonitorNotification();
  const {
    mutate: updateMonitorNotification,
    isLoading: isUpdateMonitorNotificaitonLoading,
  } = monitorApi.useUpdateMonitorNotification();

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!presetName) {
      newErrors.presetName = 'This field is required';
    }
    const { email, pagerDuty } = customNotificationSettigs;

    if (email.enabled) {
      if (!email.value) {
        newErrors.email = 'This field is required';
      }

      if (email.value && !email.value.split(',').every((item) => validateEmail(item.trim()))) {
        newErrors.email = 'Incorrect email format';
      }
    }

    if (pagerDuty.enabled) {
      if (!pagerDuty.value) {
        newErrors.pagerDuty = 'This field is required';
      }

      if (pagerDuty.value && !validateEmail(pagerDuty.value)) {
        newErrors.pagerDuty = 'Incorrect email format';
      }
    }

    setCustomerNotificationSettings((old) => ({
      ...old,
      ...(newErrors.email && {
        email: { ...old.email, error: newErrors.email },
      }),
      ...(newErrors.pagerDuty && {
        pagerDuty: { ...old.pagerDuty, error: newErrors.pagerDuty },
      }),
    }));

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [presetName, customNotificationSettigs]);

  const onCreateOrUpdateClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    setGeneralError(null);

    const dataToSave = {
      presetName,
      ...customNotificationSettigs.email.enabled && {
        emails: customNotificationSettigs.email.value.split(',').map((email) => email.trim()),
      },
      ...customNotificationSettigs.sms.enabled && { sms: customNotificationSettigs.sms.value },
      ...customNotificationSettigs.slack.enabled && { slack: customNotificationSettigs.slack.value },
      ...customNotificationSettigs.pagerDuty.enabled && { pagerDuty: customNotificationSettigs.pagerDuty.value },
      schedule: notificationFrequencySelectValue,
    };

    if (initialData) {
      updateMonitorNotification({ id: initialData.id, ...dataToSave }, {
        onSuccess: (data) => {
          uiNotificationService.showSuccess('Notification Preset has been updated');

          if (onUpdated) {
            onUpdated(data);
          }

          onClose();
        },
        onError: (error) => {
          if (error.status === 401) {
            uiNotificationService.showSuccess('Notification Preset has been updated');

            queryClient.invalidateQueries('monitor.notificationList');

            onClose();

            return;
          }

          setGeneralError(error.data['hydra:description']);
        },
      });

      return;
    }

    createMonitorNotification(dataToSave, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Notification Preset has been created');

        if (onAdded) {
          onAdded();
        }

        onClose();
      },
      onError: (error) => {
        if (error.status === 401) {
          uiNotificationService.showSuccess('Notification Preset has been created');

          queryClient.invalidateQueries('monitor.notificationList');

          onClose();

          return;
        }

        setGeneralError(error.data['hydra:description']);
      },
    });
  }, [validateForm, customNotificationSettigs, presetName, onClose,
    createMonitorNotification, initialData, notificationFrequencySelectValue,
    onAdded, updateMonitorNotification, onUpdated]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${initialData ? 'Edit' : 'Add'} Notification Preset`}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <div className="add-or-edit-notification-preset-modal-wrapper">
        {generalError && (
        <div className="general-error-wrapper">
          <ErrorOutlineIcon />
          <p>{generalError}</p>
        </div>
        )}

        <div className="inputs">
          <Input
            className="notification-preset-name-input"
            placeholder="Notification Preset Name"
            value={presetName}
            onChange={(value) => onChangeInput({ value, setter: setPresetName, errorField: 'presetName' })}
            error={!!errors.presetName}
            helperText={errors.presetName}
          />

          <Select
            value={notificationFrequencySelectValue}
            onChange={setNotificationFrequencySelectValue}
            items={notificationFrequencySelectItems}
            type="filled"
            renderValue={(renderValue) => (
              <div className="select-value">
                <p>Notification Frequency</p>
                <p>
                  {notificationFrequencySelectItems
                    .find((selectItem) => selectItem.value === renderValue).label}
                </p>
              </div>
            )}
          />
        </div>

        <div className="line" />

        <div className="notification-custom-settings">
          <p>Delivery Method</p>

          <div className="switches-wrapper">
            <div>
              <FormControlLabel
                control={(
                  <Switch
                    checked={customNotificationSettigs.email.enabled}
                    onChange={(e) => setCustomerNotificationSettings((old) => ({
                      ...old,
                      email: {
                        ...old.email,
                        enabled: e.target.checked,
                      },
                    }))}
                  />
                  )}
                label="Email"
              />

              {customNotificationSettigs.email.enabled && (
              <div>
                <Input
                  multiline
                  rows={3}
                  className="emails-textarea"
                  value={customNotificationSettigs.email.value}
                  onChange={(value) => setCustomerNotificationSettings((old) => ({
                    ...old,
                    email: {
                      ...old.email,
                      value,
                      error: null,
                    },
                  }))}
                  error={!!customNotificationSettigs.email.error}
                />
                <p className={cn('helper-text', { 'helper-text-error': !!customNotificationSettigs.email.error })}>
                  {customNotificationSettigs.email.error || 'Separate by a comma'}
                </p>
              </div>
              )}
            </div>

            <div>
              <FormControlLabel
                control={(
                  <Switch
                    checked={customNotificationSettigs.sms.enabled}
                    onChange={(e) => setCustomerNotificationSettings((old) => ({
                      ...old,
                      sms: {
                        ...old.sms,
                        enabled: e.target.checked,
                      },
                    }))}
                  />
                  )}
                label={(
                  <div className="switch-label">
                    <p>SMS</p>

                    <div className="comming-soon-label">
                      <p>Comming soon...</p>
                    </div>
                  </div>
                  )}
                disabled
              />
            </div>

            <div>
              <FormControlLabel
                control={(
                  <Switch
                    checked={customNotificationSettigs.slack.enabled}
                    onChange={(e) => setCustomerNotificationSettings((old) => ({
                      ...old,
                      slack: {
                        ...old.slack,
                        enabled: e.target.checked,
                      },
                    }))}
                  />
                  )}
                label={(
                  <div className="switch-label">
                    <p>Slack</p>

                    <div className="comming-soon-label">
                      <p>Comming soon...</p>
                    </div>
                  </div>
                  )}
                disabled
              />
            </div>

            <div>
              <FormControlLabel
                control={(
                  <Switch
                    checked={customNotificationSettigs.pagerDuty.enabled}
                    onChange={(e) => setCustomerNotificationSettings((old) => ({
                      ...old,
                      pagerDuty: {
                        ...old.pagerDuty,
                        enabled: e.target.checked,
                      },
                    }))}
                  />
                  )}
                label="PagerDuty"
              />

              {customNotificationSettigs.pagerDuty.enabled && (
              <div>
                <Input
                  label="PagerDuty Email"
                  value={customNotificationSettigs.pagerDuty.value}
                  onChange={(value) => setCustomerNotificationSettings((old) => ({
                    ...old,
                    pagerDuty: {
                      ...old.pagerDuty,
                      value,
                      error: null,
                    },
                  }))}
                  error={!!customNotificationSettigs.pagerDuty.error}
                />
                <p className={cn('helper-text', { 'helper-text-error': !!customNotificationSettigs.pagerDuty.error })}>
                  {customNotificationSettigs.pagerDuty.error
                      || (
                      <>
                        Check out
                        {' '}
                        <Link
                          href="https://support.pagerduty.com/docs/email-integration-guide"
                          target="_blank"
                          underline="hover"
                        >
                          Email Integration Guide
                        </Link>
                        {' '}
                        to setup a PagerDuty email
                      </>
                      )}
                </p>
              </div>
              )}
            </div>
          </div>
        </div>

        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
          <Button
            onClick={onCreateOrUpdateClick}
            loading={isUpdateMonitorNotificaitonLoading || isCreateMonitorNotificaitonLoading}
          >
            {initialData ? 'Update' : 'Create'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AddOrEditNotificationPresetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func,
  onUpdated: PropTypes.func,
  initialData: PropTypes.shape(),
};

AddOrEditNotificationPresetModal.defaultProps = {
  onAdded: null,
  onUpdated: null,
  initialData: null,
};

export default React.memo(AddOrEditNotificationPresetModal);
