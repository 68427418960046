import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { CheckIcon, MinusIcon, ArrowBottomIcon, ArrowTopIcon } from 'assets/icons';

import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { getChipValue } from 'pages/MonitorResults';

import './styles.scss';

const GroupResultSection = ({ groupResultsSection }) => {
  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 600;

  const isSpamAssassin = groupResultsSection['@type'] === 'SpamAssassinResult';

  const onDescriptionOpenToggle = useCallback(() => {
    setIsDescriptionOpened(!isDescriptionOpened);
  }, [isDescriptionOpened]);

  return (
    <div className={cn('monitor-result-group-section', 'pagebreak')}>
      <div>
        <div className="group-section-title">
          <div className={cn(!groupResultsSection.description && 'align-items-center')}>
            {groupResultsSection.state && (
            <div className={cn('group-status-mark', !groupResultsSection.state.status && 'group-status-mark-fail')}>
              {groupResultsSection.state.status ? <CheckIcon /> : <MinusIcon />}
            </div>
            )}

            <div className="group-section-text">
              <p>{groupResultsSection.title}</p>
            </div>
          </div>

          <div className="chip-and-arrow-wrapper">
            {groupResultsSection.state && (
            <div className="chip-wrapper">
              <Chip
                className="chip"
                label={getChipValue({ state: groupResultsSection.state, mobileWidth })}
                variant={groupResultsSection.state.value === 'Fail' ? 'filled' : 'outlined'}
                color={groupResultsSection.state.color}
              />
            </div>
            )}

            {groupResultsSection.description && !mobileWidth && (
            <IconButton onClick={onDescriptionOpenToggle}>
              {isDescriptionOpened
                ? <ArrowTopIcon className="arrow" />
                : <ArrowBottomIcon className="arrow" />}
            </IconButton>
            )}
          </div>
        </div>

        {groupResultsSection.description && mobileWidth && (
        <div className="more-info">
          <p>More info</p>

          <IconButton onClick={onDescriptionOpenToggle}>
            {isDescriptionOpened
              ? <ArrowTopIcon className="arrow" />
              : <ArrowBottomIcon className="arrow" />}
          </IconButton>
        </div>
        )}

        {isDescriptionOpened && <p className="description">{groupResultsSection.description}</p>}

        {!!groupResultsSection.rules?.length && (
        <div className="rules">
          {groupResultsSection.rules.map((rule) => (
            <div className="rule-item" key={rule.title}>
              <div className="rule-item-content">
                <div>
                  <p>{rule.title}</p>

                  <p>{rule.description}</p>
                </div>

                {rule.state && (
                <div className={cn('rules-chip-wrapper', isSpamAssassin && 'spamassassin-chip-wrapper')}>
                  <Chip
                    className={isSpamAssassin ? 'spamassassin-chip' : 'sub-chip'}
                    label={isSpamAssassin ? rule.points : getChipValue({ state: rule.state, mobileWidth })}
                    variant={rule.state.value === 'Fail' ? 'filled' : 'outlined'}
                    color={rule.state.color}
                  />
                </div>
                )}
              </div>

              <div className="line" />
            </div>
          ))}
        </div>
        )}
      </div>
    </div>
  );
};

GroupResultSection.propTypes = {
  groupResultsSection: PropTypes.shape().isRequired,
};

export default React.memo(GroupResultSection);
