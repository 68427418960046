import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { MoveFolderIcon, FolderIcon, OpenedFolderIcon } from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const MoveToFolder = ({ analyzeResult }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: foldersData, isLoading: isFoldersLoading } = mxriskApi.useTemplateGroups({
    page: 1,
    perPage: 50,
  });
  const { mutate: updateValidationItem, isLoadinng: isUpdateValidationItemLoading } = mxriskApi.useUpdateValidationItem();

  const folders = foldersData?.items;

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onSelectFolder = useCallback((folder) => {
    const folderId = +folder['@id'].replace('/v2/mx-risk/template-groups/', '');

    updateValidationItem({
      id: analyzeResult.id,
      groupId: folderId,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Folder has been updated');

        // closeMenu();
      },
    });
  }, [updateValidationItem, analyzeResult.id]);

  if (isFoldersLoading || folders.length <= 1) {
    return null;
  }

  return (
    <div>
      <IconButton onClick={openMenu}>
        <Tooltip
          title="Move to folder"
          placement="top"
          className="edit-icon-container"
        >
          <MoveFolderIcon />
        </Tooltip>
      </IconButton>

      <Menu
        open={!!anchorEl}
        onClose={closeMenu}
        anchorEl={anchorEl}
        className="folder-menu scroll"
      >
        {folders.map((folder) => {
          const isActive = folder['@id'] === analyzeResult.group['@id'];

          return (
            <MenuItem
              key={folder['@id']}
              onClick={() => onSelectFolder(folder)}
              className={cn('folder-menu-item', isActive && 'folder-menu-item-active')}
              disabled={isUpdateValidationItemLoading}
            >
              {isActive ? <OpenedFolderIcon /> : <FolderIcon />}
              <p>{folder.name}</p>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

MoveToFolder.propTypes = {
  analyzeResult: PropTypes.shape().isRequired,
};

export default React.memo(MoveToFolder);
