import { useParams } from 'react-router-dom';
import moment from 'moment';

import AuthorizedLayout from 'layouts/authorized';

import { AuthLogoIcon } from 'assets/icons';

import NotFound from 'pages/NotFound';

import { placementApi } from 'resources/placement';
import { userApi } from 'resources/user';

import { CircularProgress, Divider } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

import CreateAccountHeader from 'components/CreateAccountHeader';

import HeaderInfo from './components/HeaderInfo';
import InboxReport from './components/InboxReport';
import EmailProviderReport from './components/EmailProviderReport';
import EmailInfrastructure from './components/EmailInfrastructure';

import './styles.scss';

const DeliverabilityTestDetails = () => {
  const params = useParams();

  const { data: currentUser } = userApi.useCurrentUser();
  const {
    data: placementTest,
    isLoading: isPlacementTestLoading,
    error,
  } = placementApi.useGet(+params.id);
  const {
    data: placementTestRun,
    isLoading: isPlacementTestRunLoading,
  } = placementApi.useGetRun({
    id: +params.id,
    runId: +params.runId,
  });

  const isLoading = isPlacementTestLoading || isPlacementTestRunLoading;

  if ([401, 404].includes(error?.status)) {
    return <NotFound />;
  }

  const isOwner = placementTest && placementTest.account === currentUser?.account;

  const content = (
    <>
      {isLoading && <CircularProgress sx={{ display: 'block', margin: '100px auto' }} />}

      {!isLoading && placementTest && (
      <div className="deliverability-test-details">
        {currentUser && (
        <HeaderInfo
          placementTest={placementTest}
          isOwner={isOwner}
        />
        )}
        <InboxReport
          placementTestRun={placementTestRun}
          showHistoricalData={!!currentUser && placementTest.recurring}
        />
        <EmailProviderReport placementTestRun={placementTestRun} />
        {placementTestRun?.mxRightEmailAddress && (
        <EmailInfrastructure
          email={placementTestRun.mxRightEmailAddress}
          showReport={!!currentUser}
        />
        )}
      </div>
      )}
    </>
  );

  return currentUser
    ? (
      <AuthorizedLayout>
        {content}
      </AuthorizedLayout>
    )
    : (
      <div>
        {!isLoading && (
        <CreateAccountHeader
          title="To run your own deliverability tests and more, register for a Free Account"
        />
        )}

        <div className="deliverability-test-unauthorized-wrapper" style={{ paddingTop: 56 }}>
          <div className="logo-wrapper">
            <AuthLogoIcon />

            <Divider sx={{ mt: '16px' }} />
          </div>

          <div className="deliverability-test-content-wrapper">
            <div className="deliverability-test-unauthorized-header">
              <p>Deliverability Report</p>
              <p>{placementTest?.fromEmail}</p>
              <div className="date">
                <FontAwesomeIcon icon={faClock} fontSize={12} width={16} height={16} />
                <p>{moment(placementTestRun?.createdAt).format('M/D/YYYY h:mm:ss A')}</p>
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
};

export default DeliverabilityTestDetails;
