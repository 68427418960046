import { useCallback, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { routes } from 'routes';

import { userApi } from 'resources/user';

import { PersonIcon } from 'assets/icons';

import UnauthorizedLayout from 'layouts/unauthorized';

import Button from 'components/Button';
import Input from 'components/Input';

import validateEmail from 'helpers/validateEmail';
import setSecondsToWait from 'pages/EmailConfirmation/helpers/setSecondsToWait';

import ResetPasswordSent from './components/ResetPasswordSent';

import './styles.scss';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [isPasswordResetEmailSent, setIsPasswordResetEmailSent] = useState(false);
  const [errors, setErrors] = useState({
    email: null,
  });

  const { data: resetSecondsToWait } = useQuery(['resetSecondsToWait']);

  const {
    mutate: sendResetPassordEmail,
    isLoading: isSendResetEmailLoading,
  } = userApi.useSendResetPasswordEmail();

  const saveSecondsToWait = useCallback(() => {
    if (resetSecondsToWait) {
      localStorage.setItem('resetSecondsToWait', resetSecondsToWait);
    }
  }, [resetSecondsToWait]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'This field is required';
    }

    if (email && !validateEmail(email)) {
      newErrors.email = 'Incorrect email format';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [email]);

  const onResetPassword = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    sendResetPassordEmail(email, {
      onSuccess: () => {
        setIsPasswordResetEmailSent(true);
      },
    });
  }, [validateForm, sendResetPassordEmail, email]);

  const onResendResetPasswordEmail = useCallback(() => {
    sendResetPassordEmail(email);
  }, [sendResetPassordEmail, email]);

  useEffect(() => {
    const savedSecondsToWait = localStorage.getItem('resetSecondsToWait');

    if (savedSecondsToWait) {
      setSecondsToWait(savedSecondsToWait, 'resetSecondsToWait');
      localStorage.removeItem('resetSecondsToWait');
    }

    window.addEventListener('beforeunload', saveSecondsToWait);

    return () => {
      window.removeEventListener('beforeunload', saveSecondsToWait);
    };
  }, [saveSecondsToWait]);

  return (
    <UnauthorizedLayout>
      <div className="reset-password-wrapper">
        <p>Reset Password</p>

        {isPasswordResetEmailSent
          ? (
            <ResetPasswordSent
              onResend={onResendResetPasswordEmail}
              secondsToWait={resetSecondsToWait}
              email={email}
            />
          )
          : (
            <>
              <p className="text">
                Enter the email address you used when you joined
                and we’ll send you instructions to reset password.
              </p>

              <Input
                label="Email"
                value={email}
                onChange={setEmail}
                error={!!errors.email}
                helperText={errors.email}
                leftIcon={<PersonIcon />}
                autoComplete="off"
              />

              <Button
                className="reset-password"
                onClick={onResetPassword}
                loading={isSendResetEmailLoading}
                disabled={!!resetSecondsToWait}
              >
                Reset Password
                {resetSecondsToWait && ` (${resetSecondsToWait})`}
              </Button>

              <Link to={routes.signIn.path}>
                <Button
                  className="sign-in"
                  variant="text"
                  color="secondary"
                  disabled={isSendResetEmailLoading}
                >
                  Sign In
                </Button>
              </Link>
            </>
          )}
      </div>
    </UnauthorizedLayout>
  );
};

export default ResetPassword;
