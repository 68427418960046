import { QueryClient } from 'react-query';

import uiNotificationService from 'services/uiNotificatuion.service';

const onError = (error) => {
  // don't show error notification if need to fetch new token
  if (error.status === 401) {
    const userId = localStorage.getItem('userId');

    if (userId) {
      return;
    }
  }

  if ((error.status?.toString()[0] === '5' || ['ERR_NETWORK', 'ECONNABORTED'].includes(error.status))
  && window.location.pathname !== '/maintenance') {
    window.location.href = '/maintenance';
  }

  if (error.data && error.data['@type'] === 'ConstraintViolationList') {
    error.data.violations.forEach((violation) => {
      uiNotificationService.showError(violation.message);
    });
  } else if (error.data && error.data['hydra:description']) {
    uiNotificationService.showError(error.data['hydra:description']);
  }
};

const retry = (failureCount, error) => {
  if (error.status === 401 && failureCount === 0) {
    return true;
  }

  return false;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry,
      retryDelay: (retryAttempt) => 1.8 ** (retryAttempt + 1) * 1000,
      keepPreviousData: true,
      onError,
    },

    mutations: {
      onError,
      retry,
    },
  },
});

export default queryClient;
