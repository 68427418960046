import React from 'react';
import ReactDOM from 'react-dom';

import App from 'app';

import './global.scss';

const root = document.getElementById('root');

ReactDOM.render(
  <App />,
  root,
);
