import axios from 'axios';

import queryClient from 'query-client';

class ApiError extends Error {
  constructor(data, status = 500, statusText = 'Internal Server Error') {
    super(`${status} ${statusText}`);

    this.constructor = ApiError;
    this.__proto__ = ApiError.prototype; // eslint-disable-line no-proto

    this.name = this.constructor.name;
    this.data = data;
    this.status = status;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  inspect() {
    return this.stack;
  }
}
const throwApiError = ({ status, statusText, data }) => {
  console.error(`API Error: ${status} ${statusText}`, data); //eslint-disable-line
  throw new ApiError(data, status, statusText);
};

class ApiClient {
  constructor(axiosConfig) {
    this._handlers = new Map();

    this._api = axios.create(axiosConfig);
    this._api.interceptors.request.use(
      (cfg) => {
        if (cfg.withoutAuthToken) {
          delete cfg.withoutAuthToken;

          return cfg;
        }

        let authToken = null;

        const activeAccountId = +sessionStorage.getItem('activeAccountId');

        if (activeAccountId) {
          const connectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts'));
          const activeConnectedAccount = connectedAccounts.find((item) => item.accountId === activeAccountId);

          if (activeConnectedAccount) {
            authToken = activeConnectedAccount.authToken;
          }
        }

        authToken = authToken || localStorage.getItem('authToken');

        if (authToken) {
          cfg.headers.Authorization = `Bearer ${authToken}`;
        }

        return cfg;
      },
      (error) => Promise.reject(error),
    );
    this._api.interceptors.response.use(
      (response) => response.data,
      (error) => {
        // terms aren't accepted
        if (error.response?.status === 451) {
          queryClient.setQueryData(['isTermsNotAccepted'], true);

          const termsPath = '/terms';

          if (window.location.pathname !== termsPath) {
            window.location.replace(termsPath);
          }
        }

        // try to get new authToken with refreshToken
        if (error.response?.status === 401) {
          let refreshToken = null;

          const activeAccountId = +sessionStorage.getItem('activeAccountId');

          if (activeAccountId) {
            const connectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts'));

            const updatedConnectedAccounts = connectedAccounts.map((connectedAccount) => {
              if (connectedAccount.accountId === activeAccountId) {
                connectedAccount.authToken = null;
                refreshToken = connectedAccount.refreshToken;
              }

              return connectedAccount;
            });

            localStorage.setItem('connectedAccounts', JSON.stringify(updatedConnectedAccounts));
          } else {
            localStorage.removeItem('authToken');
            refreshToken = localStorage.getItem('refreshToken');
          }

          const userId = localStorage.getItem('userId');

          if (refreshToken && userId) {
            this.post('/v2/account/refresh', { refresh_token: refreshToken }, {
              withoutAuthToken: true,
            })
              .then(
                (data) => {
                  if (activeAccountId) {
                    const connectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts'));

                    const updatedConnectedAccounts = connectedAccounts.map((connectedAccount) => {
                      if (connectedAccount.accountId === activeAccountId) {
                        connectedAccount.authToken = data.token;
                        connectedAccount.refreshToken = data.refresh_token;
                      }

                      return connectedAccount;
                    });

                    localStorage.setItem('connectedAccounts', JSON.stringify(updatedConnectedAccounts));
                  } else {
                    localStorage.setItem('authToken', data.token);
                    localStorage.setItem('refreshToken', data.refresh_token);
                  }

                  this.get(`/v2/users/${userId}`).then(
                    (userData) => {
                      queryClient.setQueryData(['currentUser'], userData);

                      this._api(error.config);
                    },
                    (err) => {
                      throw err;
                    },
                  );
                },
                (err) => {
                  if (activeAccountId) {
                    const connectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts'));

                    const updatedConnectedAccounts = connectedAccounts.filter((item) => item.accountId !== activeAccountId);

                    localStorage.setItem('connectedAccounts', JSON.stringify(updatedConnectedAccounts));
                    sessionStorage.removeItem('activeAccountId');
                  } else {
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('userId');

                    queryClient.setQueryData(['currentUser'], null);
                  }

                  throw err;
                },
              );
          } else if (activeAccountId) {
            const connectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts'));

            const updatedConnectedAccounts = connectedAccounts.filter((item) => item.accountId !== activeAccountId);

            localStorage.setItem('connectedAccounts', JSON.stringify(updatedConnectedAccounts));
            sessionStorage.removeItem('activeAccountId');
          } else {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userId');
          }
        }

        if (axios.isCancel(error)) {
          throw error;
        }
        // Axios Network Error & Timeout error dont have 'response' field
        // https://github.com/axios/axios/issues/383
        const errorResponse = error.response || {
          status: error.code,
          statusText: error.message,
          data: error.data,
        };

        const errorHandlers = this._handlers.get('error') || [];
        errorHandlers.forEach((handler) => {
          handler(errorResponse);
        });

        return throwApiError(errorResponse);
      },
    );
  }

  get(url, params = {}, requestConfig = {}) {
    return this._api({
      method: 'get',
      url,
      params,
      ...requestConfig,
    });
  }

  post(url, data = {}, requestConfig = {}) {
    return this._api({
      method: 'post',
      url,
      data,
      ...requestConfig,
    });
  }

  patch(url, data = {}, requestConfig = {}) {
    return this._api({
      method: 'patch',
      url,
      data,
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
      ...requestConfig,
    });
  }

  put(url, data = {}, requestConfig = {}) {
    return this._api({
      method: 'put',
      url,
      data,
      ...requestConfig,
    });
  }

  delete(url, data = {}, requestConfig = {}) {
    return this._api({
      method: 'delete',
      url,
      data,
      ...requestConfig,
    });
  }

  on(event, handler) {
    if (this._handlers.has(event)) {
      this._handlers.get(event).add(handler);
    } else {
      this._handlers.set(event, new Set([handler]));
    }

    return () => this._handlers.get(event).remove(handler);
  }
}

export default new ApiClient({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/ld+json',
    Accept: 'application/ld+json',
  },
});
