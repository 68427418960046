import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import setSecondsToWait from 'pages/EmailConfirmation/helpers/setSecondsToWait';

const useSecondsToWait = (query) => {
  const queryName = query || 'secondsToWait';

  const { data: secondsToWait } = useQuery([queryName]);

  // Save the current secondsToWait value to localStorage
  const saveSecondsToWait = useCallback(() => {
    if (secondsToWait) {
      localStorage.setItem(queryName, secondsToWait);
    }
  }, [secondsToWait, queryName]);

  // Load and set secondsToWait from localStorage on component mount
  useEffect(() => {
    const savedSecondsToWait = localStorage.getItem(queryName);

    if (savedSecondsToWait) {
      setSecondsToWait(savedSecondsToWait, queryName);
      localStorage.removeItem(queryName);
    }

    window.addEventListener('beforeunload', saveSecondsToWait);

    return () => {
      window.removeEventListener('beforeunload', saveSecondsToWait);
    };
  }, [saveSecondsToWait, queryName]);

  return { secondsToWait, queryName };
};

export default useSecondsToWait;
