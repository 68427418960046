import { useQuery, useMutation } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useLogin() {
  const login = ({ email, password }) => api.post('/v2/account/login', { email, password });

  return useMutation(login, {
    onSuccess: (data) => {
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('userId', data['@id'].replace('/v2/users/', ''));

      if (data.connected_accounts?.length) {
        const connectedAccounts = data.connected_accounts.map((connectedAccount) => ({
          accountId: connectedAccount.accountId,
          authToken: connectedAccount.token,
          refreshToken: connectedAccount.refresh_token,
        }));

        const lastAccountId = +localStorage.getItem('lastAccountId');

        if (!connectedAccounts.some((connectedAccount) => connectedAccount.accountId === lastAccountId)) {
          localStorage.removeItem('lastAccountId');
        }

        localStorage.setItem('connectedAccounts', JSON.stringify(connectedAccounts));
      }
    },
  });
}

export function useRefreshToken() {
  const refresh = (refreshToken) => api.post('/v2/account/refresh', { refresh_token: refreshToken });

  return useMutation(refresh, {
    onSuccess: (data) => {
      const activeAccountId = +sessionStorage.getItem('activeAccountId');

      if (activeAccountId) {
        const connectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts'));

        const updatedConnectedAccounts = connectedAccounts.map((connectedAccount) => {
          if (connectedAccount.accountId === activeAccountId) {
            connectedAccount.authToken = data.token;
            connectedAccount.refreshToken = data.refresh_token;
          }

          return connectedAccount;
        });

        localStorage.setItem('connectedAccounts', JSON.stringify(updatedConnectedAccounts));

        return;
      }

      localStorage.setItem('authToken', data.token);
      localStorage.setItem('refreshToken', data.refresh_token);
    },
  });
}

export function useGetGoogleAuthUrl() {
  const getGoogleAuthUrl = () => api.get('/v2/oauth/google/url')
    .then((data) => data.url);

  return useMutation(getGoogleAuthUrl);
}

export function useGetLindedInAuthUrl() {
  const getLinkedInAuthUrl = () => api.get('/v2/oauth/linkedin/url')
    .then((data) => data.url);

  return useMutation(getLinkedInAuthUrl);
}

export function useGetAuthToken() {
  const getAuthToken = (query) => api.get(`/v2/oauth/token?${query}`);

  return useMutation(getAuthToken, {
    onSuccess: (data) => {
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('userId', data['@id'].replace('/v2/users/', ''));
      localStorage.setItem('authProvider', data.provider);

      if (data.connected_accounts?.length) {
        const connectedAccounts = data.connected_accounts.map((connectedAccount) => ({
          accountId: connectedAccount.accountId,
          authToken: connectedAccount.token,
          refreshToken: connectedAccount.refresh_token,
        }));

        const lastAccountId = +localStorage.getItem('lastAccountId');

        if (!connectedAccounts.some((connectedAccount) => connectedAccount.accountId === lastAccountId)) {
          localStorage.removeItem('lastAccountId');
        }

        localStorage.setItem('connectedAccounts', JSON.stringify(connectedAccounts));
      }
    },
  });
}

export function useHealthStatus() {
  const getHealthStatus = () => api.get('/v2/health');

  return useQuery(['health'], getHealthStatus);
}

export function useAccount() {
  let accountId = null;
  const activeAccountId = +sessionStorage.getItem('activeAccountId');

  if (activeAccountId) {
    accountId = activeAccountId;
  } else {
    const currentUser = queryClient.getQueryData(['currentUser']);

    accountId = +currentUser.account.replace('/v2/accounts/', '');
  }

  const getAccount = () => (accountId ? api.get(`/v2/accounts/${accountId}`) : null);

  return useQuery(['account', accountId], getAccount);
}

export function useUpdateAccount() {
  let accountId = null;
  const activeAccountId = +sessionStorage.getItem('activeAccountId');

  if (activeAccountId) {
    accountId = activeAccountId;
  } else {
    const currentUser = queryClient.getQueryData(['currentUser']);

    accountId = +currentUser.account.replace('/v2/accounts/', '');
  }

  const updateAccount = ({ defaultNotificationPreset }) => {
    if (!accountId) {
      throw new Error('Account ID is not defined');
    }

    return api.patch(`/v2/accounts/${accountId}`, { defaultNotificationPreset });
  };

  return useMutation(updateAccount, {
    onSuccess: (data) => queryClient.setQueryData(['account', accountId], data),
  });
}

export function useAccountUsersList() {
  let accountId = null;
  const activeAccountId = +sessionStorage.getItem('activeAccountId');

  if (activeAccountId) {
    accountId = activeAccountId;
  } else {
    const currentUser = queryClient.getQueryData(['currentUser']);

    accountId = +currentUser.account.replace('/v2/accounts/', '');
  }

  const getAccountUsersList = () => (accountId ? api.get(`/v2/accounts/${accountId}/connected-users`) : null)
    .then((data) => data['hydra:member']);

  return useQuery(['account.usersList', accountId], getAccountUsersList);
}
