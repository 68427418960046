import { useRef, useCallback, useState, useEffect } from 'react';

import AuthorizedLayout from 'layouts/authorized';

import { userApi } from 'resources/user';

import Cropper from 'components/Cropper';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';

import CircularProgress from '@mui/material/CircularProgress';

import uiNotificationService from 'services/uiNotificatuion.service';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { countries } from 'helpers/countries';

import './styles.scss';

export const getUserInitials = ({ firstName, lastName, email }) => {
  const firstNameLetter = firstName ? firstName[0].toUpperCase() : null;
  const lastNameLetter = lastName ? lastName[0].toUpperCase() : null;

  if (firstNameLetter || lastNameLetter) {
    return `${firstNameLetter || ''}${lastNameLetter || ''}`;
  }

  if (email) {
    return email.substring(0, 2).toUpperCase();
  }

  return null;
};

const ASPECT_RATIO = 1 / 1;
const COUNTRY_SELECT_DEFAULT_VALUE = 'select_country';

const CONTRY_SELECT_VALUES = [
  ...countries.map((item) => ({ label: item.name, value: item.code })),
  { label: 'Select Country', value: COUNTRY_SELECT_DEFAULT_VALUE },
];

export const CROPPER_STYLES = {
  width: `${50 * ASPECT_RATIO}vh`,
  height: '50vh',
};

export const MOBILE_CROPPER_STYLES = {
  width: `${20 * ASPECT_RATIO}vh`,
  height: '20vh',
};

const Profile = () => {
  const avatarInputRef = useRef(null);

  const [avatar, setAvatar] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState(null);

  const [croppingImageForPoster, setCroppingImageForPoster] = useState(null);
  const [isCropperShown, setIsCropperShown] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 800;

  const { data: currentUser, isLoading: isCurrentUserLoading } = userApi.useCurrentUser();
  const {
    data: imageData,
    isLoading: isCurrentUserAvatarLoading,
  } = userApi.useGetCurrentUserAvatar(currentUser.avatar);
  const isLoading = isCurrentUserLoading || isCurrentUserAvatarLoading;

  const { mutate: updateCurrentUser, isLoading: isUpdateCurrentUserLoading } = userApi.useUpdateCurrentUser();
  const {
    mutate: createUserAvatar,
    isLoading: isCreateUserAvatarLoading,
  } = userApi.useCreateCurrentUserAvatar();
  const {
    mutate: updateUserAvatar,
    isLoading: isUpdateUserAvatarLoading,
  } = userApi.useUpdateCurrentUserAvatar();
  const {
    mutate: deleteUserAvatar,
    isLoading: isDeleteUserAvatarLoading,
  } = userApi.useDeleteCurrentUserAvatar();

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName || '');
      setLastName(currentUser.lastName || '');
      setCountry(currentUser.countryCode || COUNTRY_SELECT_DEFAULT_VALUE);
    }
  }, [currentUser]);

  useEffect(() => {
    if (imageData?.image) {
      setAvatar(imageData.image);
    }
  }, [imageData]);

  const onUploadAvatarButtonClick = useCallback(() => {
    avatarInputRef.current.click();
  }, []);

  const onAvatarSelected = useCallback((event) => {
    const [file] = event.target.files;

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(file);
        setCroppingImageForPoster(imageUrl);
        setIsCropperShown(true);
      };

      reader.readAsArrayBuffer(file);
    }
  }, []);

  const onAvatarCropped = useCallback((croppedAvatar) => {
    if (!currentUser?.avatar) {
      createUserAvatar({ image: croppedAvatar }, {
        onSuccess: () => {
          uiNotificationService.showSuccess('Avatar has been created');
          setAvatar(croppedAvatar);
        },
      });
    } else {
      updateUserAvatar({
        image: croppedAvatar,
        id: currentUser.avatar.replace('/v2/user-avatars/', ''),
      }, {
        onSuccess: () => {
          uiNotificationService.showSuccess('Avatar has been updated');
          setAvatar(croppedAvatar);
        },
      });
    }

    setIsCropperShown(false);
  }, [createUserAvatar, updateUserAvatar, currentUser?.avatar]);

  const onRemoveAvatarButtonClick = useCallback(() => {
    if (currentUser?.avatar) {
      deleteUserAvatar(currentUser.avatar.replace('/v2/user-avatars/', ''), {
        onSuccess: () => {
          setAvatar(null);
        },
      });

      return;
    }

    setAvatar(null);
  }, [currentUser?.avatar, deleteUserAvatar]);

  const onSaveButtonClick = useCallback(() => {
    const countryCode = country === COUNTRY_SELECT_DEFAULT_VALUE ? null : country;

    updateCurrentUser({
      firstName,
      lastName,
      countryCode,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Account has been updated');
      },
    });
  }, [updateCurrentUser, firstName, lastName,
    country]);

  return (
    <AuthorizedLayout>
      <Cropper
        isShown={isCropperShown}
        cropperContainerStyles={mobileWidth ? MOBILE_CROPPER_STYLES : CROPPER_STYLES}
        image={croppingImageForPoster}
        aspect={ASPECT_RATIO}
        onCancel={() => setIsCropperShown(false)}
        onSubmit={onAvatarCropped}
      />

      <div className="profile-wrapper">
        <p className="title">Profile</p>

        {!isLoading ? (
          <div className="form">
            <div className="avatar-wrapper">
              {avatar
                ? <img src={avatar} alt="Avatar" className="avatar-image" /> : (
                  <div className="default-avatar">
                    <p>
                      {getUserInitials({
                        firstName: currentUser.firstName || firstName,
                        lastName: currentUser.lastName || lastName,
                        email: currentUser?.emailAddress,
                      })}
                    </p>
                  </div>
                )}

              <input
                ref={avatarInputRef}
                className="avatar-input"
                onChange={onAvatarSelected}
                value=""
                type="file"
                accept="image/*"
              />

              <div className="upload-buttons">
                <Button
                  color="secondaryButton"
                  className="upload-photo-button"
                  onClick={onUploadAvatarButtonClick}
                  loading={isCreateUserAvatarLoading || isUpdateUserAvatarLoading}
                >
                  Update Photo
                </Button>
                <Button
                  color="secondary"
                  className="remove-photo-button"
                  onClick={onRemoveAvatarButtonClick}
                  variant="text"
                  loading={isDeleteUserAvatarLoading}
                  disabled={!avatar}
                >
                  Remove Photo
                </Button>
              </div>
            </div>

            <div className="inputs">
              <div className="name-inputs">
                <Input
                  label="First Name"
                  value={firstName}
                  onChange={setFirstName}
                />
                <Input
                  label="Last Name"
                  value={lastName}
                  onChange={setLastName}
                />
              </div>

              <Input
                label="Email"
                value={currentUser.emailAddress}
                readOnly
              />

              <Select
                className="select"
                value={country}
                onChange={setCountry}
                items={CONTRY_SELECT_VALUES}
                type="filled"
                renderValue={(renderValue) => (
                  <div className="select-value">
                    <p>Country</p>
                    <p>
                      {CONTRY_SELECT_VALUES.find((selectItem) => selectItem.value === renderValue)?.label}
                    </p>
                  </div>
                )}
              />
            </div>

            <Button
              className="save-button"
              onClick={onSaveButtonClick}
              loading={isUpdateCurrentUserLoading}
            >
              Update Settings
            </Button>
          </div>
        ) : <CircularProgress style={{ margin: 'auto' }} />}
      </div>
    </AuthorizedLayout>
  );
};

export default Profile;
