import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Stack, CircularProgress, Box, Typography, Divider } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive, faEnvelope, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

import Button from 'components/Button';
import ScoreCircle from 'components/ScoreCircle';

const EmailAccounts = ({
  data,
  loading,
  onRefetch,
  isError,
}) => (
  <div className={cn('item', 'email-accounts-item')}>
    {loading && !isError && <CircularProgress className="loader" />}

    {isError && (
    <div className="item-error">
      <p>Something went wrong</p>

      <Button onClick={onRefetch}>Refresh</Button>
    </div>
    )}

    {!loading && !isError && (
    <div className="email-deliverability-block-content">
      <div>
        <p>Email Accounts</p>
      </div>

      <Stack direction="row" gap="16px">
        <Stack direction="row">
          {[
            {
              title: 'Active',
              icon: faEnvelope,
              count: data.active,
              className: 'active',
            },
            {
              title: 'Disconnected',
              icon: faTriangleExclamation,
              count: data.disconnected,
              className: 'disconnected',
            },
            {
              title: 'Archived',
              icon: faBoxArchive,
              count: data.archived,
              className: 'archived',
            },
          ].map((item) => (
            <Stack key={item.title} className="email-accounts-block-item">
              <Box className={cn('email-accounts-block-item-icon', item.className)}>
                <FontAwesomeIcon icon={item.icon} fontSize={20} />
              </Box>

              <Typography className="email-accounts-block-item-title">{item.title}</Typography>

              <Typography className="email-accounts-block-item-count">{item.count}</Typography>
            </Stack>
          ))}
        </Stack>

        <Divider orientation="vertical" flexItem />

        <Stack className="email-accounts-block-score-wrapper">
          <ScoreCircle
            mxrScoreTotal={10}
            mxrScoreTotalPass={data.average}
            ratingColor={data.score.color}
            ratingLabel={data.score.label}
            onlyCircle
            size="medium"
          />

          <Typography className="email-accounts-block-score-wrapper-average">
            Average MX Score
          </Typography>
        </Stack>
      </Stack>
    </div>
    )}
  </div>
);

EmailAccounts.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  onRefetch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

EmailAccounts.defaultProps = {
  data: null,
};

export default React.memo(EmailAccounts);
