import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';

import { ArrowRightIcon, CheckIcon, HtmlEmbedIcon, OutlinedPencilIcon } from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Button from 'components/Button';
import MXRiskSpeedometer from 'components/MXRiskSpeedometer';
import Select from 'components/Select';
import Input from 'components/Input';
import Editor from 'components/Editor';

import useOutsideClick from 'hooks/useOutsideClick';

import validateEmail from 'helpers/validateEmail';

import useElementExists from 'hooks/useElementExists';

import InsertHtmlModal from '../components/InsertHtmlModal';

import './styles.scss';

const defaultName = `Template - ${new Date().toLocaleString(navigator.language)}`;

const TemplateMXRiskAnalyze = () => {
  const navigate = useNavigate();
  const params = useParams();

  const isEditorSourceMode = useElementExists('.ck-source-editing-area');

  const editorRef = useRef(null);
  const editNameRef = useRef(null);

  const [editorData, setEditorData] = useState('');
  const [isEditorReady, setIsEditorReady] = useState(false);

  const [selectValue, setSelectValue] = useState('');
  const [firstMergeTag, setFirstMergeTag] = useState('');
  const [secondMergeTag, setSecondMergeTag] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');

  const [name, setName] = useState(defaultName);
  const [isEditName, setIsEditName] = useState(false);

  const [isHtmlEmbedModalVisible, setIsHtmlEmbedModalVisible] = useState(false);

  const [errors, setErrors] = useState({
    firstMergeTag: null,
    secondMergeTag: null,
    email: null,
    subject: null,
    body: null,
    name: null,
  });

  const { data: templateEngines, isLoading: isTemplateEnginesLoading } = mxriskApi.useTemplateEngines();

  const { mutate: analyzeTemplate } = mxriskApi.useAnalyzeTemplate();

  useOutsideClick(editNameRef, () => setIsEditName(false));

  const selectOptions = useMemo(() => (templateEngines
    ? [...templateEngines.map((item) => ({ value: item.name, label: item.vendor })), { value: 'other', label: 'Other' }]
    : []), [templateEngines]);

  useEffect(() => {
    if (templateEngines?.length && !selectValue) {
      const defaultTemplate = templateEngines[0];

      setSelectValue(defaultTemplate.name);
      setFirstMergeTag(defaultTemplate.openTag);
      setSecondMergeTag(defaultTemplate.closeTag);
    }
  }, [templateEngines, selectValue]);

  const onTemplateFormatChange = useCallback((value) => {
    const template = templateEngines.find((item) => item.name === value);

    setFirstMergeTag(template?.openTag || '');
    setSecondMergeTag(template?.closeTag || '');

    setSelectValue(value);
  }, [templateEngines]);

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!firstMergeTag) {
      newErrors.firstMergeTag = 'Merge tag syntax is missing';
    }

    if (!secondMergeTag) {
      newErrors.secondMergeTag = 'Merge tag syntax is missing';
    }

    if (!email) {
      newErrors.email = 'From is missing';
    }

    if (!subject) {
      newErrors.subject = 'Subject line is missing';
    }

    const freshEditorData = editorRef.current.editor.getData();

    if (!freshEditorData) {
      newErrors.body = 'Body is empty';
    }

    if (email && !validateEmail(email)) {
      newErrors.email = 'Incorrect email format';
    }

    if (!name) {
      newErrors.name = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [email, subject, firstMergeTag, secondMergeTag, name]);

  const onCheckEmailTemplate = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    const freshEditorData = editorRef.current.editor.getData();

    analyzeTemplate({
      openTag: firstMergeTag,
      closeTag: secondMergeTag,
      from: email,
      subjectTemplate: subject,
      template: freshEditorData,
      ...(params.templateGroupId && { folderId: +params.templateGroupId }),
      ...(name && name !== defaultName && { name }),
      ...(selectValue && selectValue !== 'other' && { templateEngine: selectValue }),
    }, {
      onSuccess: (data) => {
        const id = data['@id'].replace('/v2/mx-risk/validation-items/', '');
        navigate(routes.templateMXRiskResult.path.replace(':id', id));
      },
    });
  }, [validateForm, analyzeTemplate, firstMergeTag,
    secondMergeTag, email, subject, selectValue,
    navigate, name, params.templateGroupId]);

  useEffect(() => {
    if (isEditorReady) {
      const target = document.querySelector('.ck-toolbar__items');

      if (target) {
        const el = document.createElement('div');
        ReactDOM.render(
          <div
            className={cn('html-embed-button-wrapper', isEditorSourceMode && 'html-embed-button-wrapper-disabled')}
            onClick={() => {
              if (!isEditorSourceMode) {
                setIsHtmlEmbedModalVisible(true);
              }
            }}
            aria-hidden
          >
            <HtmlEmbedIcon width={18} />
          </div>,
          el,
        );
        target.appendChild(el);

        // Optional: Clean up on component unmount
        return () => {
          ReactDOM.unmountComponentAtNode(el);
          el.remove();
        };
      }
    }
  }, [isEditorReady, isEditorSourceMode]);

  return (
    <AuthorizedLayout>
      {isHtmlEmbedModalVisible && (
        <InsertHtmlModal
          open={isHtmlEmbedModalVisible}
          onClose={() => setIsHtmlEmbedModalVisible(false)}
          onConfirm={(html) => {
            setEditorData(html);
            setIsHtmlEmbedModalVisible(false);
          }}
        />
      )}

      <div className="email-template-mxrisk-analyze-wrapper">
        <div>
          <div className="title">
            {isEditName
              ? (
                <div className="title-edit-mode" ref={editNameRef}>
                  <Input
                    className={cn('name-input', errors.name && 'name-input-error')}
                    placeholder="Name"
                    value={name}
                    onChange={(value) => onChangeInput({ value, setter: setName, errorField: 'name' })}
                    error={!!errors.name}
                    helperText={errors.name}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        setIsEditName(false);
                      }
                    }}
                  />

                  <IconButton
                    onClick={() => setIsEditName(false)}
                    disabled={!name}
                    className={cn('check-icon-button', !name && 'check-icon-button-disabled')}
                  >
                    <CheckIcon />
                  </IconButton>
                </div>
              )
              : (
                <div className="title-show-mode">
                  <p>{name || 'Untitled template'}</p>

                  <Tooltip
                    onClick={() => setIsEditName(true)}
                    title="Edit"
                    placement="top"
                    className="edit-icon-container"
                  >
                    <OutlinedPencilIcon />
                  </Tooltip>
                </div>
              )}
          </div>

          <div className="breadcrumbs">
            <p>Template MX Risk</p>
            <ArrowRightIcon />
            <p>Analyze</p>
          </div>
        </div>

        <div className="form-wrapper">
          {isTemplateEnginesLoading && <CircularProgress className="loader" />}

          {!isTemplateEnginesLoading && (
          <div>
            <div className="speedometer-container">
              <MXRiskSpeedometer score={50} />
              <p>
                Enter from email address, subject line, and outreach template.
                Click &apos;Next&apos; to receive your MX Risk report with suggestions to
                optimize your template for maximum deliverability.
              </p>
            </div>

            <div>
              <div className="template-format-wrapper">
                {selectValue && (
                <Select
                  className="select"
                  value={selectValue}
                  onChange={onTemplateFormatChange}
                  items={selectOptions}
                  type="filled"
                  renderValue={(renderValue) => (
                    <div className="select-value">
                      <p>Template Format</p>
                      <p>
                        {selectOptions.find((selectItem) => selectItem.value === renderValue).label}
                      </p>
                    </div>
                  )}
                />
                )}

                <div className="merge-tag-wrapper">
                  <p>Provide merge tag syntax:</p>

                  <div>
                    <div className="field-wrapper">
                      <Input
                        className="first-merge-tag-input"
                        placeholder="{{"
                        value={firstMergeTag}
                        onChange={(value) => onChangeInput({ value, setter: setFirstMergeTag, errorField: 'firstMergeTag' })}
                        error={!!errors.firstMergeTag}
                        readOnly={selectValue !== 'other'}
                      />
                      <Input
                        className="merge-tag-input"
                        placeholder="field_name"
                        readOnly
                      />
                      <Input
                        className="second-merge-tag-input"
                        placeholder="}}"
                        value={secondMergeTag}
                        onChange={(value) => onChangeInput({
                          value, setter: setSecondMergeTag, errorField: 'secondMergeTag',
                        })}
                        error={!!errors.secondMergeTag}
                        readOnly={selectValue !== 'other'}
                      />
                    </div>

                    {errors.mergeTag && <p className="error">{errors.mergeTag}</p>}
                  </div>
                </div>
              </div>

              <div className="email-form">
                <Input
                  className="input"
                  placeholder="From: example@email.com"
                  value={email}
                  onChange={(value) => onChangeInput({ value, setter: setEmail, errorField: 'email' })}
                  error={!!errors.email}
                  helperText={errors.email}
                />

                <Input
                  className="input"
                  placeholder="Subject"
                  value={subject}
                  onChange={(value) => onChangeInput({ value, setter: setSubject, errorField: 'subject' })}
                  error={!!errors.subject}
                  helperText={errors.subject}
                />

                <div>
                  <div className={cn('ck-editor-wrapper', errors.body && 'ck-editor-wrapper-error')}>
                    <Editor
                      ref={editorRef}
                      data={editorData}
                      onReady={() => setIsEditorReady(true)}
                      onChange={(_, editor) => {
                        const data = editor.getData();
                        setEditorData(data);

                        if (errors.body) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            body: null,
                          }));
                        }
                      }}
                    />
                  </div>

                  {errors.body && <p className="error body-error">{errors.body}</p>}
                </div>
              </div>

              <Button className="next-button" onClick={onCheckEmailTemplate}>Next</Button>
            </div>
          </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default TemplateMXRiskAnalyze;
