import { useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import AuthorizedLayout from 'layouts/authorized';

import { routes } from 'routes';

import {
  ArrowRightIcon, ErrorWebIcon, PrinterIcon,
} from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import CircularProgress from '@mui/material/CircularProgress';

import Pagination from 'components/Pagination';

import useWindowDimensions from 'hooks/useWindowDimensions';

import uiNotificationService from 'services/uiNotificatuion.service';

import HistoryItem from './components/HistoryItem';

import './styles.scss';

const ROW_HEIGHT = 72;

const EmailGroupNameHistory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  // 420 - other elements height on page
  const perPageValue = Math.floor((height - 420) / ROW_HEIGHT);
  const perPage = perPageValue > 0 ? perPageValue : 1;
  const mobileWidth = width <= 1200;

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);

  const { data: emailGroup, isLoading: isEmailGroupLoading, error } = mxriskApi.useEmailGroup(+params.id);
  const {
    data,
    isLoading: isEmailGroupItemsLoading,
    isFetching: isEmailGroupItemsFetching,
  } = mxriskApi.useEmailGroupItems({ id: +params.id, page, perPage });
  const [fetchedChecks, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isLoading = isEmailGroupLoading || isEmailGroupItemsLoading;
  const isNotFound = error?.status === 404;

  useEffect(() => {
    if (fetchedChecks) {
      setItems(fetchedChecks);
    }
  }, [fetchedChecks]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(emailGroup.emailTo);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [emailGroup?.emailTo]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const navigateToEmailMXRiskPage = useCallback(() => {
    navigate(routes.emailMXRisk.path);
  }, [navigate]);

  const historyItems = items.map((item) => (
    <HistoryItem
      key={item.id}
      item={item}
    />
  ));

  return (
    <AuthorizedLayout>
      <div className="email-group-history-wrapper">
        <div>
          <div className="title">
            <p>{isNotFound ? 'Email Group Not Found' : emailGroup?.name}</p>
          </div>

          <div className="breadcrumbs">
            <p onClick={navigateToEmailMXRiskPage} style={{ cursor: 'pointer' }} aria-hidden>
              Email MX Risk
            </p>
            <ArrowRightIcon />
            <p>{emailGroup?.name || 'Email Group Not Found'}</p>
            <ArrowRightIcon />
            <p>History</p>
          </div>
        </div>

        {!isLoading && !!items.length && (
          <>
            {!mobileWidth && (
            <div className="list">
              {isEmailGroupItemsFetching && <CircularProgress className="table-loader" />}

              <div className="table-head">
                <p className="subject-head">Subject</p>
                <p className="mx-risk-head">MX Risk</p>
                <p className="readability-head">Readability</p>
                <p className="spam-assasin-head">SpamAssasin</p>
                <p className="check-date-head">Check Date</p>
              </div>

              <div className="table-head-line" />

              {historyItems}

              {totalItems > items.length && (
              <Pagination
                className="table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
              )}
            </div>
            )}

            {mobileWidth && (
            <div className="mobile-list">
              {historyItems}

              {totalItems > items.length && (
              <Pagination
                className="mobile-table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
              )}
            </div>
            )}
          </>
        )}

        {!isLoading && (!fetchedChecks.length && page === 1) && (
          <div className="empty-state">
            {error ? <ErrorWebIcon /> : <PrinterIcon /> }

            <p className="title">{error ? 'Email Group Not Found' : 'There are no MX Risk Scans in this Group'}</p>

            {!error && (
            <p className="description">
              Send an email to this group to initiate Email MX Risk Scan
              <span onClick={onCopyToClipboard} aria-hidden>{emailGroup?.emailTo}</span>
            </p>
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default EmailGroupNameHistory;
