import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import {
  DotsIcon, TrashIcon, PencilIcon,
  CardioLineIcon, AtIcon, HistoryIcon,
} from 'assets/icons';

import { routes } from 'routes';

import { mxriskApi } from 'resources/mxrisk';

import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import DeleteModal from 'components/DeleteModal';
import CopyEmailModal from 'pages/EmailMXRisk/components/CopyEmailModal';
import AddOrEditEmailMXRiskGroupModal from 'pages/EmailMXRisk/components/AddOrEditEmailMXRiskGroupModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const GroupItemMenu = ({ item, onDeleted }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const [isCopyEmailModalVisible, setIsCopyEmailModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditEmailMXRiskGroupVisible, setIsEditEmailMXRiskGroupVisible] = useState(false);

  const { mutate: updateEmailGroup, isLoading: isUpdateEmailGroupLoading } = mxriskApi.useUpdateEmailGroup();
  const { mutate: deleteEmailGroup, isLoading: isDeleteEmailGroupLoading } = mxriskApi.useDeleteEmailGroup();

  const itemId = item['@id'].replace('/v2/mx-risk/email-groups/', '');

  const isWaiting = !item.lastItem;

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onStatusToggle = useCallback(() => {
    updateEmailGroup({
      id: itemId,
      active: !item.active,
    }, {
      onSuccess: () => {
        closeMenu();
      },
    });
  }, [item, updateEmailGroup, closeMenu, itemId]);

  const onDelete = useCallback(() => {
    deleteEmailGroup(itemId, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email Group has been deleted.');

        onDeleted();
        setIsDeleteModalVisible(false);
        closeMenu();
      },
    });
  }, [deleteEmailGroup, itemId, closeMenu, onDeleted]);

  const onHistoryClick = useCallback(() => {
    navigate(routes.emailMXRiskHistory.path.replace(':id', itemId));
  }, [navigate, itemId]);

  return (
    <>
      <CopyEmailModal
        open={isCopyEmailModalVisible}
        onClose={() => { setIsCopyEmailModalVisible(false); closeMenu(); }}
        email={item.emailTo}
      />
      <DeleteModal
        title="Are you sure you want to delete this email group and all its history?"
        open={isDeleteModalVisible}
        onClose={() => { setIsDeleteModalVisible(false); closeMenu(); }}
        onDelete={onDelete}
        isDeleteLoading={isDeleteEmailGroupLoading}
      />
      {isEditEmailMXRiskGroupVisible && (
      <AddOrEditEmailMXRiskGroupModal
        open={isEditEmailMXRiskGroupVisible}
        onClose={() => { setIsEditEmailMXRiskGroupVisible(false); closeMenu(); }}
        initialData={item}
      />
      )}

      <div className={cn('menu-wrapper', anchorEl && 'menu-wrapper-active')}>
        <IconButton onClick={openMenu}>
          <DotsIcon />
        </IconButton>

        <Menu
          open={!!anchorEl}
          onClose={closeMenu}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={() => setIsEditEmailMXRiskGroupVisible(true)} className="menu-item">
            <PencilIcon />
            <p>Edit</p>
          </MenuItem>

          {!isWaiting && (
          <MenuItem onClick={onHistoryClick} className="menu-item">
            <HistoryIcon />
            <p>History</p>
          </MenuItem>
          )}

          <MenuItem onClick={() => setIsDeleteModalVisible(true)} className="menu-item">
            <TrashIcon />
            <p>Delete</p>
          </MenuItem>

          <div className="menu-line" />

          <MenuItem onClick={() => setIsCopyEmailModalVisible(true)} className="menu-item">
            <AtIcon />
            <p>Group Email Address</p>
          </MenuItem>

          <MenuItem className="menu-item" style={{ justifyContent: 'space-between' }}>
            <div>
              <CardioLineIcon />
              <p>Status</p>
            </div>

            <Switch
              checked={item.active}
              onChange={onStatusToggle}
              disabled={isUpdateEmailGroupLoading}
            />
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

GroupItemMenu.propTypes = {
  item: PropTypes.shape().isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default React.memo(GroupItemMenu);
