import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import queryClient from 'query-client';

import { userApi } from 'resources/user';

import { routes } from 'routes';

const getUnauthorizedRedirectPath = () => {
  const waitingForEmailConfirmation = localStorage.getItem('waitingForEmailConfirmation');

  if (waitingForEmailConfirmation) {
    return routes.emailConfirmation.path;
  }

  return routes.signIn.path;
};

const RedirectFromRoot = () => {
  const navigate = useNavigate();

  const { data: currentUser, isLoading: isCurrentUserLoading } = userApi.useCurrentUser();
  const isTermsNotAccepted = queryClient.getQueryData(['isTermsNotAccepted']);

  useEffect(() => {
    if (isTermsNotAccepted) {
      return navigate(routes.terms.path);
    }

    if (isCurrentUserLoading) {
      return;
    }

    const startPath = currentUser ? routes.dashboard.path : getUnauthorizedRedirectPath();

    navigate(startPath);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigate]);

  return null;
};

export default RedirectFromRoot;
