import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import { monitorApi } from 'resources/monitor';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import Modal from 'components/Modal';

import { getRawDataByStatus } from 'pages/RealTimeMonitor/components/IPItem';

import HistoryItem from './components/HistoryItem';

import './styles.scss';

const getStatusByChecks = (checks) => {
  if (!checks.length) {
    return {
      label: null,
      value: null,
    };
  }

  const isBlacklistedExists = checks.some((check) => check.checkFlag === 'red');

  return isBlacklistedExists
    ? { label: 'Blacklisted', value: 'error' }
    : { label: 'Healthy', value: 'success' };
};

const getDaysHistory = (checks) => {
  const array = [...Array(10).keys()]; // 0, ..., 9

  const oldestDate = checks.length
    ? checks.reduce((acc, check) => (acc.checkAt <= check.checkAt ? acc : check)).checkAt
    : null;
  const startDate = (oldestDate ? moment(oldestDate) : moment()).toDate();

  return array.map((item) => {
    const date = moment(startDate).add(item, 'd').startOf('day');

    const dateChecks = checks.filter((check) => moment(check.checkAt).startOf('day').toISOString() === date.toISOString());

    return {
      date,
      dateLabel: date.format('MMM D'),
      items: dateChecks,
      status: getStatusByChecks(dateChecks),
    };
  });
};

const HistoryModal = ({ open, onClose, monitorCheck }) => {
  const [isDetailsOpened, setIsDetailsOpened] = useState(true);

  const lastTenDaysDate = moment().subtract(9, 'days').startOf('day').toISOString();
  const { data: historyData, isLoading } = monitorApi.useMonitorCheckHistoryList({
    checkId: monitorCheck.id,
    checkAfter: lastTenDaysDate,
    getAll: true,
  });

  const rawData = getRawDataByStatus(monitorCheck.lastCheckFlag);
  const daysHistory = useMemo(() => (historyData ? getDaysHistory(historyData) : []), [historyData]);
  const items = daysHistory.flatMap((item) => item.items);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Historical Data"
    >
      <div className="history-modal-wrapper">
        <div className="ip-name">
          <div className={cn('status-icon', {
            healthy: monitorCheck.lastCheckFlag === 'green',
            down: monitorCheck.lastCheckFlag === 'yellow',
            blacklisted: monitorCheck.lastCheckFlag === 'red',
            default: monitorCheck.lastCheckFlag === null,
          })}
          >
            {rawData.icon}
          </div>

          <div className="ip-title-wrapper">
            <div>
              <p>{monitorCheck.item}</p>

              <div className="chip-container">
                <Chip label={rawData.chip.label} variant="outlined" color={rawData.chip.color} />
              </div>
            </div>

            {monitorCheck.name && <p className="ip-name-text">{monitorCheck.name}</p>}
          </div>
        </div>

        {!isLoading ? (
          <div className="blacklist">
            <p className="title">Blacklist</p>

            {daysHistory.length && (
            <div className="history-days">
              {daysHistory.map((historyDay) => (
                <Tooltip
                  key={historyDay.date}
                  title={(
                    <div className="history-days-tooltip-title">
                      <p>{historyDay.dateLabel}</p>
                      <p>{historyDay.status.label}</p>
                    </div>
                  )}
                  placement="top"
                  disableHoverListener={!historyDay.status.value}
                >
                  <div className={cn(
                    'history-item',
                    historyDay.status.value && `history-item-${historyDay.status.value}`,
                  )}
                  >
                    <div />

                    <p className="date-label">{historyDay.dateLabel}</p>
                  </div>
                </Tooltip>
              ))}
            </div>
            )}
          </div>
        ) : <CircularProgress className="loader" />}

        {!!items.length && (
        <div>
          <div className="show-details" onClick={() => setIsDetailsOpened(!isDetailsOpened)} aria-hidden>
            {isDetailsOpened
              ? <FontAwesomeIcon icon={faChevronUp} fontSize={16} color="#FFFFFF8F" />
              : <FontAwesomeIcon icon={faChevronDown} fontSize={16} color="#FFFFFF8F" />}

            <p>{isDetailsOpened ? 'Hide' : 'Show'} details</p>
          </div>

          {isDetailsOpened && (
          <div className="check-items">
            {items.map((checkItem) => {
              const data = getRawDataByStatus(checkItem.checkFlag);

              return (
                <HistoryItem key={checkItem.id} data={data} item={checkItem} />
              );
            })}
          </div>
          )}
        </div>
        )}
      </div>
    </Modal>
  );
};

HistoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  monitorCheck: PropTypes.shape().isRequired,
};

export default React.memo(HistoryModal);
