import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { Accordion, Chip, IconButton, CircularProgress, AccordionSummary, AccordionDetails } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import { monitorApi } from 'resources/monitor';
import './styles.scss';

const getChipLabelByStatus = (status) => (status
  ? { label: 'Not Listed', value: 'success' } : { label: 'Blacklisted', value: 'error' });

const HistoryItem = ({ item, data }) => {
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);

  const { data: historyData, isFetching } = monitorApi.useCheckHistoryItems(isDetailsOpened && item.id);

  const resultsbyTier = historyData?.results.reduce((acc, result) => {
    const { tier, state } = result;

    acc[tier] = acc[tier] || { items: [], status: state.status };
    acc[tier].items.push(result);

    if (!state.status) {
      acc[tier].status = state.status;
    }

    return acc;
  }, {});

  return (
    <div key={item.id} className="check-item-wrapper">
      <div
        className="check-item-content"
        onClick={() => setIsDetailsOpened(!isDetailsOpened)}
        aria-hidden
      >
        <div className="check-item-label-wrapper">
          <div className={cn('status-icon', {
            healthy: item.checkFlag === 'green',
            down: item.checkFlag === 'yellow',
            blacklisted: item.checkFlag === 'red',
            default: item.checkFlag === null,
          })}
          >
            {data.icon}
          </div>

          <p>{moment(item.checkAt).format('MM/DD/YYYY h:mm:ss A')}</p>
        </div>

        <div className="chip-container">
          <Chip label={data.chip.label} color={data.chip.color} />

          <IconButton>
            {isDetailsOpened
              ? <FontAwesomeIcon icon={faChevronUp} fontSize={16} color="#FFFFFF8F" />
              : <FontAwesomeIcon icon={faChevronDown} fontSize={16} color="#FFFFFF8F" />}
          </IconButton>
        </div>
      </div>

      {isDetailsOpened && (
      <div>
        {isFetching
          ? <CircularProgress className="loader" />
          : !!historyData?.results.length && (
          <div className="according">
            {Object.keys(resultsbyTier).map((key) => {
              const tierChipData = getChipLabelByStatus(resultsbyTier[key].status);

              return (
                <Accordion>
                  <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} fontSize={16} color="#FFFFFF8F" />}>
                    <div className="result-content">
                      <p className="tier-label">{`TIER ${key}`}</p>

                      <div className="chip-container" style={{ paddingRight: '16px' }}>
                        <Chip
                          label={tierChipData.label}
                          color={tierChipData.value}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </AccordionSummary>

                  <AccordionDetails className="according-details">
                    <div>
                      {resultsbyTier[key].items.map((result) => {
                        const chipData = getChipLabelByStatus(result.state.status);

                        return (
                          <div key={result['@id']} className="wrapper">
                            <div className="result-content">

                              <p className="tier-item">• {result.title}</p>

                              <div className="chip-container" style={{ paddingRight: '30px' }}>
                                <Chip
                                  label={chipData.label}
                                  color={chipData.value}
                                  variant="outlined"
                                />
                              </div>
                            </div>

                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}

          </div>
          )}
      </div>
      )}
    </div>
  );
};

HistoryItem.propTypes = {
  item: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
};

export default React.memo(HistoryItem);
