import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import MenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import { FormHelperText } from '@mui/material';

import './styles.scss';

const SELECT_TYPES = {
  DEFAULT: 'default',
  FILLED: 'filled',
};

const Select = forwardRef(({
  children,
  items,
  value,
  onChange,
  renderValue,
  className,
  wrapperClassName,
  type,
  open,
  onClick,
  onOpen,
  label,
  onClose,
  placeholder,
  isMultiple,
  isGhost,
  name,
  renderMenuItem,
  error,
  helperText,
  endAdornment,
  ...otherProps
}, ref) => {
  const [isOpen, setIsOpen] = useState(!!open);

  const selectRenderValue = useCallback(() => {
    if (!placeholder || value) {
      return renderValue;
    }

    return function () {
      return (
        <p className="select-placeholder">{placeholder}</p>
      );
    };
  }, [placeholder, value, renderValue]);

  const selectRenderMenuItem = useCallback((item) => {
    if (renderMenuItem) {
      return renderMenuItem(item);
    }

    if (item.tooltipText) {
      return (
        <Tooltip title={item.tooltipText} placement={item.tooltipPlacement || 'top'}>
          <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
            <p>{item.label}</p>
          </MenuItem>
        </Tooltip>

      );
    }

    return (
      <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
        {item.label}
      </MenuItem>
    );
  }, [renderMenuItem]);

  const onSelectOpen = useCallback((e) => {
    setIsOpen(true);

    onOpen?.(e);
  }, [onOpen]);

  const onSelectClose = useCallback((e) => {
    setIsOpen(false);

    onClose?.(e);
  }, [onClose]);

  return (
    <FormControl error={!!error} size="small" className={cn(wrapperClassName, isOpen && !isGhost && 'select-green-border')}>
      <MUISelect
        ref={ref}
        open={open}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className={cn(
          'select',
          { [`select-${SELECT_TYPES.FILLED}`]: type === SELECT_TYPES.FILLED },
          className,
          'scroll',
        )}
        value={value}
        onChange={(e) => onChange(e.target.value, name)}
        onClick={onClick}
        renderValue={selectRenderValue()}
        MenuProps={{ PaperProps: { sx: { maxHeight: 500 }, className: 'scroll' } }}
        displayEmpty
        placeholder={placeholder}
        onOpen={onSelectOpen}
        onClose={onSelectClose}
        multiple={isMultiple}
        endAdornment={endAdornment}
        {...otherProps}
      >
        {children || items.map(selectRenderMenuItem)}
      </MUISelect>
      {!!error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    tooltipPlacement: PropTypes.string,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  renderValue: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(SELECT_TYPES)),
  open: PropTypes.bool,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isMultiple: PropTypes.bool,
  isGhost: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  renderMenuItem: PropTypes.func,
  endAdornment: PropTypes.element,
};

Select.defaultProps = {
  value: undefined,
  children: null,
  renderValue: null,
  className: null,
  wrapperClassName: null,
  type: SELECT_TYPES.DEFAULT,
  open: undefined,
  onClick: undefined,
  placeholder: null,
  onOpen: undefined,
  onClose: undefined,
  isMultiple: false,
  isGhost: false,
  name: null,
  renderMenuItem: undefined,
  error: false,
  helperText: null,
  label: null,
  endAdornment: null,
};

export default React.memo(Select);
