import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'routes';

import UnauthorizedLayout from 'layouts/unauthorized';

import { PersonIcon, LockIcon, GoogleLogoIcon, LinkedInLogoIcon } from 'assets/icons';

import { accountApi } from 'resources/account';
import { userApi } from 'resources/user';

import Input from 'components/Input';
import Button from 'components/Button';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

const isHideSignUp = process.env.REACT_APP_USE_HIDE_SIGNUP === undefined ? false : +process.env.REACT_APP_USE_HIDE_SIGNUP;

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  const { mutate: login, isLoading: isLoginLoading } = accountApi.useLogin();
  const { mutate: getGoogleAuthUrl, isLoading: isGoogleLoginLoading } = accountApi.useGetGoogleAuthUrl();
  const { mutate: getLinkedInAuthUrl, isLoading: isLinkedInLoginLoading } = accountApi.useGetLindedInAuthUrl();
  const { mutate: getUser, isLoading: isCurrentUserLoading } = userApi.useUser();
  const isLoading = isLoginLoading || isCurrentUserLoading;

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'This field is required';
    }

    if (!password) {
      newErrors.password = 'This field is required';
    }

    if (email && !validateEmail(email)) {
      newErrors.email = 'Incorrect email format';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [email, password]);

  const onLogin = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    login({ email, password }, {
      onError: (error) => {
        setErrors({
          email: error.data.error,
        });
      },
      onSuccess: () => {
        const userId = localStorage.getItem('userId');

        getUser(userId);
      },
    });
  }, [validateForm, login, email, password, getUser]);

  const onGoogleSignInClick = useCallback(() => {
    getGoogleAuthUrl(null, {
      onSuccess: (url) => {
        window.location.replace(url);
      },
    });
  }, [getGoogleAuthUrl]);

  const onLinkedInSignInClick = useCallback(() => {
    getLinkedInAuthUrl(null, {
      onSuccess: (url) => {
        window.location.replace(url);
      },
    });
  }, [getLinkedInAuthUrl]);

  return (
    <UnauthorizedLayout>
      <div className="login-wrapper">
        <p className="title">Sign in to your account</p>

        <div className="form">
          <div>
            <Input
              label="Email"
              value={email}
              onChange={(value) => onChangeInput({ value, setter: setEmail, errorField: 'email' })}
              leftIcon={<PersonIcon />}
              error={!!errors.email}
              helperText={errors.email}
            />
            <Input
              label="Password"
              value={password}
              onChange={(value) => onChangeInput({ value, setter: setPassword, errorField: 'password' })}
              className="password-input"
              leftIcon={<LockIcon />}
              type="password"
              error={!!errors.password}
              helperText={errors.password}
            />

            <Button onClick={onLogin} className="login-button" loading={isLoading}>Login</Button>
          </div>

          {!isHideSignUp
            && (
            <div className="services">
              <p>or sign in with Google:</p>

              <div className="button-group">
                <Button
                  className="google-button"
                  startIcon={<GoogleLogoIcon />}
                  onClick={onGoogleSignInClick}
                  disabled={isGoogleLoginLoading}
                >
                  Sign in with Google
                </Button>

                <Button
                  className="linkedin-button"
                  startIcon={<LinkedInLogoIcon />}
                  onClick={onLinkedInSignInClick}
                  disabled={isLinkedInLoginLoading}
                >
                  Sign in with LinkedIn
                </Button>

              </div>
            </div>
            )}

          <div className="line" />

          <div className="form-footer">
            {!isHideSignUp && (
            <Link to={routes.signUp.path}>
              <Button variant="text" color="secondary">Create an account?</Button>
            </Link>
            )}

            <Link to={routes.resetPassword.path}>
              <Button variant="text" color="secondary">Reset password</Button>
            </Link>
          </div>
        </div>
      </div>
    </UnauthorizedLayout>
  );
};

export default SignIn;
