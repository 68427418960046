import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { IPScansEmptyIcon } from 'assets/icons';

import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';

import Item from './components/Item';

const IPScansList = ({
  data,
  loading,
  onRefetch,
  isError,
}) => (
  <div className={cn('item', 'graph-item', 'list-item', 'scroll')}>
    {loading && !isError && <CircularProgress className="loader" />}

    {isError && (
    <div className="item-error">
      <p>Something went wrong</p>

      <Button onClick={onRefetch}>Refresh</Button>
    </div>
    )}

    {!loading && !isError && (
    <div className="graph-content">
      <div>
        <p>IP Scans</p>
        <p>Last 100 scans</p>
      </div>

      {!data?.length && (
      <div className="empty-state">
        <IPScansEmptyIcon />

        <p>No recent scans</p>
      </div>
      )}

      {!!data?.length && (
      <div className="list-content-wrapper">
        {data.map((item) => <Item key={item['@id']} item={item} />)}
      </div>
      )}
    </div>
    )}
  </div>
);

IPScansList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired,
  onRefetch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

IPScansList.defaultProps = {
  data: null,
};

export default React.memo(IPScansList);
