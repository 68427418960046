import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { monitorApi } from 'resources/monitor';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const EditNameModal = ({
  open,
  onClose,
  item,
  onEditIPName,
}) => {
  const [name, setName] = useState(item.name || '');

  const {
    mutate: updateMonitorCheck,
    isLoading: isUpdateMonitorCheckLoading,
  } = monitorApi.useUpdateMonitorCheck();

  const [errors, setErrors] = useState({
    name: null,
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [name]);

  const onNextClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    updateMonitorCheck({
      id: item.id,
      name,
    }, {
      onSuccess: (data) => {
        uiNotificationService.showSuccess('Name has been updated');
        onEditIPName({ id: item.id, name: data.name });

        onClose();
      },
    });
  }, [validateForm, item.id, name, onClose, onEditIPName, updateMonitorCheck]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Rename Monitor"
    >
      <div className="edit-ip-monitor-item-modal-wrapper">
        <Input
          label="IP Name"
          value={name}
          onChange={(value) => onChangeInput({ value, setter: setName, errorField: 'name' })}
          error={!!errors.name}
          helperText={errors.name}
        />
        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
          <Button onClick={onNextClick} loading={isUpdateMonitorCheckLoading}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

EditNameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
  onEditIPName: PropTypes.func.isRequired,
};

export default React.memo(EditNameModal);
