import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';

import Button from 'components/Button';

import SendYourEmailModal from 'pages/DeliverabilityTest/StartNew/components/SeedListForm/components/SendYourEmailModal';

import { placementApi } from 'resources/placement';

import EmptyState from './components/EmptyState';
import TestCard from './components/TestCard';

import './styles.scss';

const DeliverabilityTest = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [refetchIntervalId, setRefetchIntervalId] = useState(null);

  const emails = searchParams.get('emails') ? searchParams.get('emails').split(',') : [];

  const [isSendYourEmailModalVisible, setIsSendYourEmailModalVisible] = useState(!!emails.length);

  const {
    data: placementTests,
    isLoading: isPlacementTestLoading,
    refetch: refetchPlacementTests,
  } = placementApi.useList();

  const isTestsExist = !!placementTests?.length;
  const isAnyInProgress = placementTests?.some((item) => item.placementTestStatus === 'active');

  const onStartNewTestClick = useCallback(() => {
    navigate(routes.deliverabilityTestStartNew.path);
  }, [navigate]);

  // refetch placement tests every 1 minute if any of them is in progress
  useEffect(() => {
    if (isAnyInProgress && !refetchIntervalId) {
      const intervalId = setInterval(() => {
        refetchPlacementTests();
      }, 60000);

      setRefetchIntervalId(intervalId);
    }

    return () => {
      clearInterval(refetchIntervalId);
    };
  }, [isAnyInProgress, refetchPlacementTests, refetchIntervalId]);

  return (
    <AuthorizedLayout>
      <div className="deliverability-test-wrapper">
        {isSendYourEmailModalVisible && (
        <SendYourEmailModal
          open={isSendYourEmailModalVisible}
          onClose={() => {
            setIsSendYourEmailModalVisible(false);

            if (searchParams.has('emails')) {
              searchParams.delete('emails');
              setSearchParams(searchParams);
            }
          }}
          emails={emails}
        />
        )}

        <div className="title">
          <p>Deliverability Test</p>

          {!isPlacementTestLoading && isTestsExist && (
          <Button
            onClick={onStartNewTestClick}
          >
            Start a Test
          </Button>
          )}
        </div>

        {!isPlacementTestLoading && isTestsExist && (
          <div className="deliverability-tests">
            {placementTests.map((item) => <TestCard key={item.id} item={item} />)}
          </div>
        )}

        {!isPlacementTestLoading && !isTestsExist && <EmptyState onClick={onStartNewTestClick} />}
      </div>
    </AuthorizedLayout>
  );
};

export default DeliverabilityTest;
