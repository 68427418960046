import { useMutation, useQuery } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useInfrastructureMonitorList({ page, perPage } = {}) {
  const getInfrastructureMonitorList = () => api.get('/v2/infrastructure-monitors', { page, itemsPerPage: perPage })
    .then(async (data) => {
      const checksPerPage = 50;

      const monitors = await Promise.all(data['hydra:member'].map(async (monitor) => {
        const runs = await api.get(`/v2/infrastructure-monitors/${monitor.id}/runs`, {
          page: 1,
          itemsPerPage: checksPerPage,
        });

        const otherPageNumbers = Array.from({
          length: Math.ceil(runs['hydra:totalItems'] / checksPerPage) - 1,
        }, (_, i) => i + 2);

        const otherRuns = await Promise.all(otherPageNumbers
          .map(async (checkPage) => api.get(`/v2/infrastructure-monitors/${monitor.id}/runs`, {
            page: checkPage,
            itemsPerPage: checksPerPage,
          })));

        monitor.runs = [
          ...runs['hydra:member'],
          ...otherRuns.map((check) => check['hydra:member']).flat(1),
        ];

        return monitor;
      }));

      return {
        totalItems: data['hydra:totalItems'],
        items: monitors,
      };
    });

  return useQuery(['infrastructureMonitor.list', { page, perPage }], getInfrastructureMonitorList);
}

export function useUpdateInfrastructureMonitor() {
  const updateInfrastructureMonitor = ({
    id, title, isNotificationEnabled, notificationId, scheduleSeconds,
  }) => api.patch(`/v2/infrastructure-monitors/${id}`, {
    title,
    isNotificationEnabled,
    ...(notificationId && { notification: `/v2/monitor-notifications/${notificationId}` }),
    scheduleSeconds,
  });

  return useMutation(updateInfrastructureMonitor, {
    onSuccess: () => {
      queryClient.invalidateQueries('infrastructureMonitor.list');
      queryClient.invalidateQueries('monitor.notificationList');
    },
  });
}

export function useInfrastructureMonitorRunsList({ id, page, perPage } = {}) {
  const getInfrastructureMonitorRunsList = () => api.get(`/v2/infrastructure-monitors/${id}/runs`, {
    page,
    itemsPerPage: perPage,
    'order[createdAt]': 'desc',
  }).then((data) => ({
    items: data['hydra:member'],
    totalItems: data['hydra:totalItems'],
  }));

  return useQuery(['infrastructureMonitor.runsList', { id, page, perPage }], getInfrastructureMonitorRunsList, {
    enabled: !!id,
  });
}
