import { useCallback, useState } from 'react';
import UnauthorizedLayout from 'layouts/unauthorized';
import { Link, useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { PersonIcon, LockIcon, GoogleLogoIcon, LinkedInLogoIcon } from 'assets/icons';

import { userApi } from 'resources/user';
import { accountApi } from 'resources/account';

import Input from 'components/Input';
import Button from 'components/Button';

import validateEmail from 'helpers/validateEmail';

import './styles.scss';

const SignUp = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [errors, setErrors] = useState({
    email: null,
    password: null,
    repeatPassword: null,
  });

  const { mutate: register, isLoading: isRegistrationLoading } = userApi.useRegistration();
  const { mutate: getGoogleAuthUrl, isLoading: isGoogleLoginLoading } = accountApi.useGetGoogleAuthUrl();
  const { mutate: getLinkedInAuthUrl, isLoading: isLinkedInLoginLoading } = accountApi.useGetLindedInAuthUrl();

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'This field is required';
    }

    if (!password) {
      newErrors.password = 'This field is required';
    }

    if (email && !validateEmail(email)) {
      newErrors.email = 'Incorrect email format';
    }

    if (password !== repeatPassword) {
      newErrors.repeatPassword = 'The passwords don\'t match';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !!Object.keys(newErrors).length;
  }, [email, password, repeatPassword]);

  const onCreateAccount = useCallback(() => {
    const isErrors = validateForm();

    if (!isErrors) {
      register({ email, password }, {
        onSuccess: () => {
          navigate(routes.emailConfirmation.path);
        },
        onError: (error) => {
          const property = error.data.violations[0].propertyPath === 'plainPassword' ? 'password' : 'email';

          setErrors({
            [property]: error.data.violations[0].message,
          });
        },
      });
    }
  }, [validateForm, register, email, password, navigate]);

  const onGoogleSignInClick = useCallback(() => {
    getGoogleAuthUrl(null, {
      onSuccess: (url) => {
        window.location.replace(url);
      },
    });
  }, [getGoogleAuthUrl]);

  const onLinkedInSignInClick = useCallback(() => {
    getLinkedInAuthUrl(null, {
      onSuccess: (url) => {
        window.location.replace(url);
      },
    });
  }, [getLinkedInAuthUrl]);

  return (
    <UnauthorizedLayout>
      <div className="signup-wrapper">
        <p className="title">Create account</p>

        <div className="form">
          <div>
            <Input
              label="Email"
              value={email}
              onChange={(value) => onChangeInput({ value, setter: setEmail, errorField: 'email' })}
              leftIcon={<PersonIcon />}
              error={!!errors.email}
              helperText={errors.email}
              autoComplete="off"
            />
            <Input
              label="Password"
              value={password}
              onChange={(value) => onChangeInput({ value, setter: setPassword, errorField: 'password' })}
              className="password-input"
              leftIcon={<LockIcon />}
              type="password"
              error={!!errors.password}
              helperText={errors.password}
              autoComplete="off"
            />
            <Input
              label="Password"
              value={repeatPassword}
              onChange={(value) => onChangeInput({ value, setter: setRepeatPassword, errorField: 'repeatPassword' })}
              className="password-input"
              leftIcon={<LockIcon />}
              type="password"
              error={!!errors.repeatPassword}
              helperText={errors.repeatPassword}
              autoComplete="off"
            />

            <Button onClick={onCreateAccount} className="login-button" loading={isRegistrationLoading}>Create</Button>
          </div>

          <div className="services">
            <p>or sign in with Google:</p>

            <div className="button-group">
              <Button
                className="google-button"
                startIcon={<GoogleLogoIcon />}
                onClick={onGoogleSignInClick}
                disabled={isGoogleLoginLoading}
              >
                Sign in with Google
              </Button>

              <Button
                className="linkedin-button"
                startIcon={<LinkedInLogoIcon />}
                onClick={onLinkedInSignInClick}
                disabled={isLinkedInLoginLoading}
              >
                Sign in with LinkedIn
              </Button>

            </div>
          </div>

          <div className="line" />

          <div className="form-footer">
            <Link to={routes.signIn.path}>
              <Button variant="text" color="secondary">Sign In</Button>
            </Link>
          </div>
        </div>
      </div>
    </UnauthorizedLayout>
  );
};

export default SignUp;
