import React, { useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import './styles.scss';

const iconColor = 'rgba(255, 255, 255, 0.56)';

const getInputType = ({ type, isVisible }) => {
  if (isVisible) {
    return 'text';
  }

  return type;
};

const Input = forwardRef(({
  value,
  onChange,
  label,
  leftIcon,
  rightIcon,
  type,
  className,
  error,
  helperText,
  autoComplete,
  variant,
  placeholder,
  multiline,
  readOnly,
  rows,
  onKeyDown,
  onKeyUp,
  style,
  isPasswordViewIconVisible,
  name,
}, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onPasswordVisibilityToggle = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  const inputType = getInputType({ type, isVisible: isPasswordVisible });

  return (
    <div className={`input-${variant}`}>
      <TextField
        className={cn(
          'input',
          error && 'input-error',
          multiline && 'multiline',
          multiline && label && 'multiline-with-label',
          className,
        )}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value, e.target.name)}
        label={label}
        variant={variant}
        type={inputType}
        error={error}
        helperText={helperText}
        autoComplete={autoComplete}
        placeholder={placeholder}
        multiline={multiline}
        inputRef={ref}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        style={style}
        InputProps={{
          readOnly,
          disableUnderline: true,
          autoComplete,
          className: 'scroll',
          rows,
          sx: {
            color: 'white',
            fontSize: 16,
            ...(!multiline && { lineHeight: '24px' }),
          },
          ...(leftIcon && {
            startAdornment: (
              <InputAdornment position="start">
                {leftIcon}
              </InputAdornment>
            ),
          }),
          ...(rightIcon && {
            endAdornment: (
              <InputAdornment position="end">
                {rightIcon}
              </InputAdornment>
            ),
          }),
          ...(type === 'password' && isPasswordViewIconVisible && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={onPasswordVisibilityToggle}
                  edge="end"
                  sx={{ mt: '15px', pr: '12.5px' }}
                >
                  {isPasswordVisible
                    ? <VisibilityOff sx={{ color: iconColor }} />
                    : <Visibility sx={{ color: iconColor }} />}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
      />
    </div>
  );
});

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  type: PropTypes.oneOf(['text', 'password']),
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'standard']),
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  style: PropTypes.shape(),
  isPasswordViewIconVisible: PropTypes.bool,
  name: PropTypes.string,
};

Input.defaultProps = {
  value: null,
  onChange: null,
  label: null,
  leftIcon: null,
  rightIcon: null,
  type: 'text',
  className: null,
  error: false,
  helperText: null,
  autoComplete: null,
  variant: 'filled',
  placeholder: null,
  multiline: false,
  readOnly: false,
  rows: null,
  onKeyDown: null,
  onKeyUp: null,
  style: null,
  name: null,
  isPasswordViewIconVisible: true,
};

export default React.memo(Input);
