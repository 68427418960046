import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faMailbox, faMonitorWaveform } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';

import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import ScoreCircle from 'components/ScoreCircle';

import { addAlpha } from 'components/ScoreCircle/helpers/getCircleOptions';

const getIconByMonitorType = (monitorType) => {
  if (monitorType === 4) { // for account
    return {
      label: 'Ad-Hoc Scan',
      icon: faAt,
    };
  }

  if (monitorType === 2) { // for monitor
    return {
      label: 'Monitor Scan',
      icon: faMonitorWaveform,
    };
  }

  if (monitorType === 1) { // free
    return {
      label: 'Free',
      icon: faEnvelopeOpen,
    };
  }

  if (monitorType === 0) { // old
    return {
      label: 'Old',
      icon: faMailbox,
    };
  }

  return {
    label: 'Unknown',
    icon: faEnvelope,
  };
};

const getLink = (item) => {
  if (item.emailType === 4) { // ad-hoc
    return routes.result.path.replace(':email', item.emailTo);
  }

  if (item.emailType === 2) { // monitor
    return routes.monitorResult.path
      .replace(':monitorId', item.monitorId)
      .replace(':email', item.emailTo);
  }

  return null;
};

const Item = ({ item }) => {
  const navigate = useNavigate();

  const iconData = getIconByMonitorType(item.emailType);
  const link = getLink(item);

  return (
    <div>
      <div
        className="dashboard-list-item"
        {...(link && {
          onClick: () => navigate(link),
          style: { cursor: 'pointer' },
        })}
      >
        <div className="name-wrapper">
          <Tooltip title={iconData.label}>
            <div className="dashboard-list-item-icon-wrapper" style={{ background: addAlpha(item.ratingColor, 0.2) }}>
              <FontAwesomeIcon
                icon={iconData.icon}
                color={item.ratingColor}
                width={14}
              />
            </div>
          </Tooltip>

          <p className="from-email">{item.emailFrom}</p>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 32 }}>
          <p className="dashboard-list-item-time">{moment(item.scanAt).format('MMM DD, YYYY h:mmA')}</p>

          <ScoreCircle
            mxrScoreTotal={10}
            mxrScoreTotalPass={item.score}
            ratingColor={item.ratingColor}
            ratingLabel={item.ratingLabel || 'U'}
            onlyCircle
            size="tiny"
          />
        </div>
      </div>

      <Divider className="divider" />
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default React.memo(Item);
