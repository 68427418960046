import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { placementApi } from 'resources/placement';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const EditModal = ({
  open,
  onClose,
  item,
}) => {
  const [title, setTitle] = useState(item.title);
  const [errors, setErrors] = useState({
    name: null,
  });

  const {
    mutate: updatePlacementTest,
    isLoading: isUpdatePlacementTestLoading,
  } = placementApi.useUpdate();

  const itemId = item['@id'].replace('/v2/placement-tests/', '');

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!title) {
      newErrors.title = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [title]);

  const onSaveClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    updatePlacementTest({
      id: itemId,
      title,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Test has been updated');

        onClose();
      },
    });
  }, [validateForm, itemId, title, onClose, updatePlacementTest]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit Deliverability Test"
    >
      <div className="edit-deliverability-test-modal-wrapper">
        <Input
          label="Title"
          value={title}
          onChange={(value) => onChangeInput({ value, setter: setTitle, errorField: 'title' })}
          error={!!errors.title}
          helperText={errors.title}
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              onSaveClick();
            }
          }}
        />
        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose} disabled={isUpdatePlacementTestLoading}>Cancel</Button>
          <Button onClick={onSaveClick} loading={isUpdatePlacementTestLoading}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export default React.memo(EditModal);
