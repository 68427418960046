import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { ErrorOutlineIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import Modal from 'components/Modal';

import Button from 'components/Button';
import Input from 'components/Input';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const AddIPModal = ({ open, onClose, monitorId }) => {
  const [ipList, setIPList] = useState('');
  const [errors, setErrors] = useState({
    ipList: null,
  });
  const [generalErrors, setGeneralErrors] = useState([]);

  const {
    mutate: createMonitorChecks,
    isLoading: isCreateMonitorChecksLoading,
  } = monitorApi.useCreateMonitorChecks();

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!ipList) {
      newErrors.ipList = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [ipList]);

  const onSaveClick = useCallback(() => {
    setGeneralErrors([]);

    if (!validateForm()) {
      return;
    }

    createMonitorChecks({
      items: ipList,
      monitorId,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('IPs has been added');

        setGeneralErrors([]);
        setIPList('');

        onClose();
      },
      onError: (error) => {
        if (error.data.violations?.length) {
          setGeneralErrors(error.data.violations.map((violation) => violation.message));
        }
      },
    });
  }, [validateForm, ipList, createMonitorChecks, monitorId, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add IPs"
    >
      <div className="add-ips-modal-wrapper">
        {!!generalErrors.length && (
          <div className="general-error-list">
            {generalErrors.map((error, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${error}-${index}`} className="general-error-wrapper">
                <ErrorOutlineIcon />
                <p>{error}</p>
              </div>
            ))}
          </div>
        )}

        <div className="inputs">
          <Input
            label="List of IPs"
            value={ipList}
            onChange={(value) => onChangeInput({ value, setter: setIPList, errorField: 'ipList' })}
            error={!!errors.ipList}
            helperText={errors.ipList}
            multiline
            rows={7}
            placeholder={'1.1.1.1[;Name]\n2001:2222:3333:4444:5555:6666:7777:8888[;Name]'}
          />
        </div>

        <div className="confirm-buttons">
          <Button color="secondaryButton" onClick={onClose} disabled={isCreateMonitorChecksLoading}>Cancel</Button>
          <Button onClick={onSaveClick} loading={isCreateMonitorChecksLoading}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

AddIPModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  monitorId: PropTypes.number.isRequired,
};

export default React.memo(AddIPModal);
