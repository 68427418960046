const formatNumber = (number) => {
  if (number >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T']; // Add more if needed
    const suffixIndex = Math.floor(Math.log10(number) / 3);
    const scaledNumber = number / 10 ** (suffixIndex * 3);

    return scaledNumber.toFixed(1) + suffixes[suffixIndex];
  }

  return number.toString();
};

export default formatNumber;
