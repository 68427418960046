import React, { useState } from 'react';

import AuthorizedLayout from 'layouts/authorized';

import { PlusIcon, UserWithCirclesIcon } from 'assets/icons';

import { accountApi } from 'resources/account';

import Button from 'components/Button';

import CircularProgress from '@mui/material/CircularProgress';

// import UsersMenu from './components/UsersMenu';
import UserItem from './components/UserItem';
import AddOrEditUserModal from './components/AddOrEditUserModal';

import './styles.scss';

const Users = () => {
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);

  const { data, isLoading } = accountApi.useAccountUsersList();

  return (
    <AuthorizedLayout>
      {isAddUserModalVisible && (
      <AddOrEditUserModal
        open={isAddUserModalVisible}
        onClose={() => setIsAddUserModalVisible(false)}
        className="add-or-edit-user-modal"
      />
      )}

      <div className="users-wrapper">
        <div className="title-wrapper">
          <p className="title">Users</p>

          <div className="buttons">
            <Button
              className="add-user-button"
              startIcon={<PlusIcon />}
              iconClassName="button-icon"
              onClick={() => setIsAddUserModalVisible(true)}
            >
              Add User
            </Button>

            {/* <UsersMenu /> */}
          </div>
        </div>

        {isLoading && <CircularProgress style={{ margin: 'auto' }} />}

        {/* TODO: Empty State */}
        {!isLoading && !data?.length && (
        <div className="empty-state">
          <UserWithCirclesIcon />

          <p className="empty-state-title">No users yet</p>

          <Button
            className="add-user-button"
            startIcon={<PlusIcon />}
            iconClassName="button-icon"
            onClick={() => setIsAddUserModalVisible(true)}
          >
            Add User
          </Button>
        </div>
        )}

        {!isLoading && !!data?.length && (
          <div className="users-list">
            <div className="table-head">
              <p className="name-head">Name</p>
              <p className="email-head">Email</p>
              <p className="role-head">Role</p>
            </div>

            <div className="table-head-line" />

            {data.map((item) => <UserItem key={item.id} item={item} />)}
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default Users;
