import { memo } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import './styles.scss';

const ReportRow = ({ data }) => {
  const { icon, name, stats } = data;

  return (
    <TableRow className="report-row">
      <TableCell sx={{ borderBottom: 0, padding: 0 }} className="report-row-cell">
        <div className="report-row-cell-name-wrapper">
          {icon}
          <p>{name}</p>
        </div>
      </TableCell>
      {stats.map((stat, index) => {
        const isLast = index === stats.length - 1;

        return (
          <TableCell
            sx={{ borderBottom: 0, padding: 0 }}
            key={stat.name}
            className="report-row-cell"
            align="center"
          >
            <div
              className="report-row-cell-wrapper"
              {...isLast && {
                style: {
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                },
              }}
            >
              <p style={{ color: stat.color }} className="report-row-cell-name">{stat.text}</p>
              <p className="report-row-cell-percent">{stat.percent}%</p>
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

ReportRow.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default memo(ReportRow);
