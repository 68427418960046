import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { WatchingEyeIcon, ArrowBottomIcon, CardioLineBigIcon, WaitingScanIcon } from 'assets/icons';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import IPMonitorMenu from '../IPMonitorMenu';

import './styles.scss';

const getMonitorStatusByChecks = (checks) => {
  const unhealthyChecksCount = checks.filter((check) => check.lastCheckFlag === 'red').length;

  if (unhealthyChecksCount === 0) {
    return {
      text: 'All Healthy',
      color: 'default',
    };
  }

  return {
    text: `${unhealthyChecksCount} of ${checks.length} Unhealthy`,
    color: 'error',
  };
};

const getRawDataByStatus = (lastCheckFlag) => {
  switch (lastCheckFlag) {
    case 'green':
      return {
        label: 'Healthy',
        color: 'success',
      };
    case 'yellow':
      return {
        label: 'Down',
        color: 'warning',
      };
    case 'red':
      return {
        label: 'Blacklisted',
        color: 'error',
      };
    default:
      return {
        label: 'Waiting',
        color: 'default',
      };
  }
};

const IPMonitorItem = ({ id, name, enabled, ipList, scheduleSeconds, notificationId, onDeleted }) => {
  const navigate = useNavigate();

  const [showAllIPList, setShowAllIPList] = useState(ipList.length <= 5);
  const [data, setData] = useState({
    id,
    name,
    enabled,
    ipList,
    scheduleSeconds,
    notificationId,
  });

  const onShowMore = useCallback((e) => {
    e.stopPropagation();

    setShowAllIPList(true);
  }, []);

  const onNavigateToMonitorChecks = useCallback(() => {
    navigate(routes.realTimeMonitor.path.replace(':monitorId', id));
  }, [navigate, id]);

  const onUpdated = useCallback((updatedData) => {
    setData((old) => ({
      ...old,
      ...updatedData,
    }));
  }, []);

  const ipListToShow = showAllIPList ? ipList : ipList.slice(0, 4);
  const monitorStatusByChecks = getMonitorStatusByChecks(ipList);

  return (
    <div className="ip-monitor-item" onClick={onNavigateToMonitorChecks} aria-hidden>
      <div>
        <div className="ip-monitor-title-container">
          <div className={cn('watching-eye', data.enabled && 'active-watcing-eye')}>
            <WatchingEyeIcon />
          </div>

          <div className="ip-monitor-title">
            <Tooltip title={data.name} placement="bottom">
              <p className="monitor-name">{data.name}</p>
            </Tooltip>

            <p className={cn('monitor-status', `monitor-status-${monitorStatusByChecks.color}`)}>
              {monitorStatusByChecks.text}
            </p>
          </div>
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          aria-hidden
        >
          <IPMonitorMenu
            id={data.id}
            name={data.name}
            enabled={data.enabled}
            ipList={data.ipList}
            scheduleSeconds={data.scheduleSeconds}
            notificationId={data.notificationId}
            onDeleted={onDeleted}
            onUpdated={onUpdated}
          />
        </div>
      </div>

      <div className={cn(
        'ip-list-container',
        'scroll',
        (!data.enabled || !ipListToShow.length) && 'ip-list-container-disabled',
      )}
      >
        {data.enabled && !!ipListToShow.length && (
        <>
          {ipListToShow.map((ipItem) => {
            const dataByStatus = getRawDataByStatus(ipItem.lastCheckFlag);

            return (
              <div key={ipItem.id}>
                <div className="ip-address">
                  <div className={cn('circle', `circle-${dataByStatus.color}`)} />

                  <p>{ipItem.ipAddress}</p>
                </div>

                <p className={cn('label', `label-${dataByStatus.color}`)}>{dataByStatus.label}</p>
              </div>
            );
          })}

          {(ipListToShow.length < ipList.length) && (
          <Box className="more-container" onClick={onShowMore}>
            <ArrowBottomIcon />
            <p>{ipList.length - ipListToShow.length} more...</p>
          </Box>
          )}
        </>
        )}

        {data.enabled && !ipListToShow.length && (
        <div className="monitor-disabled-state">
          <WaitingScanIcon />
          <p>Waiting for initial IP</p>
        </div>
        )}

        {!data.enabled && (
        <div className="monitor-disabled-state">
          <CardioLineBigIcon />
          <p>Monitoring is Disabled</p>
        </div>
        )}
      </div>
    </div>
  );
};

IPMonitorItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  ipList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    ipAddress: PropTypes.string,
    lastCheckFlag: PropTypes.string,
    success: PropTypes.bool,
  })).isRequired,
  scheduleSeconds: PropTypes.number.isRequired,
  notificationId: PropTypes.number.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default React.memo(IPMonitorItem);
