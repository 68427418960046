import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';

import { OpenedLetterWithSmileIcon, ArrowBottomIcon, AddFolderIcon } from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Button from 'components/Button';
import Pagination from 'components/Pagination';

import useWindowDimensions from 'hooks/useWindowDimensions';

import AddFolderModal from './components/AddFolderModal';
import TemplateFolder from './components/TemplateFolder';

import './styles.scss';

const ITEM_WIDTH = 532 + 24;
const ITEM_HEIGHT = 94 + 24;

const TemplateMXRisk = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);

  const [testTemplateMenuElement, setTestTemplateMenuElement] = useState(null);
  const [isAddEmailMXRiskGroupVisible, setIsAddEmailMXRiskGroupVisible] = useState(false);

  const rows = Math.floor((width - 352) / ITEM_WIDTH);
  const columns = Math.floor((height - 306) / ITEM_HEIGHT);
  const perPage = (rows > 0 ? rows : 1) * (columns > 0 ? columns : 1);

  const {
    data,
    isLoading,
    isFetching,
  } = mxriskApi.useTemplateGroups({
    page,
    perPage,
  });
  const [fetchedFolders, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isEmptyState = !isFetching && !items.length;

  useEffect(() => {
    if (fetchedFolders) {
      setItems(fetchedFolders);
    }
  }, [fetchedFolders]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const refreshItemList = useCallback(() => {
    searchParams.set('page', 1);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onTestTemplateClick = useCallback(() => {
    navigate(routes.templateMXRiskAnalyze.path[0].replace(':templateGroupId', ''));
  }, [navigate]);

  const onOpenTestTemplateMenu = useCallback((e) => {
    e.stopPropagation();

    setTestTemplateMenuElement(e.currentTarget);
  }, []);

  const onCloseTestTemplateMenu = useCallback(() => {
    setTestTemplateMenuElement(null);
  }, []);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const EmptyState = () => (
    <div className="empty-state">
      <OpenedLetterWithSmileIcon />

      <p className="empty-state-title">Test your first template</p>

      <Button
        onClick={onTestTemplateClick}
      >
        Test Template
      </Button>
    </div>
  );

  return (
    <AuthorizedLayout>
      <AddFolderModal
        open={isAddEmailMXRiskGroupVisible}
        onClose={() => setIsAddEmailMXRiskGroupVisible(false)}
        onAdded={refreshItemList}
      />

      <div className="template-mx-risk-wrapper">
        <div>
          <div className="title">
            <p>Template MX Risk</p>

            {!isEmptyState && (
              <div>
                <Button
                  onClick={onTestTemplateClick}
                  className="test-template-button"
                >
                  <div className="test-template-button-content">
                    <p>Test Template</p>

                    <div onClick={onOpenTestTemplateMenu} aria-hidden>
                      <ArrowBottomIcon />
                    </div>
                  </div>
                </Button>

                <Menu
                  open={!!testTemplateMenuElement}
                  onClose={onCloseTestTemplateMenu}
                  anchorEl={testTemplateMenuElement}
                >
                  <MenuItem
                    onClick={() => { setIsAddEmailMXRiskGroupVisible(true); onCloseTestTemplateMenu(); }}
                    style={{ gap: 12 }}
                  >
                    <AddFolderIcon />
                    <p>Add Folder</p>
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>

        {isEmptyState && <EmptyState />}

        {!!items.length && (
          <div className="list-wrapper">
            <div className="monitor-list">
              {items.reduce((acc, item) => {
                if (item.name === 'Default') {
                  return [item, ...acc];
                }

                return [...acc, item];
              }, []).map((item) => (
                <TemplateFolder key={item['@id']} item={item} onDeleted={refreshItemList} />
              ))}
            </div>

            {totalItems > items.length && (
            <Pagination
              className="table-pagination"
              activePage={page}
              perPage={perPage}
              totalItems={totalItems}
              onPageChanged={onPageChanged}
            />
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default TemplateMXRisk;
