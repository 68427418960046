import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { Tooltip, IconButton } from '@mui/material';

import { routes } from 'routes';

import { ArrowRightIcon, CheckIcon, OutlinedPencilIcon, QuestionMarkIcon } from 'assets/icons';

import RadioGroup from 'components/RadioGroup';
import Popover from 'components/Popover';
import Button from 'components/Button';
import Input from 'components/Input';

import AuthorizedLayout from 'layouts/authorized';

import useOutsideClick from 'hooks/useOutsideClick';

import { placementApi } from 'resources/placement';

import { PLACEMENT_TYPE } from 'pages/DeliverabilityTest/helpers/tests';

import uiNotificationService from 'services/uiNotificatuion.service';

import validateEmail from 'helpers/validateEmail';

import ConnectedInboxForm from './components/ConnectedInboxForm';
import SeedListForm from './components/SeedListForm';

import './styles.scss';

const radioItems = [
  {
    label: 'Connected Inbox',
    value: PLACEMENT_TYPE.EMAIL_ACCOUNT,
  },
  {
    label: 'Send to Seed List',
    value: PLACEMENT_TYPE.SEED_LIST,
  },
];

const defaultTestTitle = `Test - ${new Date().toLocaleString(navigator.language)}`;

const DeliverabilityTestStartNew = () => {
  const navigate = useNavigate();

  const editTitleRef = useRef(null);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [questionIconRef, setQuestionIconRef] = useState(null);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [testData, setTestData] = useState({
    title: defaultTestTitle,
    placementType: PLACEMENT_TYPE.EMAIL_ACCOUNT,
    emailAccount: '',
    subject: '',
    scheduleSeconds: undefined,
    body: '',
    fromEmail: '',
    recurring: true,
  });
  const [errors, setErrors] = useState({
    title: '',
    placementType: '',
    emailAccount: '',
    subject: '',
    scheduleSeconds: '',
    body: '',
    fromEmail: '',
    recurring: '',
  });

  const isConnectedInboxForm = testData.placementType === PLACEMENT_TYPE.EMAIL_ACCOUNT;

  const {
    mutate: createPlacementTest,
    isLoading: isCreatePlacementTestLoading,
  } = placementApi.useCreate();

  useOutsideClick(editTitleRef, () => {
    if (!testData.title) {
      return;
    }
    setIsEditTitle(false);
  });

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (testData.placementType === PLACEMENT_TYPE.EMAIL_ACCOUNT) {
      if (!testData.title) {
        newErrors.title = 'This field is required';
      }

      if (!testData.emailAccount) {
        newErrors.emailAccount = 'This field is required';
      }

      if (!testData.subject) {
        newErrors.subject = 'This field is required';
      }

      if (!testData.body) {
        newErrors.body = 'This field is required';
      }

      if (testData.recurring && !testData.scheduleSeconds) {
        newErrors.scheduleSeconds = 'This field is required';
      }
    }

    if (testData.placementType === PLACEMENT_TYPE.SEED_LIST) {
      if (!testData.fromEmail) {
        newErrors.fromEmail = 'This field is required';
      }

      if (testData.fromEmail && !validateEmail(testData.fromEmail)) {
        newErrors.fromEmail = 'Incorrect email format';
      }
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [testData]);

  const onCreateTest = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    createPlacementTest({
      placementType: testData.placementType,
      title: testData.title,
      ...(testData.placementType === PLACEMENT_TYPE.EMAIL_ACCOUNT && {
        emailAccount: testData.emailAccount,
        subject: testData.subject,
        body: testData.body,
        recurring: testData.recurring,
        scheduleSeconds: testData.scheduleSeconds,
      }),
      ...(testData.placementType === PLACEMENT_TYPE.SEED_LIST && {
        fromEmail: testData.fromEmail,
      }),
    }, {
      onSuccess: (data) => {
        uiNotificationService.showSuccess('Test has been created');

        if (testData.placementType === PLACEMENT_TYPE.EMAIL_ACCOUNT) {
          navigate(routes.deliverabilityTest.path);
        }

        if (testData.placementType === PLACEMENT_TYPE.SEED_LIST) {
          navigate({
            pathname: routes.deliverabilityTest.path,
            search: `?emails=${data.inboxes.join(',')}`,
          });
        }
      },
    });
  }, [createPlacementTest, testData, validateForm, navigate]);

  const onChangeData = useCallback((value, name) => {
    setTestData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  }, [errors]);

  const reset = useCallback(() => {
    setTestData((old) => ({
      ...old,
      title: defaultTestTitle,
      emailAccount: '',
      subject: '',
      scheduleSeconds: undefined,
      body: '',
      fromEmail: '',
      recurring: true,
    }));

    setErrors((old) => Object.keys(old).reduce((acc, key) => ({
      ...acc,
      [key]: '',
    }), {}));
  }, []);

  return (
    <AuthorizedLayout>
      <div className="deliverability-test-start-new-wrapper">
        <div>
          <div className="start-new-title">
            {isEditTitle
              ? (
                <div className="title-edit-mode" ref={editTitleRef}>
                  <Input
                    className={cn('title-input', !!errors.title && 'title-input-error')}
                    value={testData.title}
                    onChange={onChangeData}
                    name="title"
                    error={!!errors.title}
                    helperText={errors.title}
                    onKeyDown={(event) => {
                      if (event.code === 'Enter' && testData.title) {
                        setIsEditTitle(false);
                      }
                    }}
                  />

                  <IconButton
                    onClick={() => {
                      if (!testData.title) {
                        return;
                      }

                      setIsEditTitle(false);
                    }}
                    className="title-check-icon-button"
                  >
                    <CheckIcon />
                  </IconButton>
                </div>
              )
              : (
                <div className="title-show-mode">
                  <p>{testData.title}</p>

                  <Tooltip
                    title="Edit"
                    placement="top"
                    className="edit-icon-container"
                    onClick={() => setIsEditTitle(true)}
                  >
                    <OutlinedPencilIcon />
                  </Tooltip>
                </div>
              )}
          </div>

          <div className="breadcrumbs">
            <p onClick={() => navigate(routes.deliverabilityTest.path)} style={{ cursor: 'pointer' }} aria-hidden>
              Email Deliverability
            </p>
            <ArrowRightIcon />
            <p>Deliverability Test</p>
          </div>
        </div>

        <div className="deliverability-test-form">
          <div className="deliverability-test-form-radio-wrapper">
            <RadioGroup
              isRow
              name="placementType"
              items={radioItems}
              value={testData.placementType}
              onChange={(value, name) => {
                onChangeData(value, name);
                reset();
              }}
            />

            <IconButton onClick={() => setIsPopoverVisible(!isPopoverVisible)}>
              <QuestionMarkIcon
                width={22}
                height={22}
                ref={setQuestionIconRef}
              />
            </IconButton>
            {isPopoverVisible && (
              <Popover
                variant="light"
                forRef={questionIconRef}
                title="Select this option to test how email senders like MailChimp
                for email marketing or HubSpot for sales handle your emails.
                By sending to our system-provided addresses,
                you can diagnose deliverability issues and gauge performance.
                Ideal for optimizing both marketing and sales email campaigns."
                isWithoutDescription
                onClose={() => setIsPopoverVisible(false)}
                placement="top"
              />
            )}
          </div>

          <div className="line" />

          {isConnectedInboxForm ? (
            <ConnectedInboxForm
              testData={testData}
              onChangeData={onChangeData}
              errors={errors}
            />
          ) : (
            <SeedListForm
              testData={testData}
              onChangeData={onChangeData}
              errors={errors}
            />
          )}

          <div className="line" />

          <div className="deliverability-test-form-controls">
            <Button disabled={isCreatePlacementTestLoading} onClick={onCreateTest} color="success">Start Test</Button>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default DeliverabilityTestStartNew;
