import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { userApi } from 'resources/user';

import { LockIcon } from 'assets/icons';

import UnauthorizedLayout from 'layouts/unauthorized';

import Button from 'components/Button';
import Input from 'components/Input';

import './styles.scss';

const SetNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [errors, setErrors] = useState({
    password: null,
    repeatPassword: null,
  });

  const {
    mutate: resetPassword,
    isLoading: isResetPasswordLoading,
  } = userApi.useResetPassword();

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!password) {
      newErrors.password = 'This field is required';
    }

    if (password !== repeatPassword) {
      newErrors.repeatPassword = 'The passwords don\'t match';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [password, repeatPassword]);

  const onSetNewPassword = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    resetPassword({ token: location.state.resetPasswordToken, password }, {
      onSuccess: () => {
        navigate(routes.signIn.path);
      },
      onError: (error) => {
        setErrors({
          password: error.data.violations[0].message,
        });
      },
    });
  }, [validateForm, resetPassword, password, location.state.resetPasswordToken, navigate]);

  return (
    <UnauthorizedLayout>
      <div className="set-new-password-wrapper">
        <p>Set New Password</p>

        <div>
          <div>
            <Input
              label="Password"
              value={password}
              onChange={(value) => onChangeInput({ value, setter: setPassword, errorField: 'password' })}
              leftIcon={<LockIcon />}
              type="password"
              error={!!errors.password}
              helperText={errors.password}
              autoComplete="off"
              className="password-input"
            />
            <Input
              label="Password"
              value={repeatPassword}
              onChange={(value) => onChangeInput({ value, setter: setRepeatPassword, errorField: 'repeatPassword' })}
              leftIcon={<LockIcon />}
              type="password"
              error={!!errors.repeatPassword}
              helperText={errors.repeatPassword}
              autoComplete="off"
            />
          </div>

          <Button
            className="reset-password"
            onClick={onSetNewPassword}
            loading={isResetPasswordLoading}
          >
            Set Password
          </Button>
        </div>
      </div>
    </UnauthorizedLayout>
  );
};

export default SetNewPassword;
