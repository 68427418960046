import React, { useCallback, useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import AuthorizedLayout from 'layouts/authorized';

import { userApi } from 'resources/user';

import { LockIcon } from 'assets/icons';

import Input from 'components/Input';
import Button from 'components/Button';

import uiNotificationService from 'services/uiNotificatuion.service';

import setSecondsToWait from 'pages/EmailConfirmation/helpers/setSecondsToWait';

import './styles.scss';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [errors, setErrors] = useState({
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null,
  });

  const {
    mutate: changePassword,
    isLoading: isChangePasswordLoading,
  } = userApi.useChangePassword();
  const {
    data: currentUser,
  } = userApi.useCurrentUser();
  const {
    mutate: sendResetPassordEmail,
    isLoading: isSendResetEmailLoading,
  } = userApi.useSendResetPasswordEmail();

  const { data: resetSecondsToWait } = useQuery(['resetSecondsToWait']);

  const saveSecondsToWait = useCallback(() => {
    if (resetSecondsToWait) {
      localStorage.setItem('resetSecondsToWait', resetSecondsToWait);
    }
  }, [resetSecondsToWait]);

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  useEffect(() => {
    const savedSecondsToWait = localStorage.getItem('resetSecondsToWait');

    if (savedSecondsToWait) {
      setSecondsToWait(savedSecondsToWait, 'resetSecondsToWait');
      localStorage.removeItem('resetSecondsToWait');
    }

    window.addEventListener('beforeunload', saveSecondsToWait);

    return () => {
      window.removeEventListener('beforeunload', saveSecondsToWait);
    };
  }, [saveSecondsToWait]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!oldPassword) {
      newErrors.oldPassword = 'This field is required';
    }

    if (!newPassword) {
      newErrors.newPassword = 'This field is required';
    }

    if (newPassword !== confirmNewPassword) {
      newErrors.confirmNewPassword = 'The passwords don\'t match';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [oldPassword, newPassword, confirmNewPassword]);

  const onChangePassword = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    changePassword({
      currentPassword: oldPassword,
      newPassword,
      confirmNewPassword,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Password was updated');
      },
      onError: (error) => {
        const newErrors = {};

        if (error.status === 403) {
          newErrors.oldPassword = error.data['hydra:description'];
        }

        if (error.status === 422) {
          newErrors.newPassword = error.data.violations[0].message;
        }

        setErrors((oldErrors) => ({
          ...oldErrors,
          ...newErrors,
        }));
      },
    });
  }, [validateForm, changePassword, oldPassword, newPassword, confirmNewPassword]);

  const onSendRestPasswordEmail = useCallback(() => {
    sendResetPassordEmail(currentUser.emailAddress, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Email has been sent');
      },
    });
  }, [sendResetPassordEmail, currentUser.emailAddress]);

  const authProvider = localStorage.getItem('authProvider');

  return (
    <AuthorizedLayout>
      <div className="change-password-wrapper">
        <p className="title">Password Reset</p>

        <div className="form">
          {!authProvider
            ? (
              <>
                <div className="inputs">
                  <Input
                    label="Old Password"
                    value={oldPassword}
                    onChange={(value) => onChangeInput({ value, setter: setOldPassword, errorField: 'oldPassword' })}
                    leftIcon={<LockIcon />}
                    type="password"
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword}
                  />
                  <Input
                    label="New Password"
                    value={newPassword}
                    onChange={(value) => onChangeInput({ value, setter: setNewPassword, errorField: 'newPassword' })}
                    leftIcon={<LockIcon />}
                    type="password"
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                  />
                  <Input
                    label="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(value) => onChangeInput({
                      value,
                      setter: setConfirmNewPassword,
                      errorField: 'confirmNewPassword',
                    })}
                    leftIcon={<LockIcon />}
                    type="password"
                    error={!!errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword}
                  />
                </div>

                <Button
                  onClick={onChangePassword}
                  disabled={Object.values(errors).some((error) => error) || isChangePasswordLoading}
                >
                  Update Settings
                </Button>
              </>
            )
            : (
              <>
                <p className="text">
                  You are logged in using &quot;{authProvider} Single sign-on&quot;.
                  If you wish to set a password for your account,
                  please click the Reset Password button below and follow the instructions.
                </p>

                <Button
                  className="reset-password-button"
                  onClick={onSendRestPasswordEmail}
                  loading={isSendResetEmailLoading}
                  disabled={!!resetSecondsToWait}
                >
                  Reset Password
                  {resetSecondsToWait && ` (${resetSecondsToWait})`}
                </Button>
              </>
            )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default ChangePassword;
