import nextId from 'react-id-generator';

export const addStyle = (styleString) => {
  const style = document.createElement('style');

  const id = nextId();

  style.setAttribute('id', id);
  style.textContent = styleString;
  document.head.append(style);

  return id;
};

export const removeStyle = (id) => {
  document.getElementById(id).remove();
};
