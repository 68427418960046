import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { mxriskApi } from 'resources/mxrisk';

import { routes } from 'routes';

import { FolderIcon, PlusIcon, CheckIcon } from 'assets/icons';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';

import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const SaveTemplateModal = ({
  open,
  onClose,
  analyzeResult,
  defaultName,
}) => {
  const navigate = useNavigate();

  const [name, setName] = useState(defaultName);
  const [isFolderSelectOpened, setIsFolderSelectOpened] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [folder, setFolder] = useState(null);

  const [isAddFolderMode, setIsAddFolderMode] = useState(false);

  const { data: templateGroups, isLoading: isTemplateGroupsLoading } = mxriskApi.useTemplateGroups();
  const { mutate: createTemplateGroup, isLoading: isCreateTemplateGroupLoading } = mxriskApi.useCreateTemplateGroup();
  const { mutate: analyzeTemplate, isLoading: isAnalyzeTemplateLoading } = mxriskApi.useAnalyzeTemplate();

  const templateGroupsSelectItems = templateGroups?.filter((templateGroup) => templateGroup.active)
    .map((emailFor) => ({
      value: emailFor['@id'],
      label: emailFor.name,
    }));

  const [errors, setErrors] = useState({
    name: null,
  });

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [name]);

  const onAddFolder = useCallback(() => {
    createTemplateGroup({
      name: newFolderName,
    }, {
      onSuccess: (data) => {
        setNewFolderName('');
        setIsAddFolderMode(false);
        setFolder(data['@id']);
        setIsFolderSelectOpened(false);
      },
    });
  }, [createTemplateGroup, newFolderName]);

  const onSaveClick = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    analyzeTemplate({
      name,
      folder,
      openTag: analyzeResult.openTag,
      closeTag: analyzeResult.closeTag,
      from: analyzeResult.from,
      subjectTemplate: analyzeResult.subjectTemplate,
      template: analyzeResult.template,
      save: true,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Template has been saved');

        const groupId = +folder.replace('/v2/mx-risk/template-groups/', '');

        if (groupId) {
          navigate(routes.templateMXRiskFolder.path.replace(':templateGroupId', groupId));
        }

        onClose();
      },
    });
  }, [validateForm, analyzeTemplate, name, folder,
    onClose, analyzeResult, navigate]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Save Template"
      className="save-template-modal"
    >
      <div className="save-template-modal-wrapper">
        {isTemplateGroupsLoading && <CircularProgress className="loader" />}

        {!isTemplateGroupsLoading && (
        <div>
          <div className="inputs">
            <Input
              label="Template name"
              value={name}
              onChange={(value) => onChangeInput({ value, setter: setName, errorField: 'name' })}
              error={!!errors.name}
              helperText={errors.name}
            />

            {templateGroupsSelectItems && (
            <Select
              placeholder="Save to folder..."
              value={folder}
              onChange={setFolder}
              open={isFolderSelectOpened}
              onOpen={() => setIsFolderSelectOpened(true)}
              onClose={() => setIsFolderSelectOpened(false)}
              type="filled"
              renderValue={(renderValue) => (
                <div className="select-value">
                  <p>Save to folder</p>
                  <p>
                    {templateGroupsSelectItems.find((selectItem) => selectItem.value === renderValue)?.label}
                  </p>
                </div>
              )}
            >
              {templateGroupsSelectItems.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  className="save-template-select-item"
                >
                  <FolderIcon />

                  <p>{item.label}</p>
                </MenuItem>
              ))}

              <div className="save-template-line" />

              <div className="add-folder-wrapper">
                {isAddFolderMode
                  ? (
                    <div className="add-folder-edit-mode">
                      <Input
                        className="name-input"
                        placeholder="Folder name"
                        value={newFolderName}
                        onChange={setNewFolderName}
                        onKeyDown={(e) => e.stopPropagation()}
                      />

                      <IconButton
                        onClick={onAddFolder}
                        disabled={!newFolderName || isCreateTemplateGroupLoading}
                        className={cn('check-icon-button', !newFolderName && 'check-icon-button-disabled')}
                      >
                        <CheckIcon />
                      </IconButton>
                    </div>
                  )
                  : (
                    <Button
                      className="add-folder-button"
                      iconClassName="button-icon"
                      startIcon={<PlusIcon />}
                      variant="text"
                      onClick={() => setIsAddFolderMode(true)}
                    >
                      Add Folder
                    </Button>
                  )}
              </div>
            </Select>
            )}
          </div>

          <div className="confirm-buttons">
            <Button color="secondaryButton" onClick={onClose}>Cancel</Button>
            <Button onClick={onSaveClick} loading={isAnalyzeTemplateLoading}>Save</Button>
          </div>
        </div>
        )}
      </div>
    </Modal>
  );
};

SaveTemplateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  analyzeResult: PropTypes.shape().isRequired,
  defaultName: PropTypes.string.isRequired,
};

export default React.memo(SaveTemplateModal);
