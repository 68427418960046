import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import { getRawDataByStatus } from 'pages/RealTimeMonitor/components/IPItem';

const Item = ({ item }) => {
  const rawData = getRawDataByStatus(item.status);

  return (
    <div>
      <div className="dashboard-list-item">
        <div className="name-wrapper">
          <div className="dashboard-list-item-icon-wrapper" style={{ background: rawData.backgroundColor }}>
            <FontAwesomeIcon
              icon={faGlobe}
              color={rawData.color}
              width={14}
            />
          </div>

          <p>{item.ipAddress}</p>
        </div>

        <p className="dashboard-list-item-time">{moment(item.scanAt).format('MMM DD, YYYY h:mmA')}</p>

        <div className="dashboard-list-item-chip">
          <Chip
            label={rawData.chip.label}
            variant="outlined"
            color={rawData.chip.color}
          />
        </div>
      </div>

      <Divider className="divider" />
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default React.memo(Item);
