import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { BigFolderIcon, BigFolderColoredIcon } from 'assets/icons';

import TemplateFolderMenu from './components/TemplateFolderMenu';

import './styles.scss';

const TemaplateFolder = ({ item, onDeleted }) => {
  const navigate = useNavigate();

  const itemId = item['@id'].replace('/v2/mx-risk/template-groups/', '');

  const onFolderClick = useCallback(() => {
    navigate(routes.templateMXRiskFolder.path.replace(':templateGroupId', itemId));
  }, [navigate, itemId]);

  return (
    <div
      className="template-folder"
      onClick={onFolderClick}
      aria-hidden
    >
      <div className="folder-name-wrapper">
        <div className="folder-icon-wrapper">
          {item.itemsCount ? <BigFolderColoredIcon /> : <BigFolderIcon style={{ fillOpacity: 0.22 }} />}

          <div className="folder-checks-count">
            {item.itemsCount || 0}
          </div>
        </div>

        <div>
          <p className="folder-name">{item.name}</p>
          {item.lastUpdate && (
            <p className="folder-date">
              Last updated: {moment(item.lastUpdate).format('MMM DD, YYYY')}
            </p>
          )}
        </div>
      </div>

      {item.name !== 'Default' && (
      <div onClick={(e) => { e.stopPropagation(); }} aria-hidden>
        <TemplateFolderMenu item={item} onDeleted={onDeleted} />
      </div>
      )}
    </div>
  );
};

TemaplateFolder.propTypes = {
  item: PropTypes.shape().isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default React.memo(TemaplateFolder);
