import { useQuery, useMutation } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useMonitorNotificationList() {
  const getMonitorNotificationList = () => api.get('/v2/monitor-notifications').then((res) => res['hydra:member']);

  return useQuery(['monitor.notificationList'], getMonitorNotificationList);
}

export function useCreateMonitorNotification() {
  const createMonitorNotification = ({
    sms, emails, slack, pagerDuty, schedule, presetName, shared = true,
  }) => api.post('/v2/monitor-notifications', { sms, emails, slack, pagerDuty, schedule, presetName, shared });

  return useMutation(createMonitorNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('monitor.notificationList');
    },
  });
}

export function useCreateMonitor() {
  const createMonitor = ({
    title, checkType, ipList, notificationId, scheduleSeconds, fromEmail,
  }) => api.post('/v2/monitors', {
    title,
    checkType,
    notificationId: `/v2/monitor-notifications/${notificationId}`,
    status: 'active',
    scheduleSeconds,
    ...(ipList && { ipList }),
    ...(fromEmail && { fromEmail }),
  });

  return useMutation(createMonitor, {
    onSuccess: () => {
      queryClient.invalidateQueries('monitor.list');
    },
  });
}

export function useMonitorList({ type, page, perPage }) {
  const getMonitorList = () => api.get('/v2/monitors', { type, page, itemsPerPage: perPage })
    .then(async (data) => {
      const checksPerPage = 50;

      const monitors = await Promise.all(data['hydra:member'].map(async (monitor) => {
        const checkItems = await api.get(`/v2/monitors/${monitor.id}/monitor-checks`, {
          page: 1,
          itemsPerPage: checksPerPage,
        });

        const otherPageNumbers = Array.from({
          length: Math.ceil(checkItems['hydra:totalItems'] / checksPerPage) - 1,
        }, (_, i) => i + 2);

        const otherChecks = await Promise.all(otherPageNumbers
          .map(async (checkPage) => api.get(`/v2/monitors/${monitor.id}/monitor-checks`, {
            page: checkPage,
            itemsPerPage: checksPerPage,
          })));

        monitor.checkItems = [
          ...checkItems['hydra:member'],
          ...otherChecks.map((check) => check['hydra:member']).flat(1),
        ];

        return monitor;
      }));

      return {
        totalItems: data['hydra:totalItems'],
        items: monitors,
      };
    });

  return useQuery(['monitor.list', { page, perPage, type }], getMonitorList);
}

export function useMonitor(id) {
  const getMonitor = () => api.get(`/v2/monitors/${id}`);

  return useQuery(['monitor', +id], getMonitor);
}

export function useUpdateMonitor() {
  const updateMonitor = ({
    id, title, status, scheduleSeconds, notificationId,
  }) => api.patch(`/v2/monitors/${id}`, {
    ...(title && { title }),
    ...(status && { status }),
    ...(scheduleSeconds && { scheduleSeconds }),
    ...(notificationId && { notificationId: `/v2/monitor-notifications/${notificationId}` }),
  });

  return useMutation(updateMonitor, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('monitor.list');
      queryClient.invalidateQueries({ queryKey: ['monitor', +data.id] });
      queryClient.invalidateQueries('monitor.notificationList');
    },
  });
}

export function useMonitorCheckList({ id, page, perPage }) {
  const getMonitorCheckList = () => api.get(`/v2/monitors/${id}/monitor-checks`, { page, itemsPerPage: perPage })
    .then((data) => ({
      totalItems: data['hydra:totalItems'],
      items: data['hydra:member'],
    }));

  return useQuery(['monitor.checkList', { id, page, perPage }], getMonitorCheckList);
}

export function useMonitorCheckListById(id) {
  const getMonitorCheckListById = () => api.get(`/v2/monitor-checks/${id}`)
    .then((data) => data);

  return useQuery(['monitor.checkListByMonitorId', id], getMonitorCheckListById);
}

export function useUpdateMonitorCheck() {
  const updateMonitorCheck = ({
    id, item, name, monitor, shared,
  }) => api.patch(`/v2/monitor-checks/${id}`, {
    ...(item && { item }),
    ...(name !== undefined && { name }),
    ...(monitor && { monitor }),
    ...(shared && { shared }),
  });

  return useMutation(updateMonitorCheck, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['monitor.checkListByMonitorId', data.id],
      });
    },
  });
}

export function useCreateMonitorCheck() {
  const createMonitorCheck = ({
    item, name, monitorId,
  }) => api.post('/v2/monitor-checks', {
    item,
    ...(name && { name }),
    ...(monitorId && { monitor: `/v2/monitors/${monitorId}` }),
  });

  return useMutation(createMonitorCheck, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['monitor.checkList', { id: +data.monitor.replace('/v2/monitors/', '') }],
      });
    },
  });
}

export function useCreateMonitorChecks() {
  const createMonitorChecks = ({
    items,
    monitorId,
  }) => api.post(`/v2/monitors/${monitorId}/bulk-add-ip`, {
    items,
  });

  return useMutation(createMonitorChecks, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['monitor.checkList', { id: +data.id }],
      });
    },
  });
}

export function useDeleteMonitor() {
  const deleteMonitor = (id) => api.delete(`/v2/monitors/${id}`);

  return useMutation(deleteMonitor, {
    onSuccess: () => {
      queryClient.invalidateQueries('monitor.list');
    },
  });
}

export function useMonitorListByNotification(notificationId) {
  const itemsPerPage = 50;

  const getMonitorList = () => api.get(`/v2/monitor-notifications/${notificationId}/monitors`, { page: 1, itemsPerPage })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get(`/v2/monitor-notifications/${notificationId}`, {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['monitor.listByNotification', notificationId], getMonitorList);
}

export function useDeleteMonitorNotification() {
  const deleteMonitorNotification = (id) => api.delete(`/v2/monitor-notifications/${id}`);

  return useMutation(deleteMonitorNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('monitor.notificationList');
    },
  });
}

export function useUpdateMonitorNotification() {
  const updateMonitorNotification = ({
    id,
    monitor,
    presetName,
    sms,
    emails,
    slack,
    pagerDuty,
    schedule,
  }) => api.patch(`/v2/monitor-notifications/${id}`, {
    ...(monitor && { monitor }),
    ...(presetName && { presetName }),
    ...(sms && { sms }),
    ...(emails && { emails }),
    ...(slack && { slack }),
    ...(pagerDuty && { pagerDuty }),
    ...(schedule && { schedule }),
  });

  return useMutation(updateMonitorNotification, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['monitor.notification', data.id],
      });
    },
  });
}

export function useDeleteMonitorCheck() {
  const deleteMonitorCheck = ({ id, monitorId }) => api.delete(`/v2/monitor-checks/${id}`)
    .then(() => ({ id, monitorId }));

  return useMutation(deleteMonitorCheck, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['monitor.checkList', { id: +data.monitorId }],
      });
    },
  });
}

export function useMonitorCheckHistoryList({
  checkId,
  checkAfter,
  page,
  perPage,
  getAll,
}) {
  const itemsPerPage = 50;

  const getMonitorCheckHistoryList = () => api.get(`/v2/monitor-checks/${checkId}/history`, {
    page,
    itemsPerPage: perPage,
    ...(checkAfter && { 'checkAt[after]': checkAfter }),
    'order[checkAt]': 'desc',
  })
    .then((data) => ({
      items: data['hydra:member'],
      totalItems: data['hydra:totalItems'],
    }));

  const getAllMonitorCheckHistoryList = () => api.get(`/v2/monitor-checks/${checkId}/history`, {
    page: 1,
    itemsPerPage,
    ...(checkAfter && { 'checkAt[after]': checkAfter }),
    'order[checkAt]': 'desc',
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (pageNumber) => api.get(`/v2/monitor-checks/${checkId}/history`, {
          page: pageNumber,
          itemsPerPage,
          ...(checkAfter && { 'checkAt[after]': checkAfter }),
          'order[checkAt]': 'desc',
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['monitor.checkHistoryList', checkId, page, perPage, getAll], getAll
    ? getAllMonitorCheckHistoryList
    : getMonitorCheckHistoryList, {
    enabled: !!checkId,
  });
}

export function useMonitorCheckScanNow() {
  const runScanNow = (id) => api.post(`/v2/run-monitor-check/${id}`);

  return useMutation(runScanNow);
}

export function useCheckHistoryItems(id) {
  const getCheckHistoryItems = () => api.get(`/v2/check-history-items/${id}`);

  return useQuery(['monitor.checkHistoryItems', id], getCheckHistoryItems, {
    enabled: !!id,
  });
}

export function useUpdateCheckHistoryItem() {
  const updateCheckHistoryItem = ({ id, shared }) => (id ? api.patch(`/v2/check-history-items/${id}`, {
    shared,
  }) : null);

  return useMutation(updateCheckHistoryItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['monitor.checkHistoryItems', data.id],
      });
    },
  });
}

export function useGetMonitorNotification(id) {
  const getMonitorNotification = () => (id ? api.get(`/v2/monitor-notifications/${id}`) : null);

  return useQuery(['monitor.notification', +id], getMonitorNotification);
}
