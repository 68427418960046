import { useCallback, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import AuthorizedLayout from 'layouts/authorized';

import { routes } from 'routes';

import {
  ArrowRightIcon, ErrorWebIcon, PrinterIcon,
  PlusIcon, CheckIcon, OutlinedPencilIcon,
} from 'assets/icons';

import { mxriskApi } from 'resources/mxrisk';

import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Button from 'components/Button';
import Input from 'components/Input';
import Pagination from 'components/Pagination';

import useWindowDimensions from 'hooks/useWindowDimensions';
import useOutsideClick from 'hooks/useOutsideClick';

import uiNotificationService from 'services/uiNotificatuion.service';

import HistoryItem from './components/HistoryItem';

import './styles.scss';

const ROW_HEIGHT = 72;

const TemplateMXRiskFolder = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  const editNameRef = useRef(null);

  // 420 - other elements height on page
  const perPageValue = Math.floor((height - 420) / ROW_HEIGHT);
  const perPage = perPageValue > 0 ? perPageValue : 1;
  const mobileWidth = width <= 1200;

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);

  const [name, setName] = useState(null);
  const [isEditName, setIsEditName] = useState(false);

  const [emailGroupItemsIntervalId, setEmailGroupItemsIntervalId] = useState(null);

  const {
    data: templateGroup,
    isLoading: isTemplateGroupLoading,
    error,
  } = mxriskApi.useTemplateGroup(+params.templateGroupId);
  const {
    data,
    isLoading: isEmailGroupItemsLoading,
    isFetching: isEmailGroupItemsFetching,
    refetch: refetchEmailGroupItems,
  } = mxriskApi.useEmailGroupItems({ id: +params.templateGroupId, page, perPage });
  const { mutate: updateTemplateGroup } = mxriskApi.useUpdateTemplateGroup();

  const [fetchedChecks, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isLoading = isTemplateGroupLoading || isEmailGroupItemsLoading;
  const isNotFound = error?.status === 404;

  const isAnyItemInProcess = items.some((item) => item.status === 'in_process');

  useOutsideClick(editNameRef, () => setIsEditName(false));

  useEffect(() => {
    if (fetchedChecks) {
      setItems(fetchedChecks);
    }
  }, [fetchedChecks]);

  useEffect(() => {
    if (templateGroup && name === null) {
      setName(templateGroup.name);
    }
  }, [templateGroup, name]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  useEffect(() => {
    if (isAnyItemInProcess && !emailGroupItemsIntervalId
      && !(isEmailGroupItemsFetching || isEmailGroupItemsLoading)) {
      const intervalId = setInterval(() => {
        refetchEmailGroupItems();
      }, 5000);
      setEmailGroupItemsIntervalId(intervalId);
    }

    if (!isAnyItemInProcess && emailGroupItemsIntervalId) {
      clearInterval(emailGroupItemsIntervalId);
      setEmailGroupItemsIntervalId(null);
    }
  }, [emailGroupItemsIntervalId, refetchEmailGroupItems, isEmailGroupItemsFetching,
    isEmailGroupItemsLoading, isAnyItemInProcess]);

  const onTestTemplateClick = useCallback(() => {
    navigate(routes.templateMXRiskAnalyze.path[1].replace(':templateGroupId', params.templateGroupId));
  }, [navigate, params.templateGroupId]);

  const onEditName = useCallback(() => {
    if (!name) {
      return;
    }

    updateTemplateGroup({
      id: +params.templateGroupId,
      name,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Name has been updated');

        setIsEditName(false);
      },
    });
  }, [updateTemplateGroup, name, params.templateGroupId]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const historyItems = items.map((item) => (
    <HistoryItem
      key={item.id}
      item={item}
    />
  ));

  return (
    <AuthorizedLayout>
      <div className="template-mx-risk-folder-wrapper">
        <div>
          <div className="title-wrapper">
            <div className="title">
              {isEditName
                ? (
                  <div className="title-edit-mode" ref={editNameRef}>
                    <Input
                      className="name-input"
                      placeholder="Name"
                      value={name}
                      onChange={setName}
                      onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                          onEditName();
                        }
                      }}
                    />

                    <IconButton
                      onClick={onEditName}
                      disabled={!name}
                      className={cn('check-icon-button', !name && 'check-icon-button-disabled')}
                    >
                      <CheckIcon />
                    </IconButton>
                  </div>
                )
                : (
                  <div className="title-show-mode">
                    <p>{isNotFound ? 'Email Group Not Found' : templateGroup?.name}</p>

                    {!isLoading && templateGroup?.name !== 'Default' && (
                    <Tooltip
                      onClick={() => setIsEditName(true)}
                      title="Edit"
                      placement="top"
                      className="edit-icon-container"
                    >
                      <OutlinedPencilIcon />
                    </Tooltip>
                    )}
                  </div>
                )}
            </div>
            {!isNotFound && (
            <Button
              onClick={onTestTemplateClick}
              className="test-template-button"
              iconClassName="button-icon"
              startIcon={<PlusIcon />}
            >
              {width >= 500 && 'Test Template'}
            </Button>
            )}
          </div>

          <div className="breadcrumbs">
            <p>Template MX Risk</p>
            <ArrowRightIcon />
            <p>{isNotFound ? 'Email Group Not Found' : name}</p>
            <ArrowRightIcon />
            <p>History</p>
          </div>
        </div>

        {!isLoading && !!items.length && (
          <>
            {!mobileWidth && (
            <div className="list">
              {isEmailGroupItemsFetching && <CircularProgress className="table-loader" />}

              <div className="table-head">
                <p className="name-head">Template Name</p>
                <p className="mx-risk-head">MX Risk</p>
                <p className="readability-head">Readability</p>
                <p className="sentiment-assasin-head">Sentiment</p>
                <p className="check-date-head">Check Date</p>
              </div>

              <div className="table-head-line" />

              {historyItems}

              {totalItems > items.length && (
              <Pagination
                className="table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
              )}
            </div>
            )}

            {mobileWidth && (
            <div className="mobile-list">
              {historyItems}

              {totalItems > items.length && (
              <Pagination
                className="mobile-table-pagination"
                activePage={page}
                perPage={perPage}
                totalItems={totalItems}
                onPageChanged={onPageChanged}
              />
              )}
            </div>
            )}
          </>
        )}

        {!isLoading && (!fetchedChecks.length && page === 1) && (
          <div className="empty-state">
            {error ? <ErrorWebIcon /> : <PrinterIcon /> }

            <p className="title">{error ? 'Template Group Not Found' : 'There are no MX Risk Scans in this folder'}</p>

            {!error && (
            <p className="description">
              Create an email template to initiate Template MX Risk Scan
            </p>
            )}
          </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default TemplateMXRiskFolder;
