import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import cn from 'classnames';

import { routes } from 'routes';

import { SmallClockIcon } from 'assets/icons';

import { infrastructureMonitorApi } from 'resources/infrastructureMonitor';

import MenuItem from '@mui/material/MenuItem';

import Select from 'components/Select';
import ScoreCircle from 'components/ScoreCircle';
import ReportHistoryModal from 'components/ReportHistoryModal';

import './styles.scss';

const PER_PAGE = 10;

const ReportHistorySelect = ({ className, show, resultAt }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [value, setValue] = useState(params.email || null);
  const [open, setOpen] = useState(false);
  const [isReportHistoryModalVisible, setIsReportHistoryModalVisible] = useState(false);

  const [page, setPage] = useState(1);

  const { data: monitorRunsList } = infrastructureMonitorApi.useInfrastructureMonitorRunsList({
    id: params.monitorId,
    page,
    perPage: PER_PAGE,
  });

  const onOpenReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(true);
    setOpen(false);
  }, []);

  const onCloseReportHistoryModal = useCallback(() => {
    setIsReportHistoryModalVisible(false);
  }, []);

  const selectItems = monitorRunsList ? monitorRunsList.items.map((item) => ({
    value: item.toEmail,
    label: moment(item.createdAt).format('MMM DD, YYYY [at] h:mmA'),
    ...item,
  })) : [];

  const onReportHistoryItemClick = useCallback((email) => {
    setValue(email);

    navigate(routes.monitorResult.path
      .replace(':monitorId', params.monitorId)
      .replace(':email', email));
  }, [navigate, params.monitorId]);

  if (!show) {
    return null;
  }

  return (
    <>
      {!!selectItems.length && isReportHistoryModalVisible && (
      <ReportHistoryModal
        open={isReportHistoryModalVisible}
        onClose={onCloseReportHistoryModal}
        forName="Monitor"
        items={selectItems}
        page={page}
        perPage={PER_PAGE}
        totalItems={monitorRunsList?.totalItems}
        onPageChanged={(newPage) => setPage(newPage)}
        onItemClick={(item) => onReportHistoryItemClick(item.value)}
      />
      )}
      {value && (
      <Select
        value={value}
        open={open}
        className={cn('report-history-select', className)}
        onChange={onReportHistoryItemClick}
        onClick={() => setOpen(!open)}
        items={selectItems}
        renderValue={() => {
          const date = new Date(resultAt);

          return (
            <div className="report-history-select-value">
              <SmallClockIcon />
              <p>{date?.toLocaleString(navigator.language)}
              </p>
            </div>
          );
        }}
      >
        {selectItems.slice(0, 10).map((item) => (
          <MenuItem key={item['@id']} value={item.value} className="report-history-select-menu-item">
            <ScoreCircle
              mxrScoreTotal={10}
              mxrScoreTotalPass={item.score}
              ratingColor={item.ratingColor}
              ratingLabel={item.ratingLabel || 'U'}
              onlyCircle
              size="tiny"
            />

            <p className="label">{item.label}</p>
          </MenuItem>
        ))}
        {monitorRunsList?.totalItems > 10 && (
        <div>
          <div className="report-history-select-line" />
          <p className="report-history-select-see-more" onClick={onOpenReportHistoryModal} aria-hidden>
            See more
          </p>
        </div>
        )}
      </Select>
      )}
    </>
  );
};

ReportHistorySelect.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  resultAt: PropTypes.string.isRequired,
};

ReportHistorySelect.defaultProps = {
  className: null,
};

export default React.memo(ReportHistorySelect);
