import {
  FaceGoodIcon, FaceNormalIcon, FaceBadIcon,
} from 'assets/icons';

import Mailchimp from 'assets/illustrations/mailchimp.png';
import ConstantContact from 'assets/illustrations/constant-contact.png';
import SendinBlue from 'assets/illustrations/sendin-blue.png';
import GetResponse from 'assets/illustrations/get-response.png';
import ActiveCampaign from 'assets/illustrations/active-campaign.png';
import AWeber from 'assets/illustrations/aweber.png';
import MailerLite from 'assets/illustrations/mailer-lite.png';
import CampaignMonitor from 'assets/illustrations/campaign-monitor.png';
import Moosend from 'assets/illustrations/moosend.png';
import BenchmarkEmail from 'assets/illustrations/benchmark-email.png';
import ConvertKit from 'assets/illustrations/convert-kit.png';
import SendGrid from 'assets/illustrations/sendgrid.png';
import Drip from 'assets/illustrations/drip.png';
import HubSpot from 'assets/illustrations/hub-spot.png';
import Klaviyo from 'assets/illustrations/klaviyo.png';
import Omnisend from 'assets/illustrations/omnisend.png';
import Mailjet from 'assets/illustrations/mailjet.png';
import Keap from 'assets/illustrations/keap.png';
import Iterable from 'assets/illustrations/iterable.png';
import SendPulse from 'assets/illustrations/send-pulse.png';

export const getSpeedometerLabelByScore = (score) => {
  if (score >= 75) {
    return {
      label: 'Very High',
      color: '#F02B4F',
    };
  }

  if (score >= 60) {
    return {
      label: 'High',
      color: '#F02B4F',
    };
  }

  if (score >= 24) {
    return {
      label: 'Medium',
      color: '#F2C94C',
    };
  }

  if (score >= 15) {
    return {
      label: 'Low',
      color: '#17EDC3',
    };
  }

  if (score >= 0) {
    return {
      label: 'Very Low',
      color: '#17EDC3',
    };
  }
};

export const getRuleDataBySentiment = (rule) => {
  const { sentiment } = rule || {};

  if (sentiment === 'Positive') {
    return {
      icon: <FaceGoodIcon />,
      color: '#17EDC3',
    };
  }

  if (sentiment === 'Negative') {
    return {
      icon: <FaceBadIcon />,
      color: '#F02B4F',
    };
  }

  return {
    icon: <FaceNormalIcon />,
    color: '#FFA726',
  };
};

export const getImageBySender = (sender) => {
  switch (sender) {
    case 'mailchimp':
      return Mailchimp;

    case 'constant_contact':
      return ConstantContact;

    case 'send_in_blue':
      return SendinBlue;

    case 'get_response':
      return GetResponse;

    case 'active_campaign':
      return ActiveCampaign;

    case 'a_weber':
      return AWeber;

    case 'mailer_lite':
      return MailerLite;

    case 'campaign_monitor':
      return CampaignMonitor;

    case 'moo_send':
      return Moosend;

    case 'benchmark_email':
      return BenchmarkEmail;

    case 'convert_kit':
      return ConvertKit;

    case 'send_grid':
      return SendGrid;

    case 'drip':
      return Drip;

    case 'hub_spot':
      return HubSpot;

    case 'klaviyo':
      return Klaviyo;

    case 'omnisend':
      return Omnisend;

    case 'mail_jet':
      return Mailjet;

    case 'keap':
      return Keap;

    case 'iterable':
      return Iterable;

    case 'send_pulse':
      return SendPulse;

    default:
      return null;
  }
};
