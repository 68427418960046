import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navbar as LayoutNavbar, ScrollArea, Box, Stack } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { userApi } from 'resources/user';
import { mxriskApi } from 'resources/mxrisk';

import {
  ScanIcon, MonitorIcon, ProfileIcon, HTMLTagIcon, LockSidebarIcon,
  PaymentCardIcon, PeopleGroupIcon, StackIcon, ArrowBackIcon,
  EmailPresetIcon, DashboardIcon, LetterPlaneIcon,
} from 'assets/icons';

import { USER_ROLES } from 'resources/user/user.constants';

import { LinksGroup } from './components/NavbarLinksGroup';

import { useStyles } from './styles';

export const TEMPLATE_MX_RISK_LABEL = 'Template MX Risk';

const findLabelByPathAndLevel = (path, items, level) => {
  const traverseItems = (currentItems, currentLevel, targetLevel, targetPath) => currentItems.reduce((acc, item) => {
    if (acc) return acc; // If label found, return immediately

    const isPathMatch = item.link === targetPath
    || (item.subLinks && item.subLinks.some((subPath) => targetPath.startsWith(subPath)));

    if (isPathMatch && currentLevel === targetLevel) {
      return item.label;
    }

    if (item.links) {
      const result = traverseItems(item.links, currentLevel + 1, targetLevel, targetPath);

      if (result) {
        return currentLevel === targetLevel - 1 ? item.label : result;
      }
    }

    return null;
  }, null);

  return traverseItems(items, 0, level, path);
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();

  const {
    data: templateGroupsData,
    isLoading: isTemplateGroupsLoading,
  } = mxriskApi.useTemplateGroups({
    page: 1,
    perPage: 100,
    pagination: false,
  });

  const { data: connectedAccounts } = userApi.useConnectedAccounts();
  const activeAccountId = +sessionStorage.getItem('activeAccountId');
  const activeAccount = activeAccountId && connectedAccounts
    ?.find((connectedAccount) => connectedAccount.account.id === activeAccountId);

  const isOwner = !activeAccountId;
  const isAdmin = activeAccount?.roles?.includes(USER_ROLES.ADMIN);

  const SIDEBAR_ITEMS = useMemo(() => [
    {
      label: 'Dashboard',
      icon: DashboardIcon,
      link: '/',
    },
    {
      label: 'Content',
      icon: ScanIcon,
      links: [
        {
          label: 'Email MX Risk',
          link: '/email-mx-risk',
          subLinks: ['/email-mx-risk/'],
        },
        {
          label: TEMPLATE_MX_RISK_LABEL,
          links: templateGroupsData?.items.map((item) => {
            const id = +item['@id'].replace('/v2/mx-risk/template-groups/', '');

            return {
              label: item.name,
              link: `/template-mx-risk/${id}`,

              templateMXRiskFolder: {
                item: {
                  id,
                  ...item,
                },
              },
            };
          }) || [],
          subLinks: ['/template-mx-risk/'],
        },
      ],
    },
    {
      label: 'Infrastructure Health',
      icon: MonitorIcon,
      links: [
        {
          label: 'IP Monitor',
          link: '/ip-monitor',
          subLinks: ['/real-time-monitor'],
        },
        {
          label: 'Email Infrastructure',
          link: '/email-infrastructure',
        },
      ],
    },
    {
      label: 'Deliverability',
      icon: LetterPlaneIcon,
      links: [
        {
          label: 'Deliverability Test',
          link: '/deliverability-test',
          subLinks: ['/deliverability-test/'],
        },
        {
          label: 'Email Accounts',
          link: '/email-accounts',
        },
      ],
    },
  ], [templateGroupsData]);

  const ACCOUNT_SETTINGS_SIDEBAR_ITEMS = useMemo(() => [
    {
      label: 'My Profile',
      icon: ProfileIcon,
      link: '/profile',
    },
    {
      label: 'API Keys',
      icon: HTMLTagIcon,
      link: '/api-keys',
    },
    {
      label: 'Password Reset',
      icon: LockSidebarIcon,
      link: '/change-password',
    },
    (isOwner || isAdmin) && {
      label: 'Users',
      icon: PeopleGroupIcon,
      link: '/users',
    },
    {
      label: 'Notification presets',
      icon: EmailPresetIcon,
      link: '/notification-presets',
    },
    (isOwner || isAdmin) && {
      label: 'Subscription & Usage',
      icon: StackIcon,
      link: '/subscription-and-usage',
      subLinks: ['/plans'],
    },
    isOwner && {
      label: 'Billing',
      icon: PaymentCardIcon,
      link: '/billing',
    },
  ].filter((item) => !!item), [isOwner, isAdmin]);

  const [openedGroup, setOpenedGroup] = useState(
    findLabelByPathAndLevel(location.pathname, [...SIDEBAR_ITEMS, ...ACCOUNT_SETTINGS_SIDEBAR_ITEMS], 1),
  );
  const [openedSubLink, setOpenedSubLink] = useState(
    findLabelByPathAndLevel(location.pathname, [...SIDEBAR_ITEMS, ...ACCOUNT_SETTINGS_SIDEBAR_ITEMS], 2),
  );

  useEffect(() => {
    if (!isTemplateGroupsLoading && templateGroupsData?.items?.length) {
      setOpenedSubLink(findLabelByPathAndLevel(location.pathname, [...SIDEBAR_ITEMS, ...ACCOUNT_SETTINGS_SIDEBAR_ITEMS], 2));
    }
  }, [location.pathname, SIDEBAR_ITEMS, ACCOUNT_SETTINGS_SIDEBAR_ITEMS, isTemplateGroupsLoading, templateGroupsData]);

  const isAccountSettingsSideBar = useMemo(
    () => !!ACCOUNT_SETTINGS_SIDEBAR_ITEMS
      .find((item) => item.link === location.pathname
        || item.subLinks?.find((subPath) => location.pathname.includes(subPath))),
    [ACCOUNT_SETTINGS_SIDEBAR_ITEMS, location.pathname],
  );

  const links = useMemo(() => SIDEBAR_ITEMS.map((item) => (
    <LinksGroup
      {...item}
      key={item.label}
      opened={item.label === openedGroup}
      onClick={() => setOpenedGroup((prev) => (prev === item.label ? null : item.label))}
      openedSubLink={openedSubLink}
      setOpenedSubLink={setOpenedSubLink}
    />
  )), [openedGroup, SIDEBAR_ITEMS, openedSubLink]);

  const accountSettingsLinks = useMemo(() => ACCOUNT_SETTINGS_SIDEBAR_ITEMS.map((item) => (
    <LinksGroup
      {...item}
      key={item.label}
      opened={item.label === openedGroup}
      onClick={() => setOpenedGroup((prev) => (prev === item.label ? null : item.label))}
      openedSubLink={openedSubLink}
      setOpenedSubLink={setOpenedSubLink}
    />
  )), [ACCOUNT_SETTINGS_SIDEBAR_ITEMS, openedGroup, openedSubLink]);

  const sidebarItems = useMemo(
    () => (isAccountSettingsSideBar ? accountSettingsLinks : links),
    [isAccountSettingsSideBar, links, accountSettingsLinks],
  );

  const onNavigateToHomePage = useCallback(() => {
    navigate(routes.dashboard.path);
  }, [navigate]);

  return (
    <LayoutNavbar className={classes.navbar}>
      {isAccountSettingsSideBar && (
        <Stack spacing={22} className={classes.backWrapper}>
          <Box className={classes.backContent} onClick={onNavigateToHomePage}>
            <ArrowBackIcon />
            <p>Dashboard</p>
          </Box>

          <Box className="line" />
        </Stack>
      )}

      <LayoutNavbar.Section grow className={classes.links} component={ScrollArea}>
        <Box className={classes.linksInner}>{sidebarItems}</Box>
      </LayoutNavbar.Section>
    </LayoutNavbar>
  );
};

export default React.memo(Sidebar);
