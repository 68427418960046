import { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCalendarClock, faEnvelopeDot, faRotateRight } from '@fortawesome/pro-light-svg-icons';

import MXRiskSpeedometer from 'components/MXRiskSpeedometer';
import Modal from 'components/Modal';
import Select from 'components/Select';

import './styles.scss';

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      stacked: true,
    }],
    yAxes: [{
      stacked: false,
      ticks: {
        callback(value) {
          return `${value}%`;
        },
      },
    }],
  },
};

const labels = new Array(30).fill('Aug1');

const max = 100;
const min = -100;

const data = {
  labels,
  datasets: [
    {
      label: 'Inbox',
      data: labels.map(() => Math.random() * (max - min) + min),
      backgroundColor: '#17EDC3',
    },
    {
      label: 'Promo',
      data: labels.map(() => Math.random() * (max - min) + min),
      backgroundColor: '#FFA726',
    },
    {
      label: 'Spam',
      data: labels.map(() => Math.random() * (max - min) + min),
      backgroundColor: '#EF5350',
    },
    {
      label: 'Not Delivered',
      data: labels.map(() => Math.random() * (max - min) + min),
      backgroundColor: '#78909C',
    },
  ],
};

const stats = [
  {
    text: 'Scans',
    icon: faRotateRight,
    value: 12,
    additionalText: 'Tests',
  },
  {
    text: 'Active For',
    icon: faCalendarClock,
    value: 65,
    additionalText: 'days',
  },
  {
    text: 'Messages',
    icon: faEnvelopeDot,
    value: 455,
    additionalText: '',
  },
];

const selectItems = [
  {
    label: 'Last 30 days',
    value: 30,
  },
];

const InboxReportHistoryModal = ({
  open,
  onClose,
}) => {
  const [periodSelectValue, setPeriodSelectValue] = useState(selectItems[0].value);

  const onChangePeriod = useCallback(() => {
    setPeriodSelectValue(selectItems[0].value);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Historical Data for name@example.com"
    >
      <div className="inbox-report-history-modal-wrapper">
        <div className="last-test-date-block">
          <FontAwesomeIcon fontSize={16} icon={faCircleCheck} color="#17EDC3" />
          <p>Last Test: 7/21/2020 at 3:19:11 PM</p>
        </div>

        <div className="line" />

        <div className="inbox-report-statistic">
          <div className="inbox-report-statistic-score">
            <MXRiskSpeedometer
              isRounded
              isOnlyArc
              providedGradients={['#17EDC3', '#0091FF']}
              size="giant"
              score={95}
            />

            <div className="inbox-report-statistic-score-info">
              <p className="inbox-report-statistic-score-info-percents">95%</p>
              <p className="inbox-report-statistic-score-info-text">MX Delivery Score</p>
            </div>
          </div>

          {stats.map((item) => (
            <div className="inbox-report-statistic-item">
              <FontAwesomeIcon fontSize={24} icon={item.icon} color="#0091FF" />
              <div>
                <p className="inbox-report-statistic-item-name">{item.text}</p>
                <p className="inbox-report-statistic-item-value">{item.value} {item.additionalText}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="line" />

        <div className="inbox-report-diagram">
          <div className="inbox-report-diagram-header">
            <p>Deliverability Report</p>

            <Select
              value={periodSelectValue}
              onChange={onChangePeriod}
              items={selectItems}
              type="filled"
              renderValue={(renderValue) => (
                <p className="select-value">
                  {selectItems
                    .find((selectItem) => selectItem.value === renderValue).label}
                </p>
              )}
            />
          </div>

          <div className="inbox-report-diagram-content">
            <Bar className="inbox-report-diagram-chart" options={options} data={data} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

InboxReportHistoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(InboxReportHistoryModal);
