import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FilesIcon } from 'assets/icons';

import { placementApi } from 'resources/placement';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const SharePlacementTestModal = ({
  placementTest,
  onClose,
}) => {
  const {
    mutate: updatePlacementTest,
    isLoading: isUpdatePlacementTestLoading,
  } = placementApi.useUpdate();

  const placementTestId = +placementTest['@id'].replace('/v2/placement-tests/', '');
  const linkToShare = window.location.href;

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(linkToShare);

    uiNotificationService.showSuccess('Copied to Clipboard');
  }, [linkToShare]);

  return (
    <Modal
      open
      onClose={onClose}
      title="Share report"
    >
      <div className="share-report-modal-wrapper">
        <FormControlLabel
          className="switch-container"
          control={(
            <Switch
              checked={placementTest.shared}
              onChange={(e) => {
                updatePlacementTest({
                  id: placementTestId,
                  shared: e.target.checked,
                });
              }}
            />
          )}
          label="Shared"
          disabled={isUpdatePlacementTestLoading}
        />

        <div className="link-to-share-wrapper">
          <p>Share Link</p>

          <div className="copy-button-wrapper">
            <Input className="link-input" value={linkToShare} />

            <Button
              className="copy-button"
              startIcon={<FilesIcon />}
              iconClassName="copy-icon"
              onClick={onCopyToClipboard}
            >
              Copy Link
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SharePlacementTestModal.propTypes = {
  placementTest: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(SharePlacementTestModal);
