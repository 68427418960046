import React, { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { GreenStarIcon } from 'assets/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faChevronRight, faGear } from '@fortawesome/pro-regular-svg-icons';
import { faBell, faCheck, faCode, faCreditCard, faLayerGroup, faUsers } from '@fortawesome/pro-light-svg-icons';

import queryClient from 'query-client';

import { routes } from 'routes';

import { userApi } from 'resources/user';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { getUserInitials } from 'pages/Profile';

import generateLinearGradientitem from 'helpers/getRandomGradient';

import './styles.scss';

const getLetters = (inputString) => {
  const words = inputString.split(' ');

  if (words.length === 1) {
    if (words[0].length >= 2) {
      return words[0].substring(0, 2);
    }

    return words[0];
  }

  if (words.length >= 2) {
    return words[0][0] + words[1][0];
  }

  return '';
};

const AuthMenu = ({
  withName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);

  const { data: currentUser } = userApi.useCurrentUser();
  const {
    data: avatarData,
  } = userApi.useGetCurrentUserAvatar(currentUser?.avatar);
  const { data: accountsData, refetch: refetchConnectedAccounts } = userApi.useConnectedAccounts();

  const activeAccountId = +sessionStorage.getItem('activeAccountId');

  const activeAccountData = accountsData?.find((accountData) => accountData.account.id === activeAccountId);

  const { mutate: logout } = userApi.useLogout();

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);

    refetchConnectedAccounts();
  }, [refetchConnectedAccounts]);

  const onLogout = useCallback(() => {
    logout({}, {
      onSuccess: () => {
        navigate(routes.signIn.path);
      },
    });
  }, [logout, navigate]);

  const onNavigateToAccountSettings = useCallback(() => {
    navigate(routes.profile.path);
  }, [navigate]);

  const onAccountClick = useCallback((accountData) => {
    const accountId = accountData.account.id;
    const protectedPaths = [routes.subscriptionAndUsage.path, routes.users.path, routes.billing.path];

    localStorage.setItem('lastAccountId', accountId);
    sessionStorage.setItem('activeAccountId', accountId);

    queryClient.invalidateQueries();

    if (protectedPaths.includes(location.pathname)) {
      navigate(routes.dashboard.path);
    }

    closeMenu();
  }, [closeMenu, location.pathname, navigate]);

  const onMyAccountClick = useCallback(() => {
    localStorage.removeItem('lastAccountId');
    sessionStorage.removeItem('activeAccountId');

    queryClient.invalidateQueries();

    closeMenu();
  }, [closeMenu]);

  return (
    <div className="auth-menu-wrapper">
      {withName && <div className="line" />}

      {withName
        ? (
          <div className="auth-menu" onClick={openMenu} aria-hidden>
            <IconButton sx={{ padding: 0 }}>
              {avatarData?.image
                ? <img src={avatarData?.image} alt="Avatar" className="avatar-image" /> : (
                  <div className="default-avatar">
                    <p>
                      {getUserInitials({
                        firstName: currentUser?.firstName,
                        lastName: currentUser?.lastName,
                        email: currentUser?.emailAddress,
                      })}
                    </p>
                  </div>
                )}
            </IconButton>

            <div className="menu-name-wrapper">
              <p className="full-name noselect">{(currentUser?.firstName || currentUser?.lastName)
                ? [currentUser.firstName, currentUser.lastName].filter(Boolean).join(' ')
                : currentUser?.emailAddress}
              </p>
              {activeAccountData && (
              <p className="active-account noselect">
                {activeAccountData.account.companyName}
              </p>
              )}
            </div>
          </div>
        )
        : (
          <IconButton onClick={openMenu}>
            {avatarData?.image
              ? <img src={avatarData?.image} alt="Avatar" className="avatar-image" /> : (
                <div className="default-avatar">
                  <p>
                    {getUserInitials({
                      firstName: currentUser?.firstName,
                      lastName: currentUser?.lastName,
                      email: currentUser?.emailAddress,
                    })}
                  </p>
                </div>
              )}
          </IconButton>
        )}

      <Menu
        open={!!anchorEl}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className="profile-menu-user-data">
          <IconButton sx={{ padding: 0 }}>
            {avatarData?.image
              ? <img src={avatarData?.image} alt="Avatar" className="avatar-image" /> : (
                <div className="default-avatar">
                  <p>
                    {getUserInitials({
                      firstName: currentUser?.firstName,
                      lastName: currentUser?.lastName,
                      email: currentUser?.emailAddress,
                    })}
                  </p>
                </div>
              )}
          </IconButton>

          <div className="menu-name-wrapper">
            <p className="full-name">{(currentUser?.firstName || currentUser?.lastName)
              ? [currentUser.firstName, currentUser.lastName].filter(Boolean).join(' ')
              : currentUser?.emailAddress}
            </p>
            {activeAccountData && (
              <p className="active-account">
                {activeAccountData.account.companyName}
              </p>
            )}
          </div>
        </div>

        <MenuItem onClick={onNavigateToAccountSettings}>
          <div className="profile-menu-item">
            <FontAwesomeIcon icon={faUser} width={20} height={20} />
            <p>My Profile</p>
          </div>
        </MenuItem>

        <MenuItem className={cn(!!settingsAnchorEl && 'profile-menu-item-active')}>
          <div
            className={cn('profile-menu-item', 'profile-menu-item-nested')}
            onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
            aria-hidden
          >
            <div className="profile-menu-item-content">
              <FontAwesomeIcon icon={faGear} width={20} height={20} />
              <p>Settings</p>
            </div>

            <FontAwesomeIcon icon={faChevronRight} fontSize={12} />
          </div>

          <Menu
            open={!!settingsAnchorEl}
            onClose={() => setSettingsAnchorEl(null)}
            anchorEl={settingsAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {!activeAccountId && (
              <MenuItem onClick={() => { navigate(routes.users.path); }}>
                <div className="settings-menu-item">
                  <FontAwesomeIcon icon={faUsers} width={20} height={20} />
                  <p>Users</p>
                </div>
              </MenuItem>
            )}

            <MenuItem onClick={() => { navigate(routes.notificationPresets.path); }}>
              <div className="settings-menu-item">
                <FontAwesomeIcon icon={faBell} width={20} height={20} />
                <p>Notification Presets</p>
              </div>
            </MenuItem>

            <MenuItem onClick={() => { navigate(routes.apiKeys.path); }}>
              <div className="settings-menu-item">
                <FontAwesomeIcon icon={faCode} width={20} height={20} />
                <p>API Keys</p>
              </div>
            </MenuItem>

            {!activeAccountId && (
              <>
                <MenuItem onClick={() => { navigate(routes.subscriptionAndUsage.path); }}>
                  <div className="settings-menu-item">
                    <FontAwesomeIcon icon={faLayerGroup} width={20} height={20} />
                    <p>Subscription & Usage</p>
                  </div>
                </MenuItem>

                <MenuItem onClick={() => { navigate(routes.billing.path); }}>
                  <div className="settings-menu-item">
                    <FontAwesomeIcon icon={faCreditCard} width={20} height={20} />
                    <p>Billing</p>
                  </div>
                </MenuItem>
              </>
            )}
          </Menu>
        </MenuItem>

        <MenuItem onClick={onLogout}>
          <div className="profile-menu-item">
            <FontAwesomeIcon icon={faArrowRightFromBracket} width={20} height={20} />
            <p>Logout</p>
          </div>
        </MenuItem>

        {!!accountsData?.length && (
        <>
          <div className="profile-menu-line" />

          <div>
            <p className="switch-workspace">
              Switch Workspace:
            </p>

            <div className="switch-workspace-accounts-list scroll">
              <div
                onClick={() => onMyAccountClick()}
                aria-hidden
                className="workspace-item-wrapper"
              >
                <div className="workspace-item">
                  <div className="default-workspace">
                    <p>MA</p>
                  </div>
                  <p>My Account</p>
                </div>

                {!activeAccountId && <FontAwesomeIcon icon={faCheck} />}
              </div>

              {accountsData.map((accountData) => {
                const isActive = accountData.account.id === activeAccountId;

                return (
                  <div
                    key={accountData.account.id}
                    onClick={() => onAccountClick(accountData)}
                    aria-hidden
                    className="workspace-item-wrapper"
                  >
                    <div className="workspace-item">
                      <div
                        className="default-workspace"
                        style={{ background: generateLinearGradientitem(accountData.account.companyName) }}
                      >
                        <p>{getLetters(accountData.account.companyName)}</p>
                      </div>
                      <p>{accountData.account.companyName}</p>
                    </div>

                    {isActive && <FontAwesomeIcon icon={faCheck} />}
                  </div>
                );
              })}
            </div>
          </div>
        </>
        )}

        <div className="profile-menu-line" />

        <div className="share-with-friends-wrapper">
          <GreenStarIcon />

          <p>Share with a friend. Earn free credits.</p>
        </div>
      </Menu>
    </div>
  );
};

AuthMenu.propTypes = {
  withName: PropTypes.bool,
};

AuthMenu.defaultProps = {
  withName: false,
};

export default React.memo(AuthMenu);
