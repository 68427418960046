import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

import { CrossIcon } from 'assets/icons';

import { IconButton } from '@mui/material';

import useOutsideClick from 'hooks/useOutsideClick';

import './styles.scss';

const Popover = ({
  title,
  description,
  forRef,
  onClose,
  placement,
  variant,
  isWithoutDescription,
}) => {
  const [tooltipRef, setTooltipRef] = useState(null);

  const { styles, attributes } = usePopper(forRef, tooltipRef, {
    ...(placement && { placement }),
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  useOutsideClick({ current: tooltipRef }, () => onClose());

  const isLight = variant === 'light';
  const tooltipBackground = isLight ? '#ffffff'
    : 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23';
  const tooltipHeaderBackground = isLight ? '#F0F3F6'
    : 'linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024';
  const color = isLight ? '#242A33EB' : '#ffffff';

  return (
    <div
      ref={setTooltipRef}
      className="tooltip"
      style={{ ...styles.popper, background: tooltipBackground, color }}
      {...attributes.popper}
    >
      <div
        style={{
          background: tooltipHeaderBackground,
          alignItems: isWithoutDescription ? 'flex-start' : 'center',
          borderRadius: isWithoutDescription ? '4px' : 0,
        }}
        className="title-container"
      >
        <p className={isWithoutDescription ? 'single-title' : 'title'}>{title}</p>

        <IconButton
          style={{ marginTop: isWithoutDescription ? '-9px' : 0 }}
          onClick={onClose}
        >
          <CrossIcon className={isLight ? 'dark-path' : 'light-path'} />
        </IconButton>
      </div>

      {!isWithoutDescription && <p className="description">{description}</p>}

      <div className="popover-arrow" data-popper-arrow style={styles.arrow} />
    </div>
  );
};

Popover.propTypes = {
  forRef: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  variant: PropTypes.oneOf([
    'light', 'dark',
  ]),
  placement: PropTypes.oneOf([
    'top-start', 'top', 'top-end',
    'right-start', 'right', 'right-end',
    'bottom-start', 'bottom', 'bottom-end',
    'left-start', 'left', 'left-end',
  ]),
  isWithoutDescription: PropTypes.bool,
};

Popover.defaultProps = {
  placement: null,
  description: null,
  variant: 'dark',
  isWithoutDescription: false,
};

export default memo(Popover);
