import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { DotsIcon, PencilIcon, TrashIcon, CardioLineIcon } from 'assets/icons';

import { monitorApi } from 'resources/monitor';

import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import DeleteModal from 'components/DeleteModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import AddOrEditIPMonitorModal from '../AddOrEditIPMonitorModal';

import './styles.scss';

const IPMonitorMenu = ({
  id,
  name,
  enabled,
  ipList,
  scheduleSeconds,
  notificationId,
  onDeleted,
  onUpdated,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const {
    mutate: updateMonitor,
    isLoading: isUpdateMonitorLoading,
  } = monitorApi.useUpdateMonitor();
  const {
    mutate: deleteMonitor,
    isLoading: isDeleteMonitorLoading,
  } = monitorApi.useDeleteMonitor();

  const openMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onMonitorStatusToggle = useCallback(() => {
    updateMonitor({
      id,
      status: enabled ? 'disabled' : 'active',
    }, {
      onSuccess: () => {
        if (onUpdated) {
          onUpdated({
            id,
            enabled: !enabled,
          });
        }

        closeMenu();
      },
    });
  }, [id, onUpdated, enabled, updateMonitor, closeMenu]);

  const onDelete = useCallback(() => {
    deleteMonitor(id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Monitor has been deleted successfully!');

        setIsDeleteModalVisible(false);
        closeMenu();

        onDeleted();
      },
    });
  }, [deleteMonitor, id, onDeleted, closeMenu]);

  const onMonitorUpdated = useCallback((data) => {
    setIsEditModalVisible(false);
    closeMenu();

    if (onUpdated) {
      onUpdated(data);
    }
  }, [onUpdated, closeMenu]);

  return (
    <>
      <DeleteModal
        title="Are you sure you want to delete this monitor and all its history?"
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={onDelete}
        isDeleteLoading={isDeleteMonitorLoading}
      />

      {isEditModalVisible && (
        <AddOrEditIPMonitorModal
          open={isEditModalVisible}
          onClose={() => { setIsEditModalVisible(false); closeMenu(); }}
          initialData={{ id, name, ipList, scheduleSeconds, notificationId }}
          onMonitorUpdated={onMonitorUpdated}
        />
      )}

      <div className={cn('menu-wrapper', anchorEl && 'menu-wrapper-active')}>
        <IconButton onClick={openMenu}>
          <DotsIcon />
        </IconButton>

        <Menu
          open={!!anchorEl}
          onClose={closeMenu}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={() => setIsEditModalVisible(true)} className="menu-item">
            <PencilIcon />
            <p>Edit</p>
          </MenuItem>
          <MenuItem onClick={() => setIsDeleteModalVisible(true)} className="menu-item" disabled={isDeleteMonitorLoading}>
            <TrashIcon />
            <p>Delete</p>
          </MenuItem>

          <div className="menu-line" />

          <MenuItem className="menu-item" disabled={isUpdateMonitorLoading}>
            <CardioLineIcon />
            <p>Status</p>

            <Switch
              checked={enabled}
              onChange={onMonitorStatusToggle}
              disabled={isUpdateMonitorLoading}
            />
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

IPMonitorMenu.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  ipList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    ipAddress: PropTypes.string,
    lastCheckFlag: PropTypes.string,
    success: PropTypes.bool,
  })).isRequired,
  scheduleSeconds: PropTypes.number.isRequired,
  notificationId: PropTypes.number.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onUpdated: PropTypes.func,
};

IPMonitorMenu.defaultProps = {
  onUpdated: null,
};

export default React.memo(IPMonitorMenu);
