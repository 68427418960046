import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import UnauthorizedLayout from 'layouts/unauthorized';

import { routes } from 'routes';

import { userApi } from 'resources/user';

import { EmailIcon, CloseCircleIcon } from 'assets/icons';

import IconButton from '@mui/material/IconButton';

import Button from 'components/Button';

import useSecondsToWait from 'hooks/useSecondsToWait';

import './styles.scss';

const EmailVerification = () => {
  const navigate = useNavigate();

  const { secondsToWait } = useSecondsToWait();

  const [errorMessage, setErrorMessage] = useState(null);

  const { mutate: resendEmail, isLoading: isResendEmailLoading } = userApi.useResendEmail();
  const email = localStorage.getItem('registrationEmail');

  const onErrorMessageClose = useCallback(() => {
    setErrorMessage(null);
  }, []);

  const onLogout = useCallback(() => {
    localStorage.clear();
    navigate(routes.signIn.path);
  }, [navigate]);

  const onResendEmail = useCallback(() => {
    resendEmail(email, {
      onError: (error) => {
        setErrorMessage(error.data['hydra:description']);
      },
    });
  }, [email, resendEmail]);

  const onCheckIsVerified = useCallback(() => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      navigate(routes.dashboard.path);

      return;
    }

    setErrorMessage('Your email address is not yet confirmed. Please check your inbox for the email confirmation link.');
  }, [navigate]);

  return (
    <UnauthorizedLayout>
      <div className="email-verification-wrapper">
        <EmailIcon />

        <p>Verify email address</p>

        <p className="text">
          We sent an email confirmation{email ? ` to ${email}` : ''}.
          Please validate your email address before continuing.
        </p>

        <div className="buttons">
          <Button variant="outlined" onClick={onResendEmail} loading={isResendEmailLoading} disabled={!!secondsToWait}>
            Resend email
            {secondsToWait && ` (${secondsToWait})`}
          </Button>
          <Button variant="outlined" onClick={onCheckIsVerified}>I verified</Button>
        </div>

        {errorMessage && (
          <div className="error-message">
            <IconButton onClick={onErrorMessageClose}>
              <CloseCircleIcon />
            </IconButton>
            <p>{errorMessage}</p>
          </div>
        )}

        <Button
          className="logout"
          onClick={onLogout}
          variant="text"
          color="secondary"
        >
          Logout
        </Button>
      </div>
    </UnauthorizedLayout>
  );
};

export default EmailVerification;
