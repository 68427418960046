/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import parse from 'html-react-parser';
import useDraggable from 'use-draggable-hook';

import { OpenedMailIcon, VerticalViewportIcon } from 'assets/icons';

import Alert from '@mui/material/Alert';

import useWindowDimensions from 'hooks/useWindowDimensions';

import replaceCustomTagWithSpan from 'pages/TemplateMXRisk/helpers/replaceCustomTagWithSpan';

import './styles.scss';

const EmailTemplate = ({ isLoading, analyzeResult, isHighlited, isEmail }) => {
  const { width } = useWindowDimensions();
  const mobileWidth = width <= 900;

  const { target: iframeViewportIconRef, position: value } = useDraggable({
    direction: 'vertical',
    maxDistance: {
      y: {
        min: -200,
        max: 900,
      },
    },
  });

  const highlightedBody = analyzeResult?.highlightedBody && replaceCustomTagWithSpan({
    text: analyzeResult?.highlightedBody,
    openTag: '<mxr-highlight>',
    closeTag: '</mxr-highlight>',
  });
  const body = (highlightedBody && (isEmail ? highlightedBody : parse(highlightedBody)))
  || analyzeResult?.body || '';

  const content = isEmail
    ? (
      <div className="email-body-iframe-wrapper">
        <iframe
          title="email-body"
          className="email-body-iframe"
          sandbox="allow-popups allow-popups-to-escape-sandbox"
          srcDoc={`${body
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
          }
          <style type="text/css">
            .mxr-highlight {
              background-color: rgba(23, 237, 195, 0.5);
            }
          </style>`}
          style={{ height: 300 + value[1] }}
        />

        <VerticalViewportIcon
          ref={iframeViewportIconRef}
          className="vertical-viewport-icon"
        />
      </div>
    )
    : (
      <div className={cn('ck-editor-wrapper', 'ck-editor-wrapper-read-only')} style={{ width: '100%' }}>
        {body}
      </div>
    );

  return (
    <div className="email-template-wrapper">
      {!mobileWidth && (
      <div className="email-template-sidebar">
        <div className="logo">
          <OpenedMailIcon />
          <p>Mailbox</p>
        </div>

        <div className="item-list">
          {new Array(6).fill().map((_, index) => (
            <div className={cn(index === 0 && 'first-item-list')} key={index}>
              <div>
                <div />
                <div />
              </div>

              {index === 0 && <div className="last-selected-item-list" />}
            </div>
          ))}
        </div>
      </div>
      )}

      <div className={cn('email-container', isEmail && 'email-container-iframe')}>
        {isHighlited && (
        <Alert
          className="alert"
          severity="info"
        >
          Highlighted text is used to indicate sample data for merge tags in your template.
        </Alert>
        )}

        <div className="email-header">
          {[
            {
              label: 'Subject',
              value: (
                <p>{analyzeResult?.highlightedSubject ? parse(replaceCustomTagWithSpan({
                  text: analyzeResult?.highlightedSubject,
                  openTag: '<mxr-highlight>',
                  closeTag: '</mxr-highlight>',
                })) : (analyzeResult?.subject || '')}
                </p>),
              loadingTextWidth: width <= 1000 ? 200 : 371,
            },
            {
              label: 'From',
              value: <p>{analyzeResult?.from}</p>,
              loadingTextWidth: 189,
            },
            {
              label: 'To',
              value: 'Recipient’s email address',
              loadingTextWidth: 160,
            },
          ].map((item) => (
            <div key={item.label} className="email-header-item">
              <p>{item.label}</p>

              {isLoading
                ? <div className="loading-item" style={{ width: item.loadingTextWidth }} />
                : <div className="email-header-item-value">{item.value}</div>}
            </div>
          ))}
        </div>

        <div className="email-text">
          {isLoading
            ? [44, 102, 122, 18, 146, 66, 198, 78, 76, 191, 30,
              97, 128, 42, 133, 122, 44, 66, 198, 120, 184].map((itemWidth, index) => (
                <p className="loading-item" style={{ width: itemWidth }} key={index} />
            ))
            : content}
        </div>
      </div>
    </div>
  );
};

EmailTemplate.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  analyzeResult: PropTypes.shape(),
  isHighlited: PropTypes.bool,
  isEmail: PropTypes.bool,
};

EmailTemplate.defaultProps = {
  analyzeResult: null,
  isHighlited: false,
  isEmail: false,
};

export default React.memo(EmailTemplate);
