import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import AuthorizedLayout from 'layouts/authorized';

import { subscriptionApi } from 'resources/subscription';
import { accountApi } from 'resources/account';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCheck as faRegularCheck } from '@fortawesome/pro-regular-svg-icons';

import Button from 'components/Button';

import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';

import { checkSelectItems } from 'pages/IPMonitor/components/AddOrEditIPMonitorModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import PricingPlanErrorModal from './components/PricingPlanErrorModal';

import './styles.scss';

const PricingPlans = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [isPricingPlanErrorModalVisible, setIsPricingPlanErrorModalVisible] = useState(false);
  const [switchPlanErrorData, setSwitchPlanErrorData] = useState(null);

  const {
    data: subscriptionTemplates,
    isLoading: isSubscriptionTemplatesLoading,
  } = subscriptionApi.useSubscriptionTemplates();
  const {
    data: account,
    isLoading: isAccountLoading,
  } = accountApi.useAccount();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    refetch: refetchSubscription,
  } = subscriptionApi.useSubscription(account?.subscription
    ? +account.subscription.replace('/v2/subscriptions/', '')
    : null);
  const isLoading = isSubscriptionTemplatesLoading || isAccountLoading || isSubscriptionLoading;

  const {
    mutate: changeSubscription,
    isLoading: isChangeSubscriptionLoading,
  } = subscriptionApi.useChangeSubscription();

  const isMonthlySubscription = activeTabIndex === 0;

  const filteredSubscriptionTemplates = isMonthlySubscription
    ? subscriptionTemplates?.filter((subscriptionTemplate) => subscriptionTemplate.monthlyPriceId
    || subscriptionTemplate.free)
    : subscriptionTemplates?.filter((subscriptionTemplate) => subscriptionTemplate.annualPriceId);

  const onUpgradeClick = useCallback((pricingPlan) => {
    changeSubscription({
      templateIri: pricingPlan['@id'],
      annual: !isMonthlySubscription,
    }, {
      onSuccess: async () => {
        refetchSubscription().then(({ data }) => {
          if (data.paymentLink) {
            window.open(data.paymentLink, '_blank');

            return;
          }

          uiNotificationService.showSuccess('Subscription plan has been updated');
        });
      },
      onError: (error) => {
        setIsPricingPlanErrorModalVisible(true);
        setSwitchPlanErrorData(error.data);
      },
    });
  }, [isMonthlySubscription, changeSubscription, refetchSubscription]);

  const onDowngradePlan = useCallback(() => {
    const freePricingPlan = subscriptionTemplates.find((subscriptionTemplate) => subscriptionTemplate.free);

    changeSubscription({
      templateIri: freePricingPlan['@id'],
      annual: false,
    }, {
      onSuccess: () => {
        uiNotificationService.showSuccess('Subscription plan has been canceled');
      },
      onError: (error) => {
        setIsPricingPlanErrorModalVisible(true);
        setSwitchPlanErrorData(error.data);
      },
    });
  }, [changeSubscription, subscriptionTemplates]);

  return (
    <AuthorizedLayout>
      {isPricingPlanErrorModalVisible
      && (
      <PricingPlanErrorModal
        open={isPricingPlanErrorModalVisible}
        onClose={() => {
          setIsPricingPlanErrorModalVisible(false);
          setSwitchPlanErrorData(null);
        }}
        violations={switchPlanErrorData?.violations}
      />
      )}

      <div className="pricing-plans-wrapper">
        <p className="title">Pricing plans</p>

        <p className="subtitle">Simple, transparent pricing that grows with you. Try any plan free for 30 days.</p>

        {isLoading && <CircularProgress style={{ margin: 'auto' }} />}

        {!isLoading && (
          <div className="pricing-plans-content">
            <Tabs
              className="tabs"
              value={activeTabIndex}
              onChange={(_, value) => setActiveTabIndex(value)}
              centered
            >
              <Tab label="Monthly billing" />
              <Tab label={(
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  <p>Annual billing</p>
                  <Chip
                    label="Save 15%"
                    color="primary"
                    variant="outlined"
                    style={{ height: 24, fontSize: 12, fontWeight: 500 }}
                  />
                </div>
                )}
              />
            </Tabs>

            <div className="pricing-plans">
              {filteredSubscriptionTemplates.map((pricingPlan) => {
                const price = isMonthlySubscription
                  ? pricingPlan.monthlyPrice
                  : pricingPlan.annualPrice;

                const isActive = pricingPlan['@id'] === subscription?.subscriptionTemplate
                && (pricingPlan.free
                  || (subscription.monthlyPrice && isMonthlySubscription)
                  || (subscription.annualPrice && !isMonthlySubscription));

                return (
                  <div
                    className={cn('pricing-plan', pricingPlan.isMostPopular && 'pricing-plan-popular')}
                    key={pricingPlan.id}
                  >
                    <div className="pricing-plan-title">
                      <div className="most-popular-pricing-plan-title">
                        <p>{pricingPlan.title}</p>

                        {pricingPlan.isMostPopular && (
                        <Chip
                          label="Most Popular"
                          color="primary"
                          variant="outlined"
                          style={{ height: 24, fontSize: 13 }}
                        />
                        )}
                      </div>

                      <p>{pricingPlan.description}</p>

                      <p>{pricingPlan.free
                        ? 'Free'
                        : `$${(price / 100).toFixed(2)}/${isMonthlySubscription ? 'mo' : 'year'}`}
                      </p>
                    </div>

                    <div className="pricing-plan-features">
                      <p>FEATURES</p>

                      <div className="feature-list">
                        {pricingPlan.featureGroups
                          .reduce((acc, featureGroup) => [...acc, ...featureGroup.items], [])
                          .map((feature) => {
                            const isFrequency = feature.type === 'Frequency';
                            const isQuantity = feature.type === 'Quantity';
                            const isExists = feature.quantityInit > 0 || isFrequency;

                            return (
                              <div className="feature" key={feature.name}>
                                {isExists
                                  ? (
                                    <FontAwesomeIcon
                                      fontSize={14}
                                      icon={faCheck}
                                      color="#17EDC3"
                                    />
                                  )
                                  : (
                                    <FontAwesomeIcon
                                      fontSize={14}
                                      icon={faXmark}
                                      color="rgba(255, 255, 255, 0.3)"
                                      style={{ marginRight: 4 }}
                                    />
                                  )}

                                <div>
                                  <p style={!isExists ? { color: 'rgba(255, 255, 255, 0.5)' } : {}}>
                                    {feature.quantityInit > 0
                                      ? `${feature.quantityInit}${isQuantity ? '/mo' : ''} `
                                      : ''}{feature.name}
                                  </p>

                                  {isFrequency && (
                                  <div className="frequancy-wrapper">
                                    {feature.frequency.map((seconds) => (
                                      <p key={seconds}>- {checkSelectItems
                                        .find((selectItem) => selectItem.value === seconds)
                                        .label}
                                      </p>
                                    ))}
                                  </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="line" />

                      <div className="action-button-wrapper">
                        {isActive && (
                        <div>
                          <Button
                            className="active-button"
                            variant="outlined"
                            startIcon={(
                              <FontAwesomeIcon
                                fontSize={16}
                                icon={faRegularCheck}
                                color="#17EDC3"
                              />
                            )}
                            iconClassName="button-icon"
                          >
                            Active
                          </Button>

                          {!pricingPlan.free && (
                          <Link
                            href="#"
                            underline="hover"
                            color="rgba(255, 255, 255, 0.7)"
                            style={{ display: 'block', textAlign: 'center', marginTop: 8, fontSize: 12 }}
                            onClick={onDowngradePlan}
                          >
                            Downgrade Plan
                          </Link>
                          )}
                        </div>
                        )}

                        {!isActive && !pricingPlan.isProfessional && (
                        <Button
                          className="upgrade-button"
                          disabled={isChangeSubscriptionLoading}
                          onClick={() => onUpgradeClick(pricingPlan)}
                        >
                          Upgrade
                        </Button>
                        )}

                        {!isActive && pricingPlan.isProfessional && (
                        <Button
                          className="contact-button"
                          variant="outlined"
                        >
                          Contact Us
                        </Button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default PricingPlans;
