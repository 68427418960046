import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme, _params, getRef) => ({
  control: {
    display: 'block',
    width: '100%',
    height: 48,
    padding: '8px 16px 8px 20px',
    color: '#FFFFFF',
    fontSize: 15,
    WebkitFontSmoothing: 'antialiased',

    '& .mantine-Text-root': {
      marginLeft: 12,
    },

    '& svg': {
      color: 'rgba(255, 255, 255, 0.7)',
      width: 20,
    },

    '&:hover': {
      backgroundColor: '#31323e',
    },
  },

  activeControl: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    '& svg': {
      color: '#FFFFFF',
    },
  },

  link: {
    display: 'flex',
    height: 38,
    padding: '8px 16px 8px 32px',
    color: '#ccc',
    backgroundColor: 'rgba(20, 22, 35, 1)',
    fontSize: 15,
    WebkitFontSmoothing: 'antialiased',
    textAlign: 'left',
    cursor: 'pointer',

    '& svg': {
      color: 'rgba(255, 255, 255, 0.7)',
      width: 20,
    },

    '&:hover': {
      color: '#FFF',
      backgroundColor: '#31323e',

      [`& .${getRef('menu')}`]: {
        visibility: 'visible',
      },
    },
  },

  activeLink: {
    color: '#17EDC3',
  },

  chevron: {
    transition: 'transform 200ms ease',
  },

  addFolder: {
    width: 24,
    height: 24,
    borderRadius: 4,

    '& svg': {
      color: 'rgba(255, 255, 255, 0.56)',
    },

    '&:hover': {
      backgroundColor: 'rgba(23, 237, 195, 0.08)',

      '& svg': {
        color: theme.colors.primary[4],
      },
    },
  },

  menu: {
    ref: getRef('menu'),
    width: 24,
    height: 24,
    borderRadius: 4,
    visibility: 'hidden',
  },

  label: {
    maxWidth: 132,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
