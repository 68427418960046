import queryClient from 'query-client';

const setSecondsToWait = (initialSecondsToWait, query) => {
  const queryString = query || 'secondsToWait';

  queryClient.setQueryData([queryString], initialSecondsToWait);

  const timer = setInterval(() => {
    const secondsToWait = queryClient.getQueryData([queryString]) - 1;

    if (secondsToWait > 0) {
      queryClient.setQueryData([queryString], secondsToWait);
    } else {
      queryClient.setQueryData([queryString], null);
      localStorage.removeItem(queryString);
      clearInterval(timer);
    }
  }, 1000);
};

export default setSecondsToWait;
