import { useQuery } from 'react-query';

import api from 'services/api.service';

export function useEmailChecksCount({
  from,
  to,
}) {
  const getEmailChecksCount = () => api.get('/v2/dashboard/email-checks-count', { from, to });

  return useQuery(['dashboard.emailChecksCount', { from, to }], getEmailChecksCount);
}

export function useIPChecksCount({
  from,
  to,
}) {
  const getIPChecksCount = () => api.get('/v2/dashboard/ip-checks-count', { from, to });

  return useQuery(['dashboard.ipChecksCount', { from, to }], getIPChecksCount);
}

export function useMXRiskTemplateChecksCount({
  from,
  to,
}) {
  const getMXRiskTemplateChecksCount = () => api.get('/v2/dashboard/mx-risk-templates-count', { from, to });

  return useQuery(['dashboard.mxriskTempaleChecksCount', { from, to }], getMXRiskTemplateChecksCount);
}

export function useEmailMXRiskChecksCount({
  from,
  to,
}) {
  const getEmailMXRiskChecksCount = () => api.get('/v2/dashboard/mx-risk-emails-count', { from, to });

  return useQuery(['dashboard.emailMXRiskChecksCount', { from, to }], getEmailMXRiskChecksCount);
}

export function useIPScansOverview({ from, to }) {
  const itemsPerPage = 50;

  const getIPScansOverview = () => api.get('/v2/dashboard/ip-scans-overview', {
    page: 1,
    itemsPerPage,
    from,
    to,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (pageNumber) => api.get('/v2/dashboard/ip-scans-overview', {
          page: pageNumber,
          itemsPerPage,
          from,
          to,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['dashboard.ipScans', { from, to }], getIPScansOverview);
}

export function useEmailScansSummary({
  from,
  to,
}) {
  const getEmailScansSummary = () => api.get('/v2/dashboard/email-scans-summary', { from, to });

  return useQuery(['dashboard.emailScansSummary', { from, to }], getEmailScansSummary);
}

export function useIPScansList({
  from, to,
}) {
  const getIPScansList = () => api.get('/v2/dashboard/ip-scans-list', {
    from,
    to,
  })
    .then((data) => data['hydra:member']);

  return useQuery(['dashboard.ipMonitorChecks', { from, to }], getIPScansList);
}

export function useInfrastructureScansList({
  from, to,
}) {
  const getInfrastructureScansList = () => api.get('/v2/dashboard/infrastructure-scans-list', { from, to })
    .then((data) => data['hydra:member']);

  return useQuery(['dashboard.infrastructureScansList', { from, to }], getInfrastructureScansList);
}

export function useEmailAccountsOveriverview({ from, to }) {
  const getEmailAccountsOverview = () => api.get('/v2/dashboard/email-accounts-overview', { from, to });

  return useQuery(['dashboard.emailAccountsOverview', { from, to }], getEmailAccountsOverview);
}

export function usePlacementTestsOverview({ from, to }) {
  const getPlacementTestsOverview = () => api.get('/v2/dashboard/placement-tests-overview', { from, to });

  return useQuery(['dashboard.placementTestsOverview', { from, to }], getPlacementTestsOverview);
}
