import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LoadingButton from '@mui/lab/LoadingButton';

import './styles.scss';

const Button = ({
  children,
  className,
  iconClassName,
  startIcon,
  variant,
  color,
  disabled,
  onClick,
  loading,
}) => (
  <LoadingButton
    variant={variant}
    className={cn('button', variant === 'text' && 'text', className)}
    color={color}
    disabled={disabled}
    onClick={onClick}
    loading={loading}
  >
    {startIcon && <div className={cn('start-icon', iconClassName)}>{startIcon}</div>}
    {children}
  </LoadingButton>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  startIcon: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  iconClassName: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  startIcon: null,
  variant: 'contained',
  color: undefined,
  disabled: false,
  onClick: null,
  loading: false,
  iconClassName: null,
};

export default React.memo(Button);
